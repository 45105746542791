import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import { Button, Select } from "antd";
import { Input } from "formik-antd";
import MultiStepFormContext from "./MultiStepFormContext";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Badge,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Table,
  Row,
  Col,
  Modal,
  Pagination,
  Dropdown,
  ListGroup,
} from "react-bootstrap";
import axios from "axios";
import { Option } from "antd/es/mentions";
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";
const General = () => {
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const userData = JSON.parse(localStorage.getItem("userInfo"));

  const [data, setData] = useState({
    district: "",
    state: "",
  });
  const [searchLocation, setsearchLocation] = useState({
    pincode: "",
  });

  const fetchLocation = async () => {
    axios
      .get(`https://api.postalpincode.in/pincode/${searchLocation.pincode}`)
      .then((res) => {
        setData({
          ...data,
          state: res.data[0].PostOffice[0].State,
          district: res.data[0].PostOffice[0].District,
        });
      })
      .catch((err) => {
        setData({
          ...data,
          state: "",
          district: "",
        });
        // document.getElementById("pincode").className = "error";
        // setPincodeData({
        //   error: "Invalid PIN Code"
        // });
      });
  };
  const handleChange = (e) => {
    // regex for pan card number
    const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
    console.log(panRegex.test(e.target.value));
    setFormData({
      ...formData,
      "panNumber": e.target.value,
    });
    if (!panRegex.test(e.target.value)) {
      setErrors(prev=>({...prev,pan:"Please enter valid pan card number"}));
    } else {
      setErrors(prev=>({...prev,pan:""}));
    }
  };
  const handleChangeWebsite = (e) => {
    // regex for pan card number
    const websiteRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    console.log(websiteRegex.test(e.target.value));
    setFormData({
      ...formData,
      "websiteUrl": e.target.value,
    });
    if (!websiteRegex.test(e.target.value)) {
      setErrors(prev=>({...prev,website:"Please enter valid website"}));
    } else {
      setErrors(prev=>({...prev,website:""}));
    }
  }
  useEffect(() => {
    fetchLocation();
  }, [searchLocation]);
  // get details, setDetails and next from the MultiStepFormContext
  const { general, setGeneral, next } = useContext(MultiStepFormContext);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const handleSubmit = () => {
    console.log(formData);
    axios.patch(`${baseServerUrl}/updategeneral`,formData,{
      headers:{
        Authorization:`${userData.token}`
      }
    }).then((response)=>{
      console.log(response.data);
      toast.success("General Updated Successfully");
      next();
    }).catch((error)=>{
      console.log(error);
      toast.error("General Updation Failed");
    })
  }

  return (
    <Col lg="12" md="10" sm="8" className="ps-2 pe-3">
      <ToastContainer />
    <div className="">
      <Row>
        <div className="fs-6 text-black d-flex gap-3 mt-3">
          
        <p style={{color:"#2A3FA0",borderRight:"1px solid black",paddingRight:"10px"}}><IoMdMail/>  : {userData.email}</p>
         <p style={{color:"#2A3FA0"}}><FaPhone/> : {userData.phone}</p>
         {/* <p style={{color:"#2A3FA0"}}>{userData}</p> */}

        </div>
      </Row>
      <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0"}}></div>
    <Row>
              <Col className="pr-1" md="11.5">
                <div
                  className={`form__item ${
                    errors.company && "input__error"
                  }`}
                >
                  {/* <p>{JSON.stringify(formData}</p> */}
                  <div className="form-control">
                    <input
                      type="text"
                      name="organisation"
                      className="form-input"
                      placeholder="none"
                      value={formData.organisation}
                      onChange={(e)=>{
                        setFormData({
                          ...formData,
                          organisation:e.target.value
                        })
                      }}
                      required
                    />
                    <label for="name" className="form-label">
                      Organisation *
                    </label>
                  </div>
                  <p className={"error__feedback"}>{errors.company}</p>
                </div>
              </Col>
              <Col className="pr-1" md="3">
                <div
                  className={`form__item ${errors.name && "input__error"}`}
                >
                  <div className="form-control">
                    <select
                      name={"legalStatus"}
                      placeholder="choose Legal Status"
                      className="form-input"
                      style={{ width: "100%" }}
                      value={formData.legalStatus}
                      onChange={(e)=>{
                        setFormData({
                          ...formData,
                          legalStatus:e.target.value
                        })   
                      }}
                    >
                      <option>Choose Status</option>
                      <option value={"Proprietorship"}>Proprietorship</option>
                      <option value={"Partnership"}>Partnership Firm</option>
                      <option value={"Company"}>Company </option>
                      <option value={"LLP"}>LLP</option>
                      <option value={"Trust/AoP/Other"}>Trust/AoP/Others</option>
                    </select>
                    <label for="name" className="form-label">
                      Legal Status *
                    </label>
                  </div>
                  <p className={"error__feedback"}>{errors.name}</p>
                </div>
              </Col>

                <Col className="position-relative" md="3">
                  
                <p className="text-black bg-white position-absolute" style={{top:"-13px",right:"35%",zIndex:99}}>Date of Incorporation *</p>
               <div className="border rounded p-1">
                    {/* <DatePicker
                     showIcon
                     selected={new Date()}
                     value={new Date(formData.dateOfIncorporation?.split("T")[0])}
                     name="dateOfIncorporation"
                     onChange={(date) => {setFormData({
                        ...formData,
                        dateOfIncorporation: date.toISOString()
                     })}}/> */}
                     <input
                      type="date"
                      name="dateOfIncorporation"
                      className="form-input border"
                      value={formData.dateOfIncorporation?.split("T")[0]}
                      onChange={(e)=>{
                        setFormData({
                          ...formData,
                          dateOfIncorporation:e.target.value
                        })
                      }}
                      required
                     />
                   </div>
                </Col>
                
                <Col className="pl-1" md="6">
                <div
                  className={`form__item ${errors.website && "input__error"}`}
                >
                  <div className="form-control" style={{marginBottom:"0"}}>
                    <input
                      type="text"
                      name="websiteUrl"
                      className="form-input"
                      placeholder="none"
                      onChange={(e)=>handleChangeWebsite(e)}
                      value={formData.websiteUrl}
                      required
                    />
                    <label for="name" className="form-label">
                      Website *
                    </label>
                  </div>
                  <p className={"error__feedback"}>{errors.website}</p>
                </div>
              </Col>
            </Row>
            <Row>
    
         
            <Col className="pr-1" md={formData.msmeStatus == "Yes" ? "3" : "4"}>
              <div className={`form__item ${errors.MSME_status && "input__error"}`}>
                <div className="form-control">
           <select className="form-input" value={formData.msmeStatus} onChange={(e)=>{
              setFormData({
                ...formData,
                msmeStatus:e.target.value
              })
           }}>
            <option value="">MSME Status</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
           </select>
                  <label htmlFor="name" className="form-label">MSME Status *</label>
                </div>
                <p className={"error__feedback"}>{errors.MSME_status}</p>
              </div>
            </Col> 
            {formData.msmeStatus==='Yes' && (
              
              <Col className="pl-1" md="3">
              <div className={`form__item ${errors.pan && "input__error"}`}>
                <div className="form-control">
                  <input type="file" name="" className="form-input" required />
                  <label for="name" className="form-label">MSME</label>
                </div>
                <p className={"error__feedback"}>{errors.msme}</p>
              </div>
            </Col>
            )}
            
              <Col className="pl-1" md={formData.msmeStatus == "Yes" ? "3" : "4"}>
              <div className={`form__item ${errors.pan && "input__error"}`}>
                <div className="form-control" style={{marginBottom:0}}>
                  <input onChange={(e)=>handleChange(e)} value={formData.panNumber} name="panNumber" className="form-input" placeholder="none" required />
                  <label for="name" className="form-label">PAN Number *</label>
                </div>
                <p className={"error__feedback"}>{errors.pan}</p>
              </div>
            </Col>
              <Col className="pl-1" md={formData.msmeStatus == "Yes" ? "3" : "4"}>
              <div className={`form__item ${errors.pan && "input__error"}`}>
                <div className="form-control">
                  <input type="file" name="authorised_person" className="form-input" placeholder="none" required />
                  <label for="name" className="form-label">PAN Card Copy</label>
                </div>
                {/* <p className={"error__feedback"}>{errors.pan}</p> */}
              </div>
            </Col>
            </Row>
            {/* <Row>
              <Col className="pl-1" md="2">
                <div
                  className={`form__item ${
                    errors.pincode && "input__error"
                  }`}
                >
                  <div className="form-control">
                    <input
                      className="form-input"
                      id="pincode"
                      style={{ "line-height": "30px" }}
                      placeholder="Enter pin code"
                      type="text"
                      onChange={(e) =>
                        handleChanageFilter("pincode", e.target.value)
                      }
                      required
                    />
                    <label for="name" className="form-label">
                      Postal Code *
                    </label>
                  </div>

                  <p className={"error__feedback"}>{errors.pincode}</p>
                </div>
              </Col>
              <Col className="pr-1" md="5">
                <div
                  className={`form__item ${errors.state && "input__error"}`}
                >
                  <div className="form-control">
                    <input
                      type="String"
                      placeholder=" "
                      readonly
                      value={data.state}
                      class="inp form-input"
                      required
                    />
                    <label for="name" className="form-label">
                      {" "}
                      State *
                    </label>
                  </div>
                  <p className={"error__feedback"}>{errors.state}</p>
                </div>
              </Col>
              <Col className="pr-1" md="5">
                <div
                  className={`form__item ${errors.city && "input__error"}`}
                >
                  <div className="form-control">
                    <input
                      type="String"
                      placeholder=""
                      readonly
                      value={data.district}
                      class="inp form-input"
                      required
                    />
                    <label for="name" className="form-label">
                      {" "}
                      District *
                    </label>
                  </div>
                  <p className={"error__feedback"}>{errors.city}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div
                  className={`form__item ${
                    errors.address1 && "input__error"
                  }`}
                >
                  <div className="form-control">
                    <input
                      type="text"
                      name="website"
                      className="form-input"
                      placeholder="none"
                      required
                    />
                    <label for="name" className="form-label">
                      Address Line 1 *
                    </label>
                  </div>
                  <p className={"error__feedback"}>{errors.address1}</p>
                </div>
              </Col>
            </Row> */}
      <div style={{height:"1px",backgroundColor:"gray",margin:"10px 0"}}></div>

      <div
              className={
                "form__item button__items d-flex justify-content-between mt-4"
              }
            >
              {/* Render a "Back" button that moves to the previous step in the form */}
              {/* Render a "Next" button that submits the form data and moves to the next step in the form */}
              <div className="d-flex gap-2">
              <Button type={"primary"} onClick={handleSubmit} size="large">
                Submit & Next
              </Button>
              <Button type={"secondary"} className="border border-primary text-primary bg-white" onClick={()=>next()} size="large">
                Skip
              </Button>
              </div>
            </div>
            </div>
  </Col>
  );
};

export default General;
