import React from 'react';
// import { Link } from 'react-router-dom';
import './css/sl-benefit.css';
import seller from '../../assets/images/seller.png';
import dealers from '../../assets/images/dealer.png';
import sl from '../../assets/images/resource/sl-benefit.svg';

const SlBenefitSec = () => {

    return (
        <section className="sl-benefitSec alternat-2">
            <div className="titleSec mb_30 text-center">
                <h2>Experience Unparalleled Benefits</h2>
                <p>Tailored Solutions for Scrap Sellers and Dealers, Delivering Maximum Value and Efficiency</p>
            </div>
            <div className="sl-benefitInner">
                <div className="auto-container">
                    <div className="row clearfix">
                        
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className='seller'>
                                <div>
                                    <img src={seller} alt="seller-bidder" className='sl' />
                                </div>
                                <h3 className='mb-3'>For Scrap Sellers</h3>
                                <div className='sellerList'>
                                    <p>Maximise revenue from scrap</p>
                                    <p>Reduce disposal costs</p>
                                    <p>Controlled, compliance-assured environment</p>
                                    <p>Meet disposal timelines</p>
                                    <p>Minimise disruption to routine activities</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-0 col-0 sellerImgSec">
                            <div className='sellerImgBox'>
                                <img src={sl} alt="seller-bidder" className='sellerImg' />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className='dealer'>
                                <div>
                                    <img src={dealers} alt="seller-bidder" className='sl' />
                                </div>
                                <h3 className='mb-3'>For Scrap Dealers</h3>
                                <div className='sellerList'>
                                    <p>Streamlined process</p>
                                    <p>Clearly defined scope of work</p>
                                    <p>Comprehensive information for bidding</p>
                                    <p>Fair competition advantage</p>
                                    <p>Support with all compliance matters</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SlBenefitSec;
