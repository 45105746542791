import React from "react";
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import './css/index.css';
import Header from '../../components/header';
import Footer from '../../components/footer';
import img1 from '../../assets/images/gallery/images-1.webp';
import img2 from '../../assets/images/gallery/images-2.webp';
import img3 from '../../assets/images/gallery/images-3.webp';
import img4 from '../../assets/images/gallery/images-4.webp';
import img5 from '../../assets/images/gallery/images-5.webp';
//import img6 from '../assets/images/gallery/images-6.webp';
import ListOfIndustries from './ListOfIndustries';

const Services = () => {
    // const services = [
    //     {
    //         id: '1',
    //         img: img1,
    //         title: "Raw Material Suppliers",
    //         desc: "These industries procure and supply raw materials to various sectors."
    //     },
    //     {
    //         img: img2,
    //         title: "Logistics and Transportation",
    //         desc: "Logistics and transportation industries move raw materials efficiently for manufacturing."
    //     },
    //     {
    //         img: img3,
    //         title: "Quality Control",
    //         desc: "Ensuring raw materials meet specifications before use through quality control measures. "
    //     },
    //     {
    //         img: img4,
    //         title: "Testing and Certification",
    //         desc: "Testing and certification industries verify raw material quality, purity, and compliance."
    //     },
    //     {
    //         img: img5,
    //         title: "Market Research and Consulting",
    //         desc: "Market research and consulting offer insights on raw material trends and pricing."
    //     },
    //     {
    //         img: img6,
    //         title: "Risk Management and Insurance",
    //         desc: "Risk management and insurance industries protect businesses from raw material risks."
    //     },
    // ]
    return (
        <>
        <Header />
        <div>
            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Our Services</li>
                        </ul>
                    </div>
                </div>
            </section>

           

            {/* <section className="service-page-section centred">
                <div className="auto-container">
                    <div className="titleSec mb_50">
                        <h2>A wide range of waste get max value services</h2>
                    </div>
                    <div className="row clearfix">
                        {services.map((item, index) => (
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 feature-block" key={index}>
                                <div className="feature-block-two wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div className="inner-box">
                                        <div className="icon-box">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="content-box">
                                            <h4>{item.title}</h4>
                                            <p>{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}

            <section className="servicesBox centred">
                <div className="auto-container">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                        <div className="titleSec mb_50 text-center">
                            <h2 className="title text-center mb-4"> Services for Each Industry</h2>
                        </div>
                    </div>
                    <Tab.Container id="nested-inner-tabs" defaultActiveKey="key1">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-5 col-sm-12 side1">
                                <div className="srvSidebar">
                                    <div className="widget-content">
                                        <Nav variant="pills" className="flex-column ul">
                                            <Nav.Item className='li'>
                                                <Nav.Link eventKey="key1"><span>Raw Material Suppliers</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='li'>
                                                <Nav.Link eventKey="key2"><span>Logistics and Transportation</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='li'>
                                                <Nav.Link eventKey="key3"><span>Quality Control</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='li'>
                                                <Nav.Link eventKey="key4"><span>Testing and Certification</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='li'>
                                                <Nav.Link eventKey="key5"><span>Market Research and Consulting</span></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-12 col-12">
                                <div className='serviceBox'>
                                    <div className="srvInnerBlock mb_35 text-start wow fadeInUp animated tilt-effect" data-wow-delay="00ms" data-wow-duration="1500ms">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="key1" className="row">
                                                <div className="inner">
                                                    <div className="thumb col-lg-4">
                                                        <img src={img1} alt="" />
                                                    </div>
                                                    <div className="content col-lg-8">
                                                        <h4 className="pb-3">Banks and FIs</h4>
                                                        <p>GetMaxValue offers invaluable assistance to banks and financial institutions by streamlining the management of assets from Non-Performing Accounts (NPAs). Our expertise in scrap management allows banks to efficiently auction properties such as land and buildings, as well as inventory items. With our transparent E-Auction process and verified vendor network, banks and FIs can maximise returns on these assets while ensuring compliance with regulatory requirements. </p>
                                                        <p>We handle all documentation intricacies, making the process smooth and hassle-free. Banks and FIs who partner with GetMaxValue can easily transform idle assets into valuable resources, optimising their financial portfolio.</p>
                                                    </div>
                                                    <div class="top-circle-shape"></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="key2">
                                                <div className="inner">
                                                    <div className="thumb col-lg-4">
                                                        <img src={img2} alt="" />
                                                    </div>
                                                    <div className="content col-lg-8">
                                                        <h4 className="pb-3">Insurance Companies</h4>
                                                        <p>Insurance companies benefit significantly from GetMaxValue's services, especially when it comes to dealing with damaged or claimed items. We provide a platform that simplifies the liquidation process, helping insurers efficiently sell items that are no longer viable. </p>
                                                        <p>Our transparent auction procedures ensure that insurance companies get the best possible value for these items while adhering to compliance regulations. With our assistance, insurers can expedite claims processes and maximise returns, ultimately enhancing their operational efficiency and profitability.</p>
                                                    </div>
                                                    <div class="top-circle-shape"></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="key3">
                                                <div className="inner">
                                                    <div className="thumb col-lg-4">
                                                        <img src={img3} alt="" />
                                                    </div>
                                                    <div className="content col-lg-8">
                                                        <h4 className="pb-3">Manufacturers</h4>
                                                        <p>Manufacturers regularly generate scrap during their production processes, and GetMaxValue offers a seamless solution for selling this scrap profitably. We simplify the entire process, from verification and valuation to vendor selection and E-Auction. </p>
                                                        <p>Manufacturers can focus on their core operations while we manage the intricacies of scrap disposal. Our expertise ensures that manufacturers get the best prices for their scrap, turning what was once considered waste into a valuable revenue stream. Manufacturers who partner with us can efficiently optimise their resource management and improve their bottom line.</p>
                                                    </div>
                                                    <div class="top-circle-shape"></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="key4" className="row">
                                                <div className="inner">
                                                    <div className="thumb col-lg-4">
                                                        <img src={img4} alt="" />
                                                    </div>
                                                    <div className="content col-lg-8">
                                                        <h4 className="pb-3">Commercial and Industrial Premises</h4>
                                                        <p>Companies vacating, dismantling and restructuring commercial and industrial premises often face the challenge of efficiently managing and monetising scrap generated during the process. GetMaxValue steps in to provide comprehensive hassle-free solutions. </p>
                                                        <p>We verify and value assets, handle all documentation, and select trusted vendors to ensure a smooth transition. Our transparent E-Auction process secures the best prices for the scrap, allowing companies to maximize their returns. Our expertise helps businesses to efficiently manage scrap generated during premises changes or restructurings, minimising disruptions and financial losses.</p>
                                                    </div>
                                                    <div class="top-circle-shape"></div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="key5">
                                                <div className="inner">
                                                    <div className="thumb col-lg-4">
                                                        <img src={img5} alt="" />
                                                    </div>
                                                    <div className="content col-lg-8">
                                                        <h4 className="pb-3">Liquidators</h4>
                                                        <p>Liquidators play a critical role in selling assets of businesses going through insolvency or restructuring. GetMaxValue's services empower liquidators to expedite asset liquidation. We provide access to a verified listing of scrap dealers, simplifying vendor selection. </p>
                                                        <p>Our transparent auction procedures guarantee fair prices, ensuring that liquidators can maximise returns for creditors. Partnering with GetMaxValue enables liquidators to efficiently manage the scrap disposal process, enhance transparency, and fulfil their fiduciary responsibilities to stakeholders.</p>
                                                    </div>
                                                    <div class="top-circle-shape"></div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </section>

        </div>
        <ListOfIndustries />
    <Footer />
    </>
    )
}
export default Services;