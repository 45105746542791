import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Card,
  Badge,
  Alert,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
import { FaFileAlt, FaImages, FaVideo } from "react-icons/fa";
import BaseService from "../../config/axiosService";
import { useParams } from "react-router-dom";
import Momemt from "react-moment";

function ProjectMoreDetails() {
  const [showMessage, setShowMessage] = useState(false);
  const [notInterested, setNotInterested] = useState(false);
  const { id } = useParams();

  const [projectDetails, setProjectDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDetails = async () => {
    setLoading(true);
    // Fetch project details
    try {
      const response = await BaseService.get(`/api/v2/project/auction/${id}`);
      console.log(response.data);

      setProjectDetails(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);

  return (
    <>
      <Topbar />
      <Container fluid>
        <Row>
          <Col lg="2" md="2" sm="4" style={{ background: "#2A3FA0" }}>
            <Sidebar />
          </Col>
          {!loading && projectDetails && (
            <Col lg="10" md="10" sm="8" className="">
              <Card className="mb-4">
                <Card.Body>
                  <div className="mb-3">
                    <small className="text-muted fs-5">
                      Projects &gt; New offerings &gt; more information
                    </small>
                    <h2
                      style={{
                        color: "#2A3FA0",
                      }}
                      className="mt-2"
                    >
                      More information about the project
                    </h2>
                  </div>

                  <h6
                    className="p-2 text-white rounded-3 my-2"
                    style={{
                      backgroundColor: "#2A3FA0",
                      width: "fit-content",
                    }}
                  >
                    Assignment for you
                  </h6>
                  <h3 className="text-dark">Participation in eAuction</h3>
                  <p className="text-muted">
                    Project Description
                    <br />
                    {projectDetails.description}
                  </p>

                  <Table responsive bordered hover className="mb-4">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th>Project ID</th>
                        <th>Material Category</th>
                        <th>Location</th>
                        <th>Estimated Value</th>
                        <th>Current Status</th>
                        <th>Manager</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{projectDetails.project?.projectId}</td>
                        <td>{projectDetails.project?.materialCategory ?? "N/A"}</td>
                        <td>
                          {projectDetails.project?.location?.locationName ?? "N/A"}
                        </td>
                        <td>
                          {projectDetails.project?.estimatedValue ?? "- - -"}
                        </td>
                        <td>
                          <Badge bg="warning">Yet to Start</Badge>
                        </td>
                        <td>
                          <strong>
                            {projectDetails.project?.projectManager?.username}
                          </strong>
                          <br />
                          <small>
                            M: {projectDetails.project?.projectManager?.phone}
                            <br />
                            E: {projectDetails.project?.projectManager?.email}
                          </small>
                        </td>
                        <td>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="m-1"
                          >
                            <FaFileAlt /> Tender Doc
                          </Button>
                          <Button
                            variant="outline-info"
                            size="sm"
                            className="m-1"
                          >
                            <FaImages /> Images
                          </Button>
                          <Button
                            variant="outline-success"
                            size="sm"
                            className="m-1"
                          >
                            <FaVideo /> Videos
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Card className="mb-4">
                    <Card.Header className="text-white">
                      Project Timeline
                    </Card.Header>
                    <Card.Body>
                      <Table responsive bordered>
                        <tbody>
                          <tr className="bg-light">
                            <th>Tender Opening</th>
                            <th>Tender Closure</th>
                            <th>EMD Last Date</th>
                            <th>eAuction Start</th>
                            <th>eAuction Close</th>
                          </tr>
                          <tr>
                            <td>
                              {projectDetails.project?.createdAt ? (
                                <Momemt
                                  date={projectDetails.project?.openning}
                                />
                              ) : (
                                "- - -"
                              )}
                            </td>
                            <td>
                            {projectDetails.project?.createdAt ? (
                                <Momemt
                                  date={projectDetails.project?.closing}
                                />
                              ) : (
                                "- - -"
                              )}
                            </td>
                            <td>
                              {projectDetails.lastDateForEarnestMoneyDeposit ? (
                                <Momemt
                                  date={
                                    projectDetails.lastDateForEarnestMoneyDeposit
                                  }
                                />
                              ) : (
                                "- - -"
                              )}
                            </td>
                            <td>
                              {projectDetails.startDate ? (
                                <Momemt date={projectDetails.startDate} />
                              ) : (
                                "- - -"
                              )}
                            </td>
                            <td>
                              {projectDetails.endDate ? (
                                <Momemt date={projectDetails.endDate} />
                              ) : (
                                "- - -"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>

                  <Card className="mb-4">
                    <Card.Header className="text-white">
                      Project Details
                    </Card.Header>
                    <Card.Body>
                      <Table responsive bordered>
                        <tbody>
                          <tr>
                            <td colSpan={1}>1</td>
                            <td colSpan={5}>
                              <strong>Earnest Money to be Deposit</strong>
                            </td>
                            <td colSpan={5}>
                              {
                                projectDetails.embtoggle ? `${projectDetails.embValue}% of the base price` : projectDetails.embValue
                              }
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={1}>2</td>
                            <td colSpan={5}>
                              <strong>GST Rate</strong>
                            </td>
                            <td colSpan={5}>18%</td>
                          </tr>
                          <tr>
                            <td colSpan={1}>3</td>
                            <td colSpan={5}>
                              <strong>GMV Charges</strong>
                            </td>
                            <td colSpan={5}>10% of the eAuction Value</td>
                          </tr>
                          <tr>
                            <td colSpan={1}>4</td>
                            <td colSpan={5}>
                              <strong>Rebate/Discounts</strong>
                            </td>
                            <td colSpan={5}>
                              2% rebate if project closed within the time
                              provided.
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={1}>5</td>
                            <td colSpan={5}>
                              <strong>
                                Time allowed for close the project
                              </strong>
                            </td>
                            <td colSpan={5}>Yet to be announced</td>
                          </tr>
                          <tr>
                            <td colSpan={1}>6</td>
                            <td colSpan={5}>
                              <strong>Detail....</strong>
                            </td>
                            <td colSpan={5}></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>

                  <div className="mb-3">
                    <Button variant="link">Download general T & C</Button>
                    <Button variant="link">Download.....</Button>
                    <Button variant="link">Download.....</Button>
                  </div>

                  <Button
                    style={{
                      background: "#2A3FA0",
                      border: "none",
                    }}
                    variant="primary"
                    size="lg"
                    className="mb-4"
                  >
                    Deposit Earnest Money
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          )}
          {loading && (
            <Col lg="10" md="10" sm="8" className="">
              <Card className="mb-4">
                <Card.Body>
                  <h2>Loading...</h2>
                </Card.Body>
              </Card>
            </Col>
          )}
          {!loading && !projectDetails && (
            <Col lg="10" md="10" sm="8" className="">
              <Card className="mb-4">
                <Card.Body>
                  <h2>Project not found</h2>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default ProjectMoreDetails;
