import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import { Button } from "antd";
import { Input } from "formik-antd";
import MultiStepFormContext from "./MultiStepFormContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Badge,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import {toast,ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Define a component named "Details"
const Financial = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const baseServerUrl = process.env.REACT_APP_baseServerURL;


  const { financial, setFinancial, next, prev } = useContext(MultiStepFormContext);
  const [addFormData, setAddFormData] = useState({
    financialYear:`${new Date().getFullYear()}-${new Date().getFullYear() + 1}`
  })
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setAddFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  const handeAddSubmit = () => { 
    console.log(addFormData);
    axios
      .post(`${baseServerUrl}/createfinancial`,  {
        financialYear: addFormData.financialYear,
        totalTurnover: addFormData.totalTurnover,
        scrapHandlingTurnover: addFormData.scrapHandlingTurnover,
        netWorth: addFormData.netWorth,
        totalTeamCount: addFormData.totalTeamCount,
        teamCountForScrap: addFormData.teamCountForScrap,
      }, {
        headers: {
          "Authorization": JSON.parse(localStorage.getItem("userInfo")).token
        }
      })
      .then((response) => {
        console.log(response);
        toast.success("Financial added successfully");
        next();
        // handleClose();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in adding Financial");
      });
  }



  return (
  
          <form className="bookingForm">
            <ToastContainer />
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-12 pb-3">
                <div className="d-flex align-items-center">
                <label for="financialYear" style={{color:"black",fontSize:"1.5rem",width:"25%"}}>Financial Year :</label>
                <DatePicker
                  dateFormat="yyyy"
                  showYearPicker
                  name="financialYear"
                  id="financialYear"
                  peekNextMonth
                  showMonthDropdown={false}
                  scrollableYearDropdown
                  selected={addFormData.financialYear ? new Date(addFormData.financialYear?.split("-")[0]) : new Date()}
                  className="fs-5 bg-white p-2 rounded-3 w-25 text-center"
                  onChange={(date) => {
                    setAddFormData({
                      ...addFormData,
                      financialYear: `${date.getFullYear()}-${date.getFullYear() + 1}`
                    });
                  }}
                />
                {/* <p>{`${parseInt(JSON.stringify(addFormData.financialYear).split("-")[0])} - ${parseInt(JSON.stringify(addFormData.financialYear).split("-")[0])+1}`}</p> */}
                </div>
              </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"0"}}></div>

              <div class="col-md-12  d-flex align-items-center gap-5">
                <label for="branch" className="fs-6 text-black">Total Turnover (Rs. in Lakhs)?</label>
                <input
                  class="form-control mt-2"
                  id="totalTurnover"
                  type="text"
                  name="totalTurnover"
                  onChange={(e)=>handleAddChange(e)}
                  value={addFormData.totalTurnover}
                  required
                />
              </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"0"}}></div>
              <div class="col-md-12  d-flex align-items-center gap-5">
                <label for="email" className="fs-6 text-black">
                  Scrap Handling Work Turnover(Rs. in Lakhs)?
                </label>
                <input
                  class="form-control mt-2"
                  id="scrapHandlingTurnover"
                  name="scrapHandlingTurnover"
                  type="text"
                  onChange={(e)=>handleAddChange(e)}
                  value={addFormData.scrapHandlingTurnover}
                  required
                />
              </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"0"}}></div>

              <div class="col-md-12  d-flex align-items-center gap-5">
                <label for="name" className="fs-6 text-black">
                  Net Worth of the Company(Rs. in Lakhs)?
                </label>
                <input
                  class="form-control mt-2"
                  id="netWorth"
                  name="netWorth"
                  type="text"
                  onChange={(e)=>handleAddChange(e)}
                  value={addFormData.netWorth}
                  required
                />
              </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"0"}}></div>

              <div class="col-md-12  d-flex align-items-center gap-5">
                <label for="name" className="fs-6 text-black">Total Team Count?</label>
                <input
                  class="form-control mt-2"
                  id="totalTeamCount"
                  name="totalTeamCount"
                  type="text"
                  onChange={(e)=>handleAddChange(e)}
                  value={addFormData.totalTeamCount}
                  required
                />
              </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"0"}}></div>

              <div class="col-md-12  d-flex align-items-center gap-5">
                <label for="name" className="fs-6 text-black">Team Count for Scrap?</label>
                <input
                  class="form-control mt-2"
                  id="teamCountForScrap"
                  name="teamCountForScrap"
                  type="text"
                  onChange={(e)=>handleAddChange(e)}
                  value={addFormData.teamCountForScrap}
                  required
                />
              </div>
            </div>
          </div>
          <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>

    
          <div
              className={
                "form__item button__items d-flex justify-content-between mt-4"
              }
            >
              {/* Render a "Back" button that moves to the previous step in the form */}
              <Button type={"default"} onClick={prev} size="large" className="border-primary text-primary">
                Back
              </Button>
              {/* Render a "Next" button that submits the form data and moves to the next step in the form */}
              <div className="d-flex gap-2">
              <Button type={"primary"} onClick={handeAddSubmit} size="large">
                Submit & Next
              </Button>
              <Button type={"secondary"} className="border border-primary text-primary bg-white" onClick={()=>next()} size="large">
                Skip
              </Button>
              </div>
            </div>
    
        </form>
  );
      
};

export default Financial;
