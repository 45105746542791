import React from "react";
import { Link } from "react-router-dom";
import "./css/chat.css";

const ChatSec = () => {
  return (
    <>
      <div class="buy-theme alt-font d-none d-lg-inline-block">
        <Link to="https://wa.me/918588840800" target="_blank">
          <i class="fab fa-whatsapp"></i>
          <span>Quick Whatsapp</span>
        </Link>
      </div>
      <div class="all-demo alt-font d-none d-lg-inline-block pe-2">
        <Link to="tel:91-8588840800">
          <i class="fas fa-phone"></i>
          <span>+91-8588840800</span>
        </Link>
      </div>
    </>
  );
};
export default ChatSec;
