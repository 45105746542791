import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import { Button } from "antd";
import { Input } from "formik-antd";
import MultiStepFormContext from "./MultiStepFormContext";
import { State, City } from 'country-state-city';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  Badge,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Modal,
  ListGroup,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
// Component for the Links page of the form
const Other = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));


  const [formValues, setFormValues] = useState([{ state: "", citys : ""}])
  let addFormFields = () => {
    setFormValues([...formValues, { state: "", citys: "" }])
  }

let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
}
  const countryCode = 'IN';
  const data = State.getStatesOfCountry(countryCode).map(state => ({
    value: state.name,
    isoCode: state.isoCode,
    displayValue: state.name
  }))
  const [cityData, setcityData] = useState([]);

  const [searchCity, setsearchCity] = useState({
    state: ''
  })
  const handleChanageFilter = (name, value) => {
    setsearchCity((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handleSubmit = ()=>{

  }
  const fetchCity = async () => {
    console.log(searchCity.state);
    const citysData = City.getCitiesOfState(countryCode, searchCity.state).map(city => ({
      value: city.name,
      label: city.name
    }))
    setcityData(citysData);
  } 
  useEffect(() => {
    fetchCity();
  }, [searchCity]);

  const { other, setOther, next, prev } = useContext(
    MultiStepFormContext
  );
  const [addFormData, setAddFormData] = useState({});
 
  const handleAddChange = (e) => {
    const fieldName = e.target.getAttribute('name');
    if(fieldName === 'name' || fieldName === 'phone' || fieldName === 'email'){
      setAddFormData({
        ...addFormData,
        authorisedPerson:{
          ...addFormData.authorisedPerson,
          [fieldName]: e.target.value
        }
      })
      return;
    }
    const fieldValue = e.target.value;
    setAddFormData({
      ...addFormData,
      [fieldName]: fieldValue
    })
  }
  const handleAddSubmit = () => {
    axios.post(`${baseServerUrl}/createaddressbook`,addFormData,{
      headers:{
        'Authorization': `${userInfo.token}`
      }
    }).then((response) => {
      
      console.log(response.data);
      next();
      toast.success('Address Book Added Successfully');
    }).catch((error) => {
      console.log(error);
      toast.error('Address Book Not Added');
    })

  };


  return (
    <form className="bookingForm">
    <div class="card-body">
      <div class="row mt-4">
        <div className="row">
        <div class="col-md-3">
          {/* <p>GST Number</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="gstNumber"
              placeholder="GST Number"
              type="text"
              name="GSTNumber"
              onChange={handleAddChange}
              value={addFormData.GSTNumber}
              required
            />
                <label className="form-label">
                  GST Number
            </label>
          </div>
        </div>
        <div class="col-md-6">
          {/* <p>Location Name</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="locationName"
              placeholder="Location Name"
              name="locationName"
              onChange={handleAddChange}
              value={addFormData.locationName}
              type="text"
              required
            />
            <label className="form-label">
              Location Name
            </label>
          </div>
        </div>
        <div class="col-md-3">
          {/* <p>Pincode</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="pincode"
              name="pincode"
              onChange={handleAddChange}
              value={addFormData.pincode}
              placeholder="Pincode"
              type="text"
              required
            />
                <label className="form-label">
                  Pincode
            </label>
          </div>
        </div>
   
        <div class="col-md-6">
          {/* <p>State</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="state"
              placeholder="State"
              name="state"
              onChange={handleAddChange}
              value={addFormData.state}
              type="text"
              required
            />
                <label className="form-label">
                  State
            </label>
          </div>
        </div>
        <div class="col-md-6">
          {/* <p>District</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="district"
              placeholder="District"
              name="district"
              value={addFormData.district}
              onChange={handleAddChange}
              type="text"
              required
            />
                <label className="form-label">
                  District
            </label>
          </div>
        </div>
        <div class="col-md-12">
          {/* <p>Address Line 1</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="addressLine1"
              placeholder="Address Line 1"
              name="addressLine1"
              onChange={handleAddChange}
              value={addFormData.addressLine1}
              type="text"
              required
            />
                <label className="form-label">
                  Address Line 1
            </label>
          </div>
        </div>
        <div class="col-md-12">
          {/* <p>Address Line 2</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="addressLine2"
              name="addressLine2"
              onChange={handleAddChange}
              placeholder="Address Line 2"
              value={addFormData.addressLine2}
              type="text"
              required
            />
                <label className="form-label">
                  Address Line 2
            </label>
          </div>
        </div>
    <h5>Authorised Person</h5>

        <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0",marginTop:"0"}}></div>

        <div class="col-md-4">
          <div className="form-control">
            <input
              class="form-input"
              id="authorisedPerson"
              placeholder="Authorised Person"
              name="name"
              onChange={handleAddChange}
              value={addFormData.authorisedPerson?.name}
              type="text"
              required
            />
                <label className="form-label">
                Name
            </label>
          </div>
        </div>
        <div class="col-md-4">
          {/* <p>Phone</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="phone"
              placeholder="Phone"
              name="phone"
              onChange={handleAddChange}
              value={addFormData.authorisedPerson?.phone}
              type="text"
              required
            />
                <label className="form-label">
                  Phone
            </label>
          </div>
        </div>
        <div class="col-md-4">
          {/* <p>Email</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="email"
              placeholder="Email"
              type="email"
              name="email"
              onChange={handleAddChange}
              value={addFormData.authorisedPerson?.email}
              required
            />
                <label className="form-label">
                  Email
            </label>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0"}}></div>

 
    <div
              className={
                "form__item button__items d-flex justify-content-between mt-4"
              }
            >
              {/* Render a "Back" button that moves to the previous step in the form */}
              <Button type={"default"} onClick={prev} size="large" className="border-primary text-primary">
                Back
              </Button>
              {/* Render a "Next" button that submits the form data and moves to the next step in the form */}
              <div className="d-flex gap-2">
              <Button type={"primary"} onClick={handleAddSubmit} size="large">
                Submit & Next
              </Button>
              <Button type={"secondary"} className="border border-primary text-primary bg-white" onClick={()=>next()} size="large">
                Skip
              </Button>
              </div>
            </div>
    
  </form>
  );
};

export default Other;
