import React from 'react';
import './css/banner.css';


const HeroSec = () => {
    return (
        <section className="banner-section">
            <div className="pattern-layer shape-img-1"></div>
            <div className="banner-carousel">
                <div className="slide-item p_relative">
                    <div className="shape">
                        <div className="shape-1 shape-img-3"></div>
                        {/* <div className="shape-2 shape-img-16 float-bob-y"></div>
                        <div className="shape-3 shape-img-5 rotate-me"></div> */}
                    </div>
                    <div className="auto-container text-center">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12 col-sm-12 content-column">
                                <div className="content-box p_relative d_block z_5">
                                    <h2 className="p_relative d_block">Safe and Compliant</h2>
                                    
                                    <p className="ph4 p_relative d_block">disposal of SCRAP and ASSETS</p>
                                    <p className="p_relative d_block">Along with maximum financial outcomes</p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 image-column">
                                <div className="image-box p_relative">
                                    <div className="image-shape-1 shape-img-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HeroSec;
