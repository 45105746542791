import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Sidebar/Copyright";
import { Col, Row } from "react-bootstrap";
import { LuPlus } from "react-icons/lu";
import Topbar from "../../components/Sidebar/Topbar";
import { Switch } from "antd";
import { LuMinus } from "react-icons/lu";
import BaseService from "../../config/axiosService";
import { FaFileUpload } from "react-icons/fa";
import toast from "react-hot-toast";
import uploadFiles from "../../helpers/uploadFile";
import { useNavigate } from "react-router-dom";

const CreateAuction = () => {
  const navigate = useNavigate();
  const [numberofautoextensio, setNumberofautoextensio] = useState("");
  const [emb, setEmb] = useState(false);
  const [autoExt, setAutoExt] = useState(false);
  const [finalInspection, setFinalInspection] = useState(false);
  const [supportTeam, setSupportTeam] = useState([{ id: "", name: "" }]);
  const [members, setMembers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [bidderManualDoc, setBidderManulDoc] = useState(null);
  const [auctionNoticeDoc, setAuctionNoticeDoc] = useState(null);
  const [eAuctionTermsDoc, setEAuctionTermsDoc] = useState(null);
  const [termsAndConditionsDoc, setTermsAndConditionsDoc] = useState(null);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [otherDocumentsFiles, setOtherDocumentsFiles] = useState([]);
  const [auctionname, setAuctionname] = useState("");
  const [basicvalue, setBasicvalue] = useState("");
  const [fixedvalue, setFixedvalue] = useState("");
  const [minbidincrement, setMinbidincrement] = useState("");
  const [biddingtimes, setBiddingtimes] = useState("");
  const [elapsetime, setElapsetime] = useState("");
  const [bidautoext, setBidautoext] = useState("");
  const [autoextallowed, setAutoextallowed] = useState(false);
  const [eauctionfees, setEauctionfees] = useState("");
  const [maxprice, setMaxprice] = useState("");
  const [lastdateEauctionApplication, setLastdateEauctionApplication] =
    useState("");
  const [lastdateEarnestMoneyDeposit, setLastdateEarnestMoneyDeposit] =
    useState("");
  const [startDate, setStartDate] = useState("");
  const [keepOpenForDays, setKeepOpenForDays] = useState("");
  const [endDate, setEndDate] = useState("");
  const [finalInspectionOpenDate, setFinalInspectionOpenDate] = useState("");
  const [finalInspectionCloseDate, setFinalInspectionCloseDate] = useState("");
  const [publishDate, setPublishDate] = useState("");

  const fetchProjects = () => {
    BaseService.get("/api/v2/project")
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await BaseService.get("/api/v2/team");
      setMembers(response.data.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching team members: ", error);
    }
  };

  const handleFileChange = (e, setFileState) => {
    const files = e.target.files;
    setFileState(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bidderManualDocUrl = bidderManualDoc
      ? (await uploadFiles(bidderManualDoc))[0]
      : "";
    const auctionNoticeDocUrl = auctionNoticeDoc
      ? (await uploadFiles(auctionNoticeDoc))[0]
      : "";
    const eAuctionTermsDocUrl = eAuctionTermsDoc
      ? (await uploadFiles(eAuctionTermsDoc))[0]
      : "";
    const termsAndConditionsDocUrl = termsAndConditionsDoc
      ? (await uploadFiles(termsAndConditionsDoc))[0]
      : "";
    const otherDocumentsUrl = otherDocumentsFiles
      ? await uploadFiles(otherDocumentsFiles)
      : [];
    const data = {
      project: selectedProject._id,
      auctionName: auctionname,
      basicValue: basicvalue,
      embtoggle: emb,
      embValue: fixedvalue,
      minbidingValue: minbidincrement,
      numberOfBids: biddingtimes,
      elapsetimelapseMin: elapsetime,
      bidauoextensioMin: bidautoext,
      unlimitedauoextensio: autoextallowed,
      numberofautoextensio: numberofautoextensio,
      auctionfees: eauctionfees,
      maximumPrice: maxprice,
      lastDateForEauction: lastdateEauctionApplication,
      lastDateForEarnestMoneyDeposit: lastdateEarnestMoneyDeposit,
      startDate: startDate,
      numberofdays: keepOpenForDays,
      endDate: endDate,
      finalinsepection: finalInspection,
      opendate: finalInspectionOpenDate,
      closedate: finalInspectionCloseDate,
      publishDate: publishDate,
      supportteams: supportTeam.map((member) => member.id),
      biddermanual: bidderManualDocUrl,
      eauctionnotice: auctionNoticeDocUrl,
      eauctiontermsandconditions: eAuctionTermsDocUrl,
      termsandconditions: termsAndConditionsDocUrl,
      otherdocuments: otherDocuments,
      otherdocumentsfiles: otherDocumentsUrl.join(","),
    };
    BaseService.post("/api/v2/auction", data)
      .then((response) => {
        console.log(response);
        toast.success("Auction added successfully");
        navigate("/projects-under-eaution");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to add auction");
      });
    // .finally(() => {
    //   setLoading(false);
    // });
  };

  useEffect(() => {
    fetchTeamMembers();
    fetchProjects();
  }, []);

  return (
    <div>
      <Topbar />
      <Row>
        <Col
          lg="2"
          md="2"
          sm="4"
          style={{ background: "#2B3FA1", paddingRight: "0" }}
        >
          <Sidebar />
        </Col>
        <Col
          lg="10"
          md="10"
          sm="8"
          style={{
            background: "#F5F6FA",
            minHeight: "100vh",
            overflowY: "scroll",
          }}
        >
          <div
            className="p-3"
            style={{
              height: "100vh",
              overflowY: "scroll",
            }}
          >
            <h4>Create eAuction Room</h4>
            <hr />
            <div>
              <h6
                style={{
                  backgroundColor: "#2A3FA0",
                  width: "fit-content",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "15px",
                }}
              >
                Basic Settings
              </h6>
              <div
                className="mt-3 gap-2"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <div>
                  <label htmlFor=""> Project Id</label>
                  <select
                    onChange={(e) => {
                      const project = projects.find(
                        (project) => project._id === e.target.value
                      );
                      setSelectedProject(project);
                    }}
                    className="form-control"
                    name=""
                    id=""
                  >
                    <option selected={true} disabled={true} value="">
                      Please Select Project
                    </option>
                    {projects.map((project) => (
                      <option value={project._id}>{project.projectId}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="">Seller's Name</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled={true}
                    placeholder="Please Select Project"
                    value={
                      selectedProject
                        ? selectedProject?.createdBy?.username
                        : "Please Select Project"
                    }
                  />
                </div>
              </div>
              <div>
                <label htmlFor="">Auction Room Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setAuctionname(e.target.value)}
                />
              </div>
            </div>
            <hr />
            <div>
              <h6
                style={{
                  backgroundColor: "#2A3FA0",
                  width: "fit-content",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "15px",
                }}
              >
                Project Details
              </h6>
              <div className="mt-3">
                <div>
                  <label htmlFor="">Project ID</label>
                  <input
                    value={selectedProject ? selectedProject.projectId : ""}
                    type="text"
                    disabled={true}
                    className="form-control"
                  />
                </div>
                <div>
                  <label htmlFor="">Seller's Name and Address</label>
                  <input
                    value={
                      selectedProject
                        ? `${selectedProject?.createdBy?.username}, ${selectedProject?.location?.locationName}`
                        : ""
                    }
                    type="text"
                    disabled={true}
                    className="form-control"
                  />
                </div>
                <div>
                  <label htmlFor="">Seller's PAN and GST Details</label>
                  <input
                    type="text"
                    disabled={true}
                    value={
                      selectedProject
                        ? `${selectedProject?.createdBy?.panNumber}, ${selectedProject?.location?.GSTNumber}`
                        : ""
                    }
                    className="form-control"
                  />
                </div>
                <div>
                  <label htmlFor="">Material/ Site Location</label>
                  <input type="text" disabled={true} className="form-control" />
                </div>
                <div>
                  <label htmlFor="">Project Description</label>
                  <input
                    type="text"
                    disabled={true}
                    className="form-control"
                    value={selectedProject ? selectedProject.description : ""}
                  />
                </div>
                <div>
                  <label htmlFor="">Details of Material under eAuction</label>
                  <input type="text" disabled={true} className="form-control" />
                </div>
                <div>
                  <p>Project Medias and Document</p>
                  <div className="flex gap-2">
                    {selectedProject &&
                    selectedProject.services &&
                    selectedProject.services.servicesDetails
                      ? selectedProject.services.servicesDetails
                          .flatMap((service) => [
                            ...(service.documents || []),
                            ...(service.medias || []),
                          ])
                          .map((file, index) => (
                            <a
                              key={index} // Remember to add a unique key when mapping
                              href={`${process.env.REACT_APP_baseServerURL}${file}`}
                              className="text-primary text-decoration-underline ms-2"
                            >
                              file - {index + 1}
                            </a>
                          ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <h6
                style={{
                  backgroundColor: "#2A3FA0",
                  width: "fit-content",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "15px",
                }}
              >
                Auction Details
              </h6>
              <div className="mt-3">
                <div>
                  <label htmlFor="">Basic Value</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Basic Value"
                    onChange={(e) => setBasicvalue(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">
                    EMB to be Deposit
                    {emb ? " (% of the Basic Value)" : " Fixed Value"}
                  </label>
                  <Switch
                    style={{
                      backgroundColor: "#2A3FA0",
                    }}
                    onChange={(checked) => {
                      setEmb(checked);
                    }}
                  />
                  <input
                    style={{
                      marginTop: "10px",
                    }}
                    type="number"
                    className="form-control"
                    placeholder={
                      emb ? "Enter % of Basic Value" : "Enter Fixed Value"
                    }
                    onChange={(e) => setFixedvalue(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">Minimum Bid Increment</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Minimum Bid Increment"
                    onChange={(e) => setMinbidincrement(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">Number of times bidding</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Number of times bidding"
                    onChange={(e) => setBiddingtimes(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">Elapse Time in Min</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Elapse Time in Min"
                    onChange={(e) => setElapsetime(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">Bid Auto Extension in Min</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Bid Auto Extension in Min"
                    onChange={(e) => setBidautoext(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">
                    {autoExt
                      ? "Number of Auto Extensions Allowed"
                      : "Unlimited Auto Extensions"}
                  </label>
                  <Switch
                    style={{
                      backgroundColor: "#2A3FA0",
                    }}
                    onChange={(checked) => {
                      setAutoExt(checked);
                    }}
                  />
                  {autoExt ? (
                    <input
                      style={{
                        marginTop: "10px",
                      }}
                      type="number"
                      className="form-control"
                      placeholder="Enter Number of Auto Extensions Allowed"
                      onChange={(e) => setNumberofautoextensio(e.target.value)}
                    />
                  ) : (
                    <input
                      style={{
                        marginTop: "10px",
                      }}
                      type="text"
                      className="form-control"
                      disabled={true}
                      placeholder="Unlimited Auto Extensions"
                    />
                  )}
                </div>
                <div>
                  <label htmlFor="">eAuction Fees</label>
                  <input
                    type="number"
                    placeholder="Enter eAuction Fees"
                    className="form-control"
                    onChange={(e) => setEauctionfees(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">Set Maximum Price</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Maximum Price"
                    onChange={(e) => setMaxprice(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div>
              <h6
                style={{
                  backgroundColor: "#2A3FA0",
                  width: "fit-content",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "15px",
                }}
              >
                Critical Dates
              </h6>
              <div className="mt-3">
                <div>
                  <label htmlFor="">
                    Last Date and Time to Submit eAuction Application
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    onChange={(e) =>
                      setLastdateEauctionApplication(e.target.value)
                    }
                  />
                </div>
                <div>
                  <label htmlFor="">
                    Last Date and Time for Earnest Money Deposit
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    onChange={(e) =>
                      setLastdateEarnestMoneyDeposit(e.target.value)
                    }
                  />
                </div>
                <div>
                  <label htmlFor="">Start Date and Time</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">Keep open for days</label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Enter Number of Days"
                    onChange={(e) => setKeepOpenForDays(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="">End Date and Time</label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div>
                  {/* label->Final Inspection Required two option Yes or No */}
                  <label htmlFor="">Final Inspection Required</label>
                  <div className="d-flex gap-3">
                    <div className="d-flex gap-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        style={{}}
                        name="final_inspection"
                        id="yes"
                        value="yes"
                        onChange={(e) => {
                          setFinalInspection(e.target.value);
                          console.log(e.target.value);
                        }}
                      />
                      <label className="form-check-label" htmlFor="yes">
                        Yes
                      </label>
                    </div>
                    <div className="d-flex gap-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="no"
                        style={{}}
                        name="final_inspection"
                        id="no"
                        onChange={(e) => {
                          setFinalInspection(e.target.value);
                          console.log(e.target.value);
                        }}
                      />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {finalInspection === "yes" && (
                  <div className="d-flex gap-3 mt-3">
                    <div>
                      <label htmlFor="">Open Date and Time</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        onChange={(e) =>
                          setFinalInspectionOpenDate(e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="">Close Date and Time</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        onChange={(e) =>
                          setFinalInspectionCloseDate(e.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
                <div>
                  <label htmlFor="">
                    Date and Time to Publish this Auction
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    onChange={(e) => setPublishDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div>
              <h6
                style={{
                  backgroundColor: "#2A3FA0",
                  width: "fit-content",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "15px",
                }}
              >
                Support Teams
              </h6>
              <div className="mt-3">
                {supportTeam.length > 0 &&
                  supportTeam.map((team, index) => (
                    <div
                      key={index}
                      className="d-flex gap-2 align-items-center"
                    >
                      <div>
                        <label htmlFor={`name-${index}`}>Name</label>
                        <select
                          id={`name-${index}`}
                          onChange={(e) => {
                            const selectedMember = members.find(
                              (member) => member._id === e.target.value
                            );
                            console.log(selectedMember);
                            setSupportTeam(
                              supportTeam.map((team, i) => {
                                if (i === index) {
                                  return {
                                    ...team,
                                    id: selectedMember._id,
                                    name: selectedMember.username,
                                    email: selectedMember.email,
                                    phone: selectedMember.phone,
                                    role: selectedMember?.rolesAndPermissions
                                      ?.name,
                                  };
                                }
                                return team;
                              })
                            );
                          }}
                          className="form-control"
                          value={team.id}
                        >
                          <option disabled={true} value="">
                            Select Team Member
                          </option>
                          {members.map((member) => (
                            <option
                              key={member._id}
                              value={member._id}
                              disabled={supportTeam.find(
                                (team) => team.id === member._id
                              )}
                            >
                              {member.username}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label htmlFor={`role-${index}`}>Role</label>
                        <input
                          id={`role-${index}`}
                          className="form-control"
                          type="text"
                          disabled
                          value={team.role || ""}
                        />
                      </div>
                      <div>
                        <label htmlFor={`email-${index}`}>Email ID</label>
                        <input
                          id={`email-${index}`}
                          className="form-control"
                          type="text"
                          disabled
                          value={team.email || ""}
                        />
                      </div>
                      <div>
                        <label htmlFor={`phone-${index}`}>Phone Number</label>
                        <input
                          id={`phone-${index}`}
                          className="form-control"
                          type="text"
                          disabled
                          value={team.phone || ""}
                        />
                      </div>
                      {index === supportTeam.length - 1 && (
                        <div className="d-flex gap-2">
                          <button
                            style={{
                              backgroundColor: "#2A3FA0",
                              color: "white",
                              border: "none",
                              borderRadius: "5px",
                              height: "40px",
                            }}
                            onClick={() => {
                              setSupportTeam([
                                ...supportTeam,
                                { id: "", name: "" },
                              ]);
                            }}
                          >
                            <LuPlus size={40} color="white" />
                          </button>
                          {supportTeam.length > 1 && (
                            <button
                              style={{
                                color: "white",
                                border: "1px solid #2A3FA0",
                                borderRadius: "5px",
                                height: "40px",
                              }}
                              onClick={() => {
                                if (supportTeam.length > 1) {
                                  setSupportTeam(
                                    supportTeam.filter((_, i) => i !== index)
                                  );
                                }
                              }}
                            >
                              <LuMinus size={40} color="#2A3FA0" />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <hr />
            <div>
              <h6
                style={{
                  backgroundColor: "#2A3FA0",
                  width: "fit-content",
                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "15px",
                }}
              >
                Documents
              </h6>
              <div className="mt-3">
                <div>
                  <label htmlFor="">Upload Bidder Manual</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleFileChange(e, setBidderManulDoc)}
                  />
                </div>
                <div>
                  <label htmlFor="">Upload eAuction Notice</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleFileChange(e, setAuctionNoticeDoc)}
                  />
                </div>
                <div>
                  <label htmlFor="">
                    Upload this eAuction specific Terms and Conditions
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleFileChange(e, setEAuctionTermsDoc)}
                  />
                </div>
                <div>
                  <label htmlFor="">Upload Terms and Conditions</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) =>
                      handleFileChange(e, setTermsAndConditionsDoc)
                    }
                  />
                </div>
                <div>
                  <p className="fs-6">Upload Other Documents</p>
                  <div className="d-flex align-items-center gap-2">
                    <div className="w-50">
                      <label htmlFor="">Name of the Document</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter the Name of the Document"
                        onChange={(e) =>
                          setOtherDocuments([...otherDocuments, e.target.value])
                        }
                      />
                    </div>
                    <div className="w-50">
                      <label htmlFor="">Upload Document</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) =>
                          handleFileChange(e, setOtherDocumentsFiles)
                        }
                      />
                    </div>
                    <button
                      style={{
                        backgroundColor: "#2A3FA0",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        padding: "15px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <FaFileUpload size={20} />
                        Upload{" "}
                      </div>
                    </button>
                  </div>
                </div>
                <div>
                  <p className="fs-6">Terms and Conditions</p>
                  <div className="d-flex align-items-centre gap-2">
                    <div className="w-50">
                      <label htmlFor="">Name of the Document</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex gap-2">
              <button
                style={{
                  backgroundColor: "#2A3FA0",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  padding: "15px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                Show Preview
              </button>
              <button
                style={{
                  backgroundColor: "#2A3FA0",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  padding: "15px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                style={{
                  border: "1px solid #2A3FA0",
                  color: "#2A3FA0",
                  borderRadius: "5px",
                  padding: "15px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                Clear Form
              </button>
              <button
                style={{
                  border: "1px solid #2A3FA0",
                  color: "#2A3FA0",
                  borderRadius: "5px",
                  padding: "15px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                Don't Save and Exit
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CreateAuction;
