import React from "react";

import Topbar from "../../components/Sidebar/Topbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Sidebar/Copyright";
import { Col, Row } from "react-bootstrap";

const AccountStatement = () => {
  const tableData = [
    {
      projectId: "ABC123",
      entryDate: "10/02/2024",
      transactionType: "Invoice",
      description:
        "Invoice for First Instalment paid (20% of total bid amount)",
      documentId: "Buyer/2324/005",
      debit: null,
      credit: 23600,
      balance: "23,600 Dr",
    },
    {
      projectId: "ABC123",
      entryDate: "11/02/2024",
      transactionType: "Instalment",
      description:
        "Payment against first instalment TDS against first instalment",
      documentId: null,
      debit: 23200,
      credit: null,
      balance: "400 Dr",
    },
    {
      projectId: "ABC123",
      entryDate: "11/02/2024",
      transactionType: "Advance",
      description: "Advance paid against second instalment",
      documentId: "Adv/001",
      debit: 25000,
      credit: null,
      balance: "25000 Cr",
    },
  ];

  const tableData2 = [
    {
      projectId: "ABC123",
      entryDate: "10/02/2024",
      transactionType: "eAuction",
      description: "Bid closed",
      natureOfTransaction: "Bid Value",
      amount: 118000,
      netPayableReceivable: 118000,
    },
    {
      projectId: "ABC123",
      entryDate: "10/02/2024",
      transactionType: null,
      description: "Payment done",
      natureOfTransaction: "Payment",
      amount: -23200,
      netPayableReceivable: 94800,
    },
    {
      projectId: "ABC123",
      entryDate: "10/02/2024",
      transactionType: "TDS",
      description: "TDS Deducted",
      natureOfTransaction: "Payment",
      amount: -400,
      netPayableReceivable: 94400,
    },
  ];
  return (
    <div>
      
      <Topbar />
      <Row>
        <Col
          md={2}
          lg={2}
          sm={4}
          style={{
            backgroundColor: "#2A3FA0",
            // minHeight: "100vh",
            padding: "0",
          }}
        >
          <Sidebar />
        </Col>
        <Col
          md={10}
          lg={10}
          sm={8}
          style={{
            padding: "20px",
          }}
        >
                      <h3>Account Statement</h3>
                      <hr />

          <div className="d-flex">
            <br />
            <h4 className="mb-3">
              XYZ Private Limited for Project ID (ABC123) as on 10 Feb 2024
            </h4>
            <div className="ms-auto d-flex gap-2">
              <a href="#" className="">
                Download
              </a>
              <a href="#" className="">
                Pdf
              </a>
              <a href="#" className="">
                MS Excel
              </a>
            </div>
          </div>
          <table className="table table-striped border">
            <thead>
              <tr>
                <th>Project ID</th>
                <th>Entry Date</th>
                <th>Transaction Type</th>
                <th>Description</th>
                <th>Document ID</th>
                <th>Debit (Dr) INR</th>
                <th>Credit (Cr) INR</th>
                <th>Balance INR</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.projectId}</td>
                  <td>{row.entryDate}</td>
                  <td>{row.transactionType}</td>
                  <td>{row.description}</td>
                  <td>{row.documentId}</td>
                  <td>{row.debit}</td>
                  <td>{row.credit}</td>
                  <td>{row.balance}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="fw-bold text-center fs-5">Total</td>
                <td></td>
                <td className="fw-bold">48,600</td>
                <td className="fw-bold">23600</td>
                <td></td>
                {/* <td></td> */}
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
          <hr />
          <div className="d-flex">
            <h4 className="mb-3">Bid/Contract Outstanding Statement</h4>
            <div className="ms-auto d-flex gap-2">
              <a href="#" className="">
                Download
              </a>
              <a href="#" className="">
                Pdf
              </a>
              <a href="#" className="">
                MS Excel
              </a>
            </div>
          </div>
          <table className="table table-striped border">
            <thead>
              <tr>
                <th>Project ID</th>
                <th>Entry Date</th>
                <th>Transaction Type</th>
                <th>Description</th>
                <th>Nature of Transaction</th>
                <th>Amount</th>
                <th>Net Payable/ (-Receivable)</th>
              </tr>
            </thead>
            <tbody>
              {tableData2.map((row, index) => (
                <tr key={index}>
                  <td>{row.projectId}</td>
                  <td>{row.entryDate}</td>
                  <td>{row.transactionType || "-"}</td>
                  <td>{row.description}</td>
                  <td>{row.natureOfTransaction}</td>
                  <td>{row.amount.toLocaleString()}</td>
                  <td>{row.netPayableReceivable.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default AccountStatement;
