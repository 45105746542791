import axios from "axios";

import toast from "react-hot-toast";

const baseServerUrl = process.env.REACT_APP_baseServerURL;

const BaseService = axios.create({
  baseURL: baseServerUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

const user = JSON.parse(localStorage.getItem("userInfo"));

let token = null;

if (user) {
  token = user.token;
}

BaseService.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx causes this function to trigger
    return response;
  },
  (error) => {
    // Any status codes outside the range of 2xx cause this function to trigger
    const errorMessage =
      error.response?.data?.message || error.message || "Something went wrong";
    toast.error(errorMessage);
    return Promise.reject(error);
  }
);

export default BaseService;
