import React from "react";
import { Link } from "react-router-dom";
// react plugin for creating notifications over the dashboard
// import NotificationAlert from "react-notification-alert";
// react-bootstrap components
import {
  Alert,
  Badge,
  Button,
  Card,

  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';

const Notifications = () =>{
  return (
    <>
    <Topbar />
    {/* <Header /> */}
    <container-fluid className="mb-4">
      <Row>
      <Col lg="2" md="2" sm="4" style={{background: '#2A3FA0'}}>       
        <Sidebar />
      </Col>
      <Col lg="10" md="10" sm="8">
        <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/buyer-notification" >Notifications</Link></div>
            <Card>
              <Card.Header>
                <Card.Title as="h6">Notification from admin</Card.Title>
              </Card.Header>
              <Card.Body>
                <Alert variant="success" style={{ width: "55rem" }}>
                  <Alert.Heading>
                    This is a success alert which has green background
                  </Alert.Heading>
                  <Button variant="success">Make Payment</Button>
                </Alert>
            
                <Alert variant="secondary" style={{ width: "55rem" }}>
                  <Alert.Heading>
                    This is a secondary alert which has grey background
                  </Alert.Heading>
                </Alert>
            
                <Alert variant="primary" style={{ width: "55rem" }}>
                  <Alert.Heading>
                    This is a primary alert which has blue background
                  </Alert.Heading>
                  <Button variant="success">Make Payment</Button>
                </Alert>
            
                <Alert variant="danger" style={{ width: "55rem" }}>
                  <Alert.Heading>
                    This is a danger alert which has red background
                  </Alert.Heading>
                </Alert>
            
                <Alert variant="warning" style={{ width: "55rem" }}>
                  <Alert.Heading>
                    This is a warning alert which has yellow background
                  </Alert.Heading>
                  <Button variant="success">Make Payment</Button>
                </Alert>
            
                <Alert variant="light" style={{ width: "55rem" }}>
                  <Alert.Heading>
                    This is a light alert which has white background
                  </Alert.Heading>
                </Alert>
            
                <Alert variant="dark" style={{ width: "55rem" }}>
                  <Alert.Heading>
                    This is a dark alert which has dark grey background
                  </Alert.Heading>
                </Alert>
            
                <Alert variant="info" style={{ width: "55rem" }}>
                  <Alert.Heading>
                    This is a info alert which has light blue background
                  </Alert.Heading>
                </Alert>
                </Card.Body>
            </Card>
          </Col>
      </Row>
            <Footer />
      </container-fluid>
    
    </>
  )
}

export default Notifications;
