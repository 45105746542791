import React from 'react';
import { Link } from 'react-router-dom';
import './css/auction-listing.css';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
const AuctionListingSec = ({auctionList}) => {
    return (
        <>
        <section className="auctionListing alternat-2 centred">   
            <div className="auto-container">
                <div className="titleSec mb_40">
                    {/* <span className="sub-title lableOrange">Auction</span> */}
                    <h3>Get Max Value : Deal Book</h3>
                </div>
                <div className="">
                        <Swiper
                            autoplay={{ delay: 4000 }}
                            loop={true}
                            // pagination= {{ clickable: true }}
                            spaceBetween={10}
                            slidesPerView={4}

                            // navigation
                            breakpoints={{
                                568: {
                                    slidesPerView: 4,
                                },
                                299: {
                                    slidesPerView: 2,
                                },
                            }}
                        >
                    {auctionList.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className="auction-block wow fadeInUp animated mb_70"
                                data-wow-delay="00ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <Link to={`/auction-details/${item.slug}`}>
                                            <img src={item.projectId.image} alt="" />
                                        </Link>
                                    </figure>
                                    <div className="lower-content border-top">
                                        <div className='block-1 text-start py-2 px-3 border-bottom'>
                                            {/* <span className="mb-0">Bids: {item.bids} ₹</span> */}
                                            <Link to={`/auction-details/${item.slug}`}>
                                                <h6 className=''>
                                                    {item.name.substr(0, 50)} ...
                                                </h6>
                                            </Link>
                                        </div>
                                        
                                        {/* <div className='block-2 pt-0 pb-2 px-3'>
                                            <div className='row pt-2'>
                                                <div className="col-5 text-start">
                                                    <p className=''>Current Bids </p>
                                                    <small>{item.price} ₹</small>
                                                </div>
                                                <div className="col-7">
                                                    <p className='text-end'>Time Reaming </p>
                                                    <small className='text-end d-block'>{  Math.ceil(Math.abs(item.startDate, item.endDate) / (1000 * 60 * 60 * 24)) } </small>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            </SwiperSlide>
                    ))}
                    </Swiper>
                    <div className=''>
                        <Link to="/auction" className="theme-btn btn-two me-3 myBtn"><span>View Current Deals</span></Link>
                        <Link to="/upcoming-auction" className="theme-btn btn-two myBtn"><span>View Future Deals</span></Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default AuctionListingSec;
