import React, { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import forgot from '../../assets/images/forgot.png';
import { Form, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';
import Header from '../../components/header';
import Footer from '../../components/footer';
const Forgot = () => {
    const {id, token} = useParams();
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const [formData, setformData] = useState({
        newpassword: '',
    });
    const navigate = useNavigate()
    const [errors, setErrors] = useState({})
    const handleChange = (name, value) => {
        setformData((pre) => ({
            ...pre,
            [name]: value,
        }))
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const validationError =  Validation(formData);
        if(Object.keys(validationError).length > 0){
            setErrors(validationError);
        }else{
            axios.post(`${baseServerUrl}/resetpassword/${id}/${token}`, formData).then((resp)=> {    
            resp.data.status
                ? toast.success(resp.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                : toast.error(resp.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                })
                navigate('/login', { state: resp.data.message })
            })
            .catch((error) => {
                if (error.response) {
                  toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                } else {
                  toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }
              })
        }
    };

    function Validation(formData){
        const errors = {};
        if (!formData.newpassword.trim()) {
            errors.newpassword = 'New Password is required'
            } else if (formData.newpassword.trim().length < 6) {
            errors.newpassword = 'New Password must be at least 6 characters long'
            }
        return errors;
    }
    return (
        <>
        <Header />
        <div className='forgotPage'>
            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <h1>Reset Password</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Reset Password</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="login contact-section pricing-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 form-column">
                            <div className="form-inner shadow">
                                <div className="row clearfix">

                                    <div className="titleSec mb_45 text-center">
                                        <span className="sub-title lableOrange">Reset Password</span>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 content-column text-center">
                                        <img src={forgot} alt="signin" />
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                                        <div className=" shadow p-3 rounded-3">
                                        <form id="contact-form" className="default-form" onSubmit={handleSubmit} >
                                                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <label htmlFor="newpassword">New Password*:</label>
                                                    <input
                                                        type="password"
                                                        id="newpassword"
                                                        name="newpassword"
                                                        onChange={(e) => handleChange('newpassword', e.target.value)}
                                                        placeholder="Enter New Password"
                                                        autoComplete='off'
                                                        required
                                                    />
                                                    {errors.newpassword && <span className="text-danger">{errors.newpassword}</span>}
                                                </div>
                                                    <div className='text-center'>
                                                        <button className="theme-btn btn-two shadow" type="submit">
                                                            <span className='py-1 px-3'> Reset Password</span>
                                                        </button>
                                                    </div>
                                                </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    <Footer />
    </>
    );
}

export default Forgot;