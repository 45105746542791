import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../../components/header';
import Footer from '../../components/footer';
import AboutCompanySec from "./aboutCompanySec";
import AdvisoryTemSec from "./advisoryTeamSec";
import AboutManagementTeam from "./aboutManagementTeam";
import InvestorLogoSec from "./investorLogoSec";
import HomeBlogSec from '../home/homeBlogSec';

const AboutUs = () => {
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const [perPage] = useState(3);
    const [skip] = useState(0);
    const [blogData, setblogData] = useState([]);
    const [aboutData, setaboutData] = useState({body: ''})
    const [advisoryTeam, setadvisoryTeam] = useState([]);
    const [investerLogo, setinvesterLogo] = useState([]);
    const fetchAbout = async () =>{
        try{
            await axios.get(`${baseServerUrl}/getabout/64a68f624775439e24f0f0d4`).then((respAbout) => {
                setaboutData({...aboutData, body: respAbout.data.data.body})
            })
        }
        catch(err){
            console.log(err);
        }
    }
    const fetchAdvisoryTeam = async () =>{
        try {
            await axios.get(`${baseServerUrl}/alladvisory`).then((respadteam) =>{
                setadvisoryTeam(respadteam.data.data);
            })
        } catch (error) {
            console.log(error);
            
        }
    }
    // const fetchInvester = async () => {
    //     await axios.get(`${baseServerUrl}/allInvestor`).then((respinvester) => {
    //         setinvesterLogo(respinvester.data.data);
    //     })
    // }
    // const fetchBlog = async () =>{
    //     await axios.post(`${baseServerUrl}/allblog`, {page: perPage, skip: skip}).then((respblog) => {
    //         setblogData(respblog.data.data);
    //     })
    // }
    useEffect(() =>{
        fetchAbout();
        fetchAdvisoryTeam();
        // fetchInvester();
        // fetchBlog();
    }, [])
    return (
        <>
        <Header />
        <div className="boxed_wrapper">

{/*  */}
            <AboutCompanySec aboutData={aboutData}/>
            {/* <AboutManagementTeam /> */}
            <AdvisoryTemSec advteamData={advisoryTeam}/>
            {/* <InvestorLogoSec investerList={investerLogo}/> */}
            {/* <HomeBlogSec blogList={blogData} /> */}
        </div>
    <Footer />
    </>
    )
}
export default AboutUs;