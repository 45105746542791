import { Button } from 'antd'
import React, { useState,useEffect } from 'react'
import { Card, Col, Dropdown, Modal, Row, Table} from 'react-bootstrap'
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import Sidebar from '../../components/Sidebar/SidebarBidder';
import { Link } from 'react-router-dom';
import BaseService from '../../config/axiosService';
import toast, { Toaster } from 'react-hot-toast';


const baseServerUrl = process.env.REACT_APP_baseServerURL;


const ProfileDocs = () => {

  const [editingDoc, setEditingDoc] = useState(null);



    const[profiledocsData, setProfileDocsData] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formValues, setFormValues] = useState([{ name: "", email : ""}])
const uploadProfileDocs = async () => {
  const formData = new FormData();
  formData.append('type', formValues.type);
  formData.append('frontImage', formValues.frontImage);
  formData.append('backImage', formValues.backImage);

  try {
    const response = await BaseService.post(`${baseServerUrl}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(response);
    toast.success('Profile Docs uploaded successfully');
    getProfileDocs();
  } catch (error) {
    console.log(error);
    toast.error('Profile Docs upload failed');
  }
  handleClose();
};



const deleteProfileDoc = async (id) => {
  try {
    const response = await BaseService.delete(`${baseServerUrl}/profile-docs/${id}`);
    console.log(response);
    toast.success('Profile Doc deleted successfully');
    getProfileDocs();
  } catch (error) {
    console.log(error);
    toast.error('Profile Doc deletion failed');
  }
};


const handleEdit = (doc) => {
  setFormValues(doc);
  setEditingDoc(doc);
  handleShow();
};


const updateProfileDoc = async (updatedDoc) => {
  const formData = new FormData();
  formData.append('type', updatedDoc.type);
  formData.append('frontImage', updatedDoc.frontImage);
  formData.append('backImage', updatedDoc.backImage);

  try {
    const response = await BaseService.put(`${baseServerUrl}/profile-docs/${editingDoc._id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(response);
    toast.success('Profile Doc updated successfully');
    getProfileDocs();
  } catch (error) {
    console.log(error);
    toast.error('Profile Doc update failed');
  }
  handleClose();
};




  
const getProfileDocs = async () => {
  try {
    const response = await BaseService.get(`${baseServerUrl}/profile-docs`);
    console.log('Response data:', response.data.data);
    setProfileDocsData(response.data.data)
  } catch (error) {
    console.error('Error fetching profile docs:', error);
  }
}
useEffect(() => {
  getProfileDocs();
}, []);  
 
    
 

  return (
    <>
    <Topbar />
    {/* <Header /> */}
    <container-fluid className="mb-4">
      <Row>
      <Col lg="2" md="2" sm="4" style={{background: '#2A3FA0'}}>       
        <Sidebar />
      </Col>
      <Col lg="10" md="10" sm="8">
        <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/profile-docs" >Profile Docs</Link></div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Profile Docs</Card.Title>
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Button variant="warning" className="pull-right btn-sm addnew" onClick={handleShow}>
                      <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Document Type</th>
                      <th className="border-0">Front Image</th>
                      <th className="border-0">Back Image</th>
                      <th className="border-0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
    {profiledocsData.map((doc, index) => (
      <tr key={index}>
        <td>{doc.type}</td>
<td>
<a href={`${baseServerUrl}/${doc.frontImage}`} target="_blank" rel="noopener noreferrer">
    {doc.frontImage}
</a>
</td>        <td><a href={`${baseServerUrl}/${doc.backImage}`} target="_blank" rel="noopener noreferrer">
    {doc.backImage}
</a></td>
        <td></td>
        <td>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm">
              Show
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item>
                <Link onClick={()=>console.log("view")}
                className='link-primary viewButton'>
                  View
                </Link>
              </Dropdown.Item> */}
              <Dropdown.Item>
                <Link
                  onClick={() => handleEdit(doc)}
                  className="link-primary editButton"
                >
                  Edit
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link onClick={()=> deleteProfileDoc(doc._id)} className='link-primary' >
                  Delete
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    ))}
  </tbody>

                  {/* <tbody>
                    {postData.length ? (
                      postData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>Kriscent</td>
                            <td>chiranji</td>
                            <td>+91-9602010307</td>
                            <td>{item.status === 1 ? (
                          <Button
                            className="btn-success btn-sm m-0 pt-0 pb-0"
                          >
                            Active
                          </Button>
                        ) : (
                          <Button
                            className="btn-danger btn-sm m-0 pt-0 pb-0"
                          >
                            Inactive
                          </Button>
                        )}</td>
                            <td>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm">
                                  Show
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <Link
                                        onClick={() => console.log("edit")}
                                        className="link-primary editButton"
                                      >
                                        Edit
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link onClick={() => console.log("delete")} className="link-primary">
                                      Delete
                                    </Link>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="6">no record found</td>
                      </tr>
                    )}
                  </tbody> */}
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
          <Footer />  
      </container-fluid>
      {/* <div></div> */}
      <Modal show={show} centered size="lg">
        <Modal.Header
          closeButton
          onHide={handleClose}
          className=""
        >
<Modal.Title as="h6">
  {editingDoc ? 'Edit Document' : 'Add New Document'}
</Modal.Title>        </Modal.Header>
     <Modal.Body>
  <form className="bookingForm"  encType="multipart/form-data">
    <div class="card-body">
      <div class="row mt-4">
        <div class="col-md-6 pb-3">
          <label for="type_of_document">Type of Document</label>
          <select class="form-control" id="type_of_document" name="type" value={formValues.type} onChange={(e) => setFormValues({ ...formValues, type: e.target.value })} required>
            <option value="">Select Document Type</option>
            <option value="Adhar Card">Adhar Card</option>
            <option value="Pan Card">Pan Card</option>
            <option value="License">License</option>
          </select>
        </div>
        <div class="col-md-6 pb-3">
          <label for="front_image">Front Image</label>
          <input class="form-control" id="front_image" type="file" name="frontImage" onChange={(e) => setFormValues({ ...formValues, frontImage: e.target.files[0] })} required/>
        </div>
        <div class="col-md-6 pb-3">
          <label for="back_image">Back Image</label>
          <input class="form-control" id="back_image" type="file" name="backImage" onChange={(e) => setFormValues({ ...formValues, backImage: e.target.files[0] })} required/>
        </div>
      </div>
    </div>
 <Button type="submit" variant="dark" onClick={(e) => {e.preventDefault(); editingDoc ? updateProfileDoc(formValues) : uploadProfileDocs(formValues)}} className='border'>
  Save
</Button>
  </form>
</Modal.Body>
      </Modal>
    
    </>
  )
}

export default ProfileDocs;
