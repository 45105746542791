// import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { Card, Col, Dropdown, Modal, Row, Table,Button } from "react-bootstrap";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import { Link } from "react-router-dom";
import { State, City } from "country-state-city";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Others = () => {

  const [show, setShow] = useState(false);
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [state, setState] = useState("");
  let [state2, setState2] = useState("");
  const [cities,setCities] = useState([]);
  const [projectHandlingData, setProjectHandlingData] = useState([]);
  const [updateShow, setUpdateShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [updateSelectProject, setUpdateSelectProject] = useState({});
  const [selectedProject, setSelectedProject] = useState({});
  
  const [addFormData, setAddFormData] = useState({});

  // const handleAddChange = (e) => {
  //   setAddFormData({
  //     ...addFormData,
  //     [e.target.name]: e.target.value,
  //   });

  // }


  const states = State.getStatesOfCountry("IN");
  let handleChange = (i, e) => {
    setAddFormData({
      ...addFormData,
      [i]: e,
    });
    
  };

  const handleDelete = (id) => {
    axios.delete(`${baseServerUrl}/deleteprojecthandling/${id}`,{
      headers:{
        Authorization: `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response)=>{
      console.log(response.data);
      getProjectHandlingData();
      toast.success("Project Handling Experience Deleted Successfully");

    }).catch((error)=>{
      console.log(error);
      toast.error("Error in deleting Project Handling Experience");
    })
  }

  const handleAddSubmit = (slug) => {
    // API call to add project handling data
    // if(!addFormData.state || !addFormData.city || !addFormData.project_size_min || !addFormData.project_size_Max || !addFormData.major_legal || !addFormData.key_managerial){
    //   toast.error("Please fill all the fields");
    //   return;
    // }
    axios.post(`${baseServerUrl}/createprojecthandling`,{
      "state": addFormData.state,
      city: addFormData.city,
      projectSizeCapability: {
        minSize: addFormData.project_size_min,
        maxSize: addFormData.project_size_Max
      },
      legalCaseUnderLitigation: addFormData.major_legal,
      keyManagerialPerson: {
        convictedForOffence: state == "Yes" ? "yes" : "no",
        details: addFormData.key_managerial
      }
    },{
      headers:{
        Authorization: `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response)=>{
      console.log(response.data);
      getProjectHandlingData();
      if(!slug){
        handleClose();
      }
      setAddFormData({
        state: "",
        city: "",
        project_size_min: "",
        project_size_Max: "",
        major_legal: "",
        key_managerial: ""

      });
      toast.success("Project Handling Experience Added Successfully");
    }).catch((error)=>{
      console.log(error);
      toast.error("Error in adding Project Handling Experience");
    })
  }

  
  const handleCitySearch = (iso) => {
    let cities = City.getCitiesOfState("IN", iso);
    setCities(cities);
  }

  const getProjectHandlingData = () => {
    // API call to get project handling data
    axios.get(`${baseServerUrl}/getprojecthandlingbyuserid`,{
      headers:{
        Authorization: `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response)=>{
      console.log(response.data);
      setProjectHandlingData(response.data.projectHandlingExperiences);
    }).catch((error)=>{
      console.log(error);
    })
  }

  const handleUpdateChange = (i,e) => {
    if(i == "project_size_min"){
      setUpdateSelectProject({
        ...updateSelectProject,
        projectSizeCapability:{
          ...updateSelectProject.projectSizeCapability,
          minSize: e
        }
      })
      return;
    }
    else if(i == "project_size_Max"){
      setUpdateSelectProject({
        ...updateSelectProject,
        projectSizeCapability:{
          ...updateSelectProject.projectSizeCapability,
          maxSize: e
        }
      })
      return;
    }
    else if(i == "major_legal"){
      setUpdateSelectProject({
        ...updateSelectProject,
        legalCaseUnderLitigation: e
      })
      return;
    }
    else if(i == "key_managerial"){
      setUpdateSelectProject({
        ...updateSelectProject,
        keyManagerialPerson:{
          convictedForOffence: state2.toLowerCase(),
          ...updateSelectProject.keyManagerialPerson,
          details: e
        }
      })
      return;
    }
    else if(i == "state"){
      setUpdateSelectProject({
        ...updateSelectProject,
        state: e
      })
      return;
    }
    else if(i == "city"){
      setUpdateSelectProject({
        ...updateSelectProject,
        city: e
      })
      return;
    }
  }

  const handelUpdateSubmit = () => {
    axios.put(`${baseServerUrl}/updateprojecthandling/${updateSelectProject._id}`,updateSelectProject,{
      headers:{
        Authorization: `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response)=>{
      console.log(response.data);
      getProjectHandlingData();
      toast.success("Project Handling Experience Updated Successfully");
      setUpdateShow(false);
    }).catch((error)=>{
      console.log(error);
      toast.error("Error in updating Project Handling Experience");
    })
  }

  useEffect(() => {
    getProjectHandlingData();
  }, []);

 

  return (
    <>
    <Topbar />
    {/* <Header /> */}
    <container-fluid className="mb-4">
      <ToastContainer />
      <Row>
        <Col lg="2" md="2" sm="4" >
          <Sidebar />
        </Col>
        <Col lg="10" md="10" sm="8" className="ps-2">
          <div className="tophd">
            Dashboard{" "}
            <span style={{ color: "#000" }}>
              <i className="fa fa-angle-right"></i>
            </span>{" "}
            <Link to="/branches">Project Handling</Link>
          </div>
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <Card.Title as="h6">Manage Project Handling</Card.Title>
                </Col>
                <Col lg="6" md="6" sm="6">
                  <Button
                    variant="warning"
                    className="pull-right btn-sm addnew"
                    onClick={handleShow}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th className="border-0">State</th>
                    <th className="border-0">City</th>
                    <th className="border-0">
                      Project Size Capability (Min Size) in Lakhs
                    </th>
                    <th className="border-0">
                      Project Size Capability (Max Size) in  Lakhs
                    </th>
                    <th className="border-0">
                      Legal Case Under Litigation
                    </th>
                    <th className="border-0">
                      Whether Any Key Managerial Person Convicted for Any
                      Offence
                    </th>
                    <th className="border-0">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {projectHandlingData.length ? (
                    projectHandlingData.map((projectHandling, index) => {
                      return (
                        <tr key={index}>
                          <td>{projectHandling.state.split(" ")[1]}</td>
                          <td>{projectHandling.city}</td>
                          <td>{projectHandling.projectSizeCapability.minSize}</td>
                          <td>{projectHandling.projectSizeCapability.maxSize}</td>
                          <td>{projectHandling.legalCaseUnderLitigation}</td>
                          <td>{projectHandling.keyManagerialPerson.convictedForOffence}</td>                     
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                size="sm"
                              >
                                Show
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                              <Dropdown.Item>
                                  <Link
                                      onClick={() => {
                                        setViewShow(true);
                                        setSelectedProject(projectHandling);
                                      }}
                                      className="link-primary viewButton"
                                    >
                                      View
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <Link
                                    onClick={() => {
                                      setUpdateShow(true);
                                      setUpdateSelectProject(projectHandling);
                                      setState2(projectHandling.keyManagerialPerson?.convictedForOffence == 'yes' ? "Yes" : "No");
                                      handleCitySearch(projectHandling.state.split(" ")[0]);
                                    }}
                                    className="link-primary editButton"
                                  >
                                    Edit
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <Link
                                    onClick={() => handleDelete(projectHandling._id)}
                                    className="link-primary"
                                  >
                                    Delete
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colspan="6">no record found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </container-fluid>
    {/* <div></div> */}
    <Modal show={show} centered size="lg">
      <Modal.Header
        closeButton
        onHide={handleClose}
        className="text-white"
      >
        <Modal.Title className="text-black" as="h6">Add New Project Handling Experience</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="bookingForm">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label for="branch">State</label>
                <select class="form-control" id="state" name="state" required onChange={(e)=>{
                  handleCitySearch(e.target.value.split(" ")[0]);
                  handleChange("state", e.target.value);
                }}>
                  <option value="">Select State</option>
                  {states.map((item, index) => {
                      return <option value={`${item.isoCode} ${item.name}`}>{item.name}</option>;
                  })}
                </select>
             
              </div>
              <div class="col-md-6">
                <label for="email">City</label>
                <select class="form-control" id="city" name="city" required onChange={
                  (e)=>handleChange("city", e.target.value)
                }>
                  <option value="">Select City</option>
                  {cities.map((item, index) => {
                      return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
                
              </div>
              <div style={{height:"1px",backgroundColor:"gray",margin:"0",marginBottom:"5px"}}></div>

              <div class="col-md-12 d-flex">
                <label for="name" className="text-black pt-2 fs-6">Project Size Capability (Rs. in Lakhs)?</label>
                <div class="row">
                  <div class="col-md-6">
                    <input
                      class="form-control"
                      id="project_size_capability_min"
                      placeholder="Min Size"
                      type="text"
                      value={addFormData.project_size_min}
                      onChange={(e) =>
                        handleChange("project_size_min", e.target.value)
                      }
                      required
                    />
                  
                  </div>
                  
                  <div class="col-md-6">
                    <input
                      class="form-control"
                      id="project_size_capability_Max"
                      placeholder="Max Size"
                      value={addFormData.project_size_Max}
                      type="text"
                      onChange={(e) =>
                        handleChange("project_size_Max", e.target.value)
                      }
                      required
                    />
                 
                  </div>
                </div>
              </div>
              <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>

              <div class="col-md-12 d-flex">
                <label for="name" className="text-black pt-2 fs-6">Legal Case Under Litigation?</label>
               

                <input  
                    class="form-control"
                  id="major_legal"
                  placeholder="Major Legal Case Under Litigation"
                  type="text"
                  value={addFormData.major_legal}
                  onChange={(e) =>
                    handleChange("major_legal", e.target.value)
                  }
                  required/>
              </div>
              <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>


              <div class="col-md-12 d-flex">
                <label for="name" className="text-black pt-2 fs-6">
                  Whether Any Key Managerial Person Convicted for Any Offence?
                </label>
                <select
                  class="form-control"
                  id="key_managerial"
                  placeholder="Whether Any Key Managerial Person Convicted for Any Offence"
                  type="text"
                  onChange={(e)=>setState(e.target.value)}
                  required
                >
                  <option value={"No"}>
                    Select Yes/No
                  </option>
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </select>
               
              </div>
              {state == "Yes" &&            <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>
}
         {state === "Yes" ? <div class="col-md-12 d-flex">
                <label for="name" className="text-black pt-2 fs-6">
                Details
                </label>
                <input
                  class="form-control"
                  id="key_managerial"
                  type="text"
                  value={addFormData.key_managerial}
                  onChange={(e) =>
                    handleChange("key_managerial", e.target.value)
                  }
                  required
                />
              
              </div>:<></>}

            </div>
          </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0",marginTop:"0"}}></div>

          <Button
            
            className="btn btn-sm"
            color="#5C79FF"
            onClick={()=>handleAddSubmit()}
            style={{backgroundColor:"#E2E7FF",color:"#5C79FF"}}
          >
            Save & Exit
          </Button>
          {"   "}
          <Button
            
            className="btn btn-primary btn-sm" // Modified: Changed button color to primary
            onClick={()=>handleAddSubmit(true)}
            style={{backgroundColor:"#5C79FF"}}
          >
            Save & Add More Project Handling Exp.
          </Button>
        </form>
      </Modal.Body>
    </Modal>
    <Modal show={updateShow} centered size="lg">
      <Modal.Header
        closeButton
        onHide={()=>setUpdateShow(false)}
        className="text-white"
      >
        <Modal.Title className="text-black" as="h6">Update Project Handling Experience</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="bookingForm">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label for="branch">State</label>
                <select class="form-control" id="state" name="state" required onChange={(e)=>{
                  handleCitySearch(e.target.value.split(" ")[0]);
                  handleUpdateChange("state", e.target.value);
                }} value={updateSelectProject.state}>
                  <option value="">Select State</option>
                  {states.map((item, index) => {
                      return <option value={`${item.isoCode} ${item.name}`}>{item.name}</option>;
                  })}
                </select>
             
              </div>
              <div class="col-md-6">
                <label for="email">City</label>
                <select class="form-control" id="city" name="city" required onChange={
                  (e)=>handleUpdateChange("city", e.target.value)
                  
                } value={updateSelectProject.city}>
                  <option value="">Select City</option>
                  {cities.map((item, index) => {
                      return <option value={item.name}>{item.name}</option>;
                  })}
                </select>
                
              </div>
              <div style={{height:"1px",backgroundColor:"gray",margin:"0",marginBottom:"5px"}}></div>

              <div class="col-md-12 d-flex">
                <label for="name" className="text-black pt-2 fs-6">Project Size Capability (Rs. in Lakhs)?</label>
                <div class="row">
                  <div class="col-md-6">
                    <input
                      class="form-control"
                      id="project_size_capability_min"
                      placeholder="Min Size"
                      type="text"
                      onChange={(e) =>
                        handleUpdateChange("project_size_min", e.target.value)
                      }
                      value={updateSelectProject.projectSizeCapability?.minSize}
                      required
                    />
                  
                  </div>
                  
                  <div class="col-md-6">
                    <input
                      class="form-control"
                      id="project_size_capability_Max"
                      placeholder="Max Size"
                      type="text"
                      onChange={(e) =>
                        handleUpdateChange("project_size_Max", e.target.value)
                      }
                      value={updateSelectProject.projectSizeCapability?.maxSize}
                      required
                    />
                 
                  </div>
                </div>
              </div>
              <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>

              <div class="col-md-12 d-flex">
                <label for="name" className="text-black pt-2 fs-6">Legal Case Under Litigation?</label>
               

                <input  
                    class="form-control"
                  id="major_legal"
                  placeholder="Major Legal Case Under Litigation"
                  type="text"
                  onChange={(e) =>
                    handleUpdateChange("major_legal", e.target.value)
                  }
                  value={updateSelectProject.legalCaseUnderLitigation}
                  required/>
      
              </div>
              <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>


              <div class="col-md-12 d-flex">
                <label for="name" className="text-black pt-2 fs-6">
                  Whether Any Key Managerial Person Convicted for Any Offence?
                </label>
                <select
                  class="form-control"
                  id="key_managerial"
                  placeholder="Whether Any Key Managerial Person Convicted for Any Offence"
                  type="text"
                  onChange={(e)=>{
                    setUpdateSelectProject({
                      ...updateSelectProject,
                      keyManagerialPerson:{
                        ...updateSelectProject.keyManagerialPerson,
                        convictedForOffence: e.target.value.toLowerCase()
                      }
                    })
                    setState2(e.target.value);
                  }}
                  value={updateSelectProject.keyManagerialPerson?.convictedForOffence == "yes" ? "Yes" : "No"}
                  required
                >
                  <option value={""}>
                    Select Yes/No
                  </option>
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </select>
               
              </div>
              {state2 == "Yes" &&            <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>
}
         {state2 === "Yes" ? <div class="col-md-12 d-flex">
                <label for="name" className="text-black pt-2 fs-6">
                Details
                </label>
                <input
                  class="form-control"
                  id="key_managerial"
                  type="text"
                  value={updateSelectProject.keyManagerialPerson?.details}
                  onChange={(e) =>
                    handleUpdateChange("key_managerial", e.target.value)
                  }
                  required
                />
              
              </div>:<></>}

            </div>
          </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0",marginTop:"0"}}></div>

          <Button
            
            className="btn btn-sm"
            color="#5C79FF"
            onClick={()=>setUpdateShow(false)}
            style={{backgroundColor:"#E2E7FF",color:"#5C79FF"}}
          >
            Close
          </Button>
          {"   "}
          <Button
            
            className="btn btn-primary btn-sm" // Modified: Changed button color to primary
            onClick={handelUpdateSubmit}
            style={{backgroundColor:"#5C79FF"}}
          >
            Update
          </Button>
        </form>
      </Modal.Body>
    </Modal>
    <Modal show={viewShow} centered size="md">
      <Modal.Header
        closeButton
        onHide={()=>setViewShow(false)}
        className=""
      >
        <Modal.Title className="text-black" as="h6">View Project Handling Exp Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedProject && (
          <>
            <h4>Project Handling Exp Details:</h4>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0"}}></div>
            <p><strong>State:</strong> {selectedProject.state}</p>
            <p><strong>City:</strong> {selectedProject.city}</p>
            <p><strong>Project Size Capability (Min Size):</strong> {selectedProject.projectSizeCapability?.minSize}</p>
            <p><strong>Project Size Capability (Max Size):</strong> {selectedProject.projectSizeCapability?.maxSize}</p>
            <p><strong>Legal Case Under Litigation:</strong> {selectedProject.legalCaseUnderLitigation}</p>
            <p><strong>Whether Any Key Managerial Person Convicted for Any Offence:</strong> {selectedProject.keyManagerialPerson?.convictedForOffence}</p>
            {selectedProject.keyManagerialPerson?.convictedForOffence === "yes" ? <p><strong>Details:</strong> {selectedProject.keyManagerialPerson?.details}</p> : <></>}
          </>
        )}
      </Modal.Body>
    </Modal>              
    <Footer />
  </>
  );
};

export default Others;
