import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdMail } from "react-icons/io";
import DatePicker from "react-datepicker";
import { FaPhone } from "react-icons/fa6";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import uploadFile from "../../helpers/uploadFile"

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";
import axios from "axios";
function SellerGeneral() {
  const [errors,setErrors] = React.useState({});
  const [startDate, setStartDate] = useState(new Date());   
  const nav = useNavigate();
  const [msme, setMSME] = React.useState("");
  const userData = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [formData, setFormData] = React.useState({});
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [pan,setPan] = useState("");
  const [website,setWebsite] = useState("");
  const handleChanageFilter = () => {};
  const handleChange = (e) => {
    // regex for pan card number
    const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
    console.log(panRegex.test(e.target.value));
    setFormData({
      ...formData,
      "panNumber": e.target.value,
    });
    if (!panRegex.test(e.target.value)) {
      setErrors(prev=>({...prev,pan:"Please enter valid pan card number"}));
    } else {
      setErrors(prev=>({...prev,pan:""}));
    }
  };
  const handleChangeWebsite = (e) => {
    // regex for pan card number
    const websiteRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    console.log(websiteRegex.test(e.target.value));
    setFormData({
      ...formData,
      "websiteUrl": e.target.value,
    });
    if (!websiteRegex.test(e.target.value)) {
      setErrors(prev=>({...prev,website:"Please enter valid website"}));
    } else {
      setErrors(prev=>({...prev,website:""}));
    }
  }

  const getUser = () => {
    axios.get(`${baseServerUrl}/getgeneralbyid`,{
      headers:{
        Authorization:`${userData.token}`
      }
    }).then((response)=>{
      console.log(response.data);
      // console.log(new Date(response.data.dateOfIncorporation.split("T")[0]));
      setFormData(response.data);
    }).catch((error)=>{
      console.log(error);
    })
  }

  const handleSubmit = () => {
    console.log(formData);
    axios.patch(`${baseServerUrl}/updategeneral`,formData,{
      headers:{
        Authorization:`${userData.token}`
      }
    }).then((response)=>{
      console.log(response.data);
      toast.success("General Updated Successfully");
    }).catch((error)=>{
      console.log(error);
      toast.error("General Updation Failed");
    })
  }
  const saveAndNext = () => {
    handleSubmit();
    nav("/seller-bank");
  }
  useEffect(()=>{
    getUser();
  },[])
  
  return (
    <>
    {/* <Topbar /> */}
    {/* <Header /> */}
  <Topbar />
  {/* <Header /> */}
  <container-fluid className="mb-4 pl-3">
  <ToastContainer/>
    <Row>
    <Col lg="2" md="2" sm="4" style={{background: "#2B3FA1",paddingRight:"0"}}>       
      <Sidebar />
    </Col>
    <Col lg="10" md="10" sm="8" className="ps-2 pe-3">
      <div className="tophd">Profile <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/seller-profile" >General</Link></div>
      <div className="">
        <Row>
          <div className="fs-6 text-black d-flex gap-3">
            
          <p style={{color:"#2A3FA0",borderRight:"1px solid black",paddingRight:"10px"}}><IoMdMail/>  : {userData.email}</p>
           <p style={{color:"#2A3FA0"}}><FaPhone/> : {userData.phone}</p>
           {/* <p style={{color:"#2A3FA0"}}>{userData}</p> */}

          </div>
        </Row>
        <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0"}}></div>
      <Row>
                <Col className="pr-1" md="11.5">
                  <div
                    className={`form__item ${
                      errors.company && "input__error"
                    }`}
                  >
                    {/* <p>{JSON.stringify(formData}</p> */}
                    <div className="form-control">
                      <input
                        type="text"
                        name="organisation"
                        className="form-input"
                        placeholder="none"
                        value={formData.organisation}
                        onChange={(e)=>{
                          setFormData({
                            ...formData,
                            organisation:e.target.value
                          })
                        }}
                        required
                      />
                      <label for="name" className="form-label">
                        Organisation *
                      </label>
                    </div>
                    <p className={"error__feedback"}>{errors.company}</p>
                  </div>
                </Col>
                <Col className="pr-1" md="3">
                  <div
                    className={`form__item ${errors.name && "input__error"}`}
                  >
                    <div className="form-control">
                      <select
                        name={"legalStatus"}
                        placeholder="choose Legal Status"
                        className="form-input"
                        style={{ width: "100%" }}
                        value={formData.legalStatus}
                        onChange={(e)=>{
                          setFormData({
                            ...formData,
                            legalStatus:e.target.value
                          })   
                        }}
                      >
                        <option value="" disabled>Choose Status</option>
                        <option value={"Proprietorship"}>Proprietorship</option>
                        <option value={"Partnership"}>Partnership Firm</option>
                        <option value={"Company"}>Company </option>
                        <option value={"LLP"}>LLP</option>
                        <option value={"Trust/AoP/Other"}>Trust/AoP/Others</option>
                      </select>
                      <label for="name" className="form-label">
                        Legal Status *
                      </label>
                    </div>
                    <p className={"error__feedback"}>{errors.name}</p>
                  </div>
                </Col>

                  <Col className="position-relative" md="3">
                    
                  <p className="text-black bg-white position-absolute" style={{top:"-13px",right:"35%"}}>Date of Incorporation *</p>
                 <div className="border rounded p-1">
                      {/* <DatePicker
                       showIcon
                       selected={new Date()}
                       value={new Date(formData.dateOfIncorporation?.split("T")[0])}
                       name="dateOfIncorporation"
                       onChange={(date) => {setFormData({
                          ...formData,
                          dateOfIncorporation: date.toISOString()
                       })}}/> */}
                       <input
                        type="date"
                        name="dateOfIncorporation"
                        className="form-input"
                        value={formData.dateOfIncorporation?.split("T")[0]}
                        onChange={(e)=>{
                          setFormData({
                            ...formData,
                            dateOfIncorporation:e.target.value
                          })
                        }}
                        required
                       />
                     </div>
                  </Col>
                  
                  <Col className="pl-1" md="6">
                  <div
                    className={`form__item ${errors.website && "input__error"}`}
                  >
                    <div className="form-control" style={{marginBottom:"0"}}>
                      <input
                        type="text"
                        name="websiteUrl"
                        className="form-input"
                        placeholder="none"
                        onChange={(e)=>handleChangeWebsite(e)}
                        value={formData.websiteUrl}
                        required
                      />
                      <label for="name" className="form-label">
                        Website *
                      </label>
                    </div>
                    <p className={"error__feedback"}>{errors.website}</p>
                  </div>
                </Col>
              </Row>
              <Row>
      
           
              <Col className="pr-1" md="3">
                <div className={`form__item ${errors.MSME_status && "input__error"}`}>
                  <div className="form-control">
             <select className="form-input" value={formData.msmeStatus} onChange={(e)=>{
                setFormData({
                  ...formData,
                  msmeStatus:e.target.value
                })
             }}>
              <option value="" disabled>Choose MSME Status</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>

             </select>
                    <label htmlFor="name" className="form-label">MSME Status *</label>
                  </div>
                  <p className={"error__feedback"}>{errors.MSME_status}</p>
                </div>
              </Col> 
              {formData.msmeStatus==='Yes' && (
                
                <Col className="pl-1" md="3">
                <div className={`form__item ${errors.pan && "input__error"}`}>
                  <div className="form-control">
                    <input type="file" name="" className="form-input" 
                      onChange={async (e) => {
                          const files = e.target.files;
                          const paths = await uploadFile(files);
                          setFormData({
                            ...formData,
                            msme: paths[0], 
                          });
                        }} required />
                    <label for="name" className="form-label">MSME</label>
                  </div>
                  <p className={"error__feedback"}>{errors.msme}</p>
                </div>
              </Col>
              )}
              
                <Col className="pl-1" md="3">
                <div className={`form__item ${errors.pan && "input__error"}`}>
                  <div className="form-control" style={{marginBottom:0}}>
                    <input onChange={(e)=>handleChange(e)} value={formData.panNumber} name="panNumber" className="form-input" placeholder="none" required />
                    <label for="name" className="form-label">PAN Number *</label>
                  </div>
                  <p className={"error__feedback"}>{errors.pan}</p>
                </div>
              </Col>
                <Col className="pl-1" md="3">
                <div className={`form__item ${errors.pan && "input__error"}`}>
                  <div className="form-control">
                    <input type="file" name="pancard" className="form-input" placeholder="none" 
                    
                onChange={async (e) => {
  const files = e.target.files;
  const paths = await uploadFile(files);
  setFormData({
    ...formData,
    pancardcopy: paths[0],
  });
                  }}
                    required 

                    />
                    <label for="name" className="form-label">PAN Card Copy</label>
                  </div>
                  {/* <p className={"error__feedback"}>{errors.pan}</p> */}
                </div>
              </Col>
              </Row>
              {/* <Row>
                <Col className="pl-1" md="2">
                  <div
                    className={`form__item ${
                      errors.pincode && "input__error"
                    }`}
                  >
                    <div className="form-control">
                      <input
                        className="form-input"
                        id="pincode"
                        style={{ "line-height": "30px" }}
                        placeholder="Enter pin code"
                        type="text"
                        onChange={(e) =>
                          handleChanageFilter("pincode", e.target.value)
                        }
                        required
                      />
                      <label for="name" className="form-label">
                        Postal Code *
                      </label>
                    </div>

                    <p className={"error__feedback"}>{errors.pincode}</p>
                  </div>
                </Col>
                <Col className="pr-1" md="5">
                  <div
                    className={`form__item ${errors.state && "input__error"}`}
                  >
                    <div className="form-control">
                      <input
                        type="String"
                        placeholder=" "
                        readonly
                        value={data.state}
                        class="inp form-input"
                        required
                      />
                      <label for="name" className="form-label">
                        {" "}
                        State *
                      </label>
                    </div>
                    <p className={"error__feedback"}>{errors.state}</p>
                  </div>
                </Col>
                <Col className="pr-1" md="5">
                  <div
                    className={`form__item ${errors.city && "input__error"}`}
                  >
                    <div className="form-control">
                      <input
                        type="String"
                        placeholder=""
                        readonly
                        value={data.district}
                        class="inp form-input"
                        required
                      />
                      <label for="name" className="form-label">
                        {" "}
                        District *
                      </label>
                    </div>
                    <p className={"error__feedback"}>{errors.city}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div
                    className={`form__item ${
                      errors.address1 && "input__error"
                    }`}
                  >
                    <div className="form-control">
                      <input
                        type="text"
                        name="website"
                        className="form-input"
                        placeholder="none"
                        required
                      />
                      <label for="name" className="form-label">
                        Address Line 1 *
                      </label>
                    </div>
                    <p className={"error__feedback"}>{errors.address1}</p>
                  </div>
                </Col>
              </Row> */}
        <div style={{height:"1px",backgroundColor:"gray",margin:"10px 0"}}></div>

               <div className="d-flex gap-2">
               <Button
            className="btn btn-sm"
            color="#5C79FF"
            style={{backgroundColor:"#E2E7FF",color:"#5C79FF"}}
            onClick={handleSubmit}
          >
            Save 
          </Button>
          {"   "}
          <Button
            onClick={saveAndNext}
            className="btn btn-primary btn-sm" // Modified: Changed button color to primary
            style={{backgroundColor:"#5C79FF"}}
            >
            Save & Next</Button>
              </div>
              </div>
    </Col>
    </Row>
    <Footer />
    </container-fluid>
    {/* <div></div> */}
  
  </>
  );
}

export default SellerGeneral;