import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  Modal,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import Moment from "react-moment";

function Transaction() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [postData, setpostData] = useState([]);
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [perPage] = useState(5);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const handlePage = (key) => {
    setSkip("");
    setSkip(key - 1);
    console.log(key);
  };
  const handlePageNext = () => {
    if (pageCount > skip) {
      setSkip(skip + 1);
    }
  };
  const handlePagePrevious = () => {
    if (skip >= 1) {
      setSkip(skip - 1);
    }
  };
  const fetchPost = async () => {
    await axios
      .post(`${baseServerUrl}/getalltransaction`, {
        buyerID: userInfo.Id,
        page: perPage,
        skip: skip,
      })
      .then((resp) => {
        setpostData(resp.data.data);
        setPageCount(Math.ceil(resp.data.count / perPage));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  useEffect(() => {
    fetchPost();
  }, [skip]);
  return (
    <>
    <Topbar />
    <div className=""></div>
    {/* <Header /> */}
    <container-fluid className="mb-4">
      <Row>
      <Col lg="2" md="2" sm="4" style={{background: '#2A3FA0'}}>       
        <Sidebar />
      </Col>
      <Col lg="10" md="10" sm="8">
        <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/transaction" >Transaction</Link></div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h6">Transaction History</Card.Title>
                
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th style={{"width": "15px" }}>#</th>
                      <th>Auction</th>
                      <th>Source</th>
                      <th>Amount</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                  {postData.length ? (
                      postData.map((item, index) => {
                        return (
                          <tr>
                            <td style={{"width": "15px" }}>{index+1}</td>
                            <td>{item.eauctionID.name}</td>
                            <td>Security Amount</td>
                            <td>{item.amount}</td>
                            <td>
                              <Moment format="DD-MM-YYYY HH:mm:ss a">
                                {item.amountDate}
                              </Moment>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="9">no record found</td>
                      </tr>
                    )}
                  </tbody>
                  <div className={"mt-2"}>
                    <Pagination>
                      <Pagination.Prev
                        className="diabled"
                        onClick={handlePagePrevious}
                        aria-label="Previous"
                      />
                      {[...Array(pageCount)]?.map((item, key) => {
                        return (
                          <Pagination.Item
                            key={key}
                            onClick={(e) => handlePage(key + 1)}
                            className={`${skip === key ? "active" : ""}`}
                          >
                            {key + 1}
                          </Pagination.Item>
                        );
                      })}
                      <Pagination.Next
                        onClick={handlePageNext}
                        aria-label="Next"
                      />
                    </Pagination>
                  </div>
                </Table>
              </Card.Body>
            </Card>
          </Col>
      </Row>
            <Footer />
      </container-fluid>
    </>
  );
}

export default Transaction;
