import React, { useState, useEffect } from "react";
import { Steps } from "antd";
import { Provider } from "./MultiStepFormContext";
import { IoCloseSharp } from "react-icons/io5";
import General from "./General";
import Compliance from "./Compliance";
// import Geographical from "./Geographical";
import Experience from "./WorkExperience";
import Financial from "./Financial";
import Other from "./Other";
import Bank from "./Bank"
import Save from "./Save";
import { RightOutlined } from "@ant-design/icons";
import {useNavigate} from "react-router-dom"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Destructuring Steps component from antd
const { Step } = Steps;

// Initial state values for user details, address details and links details
const generalInitialState = {
  name: "",
  username: "",
  email: "",
  company: "",
  address1: "",
  city: "",
  state: "",
  phone: "",
  pincode: ""
};

const complianceInitialState = {
  address1: "",
  city: "",
  country: "",
  state: "",
  phone: ""
};

// const geographicalInitialState = {
//   states_of_working: "",
//   district_of_working: "",
//   mumbai_suburb: "",
//   particular_district: "",
//   particular_city: ""
// };

const experienceInitialState = {
  type_of_work_handled: "",
  size_of_the_projects_done: "",
  name_of_the_major_clients: ""
};

const financialInitialState = {
  turnover_financial_years: "",
  turnover_to_scrap_handling: "",
  turnover_to_physical_verification: "",
  turnover_to_inspection_services: "",
  turnover_to_real_estate_valuation: "",
  turnover_to_plant_and_machinery: "",
  turnover_to_financial_valuation: "",
  net_worth_of_the_company: "",
  minimum_order_for_participation: "",
  maximum_order_for_participation: "",
  holder_name: "",
  account_number: "",
  bank_name: "",
  IFSC_code: "",
  type_of_account: "",
  account_status: ""
};

const otherInitialState = {
  portfolio: "",
  github: "",
  website: ""
};
const bankInitialState = {

}
 

// Main component
const MultiStepForm = () => {
  // Initializing state for user details, address details, links details and current step
  const nav = useNavigate();
  const [general, setGeneral] = useState(
    JSON.parse(localStorage.getItem("general")) || generalInitialState
  );
  const [compliance, setCompliance] = useState(
    JSON.parse(localStorage.getItem("compliance")) || complianceInitialState
  );
  // const [geographical, setGeographical] = useState(
  //   JSON.parse(localStorage.getItem("geographical")) || geographicalInitialState
  // );
  const [experience, setExperience] = useState(
    JSON.parse(localStorage.getItem("experience")) || experienceInitialState
  );
  const [financial, setFinancial] = useState(
    JSON.parse(localStorage.getItem("financial")) || financialInitialState
  );
  const [bank, setBank] = useState(JSON.parse(localStorage.getItem("bank")) || bankInitialState);
  const [other, setOther] = useState(
    JSON.parse(localStorage.getItem("other")) || otherInitialState
  );
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  // Save state values to localStorage
  useEffect(() => {
    localStorage.setItem("general", JSON.stringify(general));
    localStorage.setItem("compliance", JSON.stringify(compliance));
    // localStorage.setItem("geographical", JSON.stringify(geographical));
    localStorage.setItem("experience", JSON.stringify(experience));
    localStorage.setItem("financial", JSON.stringify(financial));
    localStorage.setItem("bank", JSON.stringify(bank));
    localStorage.setItem("other", JSON.stringify(other));
  }, [
      general,
      compliance,
      // geographical,
      experience,
      financial,
      bank,
      other
    ]);
  // Function to navigate to the next step
  const next = () => {
    if (currentStep === 6) {
      // Resetting state values and going back to the first step after submitting the form
      setCurrentStep(0);
      setGeneral(generalInitialState);
      setCompliance(complianceInitialState);
      // setGeographical(geographicalInitialState);
      setExperience(experienceInitialState);
      setFinancial(financialInitialState);
      setOther(otherInitialState);
      localStorage.removeItem("general");
      localStorage.removeItem("compliance");
      // localStorage.removeItem("geographical");
      localStorage.removeItem("experience");
      localStorage.removeItem("financial");
      localStorage.removeItem("bank");
      localStorage.removeItem("other");
      nav("/dashboard");
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  // Function to navigate to the previous step
  const prev = () => setCurrentStep(currentStep - 1);
  const handleCancel = () => {
    navigate("/dashboard");
  } 

  return (
    // Providing state values to the MultiStepFormContext provider
    <Provider
      value={{
        general,
        setGeneral,
        next,
        prev,
        compliance,
        setCompliance,
        // geographical,
        // setGeographical,
        experience,
        setExperience,
        financial,
        setFinancial,
        bank,
        setBank,
        other,
        setOther
      }}
    >
      
      
      <div className="border p-3 rounded">
      <div className="d-flex justify-content-end"><IoCloseSharp onClick={handleCancel} style={{
        fontSize:"30px",
        cursor:"pointer"
      }}/></div>
      <ToastContainer />
        <ul className="d-flex flex-wrap justify-content-between px-9 py-2" style={{
          fontSize:"14px",
          fontWeight:"500"
        }}> 
          <li className={`d-flex gap-2 align-items-center  border p-2 rounded border-primary font-weight-bold ${currentStep >= 0 ? 'bg-primary text-white':'bg-white text-primary'}`}><span style={{padding:"3px 12px"}} className={`rounded-circle ${currentStep >= 0 ? "bg-white text-primary":"bg-primary text-white" }`}>1</span>General</li>
          <RightOutlined color="#258BD5" />
          <li className={`d-flex gap-2 align-items-center  border p-2 rounded border-primary font-weight-bold ${currentStep >= 1 ? 'bg-primary text-white':'bg-white text-primary'}`}><span style={{padding:"3px 12px"}} className={`rounded-circle ${currentStep >= 1 ? "bg-white text-primary":"bg-primary text-white" }`}>2</span>Compliance</li>
          <RightOutlined color="#258BD5" />
          <li className={`d-flex gap-2 align-items-center  border p-2 rounded border-primary font-weight-bold ${currentStep >= 2 ? 'bg-primary text-white':'bg-white text-primary'}`}><span style={{padding:"3px 12px"}} className={`rounded-circle ${currentStep >= 2 ? "bg-white text-primary":"bg-primary text-white" }`}>3</span>Work Experience</li>
          <RightOutlined color="#258BD5" />
          <li className={`d-flex gap-2 align-items-center  border p-2 rounded border-primary font-weight-bold ${currentStep >= 3 ? 'bg-primary text-white':'bg-white text-primary'}`}><span style={{padding:"3px 12px"}} className={`rounded-circle ${currentStep >= 3 ? "bg-white text-primary":"bg-primary text-white" }`}>4</span>Financial</li>
          <RightOutlined color="#258BD5" />
          <li className={`d-flex gap-2 align-items-center  border p-2 rounded border-primary font-weight-bold ${currentStep >= 4 ? 'bg-primary text-white':'bg-white text-primary'}`}><span style={{padding:"3px 12px"}} className={`rounded-circle ${currentStep >= 4 ? "bg-white text-primary":"bg-primary text-white" }`}>5</span>Bank</li>
          <RightOutlined color="#258BD5" />
          <li className={`d-flex gap-2 align-items-center  border p-2 rounded border-primary font-weight-bold ${currentStep >= 5 ? 'bg-primary text-white':'bg-white text-primary'}`}><span style={{padding:"3px 12px"}} className={`rounded-circle ${currentStep >= 5 ? "bg-white text-primary":"bg-primary text-white" }`}>6</span>Address Book</li>
          <RightOutlined color="#258BD5" />
          <li className={`d-flex gap-2 align-items-center  border p-2 rounded border-primary font-weight-bold ${currentStep >= 6 ? 'bg-primary text-white':'bg-white text-primary'}`}><span style={{padding:"3px 12px"}} className={`rounded-circle ${currentStep >= 6 ? "bg-white text-primary":"bg-primary text-white" }`}>7</span>Project Handling Exp</li>
        </ul>
      </div>

      {/* Rendering current step */}
      <main>{renderStep(currentStep)}</main>
    </Provider>
  );
};

// Function to render current step
const renderStep = (step) => {
  switch (step) {
    case 0:
      return <General />;
    case 1:
      return <Compliance />;
    // case 2:
    //   return <Geographical />;
    case 2:
      return <Experience />;
    case 3:
      return <Financial />;
    case 4:
      return <Bank/>
    case 5:
      return <Other />;
    case 6:
      return <Save />;
    default:
      return null;
  }
};

export default MultiStepForm;
