import React, { useContext, useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import { Button } from "antd";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import Select from "react-select";
import { Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const Bank = () => {
  const { bank, setBank, next, prev } = useContext(MultiStepFormContext);
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const handleSubmit = () => {
    // Leaving this blank for now
    next();
  };

  const [addBank, setAddBank] = useState({});
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setAddBank((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleAddSubmit = () => {
    axios
      .post(`${baseServerUrl}/createbank`, addBank, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("userInfo")).token,
        },
      })
      .then((response) => {
        console.log(response.data);
        toast.success("Bank added successfully");
        next();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error adding bank");
      });
  };

  return (
    <form className="bookingForm">
      <ToastContainer />
      <div class="card-body">
        <div class="row mt-4">
          <div class="col-md-6 pb-3">
            {/* <p>IFSC Code</p> */}
            <div className="form-control">
              <input
                class="form-input"
                id="ifscCode"
                placeholder="IFSC Code"
                type="text"
                name="ifscCode"
                onChange={handleAddChange}
                value={addBank.ifscCode}
                required
              />
              <label className="form-label">IFSC Code</label>
            </div>
          </div>
          <div class="col-md-6">
            {/* <p>Bank Name</p> */}
            <div className="form-control">
              <input
                class="form-input"
                id="bankName"
                placeholder="Bank Name"
                type="text"
                name="bankName"
                onChange={handleAddChange}
                value={addBank.bankName}
                required
              />
              <label className="form-label">Bank Name</label>
            </div>
          </div>
          <div class="col-md-6 pb-3">
            {/* <p>Account Holder Name</p> */}
            <div className="form-control">
              <input
                class="form-input"
                id="accountHolderName"
                placeholder="Account Holder's Name"
                type="text"
                name="accountHolderName"
                onChange={handleAddChange}
                value={addBank.accountHolderName}
                required
              />
              <label className="form-label">Account Holder's Name</label>
            </div>
          </div>
          <div class="col-md-6">
            {/* <p>Account Number</p> */}
            <div className="form-control">
              <input
                class="form-input"
                id="accountNumber"
                placeholder="Account Number"
                type="text"
                name="accountNumber"
                onChange={handleAddChange}
                value={addBank.accountNumber}
                required
              />
              <label className="form-label">Account Number</label>
            </div>
          </div>
          <div class="col-md-6 pb-3">
            <p style={{ fontSize: "20px", color: "black" }}>Type of Account</p>
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                margin: "10px 0",
                marginTop: "0",
              }}
            ></div>
            <div className="d-flex gap-2">
              <div className="d-flex gap-2">
                <input
                  class="form-check-input"
                  type="radio"
                  id="savingsAccount"
                  value="Savings"
                  name="accountType"
                  onChange={handleAddChange}
                  // value={addBank.accountType}
                  required
                />
                <label className="form-check-label fs-6" for="savingsAccount">
                  Savings
                </label>
              </div>
              <div className="d-flex gap-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="accountType"
                  onChange={handleAddChange}
                  id="currentAccount"
                  value="Current"
                  required
                />
                <label className="form-check-label fs-6" for="currentAccount">
                  Current
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6 pb-3">
            <p style={{ fontSize: "20px", color: "black" }}>Account Status</p>
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                margin: "10px 0",
                marginTop: "0",
              }}
            ></div>
            <div className="d-flex gap-2">
              <div className="d-flex gap-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="accountStatus"
                  id="activeAccount"
                  value="Active"
                  onChange={handleAddChange}
                  required
                />
                <label className="form-check-label fs-6" for="activeAccount">
                  Active
                </label>
              </div>
              <div className="d-flex gap-2">
                <input
                  class="form-check-input"
                  type="radio"
                  name="accountStatus"
                  id="inactiveAccount"
                  value="Inactive"
                  onChange={handleAddChange}
                  required
                />
                <label className="form-check-label fs-6" for="inactiveAccount">
                  Inactive
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "1px",
          backgroundColor: "gray",
          margin: "20px 0",
          marginTop: "0",
        }}
      ></div>

      <div
        className={
          "form__item button__items d-flex justify-content-between mt-4"
        }
      >
        {/* Render a "Back" button that moves to the previous step in the form */}
        <Button
          type={"default"}
          onClick={prev}
          size="large"
          className="border-primary text-primary"
        >
          Back
        </Button>
        {/* Render a "Next" button that submits the form data and moves to the next step in the form */}
        <div className="d-flex gap-2">
          <Button type={"primary"} onClick={handleAddSubmit} size="large">
            Submit & Next
          </Button>
          <Button
            type={"secondary"}
            className="border border-primary text-primary"
            onClick={() => next()}
            size="large"
          >
            Skip
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Bank;
