import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs } from 'react-bootstrap';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  Modal,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import Moment from "react-moment";
import BaseService from "../../../src/config/axiosService";

function BuyerEauction() {
  const [auctions, setAuctions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selected, setSelected] = useState("on-going");

  const fetchAuction = async (page) => {
    try {
      const response = await BaseService.get(`/api/v2/auction/approved?limit=10&page=${page}`);
      setAuctions(response.data.data);
      setTotalPages(Math.ceil(response.data.total / 10));
    } catch (error) {
      toast.error("Failed to fetch auctions");
    }
  };

  useEffect(() => {
    fetchAuction(currentPage);
  }, [currentPage]);

  const getAuctionStatus = (startDate, endDate) => {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (now < start) {
      return "Upcoming";
    } else if (now >= start && now <= end) {
      return "Started";
    } else {
      return "Ended";
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Topbar />
      <ToastContainer></ToastContainer>
      <container-fluid className="mb-4">
        <Row>
          <Col lg="2" md="2" sm="4" style={{background: '#2A3FA0'}}>       
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8">
            <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/buyer-eauction" >eAuction</Link></div>
            <Col lg="6" md="6" sm="6">
                <ul className="nav nav-tabs gap-2 w-100">
                  <li
                    onClick={() => setSelected("on-going")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "on-going" ? "nav-link active" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#on-going"
                    >
                      On Going 
                    </a>
                  </li>
                  <li
                    onClick={() => setSelected("completed")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "completed"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#completed"
                    >
                      Upcomming 
                    </a>
                  </li>
                  <li
                    onClick={() => setSelected("all-project")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "all-project"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#all-project"
                    >
                      History
                    </a>
                  </li>
                  <li
                    onClick={() => setSelected("all-projec9t")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "all-projec9t"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#all-project"
                    >
                      All 
                    </a>
                  </li>
                </ul>
              </Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header className="tbhead">
                <Card.Title as="h6">Manage eAuction</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Project Id</th>
                      <th>Description</th>
                      <th>Start Date and Time</th>
                      <th>End Date and Time</th>
                      <th>Value</th>
                      <th>EMD</th>
                      <th>Bidding Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {auctions.map((auction, index) => {
                      const status = getAuctionStatus(auction.startDate, auction.endDate);
                      return (
                        <tr key={auction._id}>
                          <td>{(currentPage - 1) * 10 + index + 1}</td>
                          <td>{auction.project?.projectId ?? "N/A"}</td>
                          <td>{auction.project?.description ?? "N/A"}</td>
                          <td><Moment format="YYYY-MM-DD HH:mm">{auction.startDate}</Moment></td>
                          <td><Moment format="YYYY-MM-DD HH:mm">{auction.endDate}</Moment></td>
                          <td>{auction.basicValue}</td>
                          <td>{auction.embValue ?? ""}</td>
                          <td>
                            {status === "Started" && <Badge bg="success">Started</Badge>}
                            {status === "Upcoming" && <Badge bg="warning">Upcoming</Badge>}
                            {status === "Ended" && <Badge bg="danger">Ended</Badge>}
                          </td>
                          <td>
                            {status === "Started" ? 
                              <Button variant="primary" size="sm" as={Link} to={`/buyer-live-auction/${auction._id}`}>
                                View More
                              </Button> :
                              status === "Upcoming" ?
                              <Button variant="secondary" size="sm" disabled>
                                Not Started
                              </Button> :
                              <Button variant="secondary" size="sm" disabled>
                                Auction Ended
                              </Button>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Pagination className="justify-content-center mt-3">
                  <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                  {[...Array(totalPages)].map((_, i) => (
                    <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                </Pagination>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </container-fluid>
    </>
  );
}

export default BuyerEauction;