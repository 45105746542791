import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import newsImage from '../../assets/images/resource/b4.jpg';
import axios from "axios";
// import Countdown from 'react-countdown';
import TimeLeft from '../../components/Timeleft';

const AuctionDetailsSec = () => {
    const parse = require('html-react-parser');
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const {slug} = useParams();
    const [postData, setpostData] = useState({
        title: '',
        description: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        termCondition: '',
        image: '',     
    });
    useEffect(() => {
        axios
      .get(`${baseServerUrl}/geteauctiondetails/${slug}`)
      .then((resp) => {
            setpostData({
                ...postData,
                    title: resp.data.data.title,
                    description: resp.data.data.projectId.description,
                    startDate: resp.data.data.startDate,
                    startTime: resp.data.data.startTime,
                    endDate: resp.data.data.endDate,
                    endTime: resp.data.data.endTime,
                    image: resp.data.data.projectId.image,
                    termCondition: resp.data.data.projectId.specificTerms,
            });
      })
      .catch((error) => {
        console.log(error)
      })
    }, [])

    return (
        <div>
            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <h1>Auction Details</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Auction Details</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="auction auctionDetails">
                <div className="auto-container">
                    <div className="row clearfix">
                        {/* <div className="col-lg-4 col-md-5 col-sm-12 sidebar-side">
                            <div className="blog-sidebar default-sidebar">
                                <div className="sidebar-widget category-widget">
                                    <div className="widget-title text-center">
                                        <h3>Information</h3>
                                    </div>
                                    <div className="widget-content">
                                        <ul className="category-list clearfix">
                                            <li>1.	Verification of Scrap</li>
                                            <li>2.	Valuation of Asset</li>
                                            <li>3.	Bidding Document Preparation</li>
                                            <li>4.	Selection of Sellers</li>
                                            <li>5.	Arrangement of Inspection</li>
                                            <li>6.	Signing of Service Level Agreement</li>
                                            <li>7.	e-Auction Arrangement</li>
                                            <li>8.	Management of Funds Movement</li>
                                            <li>9.	Assurance of Compliances</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 content-side">
                            <div className="blog-details-content">
                                <div className="news-block-one">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <img src={postData.image} alt="" className="h-400" />
                                            </figure>
                                            {/* <div className="post-date">
                                                <h3>Bids: 0</h3>
                                            </div> */}
                                        </div>
                                        <div className="lower-content">
                                            <h3>{postData.title}</h3>

                                            <div className="row pt-2 pb_40">
                                                <div className="col-12 text-start d-flex detailRow">
                                                    {/* <div className="pr_50 d-flex">
                                                        <p className="fw-bold mb-1 pe-2">Current Bids : </p><span>{postData.price}/-</span>
                                                    </div> */}
                                                    <div className="d-flex">
                                                        <p className="fw-bold mb-1 pe-2">Time Reaming : </p><span className="d-block"> 
                                                            <TimeLeft endDate={postData.endDate} endTime={postData.endTime}/>
                                                            {/* { moment(postData.endDate).format("YYYY-MM-DD kk:mm:ss")} */}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="scrapListingd bg-light shadow-none">
                                                <Tabs defaultActiveKey="tab1" id="tab-example" tabPosition="left">
                                                    <Tab eventKey="tab1" title="Description">
                                                        {parse(postData.description)}
                                                    </Tab>
                                                    <Tab eventKey="tab4" title="Terms and Conditions">
                                                        {parse(postData.termCondition)}
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AuctionDetailsSec;