import React from "react";
import { Link } from 'react-router-dom';
import './index.css';
import industries1 from '../../assets/images/service/industries-1.webp';
import industries2 from '../../assets/images/service/industries-2.webp';
import industries3 from '../../assets/images/service/industries-3.webp';
import Header from '../../components/header';
import Footer from '../../components/footer';
const IndustriesList = () => {
    const industries = [
        {
            id: 1,
            image: industries1,
            title: 'Electrical and Electronics',
            description: 'Industry encompassing the production and utilization of electrical and electronic devices and components.',
        },
        {
            id: 2,
            image: industries2,
            title: 'Raw Extraction',
            description: 'Metal industry depends on mining for extracting iron, aluminum, copper, nickel, and other minerals.',
        },
        {
            id: 3,
            image: industries3,
            title: 'Metal Fabrication',
            description: 'Metal industry vital for construction: steel, aluminum used in building frameworks, bridges.',
        },
    ];
    return (
        <>
        <Header />
        <div>
            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Our Industries</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="service-section sec-pad centred bg-light industries my-5 pt_70 pb_70">
                <div className="pattern-layer shape-img-7"></div>
                <div className="auto-container">
                    <div className="titleSec mb_50">
                        <span className="sub-title lableBlue">Industry</span>
                        <h2> A List of the Industry</h2>
                    </div>
                    <div className="row clearfix">
                        {industries.map((service) => (
                            <div key={service.id} className="col-lg-4 col-md-6 col-sm-12 service-block">
                                <div className="service-block-one mb_35 wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div className="inner-box">
                                        <figure className="image-box"><Link to={service.link}><img src={service.image} alt="" /></Link></figure>
                                        <div className="lower-content">
                                            <h3><Link to={service.link}>{service.title}</Link></h3>
                                            <p>{service.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
        </div>
    <Footer />
    </>
    )
}
export default IndustriesList;