import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';
import './index.css';
import faqImg from '../../../src/assets/images/faq.png';
import Header from '../../components/header';
import Footer from '../../components/footer';
const Faq = () => {
    const parser = require('html-react-parser');
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const [faqs, setFaqs] = useState([]);
    useEffect(() =>{
        axios.get(`${baseServerUrl}/allfaq`).then((resp) => {
            setFaqs(resp.data.data)
        });
    }, [])
    return (
        <>
        <Header />
        <div className="boxed_wrapper">

            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>FAQ'S</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="pt_60 pb_60 bg-light my-4">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12">
                            <img src={faqImg} alt="faq" />
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12">
                            <Accordion defaultActiveKey="faqSec1">
                                {faqs.map((item, index) => (
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{item.question}</Accordion.Header>
                                    <Accordion.Body>{parser(item.answer)}</Accordion.Body>
                                </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    <Footer />
    </>
    )
}
export default Faq;
