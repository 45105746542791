import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const SellerLoginDetails = () => {
    const errors = {};

    return (
        <>
            <Topbar />
            <Container fluid className="mb-4">
                <Row>
                    <Col lg="2" md="2" sm="4" style={{background: '#000248'}}>
                        <Sidebar />
                    </Col>
                    <Col lg="10" md="10" sm="8">
                        <div className="tophd">
                            Dashboard{' '}
                            <span style={{ color: '#000' }}>
                                <i className="fa fa-angle-right"></i>
                            </span>{' '}
                            <Link to="/buyer-profile">Login Details</Link>
                        </div>
                        <div className="p-3">
                            <Card>
                                <Card.Body className=''>
                                    <div className='d-flex flex-column gap-3'>
                                        <h4>Username: JohnDoe</h4>
                                        <h4>Phone: 1234567890</h4>
                                        <h4>Email: johndoe@example.com</h4>
                                    </div>
                                    <Button variant="primary" type="submit" className='mt-3'>
                                        Change Password
                                    </Button>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default SellerLoginDetails;
