import React from "react";
import { Link } from "react-router-dom";
import "./css/cta.css";

const Cta = () => {
  return (
    <section className="cta-style-two theme-gradient-4">
      <div className="pattern-layer">
        <div className="pattern-2 shape-img-22"></div>
        <div className="pattern-3 shape-img-22"></div>
        {/* <div className="pattern-4 shape-img-8 rotate-me"></div>
        <div className="pattern-5 shape-img-24 float-bob-y"></div> */}
      </div>
      <div className="auto-container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12 col-sm-12 content-column">
            <div className="content-box ml_30 text-center">
              <h3>Let us be part of your Business </h3>
              <div className="btn-box">
                <Link to="/contact" className="theme-btn btn-one">
                  <span>Send Your Enquiry </span>
                </Link>
                <Link to="/signup" className="theme-btn btn-two">
                  <span>Register with us</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
