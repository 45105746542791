import React, { useEffect, useState } from "react";
import {Link, useParams} from 'react-router-dom';
import axios from "axios";
import Moment from "react-moment";

const BlogDetailsSec = () => {
    const {slug} = useParams();
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const parse = require('html-react-parser');
    const [postData, setpostData] = useState({
        title: '',
        content: '',
        picture: '',
        createdAt: '',    
});
    useEffect(() => {
        axios
      .get(`${baseServerUrl}/getblogdetails/${slug}`)
      .then((resp) => {
        setpostData({
            ...postData,
            title: resp.data.data.title,
            content: resp.data.data.content,
            picture: resp.data.data.picture,
            createdAt: resp.data.data.createdAt,
        })
      })
      .catch((error) => {
        console.log(error)
      })
    }, [])
    return (
        <div>
            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Blog Details</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="blog-details contact-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-1 col-md-1 col-sm-0"></div>
                        <div className="col-lg-10 col-md-10 col-sm-12 content-side">
                            <div className="blog-details-content">
                                <div className="news-block-one">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <img src={postData.picture} alt=""  className="h-400" />
                                            </figure>
                                            <div className="post-date">
                                                <h3><Moment format="DD MMMM">{postData.createdAt}</Moment></h3>
                                            </div>
                                        </div>
                                        <div className="lower-content">
                                                <h3>{postData.title}</h3>
                                                {parse(postData.content)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div className="col-lg-1 col-md-1 col-sm-0"></div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BlogDetailsSec;