import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import axios from "axios";
import Header from '../../components/header';
import Footer from '../../components/footer';
const Blog = () => {
    const parser = require('html-react-parser');
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const [blogData, setblogData] = useState([]);
    const [perPage] = useState(3);
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const handlePage = (key) =>{
        setSkip('');
        setSkip(key-1);
    }
    const handlePageNext = () =>{
        if (pageCount > skip) {
            setSkip(skip + 1)
          }
    }
    const handlePagePrevious = () => {
          if (skip >= 1) {
            setSkip(skip - 1)
          }
    }
    const fetchBlogs  = async () => { 
        await axios.post(`${baseServerUrl}/allblog`, { page: perPage, skip: skip }).then((resp) => {
            setblogData(resp.data.data);
            setPageCount(Math.ceil(resp.data.count / perPage));
        }).catch((err)=>{
            console.log(err);
        })
    }
    useEffect(() => {
        fetchBlogs();
    }, [skip, setblogData])
    return (
        <>
        <Header />
        <div>
            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Blog</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="blogPage news-section sec-pad pt_70">
                <div className="auto-container">
                    <div className="titleSec mb_50 centred">
                        <span className="sub-title lableBlue">Our Articles</span>
                        <h2>Get more update from scrap</h2>
                    </div>
                    <div className="row clearfix">
                        {
                        blogData.map((item, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 news-block" key={index}>
                                <div className="news-block-one wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image"><Link to="#!"><img src={item.picture} alt="" /></Link></figure>
                                            <div className="post-date">
                                                <h3><Moment format="DD MMMM">{item.createdAt}</Moment></h3>
                                            </div>
                                        </div>

                                        <div className="lower-content">
                                            {/* <ul className="post-info mb_6 clearfix">
                                                <li><i className="icon-24"></i>
                                                    <Link to="#!">Admin</Link>
                                                </li>
                                                <li><i className="icon-25"></i>0 Comnt</li>
                                            </ul> */}
                                            <h3>
                                                <Link to={`/blogdetails/${item.slug}`}>{item.title.slice(0, 30)}...</Link>
                                            </h3>
                                            <p>{parser(item.content.slice(0, 100))}</p>
                                            <div className="link">
                                                <Link to={`/blogdetails/${item.slug}`}><i className="icon-26"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="fpagination-wrapper centred pb-5">
                            <ul className="fpagination clearfix">
                                <li><a href="#!" onClick={handlePagePrevious}><i className="far fa-angle-left"></i></a></li>
                                {[...Array(pageCount)]?.map((item, key) => {
                                    return(
                                        <li><a href="#!" className={`${skip === key ? 'current' : ''}`} onClick={(e) => handlePage(key+1)}>{key + 1}</a></li>      
                                    )
                                })}
                                <li><a href="#!" onClick={handlePageNext}><i className="far fa-angle-right"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    <Footer />
    </>
    )
}
export default Blog;