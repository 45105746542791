import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, Col, Dropdown, Modal, Row, Table} from 'react-bootstrap'
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import Sidebar from '../../components/Sidebar/SidebarBidder';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
const BuyerPayments = () => {

    const [show, setShow] = useState(false);
    const errors = {}
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [startDateOne, setStartDateOne] = useState(new Date());
    const [startDateTwo, setStartDateTwo] = useState(new Date());
    const [startDateThree, setStartDateThree] = useState(new Date());
    const [postData, setpostData] = useState([]);
    const fetchData = async () => {
      if(startDateOne != ''){
        setShow(true);
      }
        
    }
  
    useEffect(() => {
      // fetchData();
    }, [startDateOne]);

  return (
    <>
    <Topbar />
    {/* <Header /> */}
    <container-fluid className="mb-4">
      <Row>
      <Col lg="2" md="2" sm="4" style={{background: '#2A3FA0'}}>       
        <Sidebar />
      </Col>
      <Col lg="10" md="10" sm="8">
        <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/branches" >Payments</Link></div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Payments</Card.Title>
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Button variant="warning" className="pull-right btn-sm addnew" onClick={handleShow}>
                      <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Status</th>
                      <th className="border-0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postData.length ? (
                      postData.map((item, index) => {
                        return (
                          <tr key={index}>
                                  <td>2021-2022</td>
                        <td>$1,000,000</td>
                        <td>$500,000</td>
                        <td>$2,000,000</td>
                        <td>10</td>
                        <td>5</td>
                        <td>Yes</td>
                            
                            <td>{item.status === 1 ? (
                          <Button
                            className="btn-success btn-sm m-0 pt-0 pb-0"
                          >
                            Active
                          </Button>
                        ) : (
                          <Button
                            className="btn-danger btn-sm m-0 pt-0 pb-0"
                          >
                            Inactive
                          </Button>
                        )}</td>
                            <td>
                            <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm">
                                  Show
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                <Dropdown.Item>
                                    <Link
                                        onClick={() => console.log("view")}
                                        className="link-primary viewButton"
                                      >
                                        View
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                        onClick={() => console.log("edit")}
                                        className="link-primary editButton"
                                      >
                                        Edit
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link onClick={() => console.log("delete")} className="link-primary">
                                      Delete
                                    </Link>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="6">no record found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
          <Footer />
      </container-fluid>
      {/* <div></div> */}
      <Modal show={show} centered size="lg">
        <Modal.Header
          closeButton
          onHide={handleClose}
          className="bg-green text-white"
        >
          <Modal.Title as="h6">Add New Financial</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className="bookingForm">
            <div class="card-body">
              <div class="row mt-4">
                <div class="col-md-6 pb-3">
                  <label for="email">Financial Year</label>
                  <DatePicker
                      showIcon
                      isClearable
                      selected={startDateOne}
                      onChange={(date) => setStartDateOne(date)}
                      showYearPicker
                      dateFormat="yyyy"
                      className="form-control border-primary"
                      style={{width: '100%'}}
                    />
                  {errors.scrapHandlingWorkTurnover && (
                    <span className="text-danger">{errors.scrapHandlingWorkTurnover}</span>
                  )}
                </div>
                <div class="col-md-6 pb-3">
                  <label for="branch">Total Turnover</label> 
                  <input class="form-control" id="total_turnover" placeholder="Total Turnover" type="text"  required/>
                  {errors.totalTurnover && (
                    <span className="text-danger">{errors.totalTurnover}</span>
                  )}
                </div>
                <div class="col-md-6 pb-3">
                  <label for="email">Scrap Handling Work Turnover</label>
                  <input class="form-control" id="scrap_handling_work_turnover" placeholder="Scrap Handling Work Turnover" type="text"  required/>
                  {errors.scrapHandlingWorkTurnover && (
                    <span className="text-danger">{errors.scrapHandlingWorkTurnover}</span>
                  )}
                </div>
                <div class="col-md-6 pb-3">
                  <label for="name">Net Worth of the Company</label> 
                  <input class="form-control" id="net_worth_of_the_company" placeholder="Net Worth of the Company" type="text" o required/>
                  {errors.netWorthOfTheCompany && (
                    <span className="text-danger">{errors.netWorthOfTheCompany}</span>
                  )}
                </div>
                <div class="col-md-6 pb-3">
                  <label for="name">Total Team Count</label> 
                  <input class="form-control" id="total_team_count" placeholder="Total Team Count" type="text"  required/>
                  {errors.totalTeamCount && (
                    <span className="text-danger">{errors.totalTeamCount}</span>
                  )}
                </div>
                <div class="col-md-6 pb-3">
                  <label for="name">Team Count for Scrap</label> 
                  <input class="form-control" id="team_count_for_scrap" placeholder="Team Count for Scrap" type="text"  required/>
                  {errors.teamCountForScrap && (
                    <span className="text-danger">{errors.teamCountForScrap}</span>
                  )}
                </div>
                <div class="col-md-6 pb-3">
                  <label for="name">Handling Services</label> 
                  <input class="form-control" id="handling_services" placeholder="Handling Services" type="text"  required/>
                  {errors.handlingServices && (
                    <span className="text-danger">{errors.handlingServices}</span>
                  )}
                </div>
              </div>
            </div>
            <Button type="submit" variant="dark" onClick={handleClose} className='border'>
              Save
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BuyerPayments;
