import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import Home from "./pages/home";
import AboutUs from "./pages/about";
import Services from "./pages/services";
import Gallery from "./pages/gallery";
import Contact from "./pages/contact";
import Faq from "./pages/faq";
import NotFound from "./pages/not-found";
import Industries from "./pages/industries";

// import Solutions from './solution';
import ComplianceSec from "./pages/compliance";
import Disclaime from "./pages/disclaime";
import PrivacyPolicy from "./pages/privacy-policy";
import PaymentAndRefundPolicy from "./pages/payment-and-refund-policy";
import TermsAndCondition from "./pages/terms-and-condition";

import Auction from "./pages/auction";
import AuctionDetailsSec from "./pages/auction/auction-details";
import UpcomingAuction from "./pages/auction/upcoming-auction";

import ScrapList from "./pages/scrap-list";
import ScrapDetails from "./pages/scrap-list/scraplist-details";

import Blog from "./pages/blog";
import BlogDetailsSec from "./pages/blog/blogDetailsSec";

import Login from "./pages/login";
import Signup from "./pages/signup";
import Forgot from "./pages/forgot";
import Resetpassword from "./pages/resetpassword";

import SellerAuction from "./pages/seller/SellerAuction";
import Role from "./pages/seller/Role";
import Staff from "./pages/seller/Staff";
import ChangePassword from "./pages/seller/ChangePassword";
// const Maps = React.lazy(() => import('./pages/seller/Earning';
// const Notifications = React.lazy(() => import('./pages/seller/Notifications';
import BuyerDashboard from "./pages/buyer/Dashboard";
import BuyerProfile from "./pages/buyer/Profile";
import Branches from "./pages/buyer/Branches.js";
import BuyerHistory from "./pages/buyer/BiddingHistory";
import BuyerNotification from "./pages/buyer/Notifications";
import BuyerStaff from "./pages/buyer/BuyerStaff";
import BuyerStaffRole from "./pages/buyer/Role";
import BuyerEauction from "./pages/buyer/BuyerEauction.js";
import Transaction from "./pages/buyer/Transaction";
import BuyerChangePassword from "./pages/buyer/ChangePassword";

import "bootstrap/dist/css/bootstrap.css";
import "./css-link";
import Protected from "./components/Protected";
import OnboardingWizard from "./pages/onboarding-wizard/Index.jsx";
import Compliance from "./pages/buyer/Compliance.js";
import BuyerExperience from "./pages/buyer/Experience.js";
import BuyerFinancial from "./pages/buyer/BuyerFinancial.js";
import Bank from "./pages/buyer/Bank.js";
import Others from "./pages/buyer/Others.js";
import ProfileDocs from "./pages/buyer/ProfileDocs.js";
import BuyerLoginDetails from "./pages/buyer/LoginDetails.js";
import BuyerPayments from "./pages/buyer/Payments.js";
import SellerGeneral from "./pages/seller/General.js";
import SellerBank from "./pages/seller/Bank.js";
import SellerBranches from "./pages/seller/Branches.js";
import SellerProfileDocs from "./pages/seller/ProfileDocs.js";
import SellerLoginDetails from "./pages/seller/LoginDetails.js";
import ProjectStatus from "./pages/seller/ProjectStatus.js";
import AccountStatement from "./pages/seller/AccountStatement.js";
import PermissionCheck from "./components/Permission.jsx";
import CreateAuction from "./pages/seller/CreateAuction.js";
import Reset from "./pages/forgot/reset-password.jsx";
import CreateProject from "./pages/seller/CreateProject.js";
import ProjectDetails from "./pages/seller/ProjectDetails.js";
import SellerProfile from "./pages/seller/SellerProfile.js";
import LiveAuction from "./pages/buyer/LiveAuction.js";
import NewOffering from "./pages/buyer/NewOffering.js";
import MyProjects from "./pages/buyer/MyProjects.js";
import UpdateStatus from "./pages/buyer/UpdateStatus.js";
import ProjectMoreDetails from "./pages/buyer/ProjectMoreDetails.js";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const usreinfo = JSON.parse(localStorage.getItem("userInfo"));
  // const [role, setRole] = useState("");
  const role = usreinfo ? usreinfo.role.role : "";
  // useEffect(() => {
  //   if (usreinfo) {
  //     setRole(usreinfo.role.role);
  //   }
  // }, [role]);
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={loading}></Suspense>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="*" element={<NotFound />} /> */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/media" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/industries" element={<Industries />} />
          {/* <Route path="/solutions" element={<Solutions />} /> */}

          <Route path="/blog" element={<Blog />} />
          <Route path="blogdetails/:slug" element={<BlogDetailsSec />} />

          <Route path="/auction" element={<Auction />} />
          <Route
            path="/auction-details/:slug"
            element={<AuctionDetailsSec />}
          />
          <Route path="/upcoming-auction" element={<UpcomingAuction />} />

          <Route path="/compliance" element={<ComplianceSec />} />

          <Route path="/deal-book" element={<ScrapList />} />
          <Route path="/scrap-details/:slug" element={<ScrapDetails />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/payment-and-refund-policy"
            element={<PaymentAndRefundPolicy />}
          />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/disclaime" element={<Disclaime />} />

          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route
            path="/reset-password/:id/:token"
            element={<Resetpassword />}
          />

          <Route
            exact
            path="/Login"
            name="Login"
            element={
              usreinfo ? (
                role === "Seller" ? (
                  <SellerGeneral />
                ) : (
                  <BuyerDashboard />
                )
              ) : (
                <Login />
              )
            }
          />

          {role === "Seller" ? (
            <>
              <Route
                path="/staff"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <PermissionCheck module="My Team" action="view">
                      <Staff />
                    </PermissionCheck>
                  </Protected>
                }
              />
              <Route
                path="/seller-profile-view"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <SellerProfile />
                  </Protected>
                }
              />
              <Route
                path="/seller-profile"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <SellerGeneral />
                  </Protected>
                }
              />
              <Route
                path="/seller-bank"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <SellerBank />
                  </Protected>
                }
              />
              <Route
                path="/seller-branches"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <SellerBranches />
                  </Protected>
                }
              />
              <Route
                path="/seller-login-details"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <SellerLoginDetails />
                  </Protected>
                }
              />
              <Route
                path="/seller-profile-docs"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <SellerProfileDocs />
                  </Protected>
                }
              />
              <Route
                path="/project-status"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <PermissionCheck module="Project" action="view">
                      <ProjectStatus />
                    </PermissionCheck>
                  </Protected>
                }
              />
              <Route
                path="/add-new-project"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <PermissionCheck module="Project" action="create">
                      <CreateProject />
                    </PermissionCheck>
                  </Protected>
                }
              />
              <Route
                path="/projects-under-eaution"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <PermissionCheck module="Auction" action={"view"}>
                      <SellerAuction />
                    </PermissionCheck>
                  </Protected>
                }
              />
              <Route
                path="/create-eauction"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <PermissionCheck module="Auction" action={"create"}>
                      <CreateAuction />
                    </PermissionCheck>
                  </Protected>
                }
              />
              <Route
                path="/staff-role"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <PermissionCheck module="My Team" action="view">
                      <Role />
                    </PermissionCheck>
                  </Protected>
                }
              />
              <Route
                path="/seller-changepassword"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <ChangePassword />
                  </Protected>
                }
              />
              <Route
                path="/project-details"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <ProjectDetails />
                  </Protected>
                }
              />
              <Route
                path="/seller-account-statement"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <PermissionCheck module="Account Statement" action="view">
                      <AccountStatement />
                    </PermissionCheck>
                  </Protected>
                }
              />
            </>
          ) : (
            <></>
          )}

          {role === "Buyer" ? (
            <>
              <Route
                path="*"
                name="Dashboard"
                element={
                  usreinfo ? (
                    <BuyerDashboard />
                  ) : (
                    <Navigate replace to="Login" />
                  )
                }
              />
              <Route
                path="/buyer-profile"
                name="Dashboard"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerProfile />
                  </Protected>
                }
              />
              <Route
                path="/buyer-compliance"
                name="Compliance"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <Compliance />
                  </Protected>
                }
              />
              <Route
                path="/buyer-work-experience"
                name="Work-Experience"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerExperience />
                  </Protected>
                }
              />
              <Route
                path="/buyer-financial"
                name="Financial"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerFinancial />
                  </Protected>
                }
              />
              <Route
                path="/buyer-bank"
                name="Bank"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <Bank />
                  </Protected>
                }
              />
              <Route
                path="/branches"
                name="Branches"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <Branches />
                  </Protected>
                }
              />
              <Route
                path="/buyer-others"
                name="Others"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <Others />
                  </Protected>
                }
              />
              <Route
                path="/bidding-history"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerHistory />
                  </Protected>
                }
              />
              <Route
                path="/transaction"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <Transaction />
                  </Protected>
                }
              />
              <Route
                path="/buyer-eauction"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerEauction />
                  </Protected>
                }
              />
              <Route
                path="/buyer-notification"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerNotification />
                  </Protected>
                }
              />
              <Route
                path="/buyer-profile-docs"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <ProfileDocs />
                  </Protected>
                }
              />
              <Route
                path="/staff-role"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerStaffRole />
                  </Protected>
                }
              />
              <Route
                path="/buyer-staff"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerStaff />
                  </Protected>
                }
              />
              <Route
                path="/buyer-login-details"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerLoginDetails />
                  </Protected>
                }
              />
              <Route
                path="/buyer-payments"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerPayments />
                  </Protected>
                }
              />
              <Route
                path="/buyer-changepassword"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <BuyerChangePassword />
                  </Protected>
                }
              />
              <Route
                path="/onboarding"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <OnboardingWizard />
                  </Protected>
                }
              />
              <Route
                path="/buyer-live-auction/:id"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <LiveAuction />
                  </Protected>
                }
              />
              <Route
                path="/new-offering"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <NewOffering />
                  </Protected>
                }
              />
              <Route
                path="/my-projects"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <MyProjects />
                  </Protected>
                }
              />
              <Route
                path="/update-status/:id"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <UpdateStatus />
                  </Protected>
                }
              />
              <Route
                path="/more-details/:id"
                element={
                  <Protected isSignedIn={usreinfo}>
                    <ProjectMoreDetails />
                  </Protected>
                }
              />
            </>
          ) : (
            <></>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </>
  );
};
export default App;
