import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCmtjmGxNZ2CeM0Qkz-NwMIKuqfM9kFF2A",
  authDomain: "getmaxvalue-ac9f0.firebaseapp.com",
  projectId: "getmaxvalue-ac9f0",
  storageBucket: "getmaxvalue-ac9f0.appspot.com",
  messagingSenderId: "99063354383",
  appId: "1:99063354383:web:12a3b30be2851bb4097f7a",
  measurementId: "G-BDVB8NC9EF",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
