import React, { useEffect } from "react";
import Topbar from "../../components/Sidebar/Topbar";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Footer from "../../components/Sidebar/Copyright";
import { Link } from "react-router-dom";
import BaseService from "../../config/axiosService";
import Moment from "react-moment";

const MyProjects = () => {
  const [show, setShow] = React.useState(false);
  const [selected, setSelected] = React.useState("won");
  const [projects, setProjects] = React.useState([]);

  const handleShow = () => {
    setShow(true);
  };

  const fetchProjects = async () => {
    // Fetch all projects
    try {
      const response = await BaseService.get(
        `/api/v2/project/my-projects?filter=${selected}`
      );

      console.log(response.data);

      setProjects(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [selected]);

  return (
    <div>
      <Topbar />
      <Row>
        <Col
          lg="2"
          md="2"
          sm="4"
          style={{ background: "#2A3FA0", minHeight: "78vh" }}
        >
          <Sidebar />
        </Col>
        <Col lg="10" md="10" sm="8" className="p-2 pt-0">
          <div className="tophd">
            <span style={{ color: "#000" }}></span>{" "}
            <Link to="/buyer-changepassword">My Projects</Link>
          </div>
          <Col lg="6" md="6" sm="6" className="mt-6">
            <ul className="nav nav-tabs gap-2">
              <li onClick={() => setSelected("won")} className="nav-item">
                <a
                  className={
                    selected === "won" ? "nav-link active" : "nav-link"
                  }
                  data-bs-toggle="tab"
                  href="#on-going"
                >
                  Alloted Projects
                </a>
              </li>
              <li onClick={() => setSelected("participated")} className="nav-item">
                <a
                  className={
                    selected === "participated" ? "nav-link active" : "nav-link"
                  }
                  data-bs-toggle="tab"
                  href="#completed"
                >
                  Un-alloted Projects
                </a>
              </li>
              <li onClick={() => setSelected("all")} className="nav-item">
                <a
                  className={
                    selected === "all" ? "nav-link active" : "nav-link"
                  }
                  data-bs-toggle="tab"
                  href="#all-project"
                >
                  All Projects
                </a>
              </li>
            </ul>
          </Col>
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <Card.Title as="h6">
                    Under the process of allotment
                  </Card.Title>
                </Col>
              </Row>
            </Card.Header>
            <Table className="table-hover table-striped centered">
              <thead>
                <tr>
                  <th className="border-0">Date/Time</th>
                  <th className="border-0">Project ID</th>
                  <th className="border-0">Location</th>
                  <th className="border-0">Project Details</th>
                  <th className="border-0">Estimated Value</th>
                  <th className="border-0">Items</th>
                  <th className="border-0">Current Status</th>
                  {
                    selected == "won" && (
                      <th className="border-0"></th>
                    )
                  }
                  {
                    selected == "won" && (
                      <th className="border-0"></th>
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {projects.map((project, index) => {
                  return (
                    <tr>
                      <td>
                        <Moment date={project.createdAt} />
                      </td>
                      <td>{project.projectId}</td>
                      <td>{project.location?.locationName}</td>
                      <td>{project.description}</td>
                      <td>- - -</td>
                      <td>- - -</td>
                      <td>{project.status}</td>
                      {selected == "won" && (
                        <td>
                          <Link to={"/more-details/" + project._id}>
                            <Button
                              style={{
                                background: "#2A3FA0",
                                border: "none",
                              }}
                            >
                              View More
                            </Button>
                          </Link>
                        </td>
                      )}
                      {selected == "won" && (
                        <td>
                          <Link to={"/update-status/" + project._id}>
                            <Button
                              style={{
                                background: "#2A3FA0",
                                border: "none",
                              }}
                            >
                              Update
                            </Button>
                          </Link>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      {/* Modal for not interested */}
      <Modal centered={true} show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title className="text-black">Not Interested</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>I am not interested because</p>
          <input type="text" className="form-control" />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              background: "#2A3FA0",
            }}
            variant="primary"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
};

export default MyProjects;
