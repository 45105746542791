import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  Modal,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import Moment from "react-moment";

function Earning() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [postData, setpostData] = useState([]);
  const [viewData, setviewData] = useState([]);
  const [visible, setVisible] = useState("");
  const [perPage] = useState(5);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [errors, setErrors] = useState({});

  const handlePage = (key) => {
    setSkip("");
    setSkip(key - 1);
    console.log(key);
  };
  const handlePageNext = () => {
    if (pageCount > skip) {
      setSkip(skip + 1);
    }
  };
  const handlePagePrevious = () => {
    if (skip >= 1) {
      setSkip(skip - 1);
    }
  };
  const fetchPost = async () => {
    await axios
      .post(`${baseServerUrl}/allbidhistory`, {
        buyerID: userInfo.Id,
        page: perPage,
        skip: skip,
        // search: filterValues.search,
        // categoryID: filterValues.categoryID,
        // eauctionstatus: filterValues.eauctionstatus,
      })
      .then((resp) => {
        setpostData(resp.data.data);
        setPageCount(Math.ceil(resp.data.count / perPage));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const showHistoryData = async (id) => {
    await axios
      .get(`${baseServerUrl}/viewhistory/${id}/${userInfo.Id}`)
      .then((resp) => {
        setviewData(resp.data.data);
        setVisible(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchPost();
  }, [skip]);
  return (
    <>
    <Topbar />
    {/* <Header /> */}
    <container-fluid className="mb-4">
      <Row>
      <Col lg="2" md="2" sm="4" style={{background: '#2A3FA0'}}>       
        <Sidebar />
      </Col>
      <Col lg="10" md="10" sm="8">
        <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/bidding-history" >Bidding History</Link></div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h6">Bidding History</Card.Title>
                
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th style={{"width": "15px" }}>#</th>
                      <th>Auction</th>
                      <th>Start Price</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Wining Date</th>
                      <th>Wining Price</th>
                      <th>Status</th>
                      <th>History</th>
                    </tr>
                  </thead>
                  <tbody>
                  {postData.length ? (
                      postData.map((item, index) => {
                        return (
                          <tr>
                            <td style={{"width": "15px" }}>{index+1}</td>
                            <td>{item.eauctionID.name}</td>
                            <td>{item.eauctionID.exactValue}</td>
                            <td>
                              <Moment format="DD-MM-YYYY">
                                {item.eauctionID.startDate}
                              </Moment>
                            </td>
                            <td>
                              <Moment format="DD-MM-YYYY">
                                  {item.eauctionID.endDate}
                              </Moment>
                            </td>
                            <td></td>
                            <td></td>
                            <td><span class="badge bg-primary"></span></td>
                            <td>
                              <Link
                                onClick={() => showHistoryData(item.eauctionID._id)}
                                class="btn btn-sm btn-success">
                                <i className="fa fa-eye"></i>{" "}
                              </Link>  
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="9">no record found</td>
                      </tr>
                    )}
                  </tbody>
                  <div className={"mt-2"}>
                    <Pagination>
                      <Pagination.Prev
                        className="diabled"
                        onClick={handlePagePrevious}
                        aria-label="Previous"
                      />
                      {[...Array(pageCount)]?.map((item, key) => {
                        return (
                          <Pagination.Item
                            key={key}
                            onClick={(e) => handlePage(key + 1)}
                            className={`${skip === key ? "active" : ""}`}
                          >
                            {key + 1}
                          </Pagination.Item>
                        );
                      })}
                      <Pagination.Next
                        onClick={handlePageNext}
                        aria-label="Next"
                      />
                    </Pagination>
                  </div>
                </Table>
              </Card.Body>
            </Card>
          </Col>
      </Row>
            <Footer />
      </container-fluid>
      {visible && (
        <Modal show={visible} centered size="lg">
          <Modal.Header
            closeButton
            onClick={() => setVisible(false)}
            className="bg-green text-white"
          >
          <Modal.Title as="h6">View Bidding History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <div id="tracking-pre"></div>
                  <div id="tracking">
                    <div class="tracking-list">
                      {viewData.length ? (
                        viewData.map((history, index) => {
                          return(
                            <div class="tracking-item">
                              <div class="tracking-icon status-intransit">
                                <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                  <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                </svg>
                              </div>
                              <div class="tracking-date"><i class="fa fa-gavel"></i></div>
                              <div class="tracking-content">Bidding <span style={{'color': '#33059b', 'font-size': '13px' }}>with {history.bidAmount}</span><span><Moment format="DD MMMM YYYY, HH:mm:ss a">{history.createdAt}</Moment></span></div>
                            </div>
                      );
                      })
                      ) : (
                        <></>
                      )}
                      {/* <div class="tracking-item-pending">
                        <div class="tracking-icon status-intransit">
                          <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                          </svg>
                        </div>
                        <div class="tracking-date"><img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" class="img-responsive" alt="order-placed" /></div>
                        <div class="tracking-content">Delivered<span>12 Aug 2021, 09:00pm</span></div>
                      </div> */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default Earning;
