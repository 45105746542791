import React, { useCallback, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import signup from "../../assets/images/signup.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CloseButton, Modal } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
import PhoneInput from "react-phone-input-2";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../config/firebase";
import OtpInput from "react-otp-input";
import BaseService from "../../config/axiosService";

const Signup = () => {
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [visible, setVisible] = useState(false);
  const [emailAvailable, setEmailAvailable] = useState(true);
  const [phoneAvailable, setPhoneAvailable] = useState(true);
  const [passwordShow, setPasswordShow] = useState(true);
  const emailCheckTimeoutRef = useRef(null);
  const phoneCheckTimeoutRef = useRef(null);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    role: "",
  });
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  }, []);
  const [otpData, setotpData] = useState({
    otp: "",
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const checkEmailAvailability = useCallback(async (email) => {
    if (email && /\S+@\S+\.\S+/.test(email)) {
      try {
        const response = await BaseService.get(
          `/api/v2/user/check-user-email-available?email=${email}`
        );
        console.log("Email availability response:", response.data);
        setEmailAvailable(response.data.status);
      } catch (err) {
        console.error(err);
        setEmailAvailable(true); // Assume available in case of error
      }
    } else {
      setEmailAvailable(true); // Reset to true for invalid email
    }
  }, []);

  const checkPhoneAvailability = useCallback(async (phone) => {
    if (phone && /^\d{10,14}$/.test(phone)) {
      try {
        const response = await BaseService.get(
          `/api/v2/user/check-user-phone-available?phone=${phone}`
        );
        console.log("Phone availability response:", response.data);
        setPhoneAvailable(response.data.status);
      } catch (err) {
        console.error(err);
        setPhoneAvailable(true); // Assume available in case of error
      }
    } else {
      setPhoneAvailable(true); // Reset to true for invalid phone
    }
  }, []);
  const [otp_errors, setOtpErrors] = useState({});
  const handleChange = useCallback(
    (name, value) => {
      setformData((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (name === "email") {
        if (emailCheckTimeoutRef.current) {
          clearTimeout(emailCheckTimeoutRef.current);
        }
        emailCheckTimeoutRef.current = setTimeout(() => {
          checkEmailAvailability(value);
        }, 500);
      } else if (name === "phone") {
        if (phoneCheckTimeoutRef.current) {
          clearTimeout(phoneCheckTimeoutRef.current);
        }
        phoneCheckTimeoutRef.current = setTimeout(() => {
          checkPhoneAvailability(value);
        }, 500);
      }
    },
    [checkEmailAvailability, checkPhoneAvailability]
  );

  const handleOtp = (name, value) => {
    setotpData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-btn", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); 
    const validationError = Validation(formData);
    if (Object.keys(validationError).length > 0) {
      setErrors(validationError);
    } else {
      // send otp
      if (!window.recaptchaVerifier) {
        generateRecaptcha();
      }

      setSignUpLoading(true);

      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, `+${formData.phone}`, appVerifier)
        .then((confirmationResult) => {
          console.log(confirmationResult);
          window.confirmationResult = confirmationResult;
          setVisible(true);
        })
        .catch((error) => {
          console.log(error);
        }).finally(()=>{
          setSignUpLoading(false);
        });
    }
  };

  function Validation(formData) {
    const errors = {};
    if (!formData.username.trim()) {
      errors.username = "Name is required";
    } else if (formData.username.length < 2) {
      errors.username = "Name must be at least 2 characters long";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\d{10,14}$/.test(formData.phone)) {
      errors.phone = "Please enter a valid phone number";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!formData.password.trim()) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (
      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        formData.password
      )
    ) {
      errors.password =
        "Password must contain at least one letter, one number and one special character";
    }

    if (!formData.role) {
      errors.role = "Please select whether you want to be a Seller or a Vendor";
    }

    return errors;
  }
  const handleRegistration = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrors({});

    try {
      const confirmationResult = window.confirmationResult;
      const result = await confirmationResult.confirm(otpData.otp);

      try {
        const registerResponse = await axios.post(`${baseServerUrl}/register`, {
          ...formData,
          firebaseUID: result.user.uid,
        });

        if (registerResponse.data) {
          try {
            const loginResponse = await axios.post(
              `${baseServerUrl}/userlogin`,
              {
                email: formData.email,
                password: formData.password,
              }
            );

            localStorage.setItem(
              "userInfo",
              JSON.stringify(loginResponse.data.data)
            );
            localStorage.setItem(
              "approval",
              JSON.stringify({ approval: false })
            );

            if (loginResponse.data.data.role.role === "Seller") {
              navigate("/project-status");
            } else {
              navigate("/dashboard");
            }
            window.location.reload();
          } catch (loginError) {
            handleApiError(loginError);
          }
        } else {
          toast.error(registerResponse.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (registerError) {
        handleApiError(registerError);
      }
    } catch (otpError) {
      handleOtpError(otpError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiError = (error) => {
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("An unexpected error occurred. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleOtpError = (error) => {
    let errorMessage = "Failed to verify OTP. Please try again.";
    if (error.code === "auth/invalid-verification-code") {
      errorMessage = "Invalid OTP. Please check and try again.";
    } else if (error.code === "auth/code-expired") {
      errorMessage = "OTP has expired. Please request a new one.";
    }
    else if(error.code = "auth/network-request-failed"){
      errorMessage = "Network request failed. Please try again.";
    }
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  return (
    <>
      <Header />
      <ToastContainer />

      <section className="contact-section pricing-section pt_70 pb_70">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 form-column">
              <div className="">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                    <img src={signup} alt="signin" />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                    <div className="tabs-box shadow p-3 rounded-3">
                      <div className="tab-btn-box mb_30 lableBlue rounded-4 px-3 py-2 text-center">
                        <h4> Signup </h4>
                        <span className="text-danger">
                          please fill all the mandatory fields (*)
                        </span>
                      </div>
                      <form
                        id="contact-form"
                        className="default-form"
                        autoComplete="new-password"
                        onSubmit={handleSubmit}
                      >
                        <div className="row clearfix">
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <label htmlFor="name">Name*:</label>
                            <input
                              type="text"
                              id="username"
                              name="username"
                              onChange={(e) =>
                                handleChange("username", e.target.value)
                              }
                              placeholder=""
                              required
                            />
                            {errors.username && (
                              <span className="text-danger">
                                {errors.username}
                              </span>
                            )}
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <label htmlFor="phone">Phone*:</label>
                            <div
                              className="border px-1  rounded-1"
                              style={{
                                height: "50px",
                              }}
                            >
                              <PhoneInput
                                countryCodeEditable={false}
                                country={"in"}
                                onChange={(phone) => {
                                  handleChange("phone", phone);
                                }}
                                containerStyle={{
                                  width: "100%",
                                  border: "none",
                                  height: "100%",
                                }}
                                inputStyle={{
                                  width: "100%",
                                  border: "none",
                                  height: "100%",
                                }}
                              />
                            </div>
                            {errors.phone && (
                              <span className="text-danger">
                                {errors.phone}
                              </span>
                            )}
                            {!phoneAvailable && (
                              <span className="text-success">
                                This phone number is available
                              </span>
                            )}
                            {
                              formData.phone && /^\d{10,14}$/.test(formData.phone) && phoneAvailable && (
                                <span className="text-danger">
                                  This phone number is already registered
                                </span>
                              )
                            }
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <label htmlFor="email">Email*:</label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              onChange={(e) =>
                                handleChange("email", e.target.value)
                              }
                              placeholder=""
                              required
                            />
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email}
                              </span>
                            )}
                            {!emailAvailable && (
                              <span className="text-success">
                                This email is available
                              </span>
                            )}
                            {
                              formData.email && /\S+@\S+\.\S+/.test(formData.email) && emailAvailable && (
                                <span className="text-danger">
                                  This email is already registered
                                </span>
                              )
                            }
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group position-relative">
                            <label htmlFor="password">Password*:</label>
                            <input
                              type={
                                passwordShow ? "text" : "password"
                              }
                              id="password"
                              name="password"
                              onChange={(e) =>
                                handleChange("password", e.target.value)
                              }
                              placeholder=""
                              autoComplete="off"
                              required
                            />
                            <span className="position-absolute top-50" style={{
                              right:"25px"
                            }}>
                              <i className={
                                passwordShow ? "fa fa-eye" : "fa fa-eye-slash"
                              } onClick={() => setPasswordShow(!passwordShow)}></i>
                            </span>
                            {errors.password && (
                              <span className="text-danger">
                                {errors.password}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group d-flex align-items-center">
                            <input
                              type="radio"
                              className="w-auto"
                              id="seller"
                              name="role"
                              value="64ce1037015116b791222651"
                              onChange={(e) =>
                                handleChange("role", e.target.value)
                              }
                              required
                            />
                            {errors.role && (
                              <span className="text-danger">{errors.role}</span>
                            )}
                            <label htmlFor=" agreement" className="ps-2">
                              Become a Seller
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 form-group d-flex align-items-center">
                            <input
                              type="radio"
                              className="w-auto"
                              id="bidder"
                              name="role"
                              value="64c8caa7ba6c62091e0c125d"
                              onChange={(e) =>
                                handleChange("role", e.target.value)
                              }
                              required
                            />
                            {errors.role && (
                              <span className="text-danger">{errors.role}</span>
                            )}
                            <label htmlFor=" bidder" className="ps-2">
                              Become a Vendor
                            </label>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group d-flex align-items-center">
                            {errors.agree && (
                              <span className="text-danger">
                                {errors.agree}
                              </span>
                            )}
                            <label htmlFor=" agreement" className="">
                              By sign up or logging in, you agree to all{" "}
                              <Link target="_blank" to="/terms-and-condition">
                                terms and conditions
                              </Link>{" "}
                              and{" "}
                              <Link target="_blank" to="/privacy-policy">
                                privacy policy
                              </Link>{" "}
                              of getmaxvalue.in
                            </label>
                          </div>
                          <button
                            className="theme-btn btn-one shadow"
                            type="submit"
                            id="sign-in-btn"
                            disabled={signUpLoading}
                          >
                            <span>
                              {signUpLoading ? "Processing..." : "Sign Up"}
                            </span>
                          </button>
                          <span className="mt-2">
                            Already have an account?{" "}
                            <Link to="/login" className="text-primary">
                              Login
                            </Link>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {visible && (
        <Modal show={visible} centered size="md">
          <Modal.Header>
            <p className="fw-bold">OTP Verification</p>
            <CloseButton
              onClick={() => {
                setVisible(false);
              }}
            />
          </Modal.Header>
          <Modal.Body className="rounded-3 outline-none border">
            <form className="bookingForm" onSubmit={handleRegistration}>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 pb-3">
                    <p className="mb-1 fw-bold">
                      Please enter the OTP sent to your mobile number +
                      {formData.phone}
                    </p>
                    <p className="mb-2">
                      Otp is valid for 15 minutes. Please do not share this OTP
                    </p>
                    <OtpInput
                      value={otpData.otp}
                      renderSeparator={
                        (index) => (
                          <span className="mx-2">{index < 5 ? "-" : ""}</span>
                        ) //index < 5 ? "-" : ""
                      }
                      onChange={(otp) => handleOtp("otp", otp)}
                      inputStyle={{
                        width: "3rem",
                        height: "3rem",
                        fontSize: "2rem",
                        border: "1px solid rgba(0,0,0,0.3)",
                        color: "black",
                      }}
                      numInputs={6}
                      renderInput={(props) => <input {...props} />}
                    />
                    {otp_errors.otp && (
                      <span className="text-danger">{otp_errors.otp}</span>
                    )}
                    <p className="mt-3">
                      Didn't receive the OTP?{" "}
                      <Link
                        onClick={() => {
                          toast.success("OTP sent successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                          })
                          handleSubmit({
                            preventDefault: () => {
                              console.log("preventDefault");
                            },
                          });
                        }}
                        to="#"
                        className="text-primary"
                      >
                        Resend
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="theme-btn btn-one shadow p-3"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Verify OTP"}
              </button>
            </form>
          </Modal.Body>
        </Modal>
      )}
      <Footer />
    </>
  );
};
export default Signup;
