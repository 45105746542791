import { Link } from "react-router-dom";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import logo1 from "../../assets/images/clients/pantner-logo-1.webp";
const ClientLogoSec = ({}) => {
  const partnerList = [
    {
      image: "./client-1.png",
    },
    {
      image: "./client-2.png",
    },
    {
      image: "./client-3.png",
    },
    {
      image: "./client-4.png",
    },
  ];
  return (
    <section className="clients-section theme-gradient-4">
      {/* <div className="pattern-layer shape-img-23"></div> */}
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-3 col-md-4 col-sm-12 news-block">
            <h3>Valuable Clients and Partners</h3>
            {/* <p>B2B Scrap Management Solution</p> */}
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12 news-block">
            <Swiper
              autoplay={{ delay: 2000 }}
              loop={true}
              // pagination= {{ clickable: true }}
              spaceBetween={10}
              slidesPerView={3}
              // navigation
              breakpoints={{
                568: {
                  slidesPerView: 3,
                },
                299: {
                  slidesPerView: 2,
                },
              }}
            >
              {partnerList.map((item, index) => (
                <SwiperSlide key={index}>
                  <figure className="clients-logo">
                    <img src={item.image} alt="pantner-logo" />
                  </figure>
                </SwiperSlide>
              ))}
              {/* <div className="swiper-button-prev">
                <i class="far fa-angle-left"></i>
              </div>
              <div className="swiper-button-next">
                <i class="far fa-angle-right"></i>
              </div> */}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientLogoSec;
