import React from 'react'
import Topbar from "../../components/Sidebar/Topbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Sidebar/Copyright";
import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import { PieChart } from "react-minimal-pie-chart";
import { useState } from "react";
import {
  CDBSidebar,
  CDBSidebarHeader,
  CDBSidebarMenuItem,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarSubMenu,
  CDBSidebarFooter,
} from 'cdbreact';


const ProjectDetails = () => {
 

  return (
    <div>
        <Topbar />
        <container-fluid className="mb-4">
          <Row>

          <Col
            lg="2"
            md="2"
            sm="4"
            style={{ background: "#2A3FA0", paddingRight: "0" }}
          >
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8">
          <Card>
            <Card.Header>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <p className="text-white">
                    Project Description :{" "}
                  </p>
                </Col>
                <Col lg="6" md="6" sm="6">
              
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Row>
                  <Col lg="3" md="4" sm="6">
                    <p>Project Id</p>
                    <h3>123123</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>Location</p>
                    <h3>Delhi</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>Current Status</p>
                    <h3>In-Progress</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>Manager</p>
                    <h3>Krishna</h3>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col lg="3" md="4" sm="6">
                    <p>
                      Total Receivable / <br /> (Payable)
                    </p>
                    <h3>INR 231 Lac</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>
                      Net Paid / <br /> (Received)
                    </p>
                    <h3>INR 231 Lac</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>Balance Outstanding Payable / (Receivable)</p>
                    <h3>INR 231 Lac</h3>
                    <a href="#" className="text-link">
                      View full ledger
                    </a>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <PieChart
                      totalValue={100}
                      label={({ dataEntry }) => dataEntry.value + "%"}
                      labelStyle={{
                        color: "white",
                        fontSize: "12px",
                      }}
                      style={{
                        width: "50%",
                      }}
                      data={[
                        { title: "One", value: 15, color: "#0F9ED5" },
                        { title: "Two", value: 85, color: "#4EA72E" },
                      ]}
                    />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md={4}>
                  <CDBSidebar>
                  <CDBSidebarContent>
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="th-large">Inspection</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="sticky-note">Valuation</CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="credit-card" iconType="solid">
            Physical Verification
            </CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="credit-card" iconType="solid">
              eAuction
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
                   </CDBSidebar> 
                  </Col>
                  <Col md={4}>
                      <h3>Inspection</h3>
                      
                  </Col>
                 </Row> 
              </Row>
            </Card.Body>
          </Card>
          </Col>
          

          </Row>
           <Footer />
        </container-fluid>
        
    </div>
    
  )
}

export default ProjectDetails