// import { Button } from "antd";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
  Table,
  Button,
} from "react-bootstrap";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SellerFinancial = () => {
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [financials, setFinancials] = useState({});
  const [addFormData, setAddFormData] = useState({
    financialYear: `${new Date().getFullYear()}-${
      new Date().getFullYear() + 1
    }`,
  });
  const [updateSelectedFinancial, setUpdateSelectedFinancial] = useState({});
  const [selectFinancial, setSelectFinancial] = useState({});

  const handleUpdateChange = (e) => {
    // console.log(e.target);
    setUpdateSelectedFinancial({
      ...updateSelectedFinancial,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleAddChange = (e) => {
    // console.log(e.target);
    setAddFormData({
      ...addFormData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handeAddSubmit = (slug) => {
    console.log(addFormData);
    axios
      .post(
        `${baseServerUrl}/createfinancial`,
        {
          financialYear: addFormData.financialYear,
          totalTurnover: addFormData.totalTurnover,
          scrapHandlingTurnover: addFormData.scrapHandlingTurnover,
          netWorth: addFormData.netWorth,
          totalTeamCount: addFormData.totalTeamCount,
          teamCountForScrap: addFormData.teamCountForScrap,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("userInfo")).token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (!slug) {
          handleClose();
        }
        setAddFormData({
          financialYear: `${new Date().getFullYear()}-${
            new Date().getFullYear() + 1
          }`,
          totalTurnover: "",
          scrapHandlingTurnover: "",
          netWorth: "",
          totalTeamCount: "",
          teamCountForScrap: "",
        });
        getFinancials();
        toast.success("Financial added successfully");
        // handleClose();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in adding Financial");
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseServerUrl}/deletefinancial/${id}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("userInfo")).token,
        },
      })
      .then((response) => {
        console.log(response);
        getFinancials();
        toast.success("Financial deleted successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in deleting Financial");
      });
  };

  const handleUpdateSubmit = () => {
    axios
      .put(
        `${baseServerUrl}/updatefinancial/${updateSelectedFinancial._id}`,
        updateSelectedFinancial,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("userInfo")).token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        getFinancials();
        toast.success("Financial updated successfully");
        setUpdateShow(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in updating Financial");
      });
  };

  const getFinancials = () => {
    axios
      .get(`${baseServerUrl}/getfinancialbyuserid `, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("userInfo")).token,
        },
      })
      .then((response) => {
        setFinancials(response.data.financials);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFinancials();
  }, []);

  return (
    <>
      <Topbar />
      {/* <Header /> */}
      <container-fluid className="mb-4">
        <Row>
          <Col lg="2" md="2" sm="4">
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8" className="ps-2">
            <div className="tophd">
              Dashboard{" "}
              <span style={{ color: "#000" }}>
                <i className="fa fa-angle-right"></i>
              </span>{" "}
              <Link to="/branches">Financial</Link>
            </div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Financial</Card.Title>
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Button
                      variant="warning"
                      className="pull-right btn-sm addnew"
                      onClick={handleShow}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Financial Year</th>
                      <th className="border-0">
                        Total Turnover (Rs. in Lakhs)
                      </th>
                      <th className="border-0">
                        Scrap Handling Work Turnover (Rs. in Lakhs)
                      </th>
                      <th className="border-0">
                        Net Worth of the Company (Rs. in Lakhs)
                      </th>
                      <th className="border-0">Total Team Count</th>
                      <th className="border-0">Team Count for Scrap</th>
                      <th className="border-0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financials.length ? (
                      financials.map((financial, index) => {
                        return (
                          <tr key={index}>
                            <td>{financial.financialYear}</td>
                            <td>{financial.totalTurnover}</td>
                            <td>{financial.scrapHandlingTurnover}</td>
                            <td>{financial.netWorth}</td>
                            <td>{financial.teamCountForScrap}</td>
                            <td>{financial.totalTeamCount}</td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" size="sm">
                                  Show
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <Link
                                      onClick={() => {
                                        setSelectFinancial(financial);
                                        setViewShow(true);
                                      }}
                                      className="link-primary viewButton"
                                    >
                                      View
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      onClick={() => {
                                        setUpdateSelectedFinancial(financial);
                                        setUpdateShow(true);
                                      }}
                                      className="link-primary editButton"
                                    >
                                      Edit
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      onClick={() => {
                                        handleDelete(financial._id);
                                      }}
                                      className="link-primary"
                                    >
                                      Delete
                                    </Link>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="6">no record found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </container-fluid>
      {/* <div></div> */}
      <Modal show={show} centered size="lg">
        <Modal.Header closeButton onHide={handleClose} className="text-white">
          <Modal.Title className="text-black" as="h6">
            Add New Financial
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="bookingForm">
            <div className="card-body">
              <div className="row mt-4">
                <div className="col-md-12 pb-3">
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="financialYear"
                      style={{
                        color: "black",
                        fontSize: "1.5rem",
                        width: "50%",
                      }}
                    >
                      Financial Year :
                    </label>
                    <DatePicker
                      dateFormat="yyyy"
                      showYearPicker
                      name="financialYear"
                      id="financialYear"
                      peekNextMonth
                      showMonthDropdown={false}
                      scrollableYearDropdown
                      selected={
                        addFormData.financialYear
                          ? new Date(addFormData.financialYear?.split("-")[0])
                          : new Date()
                      }
                      className="fs-5 bg-white p-2 rounded-3 w-50 text-center"
                      onChange={(date) => {
                        setAddFormData({
                          ...addFormData,
                          financialYear: `${date.getFullYear()}-${
                            date.getFullYear() + 1
                          }`,
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>

                <div className="col-md-12 d-flex align-items-center">
                  <label
                    htmlFor="totalTurnover"
                    className="fs-6 text-black"
                    style={{ width: "50%" }}
                  >
                    Total Turnover (Rs. in Lakhs)?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="totalTurnover"
                    type="number"
                    name="totalTurnover"
                    onChange={(e) => handleAddChange(e)}
                    value={addFormData.totalTurnover}
                    required
                  />
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>

                <div className="col-md-12 d-flex align-items-center">
                  <label
                    htmlFor="scrapHandlingTurnover"
                    className="fs-6 text-black"
                    style={{ width: "50%" }}
                  >
                    Scrap Handling Work Turnover(Rs. in Lakhs)?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="scrapHandlingTurnover"
                    name="scrapHandlingTurnover"
                    type="number"
                    onChange={(e) => handleAddChange(e)}
                    value={addFormData.scrapHandlingTurnover}
                    required
                  />
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>

                <div className="col-md-12 d-flex align-items-center">
                  <label
                    htmlFor="netWorth"
                    className="fs-6 text-black"
                    style={{ width: "50%" }}
                  >
                    Net Worth of the Company(Rs. in Lakhs)?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="netWorth"
                    name="netWorth"
                    type="number"
                    onChange={(e) => handleAddChange(e)}
                    value={addFormData.netWorth}
                    required
                  />
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>

                <div className="col-md-12 d-flex align-items-center">
                  <label
                    htmlFor="totalTeamCount"
                    className="fs-6 text-black"
                    style={{ width: "50%" }}
                  >
                    Total Team Count?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="totalTeamCount"
                    name="totalTeamCount"
                    type="number"
                    onChange={(e) => handleAddChange(e)}
                    value={addFormData.totalTeamCount}
                    required
                  />
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>

                <div className="col-md-12 d-flex align-items-center">
                  <label
                    htmlFor="teamCountForScrap"
                    className="fs-6 text-black"
                    style={{ width: "50%" }}
                  >
                    Team Count for Scrap?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="teamCountForScrap"
                    name="teamCountForScrap"
                    type="number"
                    onChange={(e) => handleAddChange(e)}
                    value={addFormData.teamCountForScrap}
                    required
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                marginBottom: "5px",
              }}
            ></div>

            <Button
              className="btn btn-sm"
              color="#5C79FF"
              onClick={() => handeAddSubmit()}
              style={{ backgroundColor: "#E2E7FF", color: "#5C79FF" }}
            >
              Save & Exit
            </Button>
            {"   "}
            <Button
              className="btn btn-primary btn-sm"
              onClick={() => handeAddSubmit(true)}
              style={{ backgroundColor: "#5C79FF" }}
            >
              Save & Add More Financial
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={updateShow} centered size="lg">
        <Modal.Header
          closeButton
          onHide={() => setUpdateShow(false)}
          className="text-white"
        >
          <Modal.Title className="text-black" as="h6">
            Update Financial
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="bookingForm">
            <div class="card-body">
              <div class="row mt-4">
                <div class="col-md-12 pb-3">
                  <div className="d-flex align-items-center">
                    <label
                      for="financialYear"
                      style={{
                        color: "black",
                        fontSize: "1.5rem",
                        width: "25%",
                      }}
                    >
                      Financial Year :
                    </label>
                    <DatePicker
                      dateFormat="yyyy"
                      showYearPicker
                      name="financialYear"
                      id="financialYear"
                      peekNextMonth
                      showMonthDropdown={false}
                      scrollableYearDropdown
                      selected={
                        new Date(
                          updateSelectedFinancial.financialYear?.split("-")[0]
                        )
                      }
                      className="fs-5 bg-white p-2 rounded-3 w-25 text-center"
                      onChange={(date) => {
                        setUpdateSelectedFinancial({
                          ...updateSelectedFinancial,
                          financialYear: `${date.getFullYear()}-${
                            date.getFullYear() + 1
                          }`,
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>

                <div class="col-md-12  d-flex align-items-center">
                  <label for="branch" className="fs-6 text-black" style={{
                      width:"50%"
                  }}>
                    Total Turnover (Rs. in Lakhs)?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="totalTurnover"
                    type="number"
                    name="totalTurnover"
                    onChange={(e) => handleUpdateChange(e)}
                    value={updateSelectedFinancial.totalTurnover}
                    required
                  />
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>
                <div class="col-md-12  d-flex align-items-center">
                  <label for="email" className="fs-6 text-black" style={{
                      width:"50%"
                  }}>
                    Scrap Handling Work Turnover(Rs. in Lakhs)?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="scrapHandlingTurnover"
                    name="scrapHandlingTurnover"
                    type="number"
                    onChange={(e) => handleUpdateChange(e)}
                    value={updateSelectedFinancial.scrapHandlingTurnover}
                    required
                  />
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>

                <div class="col-md-12  d-flex align-items-center">
                  <label for="name" className="fs-6 text-black" style={{
                      width:"50%"
                  }}>
                    Net Worth of the Company(Rs. in Lakhs)?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="netWorth"
                    name="netWorth"
                    type="number"
                    onChange={(e) => handleUpdateChange(e)}
                    value={updateSelectedFinancial.netWorth}
                    required
                  />
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>

                <div class="col-md-12  d-flex align-items-center">
                  <label for="name" className="fs-6 text-black" style={{
                      width:"50%"
                  }}>
                    Total Team Count?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="totalTeamCount"
                    name="totalTeamCount"
                    type="number"
                    onChange={(e) => handleUpdateChange(e)}
                    value={updateSelectedFinancial.totalTeamCount}
                    required
                  />
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    margin: "0",
                  }}
                ></div>

                <div class="col-md-12  d-flex align-items-center">
                  <label for="name" className="fs-6 text-black" style={{
                      width:"50%"
                  }}>
                    Team Count for Scrap?
                  </label>
                  <input
                    className="form-control mt-4 w-50"
                    id="teamCountForScrap"
                    name="teamCountForScrap"
                    type="number"
                    onChange={(e) => handleUpdateChange(e)}
                    value={updateSelectedFinancial.teamCountForScrap}
                    required
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                marginBottom: "5px",
              }}
            ></div>

            <Button
              className="btn btn-sm"
              color="#5C79FF"
              onClick={() => setUpdateShow(false)}
              style={{ backgroundColor: "#E2E7FF", color: "#5C79FF" }}
            >
              Close
            </Button>
            {"   "}
            <Button
              className="btn btn-primary btn-sm" // Modified: Changed button color to primary
              onClick={handleUpdateSubmit}
              style={{ backgroundColor: "#5C79FF" }}
            >
              Update
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={viewShow} centered size="md">
        <Modal.Header
          closeButton
          onHide={() => setViewShow(false)}
          className=""
        >
          <Modal.Title className="text-black" as="h6">
            View Financial Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectFinancial && (
            <>
              <h4>Financial Details:</h4>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "gray",
                  margin: "20px 0",
                }}
              ></div>
              <p>
                <strong>Financial Year:</strong> {selectFinancial.financialYear}
              </p>
              <p>
                <strong>Total Turnover:</strong> {selectFinancial.totalTurnover}
              </p>
              <p>
                <strong>Scrap Handling Work Turnover:</strong>{" "}
                {selectFinancial.scrapHandlingTurnover}
              </p>
              <p>
                <strong>Net Worth of the Company:</strong>{" "}
                {selectFinancial.netWorth}
              </p>
              <p>
                <strong>Total Team Count:</strong>{" "}
                {selectFinancial.totalTeamCount}
              </p>
              <p>
                <strong>Team Count for Scrap:</strong>{" "}
                {selectFinancial.teamCountForScrap}
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default SellerFinancial;
 