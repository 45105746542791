import { Button, Col, message, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import {
  Card,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { City, State } from "country-state-city";
const Save = () => {
  // Get details, address, and linksDetails from MultiStepFormContext
  const { next, prev } = useContext(MultiStepFormContext);
  const navigate = useNavigate();
  // Define state variables to store user details
  const [general, setGeneral] = useState({});
  const [compliance, setCompliance] = useState({});
  const [geographical, setGeographical] = useState({});
  const [experience, setExperience] = useState({});
  const [financial, setFinancial] = useState({});
  const [other, setOther] = useState({});
  const [showAll, setShowAll] = useState(false);
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  let [state, setState] = useState("");
  let [state2, setState2] = useState("");
  const [cities,setCities] = useState([]);
  const states = State.getStatesOfCountry("IN");
  const [addFormData, setAddFormData] = useState({});
  const handleCitySearch = (iso) => {
    let cities = City.getCitiesOfState("IN", iso);
    setCities(cities);
  }
  // Load user details from localStorage on mount
  useEffect(() => {
    const storedGeneral = localStorage.getItem("general");
    if (storedGeneral) {
      setGeneral(JSON.parse(storedGeneral));
    }

    const storedCompliance = localStorage.getItem("compliance");
    if (storedCompliance) {
      setCompliance(JSON.parse(storedCompliance));
    }

    const storedExperience = localStorage.getItem("experience");
    if (storedExperience) {
      setExperience(JSON.parse(storedExperience));
    }

    const storedFinancial = localStorage.getItem("financial");
    if (storedFinancial) {
      setFinancial(JSON.parse(storedFinancial));
    }

    const storedOther = localStorage.getItem("other");
    if (storedOther) {
      setOther(JSON.parse(storedOther));
    }

  }, []);

  /**
   * Define the submit function which moves to the next step and displays a success message
   * only if "showAll" flag is true in the  condition  and "confirm" button then redirects.
   */

  const onSkip = () =>{
    next();
    navigate("/dashboard");
  }

  let handleChange = (i, e) => {
    setAddFormData({
      ...addFormData,
      [i]: e,
    });
    
  };

  
  const handleAddSubmit = () => {
    // API call to add project handling data
    // if(!addFormData.state || !addFormData.city || !addFormData.project_size_min || !addFormData.project_size_Max || !addFormData.major_legal || !addFormData.key_managerial){
    //   toast.error("Please fill all the fields");
    //   return;
    // }
    axios.post(`${baseServerUrl}/createprojecthandling`,{
      "state": addFormData.state,
      city: addFormData.city,
      projectSizeCapability: {
        minSize: addFormData.project_size_min,
        maxSize: addFormData.project_size_Max
      },
      legalCaseUnderLitigation: addFormData.major_legal,
      keyManagerialPerson: {
        convictedForOffence: state == "Yes" ? "yes" : "no",
        details: addFormData.key_managerial
      }
    },{
      headers:{
        Authorization: `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response)=>{
      console.log(response.data);
      toast.success("Project Handling Experience Added Successfully");
      localStorage.setItem("fillLater","true");
      next();
    }).catch((error)=>{
      console.log(error);
      toast.error("Error in adding Project Handling Experience");
    })
  }


  //Define the  function which  sets showAll state variable to true,
  // which enables the display of all the user's entered details
  const handleView = () => {
    setShowAll(true);
  };


  // Render the Save form with the user's inputted details
  return (
    <form className="bookingForm">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <label for="branch">State</label>
          <select class="form-control" id="state" name="state" required onChange={(e)=>{
            handleCitySearch(e.target.value.split(" ")[0]);
            handleChange("state", e.target.value);
          }}>
            <option value="">Select State</option>
            {states.map((item, index) => {
                return <option value={`${item.isoCode} ${item.name}`}>{item.name}</option>;
            })}
          </select>
       
        </div>
        <div class="col-md-6">
          <label for="email">City</label>
          <select class="form-control" id="city" name="city" required onChange={
            (e)=>handleChange("city", e.target.value)
          }>
            <option value="">Select City</option>
            {cities.map((item, index) => {
                return <option value={item.name}>{item.name}</option>;
            })}
          </select>
          
        </div>
        <div style={{height:"1px",backgroundColor:"gray",margin:"0",marginBottom:"5px"}}></div>

        <div class="col-md-12 d-flex">
          <label for="name" className="text-black pt-2 fs-6">Project Size Capability (Rs. in Lakhs)?</label>
          <div class="row">
            <div class="col-md-6">
              <input
                class="form-control"
                id="project_size_capability_min"
                placeholder="Min Size"
                type="text"
                value={addFormData.project_size_min}
                onChange={(e) =>
                  handleChange("project_size_min", e.target.value)
                }
                required
              />
            
            </div>
            
            <div class="col-md-6">
              <input
                class="form-control"
                id="project_size_capability_Max"
                placeholder="Max Size"
                value={addFormData.project_size_Max}
                type="text"
                onChange={(e) =>
                  handleChange("project_size_Max", e.target.value)
                }
                required
              />
           
            </div>
          </div>
        </div>
        <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>

        <div class="col-md-12 d-flex">
          <label for="name" className="text-black pt-2 fs-6">Legal Case Under Litigation?</label>
         

          <input  
              class="form-control"
            id="major_legal"
            placeholder="Major Legal Case Under Litigation"
            type="text"
            value={addFormData.major_legal}
            onChange={(e) =>
              handleChange("major_legal", e.target.value)
            }
            required/>
        </div>
        <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>


        <div class="col-md-12 d-flex">
          <label for="name" className="text-black pt-2 fs-6">
            Whether Any Key Managerial Person Convicted for Any Offence?
          </label>
          <select
            class="form-control"
            id="key_managerial"
            placeholder="Whether Any Key Managerial Person Convicted for Any Offence"
            type="text"
            onChange={(e)=>setState(e.target.value)}
            required
          >
            <option value={"No"}>
              Select Yes/No
            </option>
            <option value={"Yes"}>Yes</option>
            <option value={"No"}>No</option>
          </select>
         
        </div>
        {state == "Yes" &&            <div style={{height:"1px",backgroundColor:"gray",marginBottom:"5px"}}></div>
}
   {state === "Yes" ? <div class="col-md-12 d-flex">
          <label for="name" className="text-black pt-2 fs-6">
          Details
          </label>
          <input
            class="form-control"
            id="key_managerial"
            type="text"
            value={addFormData.key_managerial}
            onChange={(e) =>
              handleChange("key_managerial", e.target.value)
            }
            required
          />
        
        </div>:<></>}

      </div>
    </div>
    <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0",marginTop:"0"}}></div>

   
    <div className={"form__item button__items d-flex justify-content-between mt-4"}
            >
              {/* Render a "Back" button that moves to the previous step in the form */}
              <Button type={"default"} onClick={prev} size="large" className="border-primary text-primary">
                Back
              </Button>
              {/* Render a "Next" button that submits the form data and moves to the next step in the form */}
              <div className="d-flex gap-2">
              <Button type={"primary"} onClick={handleAddSubmit} size="large">
              Submit & Next
              </Button>
              <Button type={"secondary"} className="border border-primary text-primary bg-white" onClick={()=>next()} size="large">
                Skip
              </Button>
              </div>
            </div>
  </form>
  );
};

export default Save;
