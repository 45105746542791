import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  Modal,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
import Moment from "react-moment";
import CountdownTimer from "../../components/CounterDown";
import BaseService from "../../config/axiosService";
function Eauction() {
  const [auctiondata, setAuctionData] = useState([]);
  const [visible, setVisible] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [selected, setSelected] = useState("on-going");

  const [allCategory, setallCategory] = useState([]);
  const [postData, setpostData] = useState([]);
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [viewData, setviewData] = useState([]);
  const [perPage] = useState(5);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filterValues, setfilterValues] = useState({
    search: "",
    categoryID: "",
  });
  const handleChanageFilter = (name, value) => {
    setfilterValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handlePage = (key) => {
    setSkip("");
    setSkip(key - 1);
    console.log(key);
  };
  const handlePageNext = () => {
    if (pageCount > skip) {
      setSkip(skip + 1);
    }
  };
  const handlePagePrevious = () => {
    if (skip >= 1) {
      setSkip(skip - 1);
    }
  };
  const fetchCategory = async () => {
    await axios
      .get(`${baseServerUrl}/getallcat`, {})
      .then((resp) => {
        setallCategory(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchPost = async () => {
    await axios
      .post(`${baseServerUrl}/getalleauctionseller`, {
        sellerID: userInfo.Id,
        page: perPage,
        skip: skip,
        search: filterValues.search,
        categoryID: filterValues.categoryID,
      })
      .then((resp) => {
        setpostData(resp.data.data);
        setPageCount(Math.ceil(resp.data.count / perPage));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const showData = async (id) => {
    await axios
      .get(`${baseServerUrl}/getsellereauction/${id}`)
      .then((resp) => {
        setviewData(resp.data.data);
        setVisible(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAuctionData = async () => {
    try {
      const response = await BaseService.get(`${baseServerUrl}/api/v2/auction`);
      console.log("Response data:", response.data.data);
      setAuctionData(response.data.data);
    } catch (error) {
      console.error("Error fetching profile docs:", error);
    }
  };

  useEffect(() => {
    getAuctionData();
  }, []);

  // useEffect(() => {
  //   fetchCategory();
  //   fetchPost();
  // }, [skip, filterValues]);
  return (
    <>
      <Topbar />
      <Container fluid className="mb-4">
        <Row>
          <Col
            lg="2"
            md="2"
            sm="4"
            style={{ background: "#2A3FA0", paddingRight: "0" }}
          >
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8">
            <div className="tophd">
              <Link to="/sellerauction">Projects Under eAuction</Link>
            </div>
            {/* <Row
              className="mb-3 mx-0"
              style={{ "background-color": "#2A3FA0", height: "80px" }}
            >
              <Col lg="6" md="6" sm="6" className="mt-3">
                <input
                  class="form-control"
                  id="search"
                  style={{ "line-height": "30px" }}
                  placeholder="search ..."
                  type="text"
                  onChange={(e) =>
                    handleChanageFilter("search", e.target.value)
                  }
                />
              </Col>
              <Col lg="6" md="6" sm="6" className="mt-3">
                <select
                  class="form-control custom-select"
                  id="exampleSt"
                  onChange={(e) =>
                    handleChanageFilter("categoryID", e.target.value)
                  }
                >
                  <option class="text-white bg-warning">Choose Category</option>
                  {allCategory.map((cat) => {
                    return (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row> */}
            <Row>
              <Col lg="6" md="6" sm="6">
                <ul className="nav nav-tabs gap-2">
                  <li
                    onClick={() => setSelected("on-going")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "on-going" ? "nav-link active" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#on-going"
                    >
                      On Going
                    </a>
                  </li>
                  <li
                    onClick={() => setSelected("upcomming")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "upcomming"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#completed"
                    >
                      Upcomming
                    </a>
                  </li>
                  <li
                    onClick={() => setSelected("completed")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "completed"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#all-project"
                    >
                      Completed
                    </a>
                  </li>
                  <li onClick={() => setSelected("all")} className="nav-item">
                    <a
                      className={
                        selected === "all" ? "nav-link active" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#all-project"
                    >
                      All
                    </a>
                  </li>
                </ul>
              </Col>
              <Col lg="6" md="6" sm="6">
                <Link to="/create-eauction">
                  <Button variant="warning" className="pull-right">
                    Create New eAuction Room
                  </Button>
                </Link>
              </Col>
            </Row>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h6">Manage eAuction</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "40px" }}>#</th>
                      <th>Project Id</th>
                      <th>Description</th>
                      <th>Start Date</th>
                      <th>End Date </th>
                      <th>Last Bid Price</th>
                      <th>Bidding Status</th>
                      <th>More Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    {auctiondata.length ? (
                      auctiondata.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ width: "40px" }}>{index + 1}</td>
                            <td>{item.project.projectId}</td>
                            <td>{item.project.description}</td>
                            <td>
                              {item.startDate ? (
                                <Moment format="DD-MM-YYYY">{item.startDate}</Moment>
                              ) : (
                                "Unavailable"
                              )}
                            </td>
                            <td>
                              {item.endDate ? (
                                <Moment format="DD-MM-YYYY">{item.endDate}</Moment>
                              ) : (
                                "Unavailable"
                              )}
                            </td>
                            <td>
                             {item.basicValue}
                            </td>
                            <td>
                              Ongoing
                            </td>
                            <td>
                              more info
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="11">no record found</td>
                      </tr>
                    )}
                  </tbody>
                  <div className={"mt-2"}>
                    <Pagination>
                      <Pagination.Prev
                        className="diabled"
                        onClick={handlePagePrevious}
                        aria-label="Previous"
                      />
                      {[...Array(pageCount)]?.map((item, key) => {
                        return (
                          <Pagination.Item
                            key={key}
                            onClick={(e) => handlePage(key + 1)}
                            className={`${skip === key ? "active" : ""}`}
                          >
                            {key + 1}
                          </Pagination.Item>
                        );
                      })}
                      <Pagination.Next
                        onClick={handlePageNext}
                        aria-label="Next"
                      />
                    </Pagination>
                  </div>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer></Footer>
      </Container>
      {/* {visible && (
        <Modal show={visible} centered size="lg">
          <Modal.Header
            closeButton
            onClick={() => setVisible(false)}
            className="bg-green text-white"
          >
            <Modal.Title as="h6">View eAuction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className="table-hover table-striped">
              <tbody>
                <tr>
                  <th>Created</th>
                  <td>
                    <Moment format="DD-MM-YYYY">{viewData.createdAt}</Moment>
                  </td>
                </tr>
                <tr>
                  <th>Category</th>
                  <td>{viewData.categoryID.name}</td>
                </tr>
                <tr>
                  <th>Project</th>
                  <td>{viewData.projectId.name}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{viewData.name}</td>
                </tr>
                <tr>
                  <th>Value</th>
                  <td>{viewData.exactValue}</td>
                </tr>
                <tr>
                  <th>Quantity</th>
                  <td>{viewData.quantity}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    {viewData.status == 1 ? (
                      <span className="p-1 mb-1 bg-primary text-white">
                        Active
                      </span>
                    ) : (
                      <span className="p-1 mb-1 bg-danger text-white">
                        Inactive
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Start Date</th>
                  <td>
                    <Moment format="DD-MM-YYYY">{viewData.startDate}</Moment>
                  </td>
                </tr>
                <tr>
                  <th>End Date</th>
                  <td>
                    <Moment format="DD-MM-YYYY">{viewData.createdAt}</Moment>
                  </td>
                </tr>
                <tr>
                  <th>Platform Fee (%)</th>
                  <td>{viewData.platformCharges}</td>
                </tr>
                <tr>
                  <th>Progress Status</th>
                  <td>
                    {viewData.eauctionStatus == 1 ? (
                      <span className="p-1 mb-1 bg-success text-white">
                        ongoing
                      </span>
                    ) : (
                      <span className="p-1 mb-1 bg-warning text-white">
                        Upcoming
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      )} */}

      {visible && (
        <Modal show={visible} centered size="xl">
          <Modal.Header
            closeButton
            onClick={() => setVisible(false)}
            className=""
          >
            <Modal.Title as="h6">View eAuction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="8" md="8" sm="8">
                <div className="border p-3 rounded-2">
                  <h3>eAuction Details</h3>
                  <h6 className="mt-2">
                    Bidding on scrap material of XYZ pvt ltd. Which entails –
                    Acid Batteries. Lithium
                  </h6>
                </div>
              </Col>
              <Col lg="4" md="4" sm="4">
                <div className="d-flex gap-3 align-items-center">
                  <div className="">
                    <p>Location</p>
                    <h4>Noida Depot</h4>
                  </div>
                  <div className="">
                    <p>Manager</p>
                    <h4>Ram Lal</h4>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-3 border-top pt-2">
              <Col lg="4" md="4" sm="4">
                <p>eAuction ID Number</p>
                <h3>ABC123</h3>
              </Col>
              <Col lg="4" md="4" sm="4">
                <p>Bid Status</p>
                <h3>Ongoing</h3>
              </Col>
              <Col lg="4" md="4" sm="4">
                <p>Time to end bidding</p>
                <h5>
                  <CountdownTimer endDate={"10-03-2024"} />
                </h5>
              </Col>
            </Row>
            <Row className="mt-3 border-top pt-2">
              <Col lg="4" md="4" sm="4">
                <p>Bid Start Date</p>
                <h3>15/02/2024</h3>
              </Col>
              <Col lg="4" md="4" sm="4">
                <p>Bid Start Time</p>
                <h3>12:30PM</h3>
              </Col>
              <Col lg="4" md="4" sm="4">
                <p>Bid Close Date</p>
                <h3>16/02/2024</h3>
              </Col>
            </Row>
            <Row className="mt-3 border-top pt-2">
              <Col lg="4" md="4" sm="4">
                <p>Bid Close Time</p>
                <h3>6:15PM</h3>
              </Col>
              <Col>
                <p>Base Price</p>
                <h3>INR 23.50 Lac</h3>
              </Col>
              <Col>
                <p>Highest Bid Price</p>
                <h3>INR 28.50 Lac</h3>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default Eauction;
