import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container, Button } from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
import "../../assets/scss/custom.scss";
import BaseService from "../../config/axiosService";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDashboardData = async () => {
    try {
      const response = await BaseService.get("/api/v2/user/vendor/dashboard");
      setDashboardData(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const DashboardCard = ({ title, value, icon }) => (
    <Card className="dashboard-card">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="text-muted mb-1">{title}</h6>
            <h3 className="mb-0">{value || "..."}</h3>
          </div>
          <div className="icon-shape bg-primary text-white rounded-circle shadow">
            <i className={`fas ${icon}`}></i>
          </div>
        </div>
      </Card.Body>
    </Card>
  );

  const fetchUserProfileDetails = async () => {
    setLoading(true);
    try {
      const response = await BaseService.get("/api/v2/user/vendor/profile", {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      });
      console.log(response.data.data);
      const file = new Blob([response.data], { type: "application/pdf" });

      // Create a link element, set the download attribute, and click it programmatically
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = `user_profile.pdf`;
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard-wrapper">
      <Topbar />
      <Container fluid>
        <Row>
          <Col lg={2} md={3} className="sidebar-wrapper">
            <Sidebar />
          </Col>
          <Col lg={10} md={9} className="main-content px-4">
            <Row>
              <Col lg={4} md={6} className="mb-4">
                <DashboardCard
                  title="Number of Staff"
                  value={dashboardData?.teamCount}
                  icon="fa-users"
                />
              </Col>
              <Col lg={4} md={6} className="mb-4">
                <DashboardCard
                  title="Total eAuctions"
                  value={dashboardData?.noOfDistinctAuctionsParticipated}
                  icon="fa-gavel"
                />
              </Col>
              <Col lg={4} md={6} className="mb-4">
                <DashboardCard
                  title="Winning Auctions"
                  value={dashboardData?.noOfAuctionsWon}
                  icon="fa-trophy"
                />
              </Col>
              <Col lg={4} md={6} className="mb-4">
                <DashboardCard
                  title="Number of Addresses"
                  value={dashboardData?.addressCount}
                  icon="fa-map-marker-alt"
                />
              </Col>
              <Col lg={4} md={6} className="mb-4">
                <DashboardCard
                  title="Number of Banks"
                  value={dashboardData?.bankCount}
                  icon="fa-university"
                />
              </Col>
              <Col lg={4} md={6} className="mb-4">
                <Card className="dashboard-card">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h6 className="text-muted mb-1">Download Profile</h6>
                        <h3 className="mb-0">{"..."}</h3>
                      </div>
                      <div
                        style={{
                          cursor: loading ? "default" : "pointer", // Disable pointer when loading
                        }}
                        className="icon-shape bg-primary text-white rounded-circle shadow"
                        onClick={!loading ? fetchUserProfileDetails : null} // Prevent clicking when loading
                      >
                        {loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-white"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <i className="fas fa-download"></i>
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Dashboard;
