import React, { useEffect, useState } from "react";
import { Route, Navigate } from "react-router-dom";
import toast from "react-hot-toast";
import BaseService from "../config/axiosService";

const PermissionCheck = ({ children, module, action = "view" }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const response = await BaseService.get("/api/v2/roles/permissions");

        const { permissions } = response.data;

        if (permissions.includes("every")) {
          setIsAuthorized(true);
          return;
        } else {
          const hasPermission = permissions.some(
            (permission) =>
              permission.module === module &&
              permission.actions.includes(action)
          );

          setIsAuthorized(hasPermission);
        }
      } catch (error) {
        console.error("Error checking permissions:", error);
        setIsAuthorized(false);
      }
    };

    checkAuthorization();
  }, [module, action]);

  if (isAuthorized === null) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <span className="text-primary" style={{ fontSize: "24px" }}>
          Loading...
        </span>
      </div>
    );
  }

  if (!isAuthorized) {
    toast.error("You are not authorized to access this page");
    return <Navigate to="/" />;
  }

  return <div>{children}</div>;
};

export default PermissionCheck;
