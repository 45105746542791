import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import { useParams } from "react-router-dom";
import BaseService from "../../../src/config/axiosService";
import Moment from "react-moment";
import { getSocket, initSocket } from "../../helpers/socketService";
import axios from "axios";

export default function LiveAuction() {
  const [mobileView, setMobileView] = useState(false);
  const [auctionData, setAuctionData] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState({});
  const [bidAmount, setBidAmount] = useState(0);
  const [currentHighestBid, setCurrentHighestBid] = useState(0);
  const [isHighestBidder, setIsHighestBidder] = useState(false);
  const [bidHistory, setBidHistory] = useState([]);
  const [valueDisplay, setValueDisplay] = useState('In Full');
  const { id } = useParams();
  const [ip, setIp] = useState('');
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const userId = user ? user._id : null;
  const token = user ? user.token : null;

  useEffect(() => {
    fetchAuctionDetails();
    fetchIp();



  }, [id, token]);

  useEffect(() => {
    const socket = initSocket(token);
    setupSocketListeners(socket);
    return () => {
      socket.disconnect();
    };
  }, [token])

  const fetchAuctionDetails = async () => {
    try {
      const response = await BaseService.get(`/api/v2/auction/getById/${id}`);
      const auctionData = response.data.data;
      setAuctionData(auctionData);
      setCurrentHighestBid(auctionData.currentHighestBid || auctionData.basicValue);
      setBidAmount(auctionData.currentHighestBid + auctionData.minbidingValue);

      const historyResponse = await BaseService.get(`/api/v2/bidding-history/${id}/bidHistory`);
      setBidHistory(historyResponse.data.data);
    } catch (error) {
      console.error("Error fetching auction details:", error);
    }
  };

  const fetchIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json");
      setIp(response.data.ip);
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  };

  const setupSocketListeners = (socket) => {
    socket.on('bidUpdate', (data) => {
      // console.log("DATA BID UPDATE", data);
      setCurrentHighestBid(data.currentHighestBid);
      setIsHighestBidder(data.bidder === userId);
      console.log(data, "BIDDING UPDATE");
      setBidHistory(prevHistory => [data.biddingHistory, ...prevHistory]);
      console.log(auctionData, "MIN BID VALUE");
      if(auctionData){
        setBidAmount(data.currentHighestBid + (auctionData.minbidingValue ?? 0));
      }
      else{
        updateBidAmountWithMinValue();
      }
    });

    socket.on('auctionUpdate', (data) => {
      setAuctionData(prevData => ({ ...prevData, endDate: data.endDate }));
    });

    socket.on('auctionEnded', (data) => {
      console.log('Auction ended. Winner:', data.winner);
      alert('Auction has ended.');
      // Handle auction end (e.g., disable bidding, show winner)
    });

    socket.on('bidError', (error) => {
      console.error('Bid error:', error.message);
      alert(error.message);
    });
  };

  const updateBidAmountWithMinValue = async () => {
    try{
      const response  = await BaseService.get(`/api/v2/auction/getById/${id}`);
      const auctionData = response.data.data;
      setBidAmount(auctionData.currentHighestBid + auctionData.minbidingValue);
    }
    catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    if (auctionData) {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        const endTime = new Date(auctionData.endDate).getTime();
        const difference = endTime - now;

        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          setTimeRemaining({ days, hours, minutes, seconds });
        } else {
          clearInterval(timer);
          setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [auctionData]);

  useEffect(() => {
    if (!auctionData) return;
    const socket = getSocket();
    socket.emit('joinAuction', auctionData._id);
  }, [
    auctionData
  ])

  const handleBidSubmit = () => {
    const socket = getSocket();
    socket.emit('placeBid', { auctionId: auctionData._id, bidAmount, userId });
  };

  const handleIncreaseBid = () => {
    setBidAmount(prevAmount => prevAmount + auctionData.minbidingValue);
  };

  const handleDecreaseBid = () => {
    setBidAmount(prevAmount => Math.max(prevAmount - auctionData.minbidingValue, currentHighestBid + auctionData.minbidingValue));
  };

  const formatValue = (value) => {
    switch (valueDisplay) {
      case 'In Thousands':
        return `${(value / 1000).toFixed(2)}K`;
      case 'In Lakhs':
        return `${(value / 100000).toFixed(2)}L`;
      case 'In Millions':
        return `${(value / 1000000).toFixed(2)}M`;
      case 'In Crores':
        return `${(value / 10000000).toFixed(2)}Cr`;
      default:
        return value;
    }
  };

  if (!auctionData) return <div>Loading...</div>;

  return (
    <div>
      <Topbar />
      <Row>
        <Col lg={2} md={3} sm={4} xs={12} style={{ background: '#2A3FA0' }}>
          <Sidebar />
        </Col>
        <Col lg={10} md={9} sm={8} xs={12}>
          <Card>
            <Card.Body>
              <Row className="mb-3">
                <Col lg={4}>
                  <p
                    className="text-primary text-decoration-underline text-center"
                    onClick={() => setMobileView(!mobileView)}
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                  >
                    {mobileView ? 'Change to Desktop View' : 'Change to Mobile View'}
                  </p>
                </Col>
                <Col lg={4}>
                  <p className="text-primary text-decoration-underline text-center">
                    Your IP Add: {ip || "Fetching IP..."}
                  </p>
                </Col>
                <Col lg={4}>
                  <div className="text-end">
                    Refresh
                    <i className="bi bi-arrow-clockwise ms-2"></i>
                  </div>
                </Col>
              </Row>

              <div className={`d-flex ${mobileView ? 'flex-column' : 'flex-row'} gap-4`}>
                <div className={`left ${mobileView ? 'w-100' : 'w-50'}`}>
                  {
                    new Date(auctionData.endDate).toTimeString()
                  }
                  {mobileView && <h4>Auction has been Started</h4>}
                  {!mobileView && <img src={"./auction-room-1.png"} alt="" className="img-fluid" />}
                  <div className="d-flex flex-column gap-2 mt-3">
                    <p><strong>eAuction Name:</strong> <span className="text-primary">{auctionData.auctionName}</span></p>
                    <p><strong>eAuction ID:</strong> <span className="text-primary">{auctionData._id}</span></p>
                    <p><strong>Project ID:</strong> <span className="text-primary">{auctionData.project.projectId}</span></p>
                    <p><strong>Description:</strong> {auctionData.project.description}</p>
                    <p><strong>Client Name and Code:</strong> <span className="text-primary">{auctionData.createdBy.username}</span></p>
                    <p><strong>Base Price:</strong> <span className="text-primary">₹ {auctionData.basicValue}</span></p>
                    <p><strong>Minimum Increment:</strong> <span className="text-primary">₹ {auctionData.minbidingValue}</span></p>
                    <p><strong>Bid Open Time:</strong> <span className="text-primary"><Moment format="HH:mm">{auctionData.startDate}</Moment></span></p>
                    <p><strong>Bid Close Time:</strong> <span className="text-primary"><Moment format="HH:mm">{auctionData.endDate}</Moment></span></p>
                  </div>
                  <div className="grid6x2 border p-3 mt-3">
                    <a href={auctionData.eauctiontermsandconditions}>Auction Specific T&C</a>
                    <a href={auctionData.termsandconditions}>General T&C</a>
                    <a href={auctionData.biddermanual}>Auction Manual</a>
                    <a href="">Details</a>
                    <a href="">View Images</a>
                    <a href="">View Videos</a>
                    <a href={auctionData.otherdocumentsfiles}>View Files</a>
                  </div>
                  <div className="border p-3 text-center fw-bold mt-3">
                    Help: {auctionData.supportteams[0]?.username || "Contact Support"} - {auctionData.supportteams[0]?.email || "Contact Support"} - {auctionData.supportteams[0]?.phone || "Contact Support"}
                  </div>
                </div>

                <div className={`right ${mobileView ? 'w-100' : 'w-50'}`}>
                  <h3 className="text-center">Auction has been Started</h3>
                  <div className="d-flex align-items-center border p-1 mt-3" style={{ background: '#F7C566' }}>
                    <p className="w-25 m-0">Auction Will End in</p>
                    <div className="w-75">
                      <div className="d-flex w-100">
                        {Object.entries(timeRemaining).map(([unit, value]) => (
                          <p key={unit} className="m-0 d-flex flex-column w-25 text-center">
                            <span className="text-center fs-3 fw-bold">{value}</span>{unit}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex border p-2 mt-3" style={{ background: isHighestBidder ? '#90D26D' : '#FFD700' }}>
                    <h5 className="w-50 text-center m-0">{isHighestBidder ? 'You are Highest Bidder' : 'Current Highest Bid'}</h5>
                    <h5 className="w-50 text-center m-0">{formatValue(currentHighestBid)}</h5>
                  </div>
                  <div className="border pb-3 mt-3 p-3">
                    <div className="d-flex justify-content-center mt-3">
                      <input
                        type="number"
                        placeholder="Enter Bid Amount"
                        className="form-control text-center fs-3"
                        value={bidAmount}
                        onChange={(e) => setBidAmount(Number(e.target.value))}
                      />
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <Button
                        variant="success"
                        className="w-50"
                        style={{
                          margin: 'auto',
                          padding: '10px',
                          fontSize: '20px',
                          borderRadius: '10px',
                        }}
                        onClick={handleBidSubmit}
                      >
                        Bid Now
                      </Button>
                    </div>
                  </div>
                  <div className="d-flex align-items-center border p-2 fs-5 justify-content-between mt-3">
                    <p className="m-0">Increase Bid By</p>
                    <p className="m-0 fw-bold">Rs. {auctionData.minbidingValue}</p>
                    <div className="d-flex gap-3">
                      <span className="fs-2 bg-info text-white p-3 px-4" style={{ cursor: 'pointer' }} onClick={handleIncreaseBid}>+</span>
                      <span className="fs-2 bg-info text-white p-3 px-4" style={{ cursor: 'pointer' }} onClick={handleDecreaseBid}>-</span>
                      <button className="fs-6 text-white bg-success p-2 px-3" style={{ cursor: 'pointer' }} onClick={handleBidSubmit}>Submit</button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center gap-3 border p-3 mt-3">
                    <p className="m-0">Show Value In</p>
                    <Form.Select
                      className="w-50"
                      onChange={(e) => setValueDisplay(e.target.value)}
                      value={valueDisplay}
                    >
                      <option value="In Full">In Full</option>
                      <option value="In Thousands">In Thousands</option>
                      <option value="In Lakhs">In Lakhs</option>
                      <option value="In Millions">In Millions</option>
                      <option value="In Crores">In Crores</option>
                    </Form.Select>
                  </div>
                  <div className="mt-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Bidder</th>
                          <th>Date</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bidHistory.map((bid, index) => (
                          <tr key={index}>
                            <td>{bid.bidder === userId ? 'Your Bid' : `IP ${bid.ip}`}</td>
                            <td><Moment format="DD-MM-YYYY HH:mm:ss">{bid.bidTime}</Moment></td>
                            <td>₹ {formatValue(bid.bidAmount)}</td>
                          </tr>
                        )).slice(0, 5)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}