import React from 'react-router-dom';
import shape3 from '../../assets/images/shape/shape-3.png';
import solution from '../../assets/images/resource/solution.gif';
import './css/solution-section.css';

const SolutionSec = () => {
    return (
        <section className="solution-section sec-pad">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-7 col-sm-12">
                        <div className='col-12 mb-4'>
                            <h4>GetMaxValue Advantage</h4>
                            <p>End-to-end scrap management solutions that help businesses with the challenges faced when disposing their scrap</p>
                        </div>
                        <div className='one row'>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="indList ind1">
                                     Organised Scrap Disposal
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="indList ind2">
                                     Stringent Controls & Ratings
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="indList ind3">
                                     100% Trustworthiness
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="indList ind4">
                                     Verified Pan-India Listings
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="indList ind5">
                                     Transparent Auction Procedures
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="indList ind6">
                                     Expert Scrap Management and Compliance Teams
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-5 col-sm-12 image-column align-items-center">
                        <div className="image_block_one">
                            <div className="image-box pl_30 ml_30">
                                <div className="image-shape"><img src={shape3} alt=" shape" /></div>
                                <figure className="solutionImg"><img src={solution} alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    )
}
export default SolutionSec;