import React, { useEffect, useState } from "react";
import moment from "moment";
const Timeleft = (endDate, endTime) => {
  var date = moment(endDate).format("YYYY-MM-DD");
  console.log(date)
  var time = moment(endTime).format("hh:mm:ss");
  var momentObj = moment(date + time, 'YYYY-MM-DD');
  // conversion
  var dateTime = momentObj.format('YYYY-MM-DD HH:mm:s');
  const targetTime = new Date(dateTime).getTime();
  const [currentTime, setCurrentTime] = useState(Date.now());

  const timeBetween = targetTime - currentTime;
  const seconds = Math.floor((timeBetween / 1000) % 60);
  const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
  const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // if (days + hours + minutes + seconds <= 0) {
  //   return (<span className="expired">This Deal is Expired!!!</span>);
  // } else {
  //   return (
  //       <p className="counter">
  //         <span>{days}Days </span>
  //         <span>{hours}H : </span>
  //         <span>{minutes}M :  </span>
  //         <span>{seconds}S</span>
  //       </p>
  //   );
  // }


  return (
    <>
      <p className="counter">
        <span>{days}Days </span>
        <span>{hours}H : </span>
        <span>{minutes}M :  </span>
        <span>{seconds}S</span>
      </p> 
    </>
  )
}
export default Timeleft;