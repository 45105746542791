import React, { useState, useEffect } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, Toast } from "react-bootstrap";
import toast from "react-hot-toast";
import BaseService from "../../config/axiosService";
// import "./customSideBarCSS.scss"

const Sidebar = () => {
  const navigate = useNavigate();
  const [profileSubmMenu, setProfileSubmMenu] = useState(
    JSON.parse(localStorage.getItem("pSubMenu"))?.pSubMenu || false
  );
  let adminApproval = false;
  if (localStorage.getItem("userInfo")) {
    adminApproval = JSON.parse(localStorage.getItem("userInfo")).adminApproval;
  }
  const fillLater = localStorage.getItem("fillLater");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [auctionSubMenu, setAuctionSubMenu] = useState(false);

  const [teamMenu, setTeamMenu] = useState(false);
  const [profileErrorMessage, setProfileErrorMessage] = useState("");

  const handleNavLinkClick = (route, canAccess, closeSub) => {
    if (closeSub) {
      setProfileSubmMenu(false);
      localStorage.setItem("pSubMenu", JSON.stringify({ pSubMenu: false }));
    }
    if (canAccess) {
      navigate(route);
      return;
    }
    if (!adminApproval) {
      navigate(route);
      return;
    } else {
      navigate(route);
    }
  };

  const handleSubMenuClick = (route) => {
    let pSubMenu =
      JSON.parse(localStorage.getItem("pSubMenu"))?.pSubMenu || false;
    if (profileSubmMenu && pSubMenu) {
      setProfileSubmMenu(false);
      localStorage.setItem("pSubMenu", JSON.stringify({ pSubMenu: false }));
    } else {
      setProfileSubmMenu(true);
      localStorage.setItem("pSubMenu", JSON.stringify({ pSubMenu: true }));
    }
  };

  const checkProfileStatus = async () => {
    try {
      const response = await BaseService.get("/api/v2/user/check-profile-completion");
      return { status: response.data.status, message: "" };
    } catch (err) {
      if (err.response && err.response.data.status === false) {
        return { status: false, message: err.response.data.message };
      }
      return { status: false, message: "An error occurred while checking profile status" };
    } 
  };

  useEffect(() => {
    const checkAndShowModal = async () => {
      if (!adminApproval && !fillLater) {
        const { status, message } = await checkProfileStatus();
        if (!status) {
          setProfileErrorMessage(message);
          setShowProfileModal(true);
        }
      }
    };

    checkAndShowModal();
  }, [adminApproval, fillLater]);

 
  return (
    <>
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100%",
          overflow: "scroll initial",
          minHeight: "83vh",
          userSelect: "none",
        }}
      >
        <CDBSidebar textColor="#000" backgroundColor="#fff">
          <CDBSidebarHeader>Seller Panel</CDBSidebarHeader>

          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              {/* <li onClick={() => handleNavLinkClick("/dashboard", true, true)}>
                <CDBSidebarMenuItem icon="tachometer">
                  Dashboard
                </CDBSidebarMenuItem>
              </li> */}
              <li
                onClick={() =>
                  handleNavLinkClick("/project-status", true, true)
                }
              >
                <CDBSidebarMenuItem icon="info-circle">
                  Project Status
                </CDBSidebarMenuItem>
              </li>
              <li
                onClick={() =>
                  handleNavLinkClick("/projects-under-eaution", true, true)
                }
              >
                <CDBSidebarMenuItem icon="gavel">
                  Projects under eAuction
                </CDBSidebarMenuItem>
              </li>
              <li
                onClick={() =>
                  handleNavLinkClick("/seller-account-statement", true, true)
                }
              >
                <CDBSidebarMenuItem icon="credit-card">
                  Account Statement
                </CDBSidebarMenuItem>
              </li>

              <li onClick={() => handleSubMenuClick("/seller-profile")}>
                <CDBSidebarMenuItem icon="user">
                  Profile{" "}
                  {profileSubmMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </CDBSidebarMenuItem>
              </li>
              {profileSubmMenu ? (
                <>
                  <li
                    onClick={() => handleNavLinkClick("/seller-profile", true)}
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      General Information
                    </CDBSidebarMenuItem>
                  </li>
                  {/* <li onClick={() => handleNavLinkClick("/seller-compliance",true)}>
                    <CDBSidebarMenuItem
                      className="ps-4"
                      icon="angle-right"
                    >
                      Compliance
                    </CDBSidebarMenuItem>
                  </li> */}
                  {/* <li
                    onClick={() => handleNavLinkClick("/seller-work-experience",true)}
                  >
                    <CDBSidebarMenuItem
                      className="ps-4"
                      icon="angle-right"
                    >
                      Work Experience
                    </CDBSidebarMenuItem>
                  </li> */}
                  {/* <li onClick={() => handleNavLinkClick("/seller-financial",true)}>
                    <CDBSidebarMenuItem
                      className="ps-4"
                      icon="angle-right"
                    >
                      Financial
                    </CDBSidebarMenuItem>
                  </li> */}
                  <li onClick={() => handleNavLinkClick("/seller-bank", true)}>
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Bank
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() => handleNavLinkClick("/seller-branches", true)}
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Address Details
                    </CDBSidebarMenuItem>
                  </li>
                  {/* <li onClick={() => handleNavLinkClick("/seller-others",true)}>
                    <CDBSidebarMenuItem
                      className="ps-4"
                      icon="angle-right"
                    >
                      Project Handling
                    </CDBSidebarMenuItem>
                  </li> */}
                  <li
                    onClick={() => handleNavLinkClick("/seller-profile-docs")}
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      My Documents
                    </CDBSidebarMenuItem>
                  </li>
                  <li onClick={() => handleNavLinkClick("/seller-changepassword", true)}
                  >
                     <CDBSidebarMenuItem className="ps-4" icon="lock">
                      Change Password
                    </CDBSidebarMenuItem>
                  </li>
                </>
              ) : (
                <></>
              )}

              {/* <li onClick={() => setAuctionSubMenu((prev) => !prev)}>
                <CDBSidebarMenuItem icon="gavel">
                  My Projects{" "}
                  {auctionSubMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </CDBSidebarMenuItem>
              </li> */}
              {/* {auctionSubMenu ? (
                <>
                  <li
                    onClick={() => handleNavLinkClick("/Project", false, true)}
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Project
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() =>
                      handleNavLinkClick("/sellerauction", false, true)
                    }
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Auction
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() => handleNavLinkClick("/Earning", false, true)}
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Earning
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() =>
                      handleNavLinkClick("/seller-project-docs", false, true)
                    }
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Project Docs
                    </CDBSidebarMenuItem>
                  </li>
                </>
              ) : (
                <></>
              )} */}

              <li onClick={() => setTeamMenu((prev) => !prev)}>
                <CDBSidebarMenuItem icon="users">
                  Team {teamMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </CDBSidebarMenuItem>
              </li>
              {teamMenu ? (
                <>
                    <li onClick={() => handleNavLinkClick("/staff", false, true)}>
                    <CDBSidebarMenuItem icon="angle-right">
                      View Team Members
                    </CDBSidebarMenuItem>
                  </li>
                  <li onClick={() => handleNavLinkClick("/staff", false, true)}>
                    <CDBSidebarMenuItem icon="angle-right">
                      Add New Member
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() =>
                      handleNavLinkClick("/staff-role", false, true)
                    }
                  >
                    <CDBSidebarMenuItem icon="angle-right">
                      Assign Role
                    </CDBSidebarMenuItem>
                  </li>
                
                </>
              ) : (
                <></>
              )}
              <li className="text-white ps-3 pt-2">
                <h6 className="text-white">For Feedback</h6>
                <p
                  style={{
                    fontSize: "13px",
                  }}
                  className="text-white"
                >
                  Email: feedback@getmaxvalue.in
                </p>
                <p
                  style={{
                    fontSize: "13px",
                  }}
                  className="text-white"
                >
                  Contact: 9313010417
                </p>
              </li>
            </CDBSidebarMenu>
          </CDBSidebarContent>

          {/* <CDBSidebarFooter style={{ textAlign: 'center' }}>
  <div
    style={{
      padding: '20px 5px',
    }}
  >
    Sidebar Footer
  </div>
</CDBSidebarFooter>  */}
        </CDBSidebar>
      </div>
      <Modal show={showProfileModal} animation={false}>
        <Modal.Header>
          <Modal.Title color="black" as={"h6"}>
            Profile Not Completed
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please Complete your Profile or you can fill it later
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{
              backgroundColor: "white",
              color: "#2A3FA0",
            }}
            onClick={() => {
              setShowProfileModal(false);
              localStorage.setItem("fillLater", "true");
            }}
          >
            Fill Later
          </Button>
          <Button
            variant="primary"
            style={{
              backgroundColor: "#2A3FA0",
              borderColor: "#2A3FA0",
            }}
            onClick={() => {
              navigate("/seller-profile");
              setShowProfileModal(false);
            }}
          >
            Complete your Profile
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Sidebar;

// previous sidebar code
{
  /* <CDBSidebar textColor="#000" backgroundColor="#fff">
<CDBSidebarHeader>
    Buyer Panel
</CDBSidebarHeader>

<CDBSidebarContent className="sidebar-content">
  <CDBSidebarMenu>
    <li  onClick={()=>handleNavLinkClick("/buyer-dashboard")}>
      <CDBSidebarMenuItem icon="tachometer">Dashboard</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleSubMenuClick("/buyer-profile")}>
    </li>
    <li  onClick={()=>handleNavLinkClick("/buyer-compliance")}>
      <CDBSidebarMenuItem icon="user">Compliance</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleNavLinkClick("/buyer-work-experience")}>
      <CDBSidebarMenuItem icon="user">Work Experience</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleNavLinkClick("/buyer-financial")}>
      <CDBSidebarMenuItem icon="user">Financial</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleNavLinkClick("/branches")}>
      <CDBSidebarMenuItem icon="home">Branche Office</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleNavLinkClick("/buyer-eauction")}>
      <CDBSidebarMenuItem icon="gavel">eAuctions</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleNavLinkClick("/staff-role")}>
      <CDBSidebarMenuItem icon="user-secret">Role</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleNavLinkClick("/buyer-staff")}>
      <CDBSidebarMenuItem icon="users">Staff</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleNavLinkClick("/bidding-history")}>
      <CDBSidebarMenuItem icon="history">Bidding History</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleNavLinkClick("/transaction")}>
      <CDBSidebarMenuItem icon="credit-card">Transaction</CDBSidebarMenuItem>
    </li>
    <li  onClick={()=>handleNavLinkClick("/buyer-notification")}>
      <CDBSidebarMenuItem icon="bell">Notifications</CDBSidebarMenuItem>
    </li>
  </CDBSidebarMenu>
</CDBSidebarContent>

 <CDBSidebarFooter style={{ textAlign: 'center' }}>
  <div
    style={{
      padding: '20px 5px',
    }}
  >
    Sidebar Footer
  </div>
</CDBSidebarFooter> 
</CDBSidebar> */
}
