import io from 'socket.io-client';

let socket;

export const initSocket = (token) => {
  socket = io('https://backend.getmaxvalue.in', {
    auth: {
      token: token
    }
  });

  socket.on('connect', () => {
    console.log('Connected to Socket.IO server');
  });

  socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
  });

  // Set CORS headers
  socket.io.engine.on("headers", (headers) => {
    headers["Access-Control-Allow-Origin"] = "*";
    headers["Access-Control-Allow-Methods"] = "GET, POST";
    headers["Access-Control-Allow-Headers"] = "Content-Type";
  });

  return socket;
};

export const getSocket = () => socket;