import React from 'react';
import { Link } from 'react-router-dom';

const Copyright = () => {
    return (
        <footer className="main-footer bglight">
            <div className="footer-bottom centred">
                <div className="auto-container">
                    <div className="copyright">
                        <p>Copyright © 2023 <Link to="https://kriscent.in/" target="_blank" rel="noopener noreferrer" >Kriscent Techno Hub Pvt. Ltd.</Link> All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
        /* main - footer */
    )
}
export default Copyright;