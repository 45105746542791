import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/SidebarBidder';
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { FaRegUser } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
const BuyerLoginDetails = () => {
    const loginDetails = JSON.parse(localStorage.getItem('userInfo') || '[]');

    return (
        <>
            <Topbar />
            <Container fluid className="mb-4">
                <Row>
                    <Col lg="2" md="2" sm="4" >
                        <Sidebar />
                    </Col>
                    <Col lg="10" md="10" sm="8" className='ps-2'>
                        <div className="tophd">
                            Dashboard{' '}
                            <span style={{ color: '#000' }}>
                                <i className="fa fa-angle-right"></i>
                            </span>{' '}
                            <Link to="/buyer-profile">Login Details</Link>
                        </div>
                        <div className="">
                            <Card>
                                <Card.Body className=''>
                                    <div className='d-flex flex-column gap-3'>
                                        <h6> <FaRegUser/> {loginDetails.username}</h6>
                                        <h6><FiPhone/> {loginDetails.phone}</h6>
                                        <h6><HiOutlineMail /> {loginDetails.email}</h6>
                                    </div>
                                    <div style={{height:'1px',width:"100%",backgroundColor:'lightgray',marginTop:"10px"}}></div>
                                    <Link to={'/buyer-changepassword'}><Button size='sm' type="submit" style={{backgroundColor:"#2A3FA0",border:"none"}} className='mt-3'>
                                        Change Password
                                    </Button></Link>
                                    {" "}
                                    <Link to={'/forgot'}><Button size='sm' type="submit" style={{backgroundColor:"#2A3FA0",border:"none"}} className='mt-3'>
                                        Forgot Password
                                    </Button></Link>
                                </Card.Body>
                            </Card>
                        </div>
                </Col>
                </Row>
                <Footer />
            </Container>
        </>
    );
};

export default BuyerLoginDetails
