import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
// react-bootstrap components
import {
  Badge,
  Button,
  Card,

  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
function ChangePassword() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const baseServerUrl = process.env.REACT_APP_baseServerURL;

  const [formData, setFormData] = useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  })
  const [errors, setErrors] = useState({})
  const handleChange = (name, value) => {
    setFormData((pre) => ({
      ...pre,
      [name]: value,
    }))
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const validationErrors = validateForm(formData)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
    } else {
      axios
        .put(`${baseServerUrl}/changepassword/${userInfo.Id}`, formData)
        .then((resp) => {
          resp.data.data.status
            ? toast.success(resp.data.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
            : toast.error(resp.data.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              })
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          } else {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            })
          }
        })
    }
  }
  function validateForm(formData) {
    const errors = {}
    if (!formData.old_password.trim()) {
      errors.old_password = 'Old password is required'
    }
    if (!formData.new_password.trim()) {
      errors.new_password = 'new password is required'
    }else if (formData.new_password.trim().length < 6) {
      errors.new_password = 'Password must be at least 6 characters long'
      }
    
    if (!formData.confirm_password.trim()) {
      errors.confirm_password = 'confirm password is required'
    }else if (formData.confirm_password.trim().length < 6) {
      errors.confirm_password = 'Password must be at least 6 characters long'
      }
    if (formData.new_password !== formData.confirm_password) {
      errors.new_confirm = 'New Password and Confirm Password does not match'
    }
    return errors
  }
  return (
    <>
    <Topbar />
    <ToastContainer></ToastContainer>
    {/* <Header /> */}
    <container-fluid className="mb-4">
      <Row>
      <Col lg="2" md="2" sm="4" style={{background: '#2A3FA0'}}>       
        <Sidebar />
      </Col>
      <Col lg="10" md="10" sm="8">
        <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/buyer-changepassword" >Change password</Link></div>
            <Card>
              <Card.Header>
                <Card.Title as="h6">Change Password</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-4">

                  <Col className="pl-1" md="4">
                      <Form.Group>
                        <label htmlFor="exampleInputEmail1">
                          Old Password
                        </label>
                        <Form.Control
                          type="password"
                          id="old_password"
                          name="old_password"
                          placeholder="old password"
                          onChange={(e) => handleChange('old_password', e.target.value)}
                          required
                        ></Form.Control>
                        {errors.old_password && <span className="text-danger">{errors.old_password}</span>}
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="5">
                      <Form.Group>
                        <label>New Password</label>
                        <Form.Control
                          type="password"
                          id="new_password"
                          name="new_password"
                          placeholder="new password"
                          onChange={(e) => handleChange('new_password', e.target.value)}
                          required
                        ></Form.Control>
                        {errors.new_password && <span className="text-danger">{errors.new_password}</span>}
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group>
                        <label>Confirm Password</label>
                        <Form.Control
                          type="password"
                          id="confirm_password"
                          name="confirm_password"
                          placeholder="Enter Confirm Password"
                          onChange={(e) => handleChange('confirm_password', e.target.value)}
                          required
                        ></Form.Control>
                        {errors.confirm_password && (
                          <span className="text-danger">{errors.confirm_password}</span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="primary"
                  >
                    Update Password
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
      </Row>
          <Footer />
      </container-fluid>
    </>
  );
}

export default ChangePassword;
