import React from "react";
import { Link } from "react-router-dom";
import "./css/advisory-team.css";
import teamImage1 from "../../assets/images/resource/team-11.jpg";
import teamImage2 from "../../assets/images/resource/team-12.jpg";
import teamImage3 from "../../assets/images/resource/team-13.jpg";
import NeerajAgarwal from "../../assets/Team Members/Neeraj Agarwal.jpg";
import AnkitPathak from "../../assets/Team Members/Ankit Pathak.jpg";
import Neetu from "../../assets/Team Members/Neetu_Image-removebg-preview.png";
import Punnag from "../../assets/Team Members/punnag_image-removebg-preview.png";
import SudeepShrotriya from "../../assets/Team Members/Sudeep Shrotriya.png";

const AdvisoryTemSec = () => {
  // Define working block data
  const advisoryTeam = [
    {
      img: NeerajAgarwal,
      name: "Neeraj Agarwal",
      designation: "Process",
      description:
        "He is Chartered Accountant and associated as consultant with Get Max Value and providing consultancy services to manage controls and compliances of the company. ",
    },
    {
      img: AnkitPathak,
      name: "Ankit Pathak",
      designation: "Director",
      description:
        "Mr Ankit Pathak has done MBA in HR. He has outstanding ability to handle people and manage critical administrative situations.",
    },
    {
      img: Neetu,
      name: "Neetu Agarwal",
      designation: "Investor",
      description:
        "She hold master’s degree from Br Bheem Rao Ambedkar University Agra and regularly investing in new age business",
    },
    {
      img: Punnag,
      name: "Punnag Bhuyan",
      designation: "CTO",
      description:
        "He has great exposure in Software designing and development, His work contribution in Tech development of Get Max Value is remarkable",
    },
    {
      img: SudeepShrotriya,
      name: "Sudeep Shrotriya",
      designation: "Legal",
      description:
        "He is Law graduate from Delhi University and represents legal matters in higher courts. He is Associated with the company to ensure Legal compliances.",
    },
  ];

  return (
    <section className="team-section team-page alternat-2 pt_60 centred">
      <div className="auto-container">
        <div className="titleSec mb_30">
          <h2>Our Team</h2>
        </div>
        <div className="row clearfix">
          {advisoryTeam.map((item, index) => (
            <div
              className="col-lg-3 col-md-4 col-sm-6 col-12 team-block"
              key={index}
            >
              <div
                className="team-block-one mb_75 wow fadeInUp animated border rounded-4"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <figure className="image-box">
                    <Link to="#!">
                      <img src={item.img} alt="" />
                    </Link>
                  </figure>
                  <div className="lower-content">
                    <h3>
                      {item.name},{"  "}
                      <span className="fw-light">{item.designation}</span>
                    </h3>
                    <span className="designation justify">
                      {item.description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AdvisoryTemSec;
