import React from "react";
import { Link } from "react-router-dom";
import "./css/about-section.css";

import shape3 from "../../assets/images/shape/shape-3.png";
import about1 from "../../assets/images/resource/dronview1.png";
import about3 from "../../assets/images/resource/dronview2.png";
import about2 from "../../assets/images/resource/dronview3.jpg";

const AboutUsSec = ({ data }) => {
  const parse = require("html-react-parser");
  return (
    <section className="about-section bg-light">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-6 col-md-12 col-sm-12 content-column">
            <div className="content_block_one">
              <div className="content-box mr_40">
                <div className="titleSec mb_25">
                  {/* <span className="sub-title lableBlue">About Us</span> */}
                  <h3>
                    GetMaxValue: India’s Premier Scrap Management Intermediary
                  </h3>
                </div>
                {parse(data.body)}
                <div className="btn-box">
                  <Link to="/about" className="theme-btn btn-two">
                    <span className="textLightGreen">View More</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 image-column">
            <div className="image_block_one">
              <div className="image-box pl_130 ml_30">
                <div className="image-shape">
                  <img src={shape3} alt="" />
                </div>
                <figure className="image image-1">
                  <img src={about1} alt="about us" />
                </figure>
                <figure className="image image-2">
                  <img src={about2} alt="about us" />
                </figure>
                <figure className="image image-3">
                  <img src={about3} alt="about us" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSec;
