import React, { useState } from "react";
import "animate.css/animate.min.css";
import "./css/process.css";
import Modal from "react-bootstrap/Modal";

import completeDoc from "../../assets/images/icons/complete-document.png";
import complianceAsso from "../../assets/images/icons/compliance-asso.png";
import eauction from "../../assets/images/icons/e-auction.png";
import vendorSelection from "../../assets/images/icons/vendor-selection.png";
import verification from "../../assets/images/icons/verification.png";
import material from "../../assets/images/icons/material.png";

const ProcessSec = () => {
  const [isActive, setIsActive] = useState(null);
  const [currentProcess, setCurrentProcess] = useState(null);

  const handleActive = (index) => {
    setIsActive(index === isActive ? null : index);
    setCurrentProcess(process[index]);
    handleShow();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const process = [
    {
      number: "1",
      color: "fontColor1",
      img: verification,
      title: "Verification & Valuation",
      desc: "Physically verify your Asset and ensuring their fair valuation.",
      moreDesc:
        "GMV have independent and expert team for Physical Verification and valuation to ensure correct information and value of material to be disposed of. It helps in preparing tender documents, pricing, identifying suitable vendors, time required for the process from start to end, required compliances etc.",
    },
    {
      number: "2",
      color: "fontColor2",
      img: completeDoc,
      title: "Complete Documentation",
      desc: "Comprehensive SLAs, Vendor Agreements, Bidding Docs for a hassle-free process.",
      moreDesc:
        "We have expert team of legal advisors who create all documents like tender documents, Service Level Agreements etc. to minimize complications. ",
    },
    {
      number: "3",
      color: "fontColor3",
      img: vendorSelection,
      title: "Vendor Selection",
      desc: "Pre-verified & specialised vendors handpicked to ensure complete reliability.",
      moreDesc:
        "GMV have pre verified listed vendors to buy , vendor for RFQ selected only with certain predefined parameters like past credentials, financial strength, work experience, legal compliance status etc. We also handle inspection of material by the vendors.",
    },
    {
      number: "4",
      color: "fontColor4",
      img: eauction,
      title: "E-Auction and Price Determination",
      desc: "A fair and straightforward auction process, securing the best prices for your assets.",
      moreDesc:
        "GMV is using latest and most secured technology like VPN/managed server/ mango DB/ Nord JS. eAuction process is real time, secured and transparent. The auction process and terms can be customised according to the need. ",
    },

    {
      number: "5",
      color: "fontColor5",
      img: complianceAsso,
      title: "Compliance Assurance",
      desc: "Ensure compliance with GST, TDS, Waste, Pollution, Labour & Other Laws ",
      moreDesc:
        "Waste/ Scrap transaction need lot of compliances like GST, TCS., TDS, Enterprise Producer Responsibility (EPR), Labour Compliances, Safety and Security rules etc. Our team is well experienced and support you in complying these laws and regulations. Few non compliances may be subject to imprisonment also.",
    },
    {
      number: "6",
      color: "fontColor6",
      img: material,
      title: "Material & Money Mgmt",
      desc: "Ensuring safe and reliable movement of materials and money",
      moreDesc:
        "We have seen critical issues between client and vendor after engagement, to minimise those issues, we do support in movement of money and material. We handle whole process of material movement/dismantling and money movement from EMD to final collection. ",
    },
  ];

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };

  return (
    <>
      <Modal
        onKeyDown={handleKeyDown}
        show={show}
        onHide={handleClose}
        className={`process-popup animated ${
          show ? "popup-visible animate__zoomIn" : "animate__zoomOut"
        }`}
      >
        <div className="upper-box clearfix">
          <h4>{currentProcess?.title}</h4>
          <div className="close-search pull-right">
            <span className="far fa-times" onClick={handleClose}></span>
          </div>
        </div>
        <Modal.Body>
          <p>{currentProcess?.moreDesc}</p>
        </Modal.Body>
      </Modal>

      <section className="sgpSec sec-pad bg-light">
        <div className="auto-container">
          <div className="titleSec pb_50 text-center">
            <h3>Comprehensive 6-Step Process</h3>
          </div>
          <div className="content-inner clearfix">
            <ul className="infoGraphic">
              {process.map((item, index) => (
                <li
                  key={index}
                  className={`${item.color} ${
                    isActive === index ? "active" : ""
                  }`}
                  onClick={() => handleActive(index)}
                >
                  <div className="numberWrap">
                    <div className={`${item.color} number`}>{item.number}</div>
                    <div className="coverWrap">
                      <div className="numberCover"></div>
                    </div>
                  </div>
                  <div className="content">
                    <div className="icon iconCodepen">
                      <img src={item.img} alt="scrap" />
                    </div>
                    <h2>{item.title}</h2>
                    <p>{item.desc}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProcessSec;
