// import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, Col, Dropdown, Modal, Row, Table,Button} from 'react-bootstrap'
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
import Sidebar from '../../components/Sidebar/SidebarBidder';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BuyerExperience = () => {
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [experiences, setExperiences] = useState([]);
    const [selectedExperience, setSelectedExperience] = useState({});
    const [updateSelectedExperience, setUpdateSelectedExperience] = useState({});
    const [updateShow, setUpdateShow] = useState(false);
    const [viewShow, setViewShow] = useState(false);
    const [addFormData, setAddFormData] = useState({});

    const handleChangeAdd = (e) => {
      setAddFormData({
        ...addFormData,
        [e.target.name]: e.target.value,
      });
    };

    const handleChangeUpdate = (e) => {
      setUpdateSelectedExperience({
        ...updateSelectedExperience,
        [e.target.name]: e.target.value,
      });
    }

    const handleAddSubmit = (slug) => {
      axios.post(`${baseServerUrl}/createworkexperience`, {
        clientName: addFormData.clientName,
        projectValue: addFormData.projectSize,
        workType: addFormData.workType,
      }, {
        headers: {
          "Authorization": `${JSON.parse(localStorage.getItem("userInfo")).token}`,
        }
      }).then((response) => {
        console.log(response);
        if(slug){
          setAddFormData({
            clientName: "",
            projectSize: "",
            workType: "",
          });
        }
        else{
          setShow(false);
          setAddFormData({
            clientName: "",
            projectSize: "",
            workType: "",
          });
        }
        getExperienceData();
        toast.success("Work Experience Added Successfully");
      }).catch((error) => {
        console.log(error);
        toast.error("Error Adding Work Experience");
      })
    }

    const handleUpdateSubmit = (id) => {
      axios.put(`${baseServerUrl}/updateworkexperience/${id}`, {
        clientName: updateSelectedExperience.clientName,
        projectValue: updateSelectedExperience.projectSize,
        workType: updateSelectedExperience.workType,
      }, {
        headers: {
          "Authorization": `${JSON.parse(localStorage.getItem("userInfo")).token}`,
        }
      }).then((response) => {
        console.log(response);
        getExperienceData();
        toast.success("Work Experience Updated Successfully");
        setUpdateShow(false);
      }).catch((error) => {
        console.log(error);
        toast.error("Error Updating Work Experience");
      })
    }

    const handleDelete = (id) => {
      axios.delete(`${baseServerUrl}/deleteworkexperience/${id}`, {
        headers: {
          "Authorization": `${JSON.parse(localStorage.getItem("userInfo")).token}`,
        }
      }).then((response) => {
        console.log(response);
        toast.success("Work Experience Deleted Successfully");
        getExperienceData();
      }).catch((error) => {
        console.log(error);
        toast.error("Error Deleting Work Experience");
      })
    }

    const getExperienceData = () => {
      axios.get(`${baseServerUrl}/getworkexperiencebyuserid`,{
      headers:{
            "Authorization":`${JSON.parse(localStorage.getItem("userInfo")).token}`,    
        }
      }).then((response) => {
        console.log(response.data.workExperiences);
        setExperiences(response.data.workExperiences);
      }).catch((error) => {
        console.log(error);
      })
    }

    


    
  useEffect(() => {
    getExperienceData();
  },[])

  return (
    <>
    <Topbar />
    {/* <Header /> */}
    <container-fluid className="mb-4">
    <ToastContainer />
      <Row>
      <Col lg="2" md="2" sm="4">       
        <Sidebar />
      </Col>
      <Col lg="10" md="10" sm="8" className="ps-2">
        <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/branches" >Work Experience</Link></div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Work Experience</Card.Title>
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Button variant="warning" className="pull-right btn-sm addnew" onClick={handleShow}>
                      <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width  px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Name of Client</th>
                      <th className="border-0">Size of Project (in Lakhs)</th>
                      <th className="border-0">Type of Work</th>
                      <th className="border-0">Action</th>
                    </tr>
                  </thead>
                  <tbody >
                    {experiences.length ? (
                      experiences.map((experience, index) => {
                        return (
                          <tr key={index}>
                            <td>{experience.clientName}</td>
                            <td>{experience.projectValue}</td>
                            <td>{experience.workType}</td>
                            <td>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" size="sm">
                                  Show
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <Link
                                        onClick={() => {
                                          setSelectedExperience(experience);
                                          setViewShow(true);
                                        }}
                                        className="link-primary viewButton"
                                      >
                                        View
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                        onClick={() => {
                                          setUpdateSelectedExperience(experience);
                                          setUpdateShow(true);
                                        }}
                                        className="link-primary editButton"
                                      >
                                        Edit
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link onClick={() => handleDelete(experience._id)} className="link-primary">
                                      Delete
                                    </Link>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="6">no record found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </container-fluid>
      {/* <div></div> */}
      <Modal show={show} centered size="lg">
        <Modal.Header
          closeButton
          onHide={handleClose}
          className=""
        >
          <Modal.Title className='text-black' as="h6">Add New Work Experience</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="bookingForm">
            <div class="card-body">
              <div class="row mt-4">
                <div class="col-md-6 pb-3">
                  {/* <p>Name of Client</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="clientName"
                      name="clientName"
                      onChange={(e) => handleChangeAdd(e)}
                      placeholder="Name of Client"
                      type="text"
                      value={addFormData.clientName}
                      required
                    />
                        <label className="form-label">
                    Name of Client
                  </label>

                  </div>
                </div>
                <div class="col-md-6">
                  {/* <p>Size of Project (Rs. in Lakhs)</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="projectSize"
                      name="projectSize"
                      placeholder="Size of Project (Rs. in Lakhs)"
                      type="number"
                      onChange={(e) => handleChangeAdd(e)}
                      value={addFormData.projectSize}
                      required
                    />
                    <label className="form-label">
                    Project (in Lakhs)
                  </label>
                  </div>
                </div>
                <div class="col-md-12 pb-3">
                  {/* <p>Type of Work</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="workType"
                      placeholder="Type of Work"
                      type="text"
                      name="workType"
                      onChange={(e) => handleChangeAdd(e)}
                      value={addFormData.workType}
                      required
                    />
                    <label className="form-label">
                    Type of Work
                  </label>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "1px", backgroundColor: "gray", margin: "20px 0", marginTop: "0" }}></div>

            <Button
            
            className="btn btn-sm"
            color="#5C79FF"
            onClick={() => handleAddSubmit()}
            style={{backgroundColor:"#E2E7FF",color:"#5C79FF"}}
          >
            Save & Exit
          </Button>
          {"   "}
          <Button
            
            className="btn btn-primary btn-sm" // Modified: Changed button color to primary
            onClick={() => handleAddSubmit(true)}
            style={{backgroundColor:"#5C79FF"}}
          >
              Save & Add More Experience
            </Button>
            

          </form>
        </Modal.Body>
      </Modal>
      <Modal show={updateShow} centered size="lg">
        <Modal.Header
          closeButton
          onHide={()=>setUpdateShow(false)}
          className=""
        >
          <Modal.Title className='text-black' as="h6">Update Work Experience</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="bookingForm">
            <div class="card-body">
              <div class="row mt-4">
                <div class="col-md-6 pb-3">
                  {/* <p>Name of Client</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="clientName"
                      placeholder="Name of Client"
                      type="text"
                      name='clientName'
                      value={updateSelectedExperience.clientName}
                      onChange={(e) => handleChangeUpdate(e)}
                      required
                    />
                        <label className="form-label">
                    Name of Client
                  </label>

                  </div>
                </div>
                <div class="col-md-6">
                  {/* <p>Size of Project (Rs. in Lakhs)</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="projectSize"
                      placeholder="Size of Project (Rs. in Lakhs)"
                      name='projectSize'
                      value={updateSelectedExperience.projectSize}
                      onChange={(e) => handleChangeUpdate(e)}
                      type="number"
                      required
                    />
                    <label className="form-label">
                    Project (in Lakhs)
                  </label>
                  </div>
                </div>
                <div class="col-md-12 pb-3">
                  {/* <p>Type of Work</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="workType"
                      placeholder="Type of Work"
                      name='workType'
                      value={updateSelectedExperience.workType}
                      onChange={(e) => handleChangeUpdate(e)}
                      type="text"
                      required
                    />
                    <label className="form-label">
                    Type of Work
                  </label>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "1px", backgroundColor: "gray", margin: "20px 0", marginTop: "0" }}></div>

            <Button
            
            className="btn btn-sm"
            color="#5C79FF"
            onClick={()=>setUpdateShow(false)}
            style={{backgroundColor:"#E2E7FF",color:"#5C79FF"}}
          >
            Close
          </Button>
          {"   "}
          <Button
            
            className="btn btn-primary btn-sm" // Modified: Changed button color to primary
            onClick={()=>handleUpdateSubmit(updateSelectedExperience._id)}
            style={{backgroundColor:"#5C79FF"}}
          >
              Update
            </Button>
            

          </form>
        </Modal.Body>
      </Modal>
      <Modal show={viewShow} centered size="md">
      <Modal.Header
        closeButton
        onHide={()=>setViewShow(false)}
        className=""
      >
      <Modal.Title className="text-black" as="h6">View Work Experience Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedExperience && (
          <>
            <h4>Compliance Details:</h4>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0"}}></div>
            <p><strong>Name of Client:</strong> {selectedExperience.clientName}</p>
            <p><strong>Size of Project (in Lakhs):</strong> {selectedExperience.projectValue}</p>
            <p><strong>Type of Work:</strong> {selectedExperience.workType}</p>
          </>
        )}
      </Modal.Body>
    </Modal>              
      
    <Footer />
    </>
  )
}

export default BuyerExperience
