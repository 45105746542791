import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import toast from "react-hot-toast";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
import axios from "axios";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Pagination,
  Dropdown,
  FormCheck,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/Sidebar";
import BaseService from "../../config/axiosService";
import { Switch } from "antd";
import { FaPlus,FaCheck } from 'react-icons/fa';

function Staff() {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [projects, setProjects] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [roles, setRoles] = useState([]);

  const [members, setMembers] = useState([]);

  const handleShow = () => setShow(true);

  const [visible, setVisible] = useState(false);

  const [projectShow, setProjectShow] = useState(false);

  const [teamMember, setTeamMember] = useState({
    username: "",
    email: "",
    phone: "",
    location: "",
    designation: "",
    department: "",
    rolesAndPermissions: "",
    password: "",
    isProjectManager: false,
  });

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    phone: "",
    location: "",
    designation: "",
    department: "",
    role: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamMember((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    let validationErrors = {};
    if (!teamMember.username) {
      validationErrors.username = "Username is required";
    }
    if (!teamMember.email) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(teamMember.email)) {
      validationErrors.email = "Invalid email address";
    }
    if (!teamMember.phone) {
      validationErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(teamMember.phone)) {
      validationErrors.phone = "Phone number must be 10 digits";
    }
    if (!teamMember.location) {
      validationErrors.location = "Location is required";
    }
    if (!teamMember.designation) {
      validationErrors.designation = "Designation is required";
    }
    if (!teamMember.department) {
      validationErrors.department = "Department is required";
    }
    if (!teamMember.rolesAndPermissions) {
      validationErrors.role = "Role is required";
    }
    if (!teamMember.password) {
      validationErrors.password = "Password is required";
    } else if (teamMember.password.length < 6) {
      validationErrors.password = "Password must be at least 6 characters long";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set them and prevent form submission
      setErrors(validationErrors);
    } else {
      BaseService.post("/api/v2/team", teamMember)
        .then((res) => {
          toast.success("Team member added successfully");
          handleClose();
          fetchTeamMembers();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteTeamMember = (id) => {
    BaseService.delete(`/api/v2/team/${id}`)
      .then((res) => {
        toast.success("Team member deleted successfully");
        fetchTeamMembers();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const fetchRoles = async () => {
    try {
      const response = await BaseService.get("/api/v2/roles");
      setRoles(response.data.data);
    } catch (error) {
      console.error("Error fetching roles: ", error);
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await BaseService.get("/api/v2/team");
      setMembers(response.data.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching team members: ", error);
    }
  };

  const fetchProjects = () => {
    BaseService.get("/api/v2/project")
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAssignProject = async (projectID) => {
    const  selectedUserId=teamMember._id;
    const selectedProjects = projects.filter(project => project.isSelected).map(project => project.id);
    const data = {
      userId: selectedUserId,
      projectIds: selectedProjects
    };
    try{
      const response = await BaseService.post("/api/v2/assign-projects", data);
      if(response.status === 200){
        
        toast.success("Projects assigned successfully");
        fetchProjects();
      }

    }
    catch(error){
      console.error("Error assigning projects: ", error);
      toast.error("Error assigning projects");

    }
  }

  const handleassign=()=>{
    toast.success("Projects assigned successfully");
  }

  useEffect(() => {
    fetchRoles();
    fetchTeamMembers();
    fetchProjects();

  }, []);

  return (
    <>
      <Topbar />
      <Container fluid className="mb-4">
        <Row>
          <Col
            lg="2"
            md="2"
            sm="4"
            style={{ background: "#2A3FA0", paddingRight: "0" }}
          >
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8">
            <div className="tophd">
              Dashboard{" "}
              <span style={{ color: "#000" }}>
                <i className="fa fa-angle-right"></i>
              </span>{" "}
              <Link to="/staff">Staff</Link>
            </div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Team Members</Card.Title>
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Button
                      variant="warning"
                      className="pull-right btn-sm addnew"
                      onClick={handleShow}
                    >
                      <i className="fa fa-plus me-2"></i>
                      Add Team Member
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped centered">
                  <thead>
                    <tr>
                      <th className="border-0">S.no</th>
                      <th className="border-0">Name</th>
                      <th className="border-0">Department</th>
                      <th className="border-0">Designation</th>
                      <th className="border-0">Location</th>
                      <th className="border-0">Contact</th>
                      <th className="border-0">Assign Project</th>
                      <th className="border-0">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map((member, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          {member.username}
                          {member.isProjectManager && (
                            <Badge bg="primary" className="">
                              Project Manager
                            </Badge>
                          )}
                        </td>
                        <td>{member.department || "- - -"}</td>
                        <td>{member.designation}</td>
                        <td>{member.location}</td>
                        <td>{member.phone}</td>
                        <td>
                             <a href="#" className="text-primary" onClick={handleShowModal}>
        Projects
      </a>
                          
        <Modal show={showModal} onHide={handleCloseModal} centered
        size="sm" >
        <Modal.Header closeButton>
          <Modal.Title>Projects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Projects</th>
              </tr>
            </thead>
            <tbody>
              {projects.length?(
                projects.map((project,i)=>{return(
                  <tr key={i}>
                    <td onClick={handleassign}>{project.projectId}</td>
                
                  </tr>
                )})
              ):
              (
                <tr>
                  <td colSpan="5">No data found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
                        </td>
                        <td onClick={()=> deleteTeamMember(member._id)}>
                          <i class="fas fa-trash"></i> 
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </Container>

      <Modal
        onHide={() => setProjectShow(false)}
        show={projectShow}
        centered
        size="lg"
      >
        {/* Table with these field select (checkbox) Project Id, Project Description , Location */}
        <Modal.Header closeButton onHide={handleClose} className="">
          <Modal.Title as="h6">Assign Project</Modal.Title>
        </Modal.Header>
        {/* Table */}
        <Modal.Body>
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0 text-center">Select</th>
                <th className="border-0">Project Id</th>
                <th className="border-0">Project Description</th>
                <th className="border-0">Location</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>ABC123</td>
                <td>Warehouse disposal</td>
                <td>Patna</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>XYZ123</td>
                <td>Shop Dismantling</td>
                <td>Delhi</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>IMI456</td>
                <td>E-Waste</td>
                <td>Nagpur</td>
              </tr>
            </tbody>
          </Table>
          <span className="pull-right">
            If project is not in this list,
            <Link href="/add-new-project"> add new project </Link>
          </span>
          <br />
          {/* Submit and Close Buttons */}
          <Button
            type="submit"
            style={{
              background: "#2A3FA0",
              border: "0",
            }}
            className=""
            onClick={handleClose}
          >
            Submit
          </Button>

          <Button
            type="submit"
            style={{
              background: "white",
              color: "#2A3FA0",
              border: "1px solid #2A3FA0",
            }}
            className=" ms-2"
            onClick={() => setProjectShow(false)}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={show} centered size="lg">
        <Modal.Header closeButton onHide={handleClose} className="">
          <Modal.Title as="h6">Add Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="name">Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter name"
                    name="username"
                    onChange={handleChange}
                  />
                  {errors.username && (
                    <p className="text-danger">{errors.username}</p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="email">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <p className="text-danger">{errors.email}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="phone">Phone Number:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Enter phone number"
                    name="phone"
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <p className="text-danger">{errors.phone}</p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="location">Location:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    placeholder="Enter location"
                    name="location"
                    onChange={handleChange}
                  />
                  {errors.location && (
                    <p className="text-danger">{errors.location}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="designation">Designation:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="designation"
                    placeholder="Enter designation"
                    name="designation"
                    onChange={handleChange}
                  />
                  {errors.designation && (
                    <p className="text-danger">{errors.designation}</p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="department">Department:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="department"
                    placeholder="Enter department"
                    name="department"
                    onChange={handleChange}
                  />
                  {errors.department && (
                    <p className="text-danger">{errors.department}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="role">Role:</label>
                  <select
                    className="form-control"
                    id="rolesAndPermissions"
                    name="rolesAndPermissions"
                    placeholder="Select Role"
                    onChange={handleChange}
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option value={role._id}>{role.name}</option>
                    ))}
                  </select>
                  {errors.role && <p className="text-danger">{errors.role}</p>}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="reporting">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Password"
                    placeholder="Enter Password"
                    name="password"
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <p className="text-danger">{errors.password}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="border rounded-3 p-3 d-flex align-items-center gap-3">
                <p>
                  {teamMember.isProjectManager
                    ? "Project Manager"
                    : "Team Member"}
                </p>
                <Switch
                  onChange={(checked) => {
                    setTeamMember((prev) => ({
                      ...prev,
                      isProjectManager: checked,
                    }));
                  }}
                />
              </Col>
            </Row>
            <button
              type="submit"
              className="btn text-white me-2 mt-4"
              style={{ background: "#2A3FA0" }}
            >
              Save
            </button>
            <button onClick={handleClose} className="btn border mt-4">
              Close
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Staff;