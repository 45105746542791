import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import signin from "../../assets/images/signin.png";
import axios from "axios";
import Header from "../../components/header";
import Footer from "../../components/footer";
const Login = () => {
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [passwordShow, setPasswordShow] = useState(false);

  // const [userType, setUserType] = useState('');
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      await axios
        .post(`${baseServerUrl}/userlogin`, {
          email: formData.email,
          password: formData.password,
        })
        .then((resp) => {
          //   userDispatch(
          //     login({
          //       name: resp.data.data.username,
          //       email: resp.data.data.email,
          //       token: resp.data.data.token,
          //       loggedIn: true,
          //     }),
          //   )
          // resp.data.status
          //   ? toast.success(resp.data.message, {
          //     position: toast.POSITION.TOP_RIGHT,
          //   })
          //   : toast.error(resp.data.message, {
          //       position: toast.POSITION.TOP_RIGHT,
          //     })
          setFormData(resp.data.data);
          localStorage.setItem("userInfo", JSON.stringify(resp.data.data));
          localStorage.setItem("approval", JSON.stringify({ approval: false }));
          if (resp.data.data.role.role === "Seller") {
            navigate("/seller-profile-view");
            window.location.reload();
          } else if (resp.data.data.role.role === "Buyer") {
            navigate("/dashboard");
            window.location.reload();
          }

          // navigate("/dashboard");
          // window.location.reload();
          // userDispatch({ type: 'LOGIN_SUCCESS' })
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  };
  function validateForm(formData) {
    const errors = {};
    if (!formData?.email?.trim()) {
      errors.email = "email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    // Validate password
    if (!formData?.password?.trim()) {
      errors.password = "Password is required";
    } else if (formData.password.trim().length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }
    return errors;
  }
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  return (
    <>
      <Header />
      <ToastContainer></ToastContainer>

      <section className="contact-section pricing-section pt_70 pb_70">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 form-column">
              <div className="form-inner shadow">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                    <img src={signin} alt="signin" />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                    <div className="tabs-box shadow p-3 rounded-3">
                      <div className="tab-btn-box mb_30 lableBlue rounded-4 px-3 py-2 text-center">
                        <h4> Login Form</h4>
                        <span className="text-danger">
                          please fill all the mandatory fields (*)
                        </span>
                      </div>
                      <form
                        className="default-form"
                        autoComplete="off"
                        onSubmit={handleLogin}
                      >
                        <div className="row clearfix">
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <label htmlFor="email">Email*:</label>
                            <input
                              type="email"
                              placeholder=""
                              id="email"
                              name="email"
                              onChange={(e) =>
                                handleChange("email", e.target.value)
                              }
                              required
                            />
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                            <div className="d-flex justify-content-between">
                              <label htmlFor="bidderPassword">Password*:</label>
                            </div>
                            <input
                              type={
                                passwordShow ? "text" : "password"
                              }
                              id="password"
                              name="password"
                              onChange={(e) =>
                                handleChange("password", e.target.value)
                              }
                              placeholder=""
                              autoComplete="off"
                              required
                            />
                            <span className="position-absolute top-50" style={{
                              right:"25px"
                            }}>
                              <i className={
                                passwordShow ? "fa fa-eye" : "fa fa-eye-slash"
                              } onClick={() => setPasswordShow(!passwordShow)}></i>
                            </span>
                            {errors.password && (
                              <span className="text-danger">
                                {errors.password}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <button
                              className="theme-btn btn-two shadow"
                              type="submit"
                            >
                              <span> Login</span>
                            </button>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 text-right">
                            <Link to="/forgot">
                              <small>Forgot password?</small>
                            </Link>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 form-group ">
                            <div className="border py-2 mt-3 text-center rounded-2 ">
                              <span>
                                New to GMV?{" "}
                                <Link to="/signup">Create an account.</Link>
                              </span>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Login;
