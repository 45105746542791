// import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, Modal, Row, Table,Button } from "react-bootstrap";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import { Link } from "react-router-dom";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Compliance = () => {
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [complianceData, setComplianceData] = useState([]);
  const [selectedCompliance, setSelectedCompliance] = useState({}); // Added: State to store selected compliance data [View Modal
  const [updateSelectedCompliance, setUpdateSelectedCompliance] = useState({}); // Added: State to store selected compliance data [Update Modal
  const [show, setShow] = useState(false);
  const [addFormData, setAddFormData] = useState({});
  const [updateShow, setUpdateShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeAdd = (e) => {
    if (e.target.name === "quantity" || e.target.name === "unitType") {
      setAddFormData({
        ...addFormData,
        licensedCapacity: {
          ...addFormData.licensedCapacity,
          [e.target.name]: e.target.value.trim(),
        },
      });
    } else {
      setAddFormData({
        ...addFormData,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const handleChangeUpdate = (e) => {
    if (e.target.name === "quantity" || e.target.name === "unitType") {
      setUpdateSelectedCompliance({
        ...updateSelectedCompliance,
        licensedCapacity: {
          ...updateSelectedCompliance.licensedCapacity,
          [e.target.name]: e.target.value.trim(),
        },
      });
    } else {
      setUpdateSelectedCompliance({
        ...updateSelectedCompliance,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const handleAddSubmit = (slug) => {
    console.log(addFormData);
    axios.post(`${baseServerUrl}/createcompliance`, addFormData, {
      headers: {
        "Authorization": `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response) => {
      console.log(response);
    getComplianceData();
    if(slug){
      toast.success("Compliance Added Successfully");
      setAddFormData({
        eWasteType: "",
        cpcbSpcbRegistration: "",
        location: "",
        licensedCapacity: {
          quantity: "",
          unitType: "",
        },
        licenseRegistrationNumber: "",
        licenseDetails: "",
      });
    }
    else{
      setShow(false);
      setAddFormData({});
      toast.success("Compliance Added Successfully");
    }

    }).catch((error) => {
      console.log(error);
      toast.error("Failed to add Compliance");
    })
  }
  const handleUpdateSubmit = (id) => {
    console.log(updateSelectedCompliance);
    axios.put(`${baseServerUrl}/updatecompliance/${id}`, updateSelectedCompliance, {
      headers: {
        "Authorization": `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response) => {
      console.log(response);
      getComplianceData();
      setUpdateShow(false);
      toast.success("Compliance Updated Successfully");
    }).catch((error) => {
      console.log(error);
      toast.error("Failed to update Compliance");
    })
  }

  function getComplianceData(){
    axios.get(`${baseServerUrl}/getcompliancebyuserid`,{
      headers:{
        "Authorization":`${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response)=>{
      setComplianceData(response.data.compliances);
    
    }).catch((error)=>{
      console.log(error);
      toast.error("Failed to fetch Compliance Data");
    })
  }
  const handleDelete = (id) => {
    axios.delete(`${baseServerUrl}/deletecompliance/${id}`, {
      headers: {
        "Authorization": `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response) => {
      console.log(response);
      toast.success("Compliance Deleted Successfully");
      getComplianceData();
    }).catch((error) => {
      console.log(error);
      toast.error("Failed to delete Compliance");
    })
  }
  useEffect(()=>{
    getComplianceData();
  },[])

  return (
    <>
    <Topbar />
    <container-fluid className="mb-4">
      <ToastContainer />
      <Row>
        <Col lg="2" md="2" sm="4">
          <Sidebar />
        </Col>
        <Col lg="10" md="10" sm="8" className="ps-2">
          <div className="tophd">
            Dashboard{" "}
            <span style={{ color: "#000" }}>
              <i className="fa fa-angle-right"></i>
            </span>{" "}
            <Link to="/buyer-bank">Compliance</Link>
          </div>
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <Card.Title as="h6">Manage Compliance</Card.Title>
                </Col>
                <Col lg="6" md="6" sm="6">
                  <Button
                    variant="warning"
                    className="pull-right btn-sm addnew"
                    onClick={handleShow}
                  >
                    <i className="fa fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="">
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th className="border-0">Ewaste-type</th>
                    <th className="border-0">CPCB/SPCB Registration</th>
                    <th className="border-0">Location</th>
                    <th className="border-0">Licensed Capacity</th>
                    <th className="border-0">License Registration Number</th>
                    <th className="border-0">Details of the License</th>
                    <th className="border-0">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {complianceData.length ? (
                    complianceData.map((compliance, index) => {
                      return (
                        <tr key={compliance._id}>
                          <td>{compliance.eWasteType}</td>
                          <td>{compliance.cpcbSpcbRegistration}</td>
                          <td>{compliance.location}</td>
                          <td>{`${compliance.licensedCapacity?.quantity} ${compliance.licensedCapacity?.unitType}`}</td>
                          <td>{compliance.licenseRegistrationNumber}</td>
                          <td>{compliance.licenseDetails}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                size="sm"
                              >
                                Show
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <Link
                                    onClick={() => {
                                      setSelectedCompliance(compliance); // Added: Set selected compliance data
                                      setViewShow(true);
                                    }}
                                    className="link-primary viewButton"
                                  >
                                    View
                                  </Link>
                                </Dropdown.Item>
                                  <Dropdown.Item>
                                  <Link
                                    onClick={() => {
                                      setUpdateSelectedCompliance(compliance); // Added: Set selected compliance data
                                      setUpdateShow(true);
                                    }}
                                    className="link-primary editButton"
                                  >
                                    Edit
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <Link
                                    onClick={() => handleDelete(compliance._id)}
                                    className="link-primary"
                                  >
                                    Delete
                                  </Link>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colspan="6">no record found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
          <Footer />
    </container-fluid>
    <Modal show={show} centered size="lg">
      <Modal.Header
        closeButton
        onHide={handleClose}
        className=""
      >
        <Modal.Title className="text-black" as="h6">Compliance Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="bookingForm">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-6 pb-3">
                {/* <p>E-Waste-Type</p> */}
                <div className="form-control">
                  <select
                    class="form-input"
                    id="email"
                    name="eWasteType"
                    placeholder="E-Waste-Type"
                    type="text"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.eWasteType}
                    required
                  >
                    <option>E-Waste-Type</option>
                    <option value="Plastic">Plastic Waste</option>
                    <option value="Battery">Battery Waste</option>
                    <option value="Hazardous">Hazardous Waste</option>
                    <option value="Vehicle">Vehicle Waste</option>
                    <option value="Construction">Construction Waste</option>
                    <option value="Other">Other</option>
                  </select>
                  <label className="form-label">
                    E-Waste-Type
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                {/* <p>CPCB/SPCB Registration*</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="CPCB/SPCB Registration"
                    type="text"
                    name="cpcbSpcbRegistration"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.cpcbSpcbRegistration}
                    required
                  />
                             <label className="form-label">
                  CPCB/SPCB Registration
                  </label>
                </div>
              </div>
              <div class="col-md-6 pb-3">
                {/* <p>Location</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Location"
                    type="text"
                    name="location"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.location}
                    required
                  />
                                    <label className="form-label">
                    Location
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <p style={{position:"absolute",zIndex:'99',top:"31%",right:"26%",fontSize:"0.9rem",color:"black",background:"white",padding:"0 10px"}}>Licensed Capacity</p>
                <div className="d-flex bg-white border" style={{height:"53.5px",borderRadius:"0.25rem",padding:"0 5px"}}>
                <div className="form-control" style={{border:"none",outline:"none",width:"69%"}}>
                  <input
                    class="form-input2"
                    id="email"
                    placeholder="Quantity"
                    type="number" // Modified: Changed input type to "number"
                    name="quantity"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.licensedCapacity?.quantity}
                    required
                  />
                </div>
                <div className="form-control" style={{border:"none",outline:"none",borderLeft:"1px solid lightgray",borderRadius:"0",width:"31%"}}>
                  <select // Added: Select element for unit type
                    class="form-input2"
                    id="unitType"
                    name="unitType"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.licensedCapacity?.unitType}
                    required
                  >
                    <option value=""> Type</option>
                    <option value="ton">Ton</option>
                    <option value="kg">Kg</option>
                    <option value="ltr">Ltr</option>
                    <option value="kltr">Kltr</option>
                    <option value="acre">Acre</option>
                  </select>
                </div>
                </div>
              </div>
              <div class="col-md-6 pb-3">
              {/* <p>Licence Registration Number*</p> */}

                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Licence Registration Number"
                    type="text"
                    name="licenseRegistrationNumber"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.licenseRegistrationNumber}
                    required
                  />
                                    <label className="form-label">
                    Licence Registration Number
                  </label>
   
                </div>
              </div>
             
              <div class="col-md-6 pb-3">
                {/* <p>Details of the Licence*</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Details of the Licence"
                    type="text"
                    name="licenseDetails"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.licenseDetails}
                    required
                  />
                                    <label className="form-label">
                    Details of the Licence  
                  </label>

                </div>
              </div>
            </div>
          </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0",marginTop:"0"}}></div>

          <Button
           
            className="btn btn-sm"
            color="#5C79FF"
            onClick={()=>handleAddSubmit()}
            style={{backgroundColor:"#E2E7FF",color:"#5C79FF"}}
          >
            Save & Exit
          </Button>
          {"   "}
          <Button
           
            className="btn btn-primary btn-sm" // Modified: Changed button color to primary
            onClick={()=>handleAddSubmit(true)}
            style={{backgroundColor:"#5C79FF"}}
          >
            Save & Add More Compliance
          </Button>

        </form>
      </Modal.Body>
    </Modal>
    <Modal show={updateShow} centered size="lg">
      <Modal.Header
        closeButton
        onHide={()=>setUpdateShow(false)}
        className=""
      >
        <Modal.Title className="text-black" as="h6">Update Licence Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="bookingForm">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-6 pb-3">
                {/* <p>E-Waste-Type</p> */}
                <div className="form-control">
                  <select
                    class="form-input"
                    id="email"
                    placeholder="E-Waste-Type"
                    type="text"
                    name="eWasteType"
                    value={updateSelectedCompliance.eWasteType}
                    onChange={(e)=>handleChangeUpdate(e)}
                    required
                  >
                    <option>E-Waste-Type</option>
                    <option value="Plastic">Plastic Waste</option>
                    <option value="Battery">Battery Waste</option>
                    <option value="Hazardous">Hazardous Waste</option>
                    <option value="Vehicle">Vehicle Waste</option>
                    <option value="Construction">Construction Waste</option>
                  </select>
                  <label className="form-label">
                    E-Waste-Type
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                {/* <p>CPCB/SPCB Registration*</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="CPCB/SPCB Registration"
                    type="text"
                    value={updateSelectedCompliance.cpcbSpcbRegistration}
                    name="cpcbSpcbRegistration"
                    onChange={(e)=>handleChangeUpdate(e)}
                    required
                  />
                             <label className="form-label">
                  CPCB/SPCB Registration
                  </label>
                </div>
              </div>
              <div class="col-md-6 pb-3">
                {/* <p>Location</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Location"
                    type="text"
                    name="location"
                    onChange={(e)=>handleChangeUpdate(e)}
                    value={updateSelectedCompliance.location}
                    required
                  />
                                    <label className="form-label">
                    Location
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <p style={{position:"absolute",zIndex:'99',top:"31%",right:"26%",fontSize:"0.9rem",color:"black",background:"white",padding:"0 10px"}}>Licensed Capacity</p>
                <div className="d-flex bg-white border" style={{height:"53.5px",borderRadius:"0.25rem",padding:"0 5px"}}>
                <div className="form-control" style={{border:"none",outline:"none",width:"69%"}}>
                  <input
                    class="form-input2"
                    id="email"
                    placeholder="Quantity"
                    type="number" // Modified: Changed input type to "number"
                    name="quantity"
                    onChange={(e)=>handleChangeUpdate(e)}
                    value={updateSelectedCompliance.licensedCapacity?.quantity}
                    required
                  />
                </div>
                <div className="form-control" style={{border:"none",outline:"none",borderLeft:"1px solid lightgray",borderRadius:"0",width:"31%"}}>
                  <select // Added: Select element for unit type
                    class="form-input2"
                    id="unitType"
                    value={updateSelectedCompliance.licensedCapacity?.unitType}
                    name="unitType"
                    onChange={(e)=>handleChangeUpdate(e)}
                    required
                  >
                    <option value=""> Type</option>
                    <option value="ton">Ton</option>
                    <option value="kg">Kg</option>
                    <option value="ltr">Ltr</option>
                    <option value="kltr">Kltr</option>
                    <option value="acre">Acre</option>
                  </select>
                </div>
                </div>
              </div>
              <div class="col-md-6 pb-3">
              {/* <p>Licence Registration Number*</p> */}

                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Licence Registration Number"
                    type="text"
                    value={updateSelectedCompliance.licenseRegistrationNumber}
                    name="licenseRegistrationNumber"
                    onChange={(e)=>handleChangeUpdate(e)}
                    required
                  />
                                    <label className="form-label">
                    Licence Registration Number
                  </label>
   
                </div>
              </div>
             
              <div class="col-md-6 pb-3">
                {/* <p>Details of the Licence*</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Details of the Licence"
                    type="text"
                    name="licenseDetails"
                    value={updateSelectedCompliance.licenseDetails}
                    onChange={(e)=>handleChangeUpdate(e)}
                    required
                  />
                                    <label className="form-label">
                    Details of the Licence  
                  </label>

                </div>
              </div>
            </div>
          </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0",marginTop:"0"}}></div>

          <Button
            
            className="btn btn-sm"
            color="#5C79FF"
            onClick={()=>setUpdateShow(false)}
            style={{backgroundColor:"#E2E7FF",color:"#5C79FF"}}
          >
            Close
          </Button>
          {"   "}
          <Button
            
            className="btn btn-primary btn-sm" // Modified: Changed button color to primary
            onClick={()=>handleUpdateSubmit(updateSelectedCompliance._id)}
            style={{backgroundColor:"#5C79FF"}}
          >
            Update
          </Button>

        </form>
      </Modal.Body>
    </Modal>

    <Modal show={viewShow} centered size="md">
      <Modal.Header
        closeButton
        onHide={()=>setViewShow(false)}
        className=""
      >
        <Modal.Title className="text-black" as="h6">View Compliance Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedCompliance && (
          <>
            <h4>Compliance Details:</h4>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0"}}></div>
            <p><strong>E-Waste-Type:</strong> {selectedCompliance.eWasteType}</p>
            <p><strong>CPCB/SPCB Registration:</strong> {selectedCompliance.cpcbSpcbRegistration}</p>
            <p><strong>Location:</strong> {selectedCompliance.location}</p>
            <p><strong>Licensed Capacity:</strong> {selectedCompliance.licensedCapacity?.quantity} {selectedCompliance.licensedCapacity?.unitType}</p>
            <p><strong>Licence Registration Number:</strong> {selectedCompliance.licenseRegistrationNumber}</p>
            <p><strong>Details of the Licence:</strong> {selectedCompliance.licenseDetails}</p>
          </>
        )}
      </Modal.Body>
    </Modal>
  </>
  );
};

export default Compliance;
