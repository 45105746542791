import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import './css/upcoming-auction.css';
import { Dropdown, Table } from 'react-bootstrap';
import axios from "axios";
import Moment from 'react-moment';

const UpcomingAuction = () => {
    const [perPage] = useState(5);
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [postData, setpostData] = useState([]);
    const [filterValues, setfilterValues] = useState({
        search: "",
      });
      const handleChanageFilter = (name, value) => {
        setfilterValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      };
    const handlePage = (key) => {
        setSkip("");
        setSkip(key - 1);
        console.log(key);
      };
      const handlePageNext = () => {
        if (pageCount > skip) {
          setSkip(skip + 1);
        }
      };
      const handlePagePrevious = () => {
        if (skip >= 1) {
          setSkip(skip - 1);
        }
      };

      const fetchPost = async () => {
        await axios
          .post(`${baseServerUrl}/upcomingeauction`, {
            page: perPage,
            skip: skip,
            search: filterValues.search,
          })
          .then((resp) => {
            console.log(resp.data.data)
            setpostData(resp.data.data);
            setPageCount(Math.ceil(resp.data.count / perPage));
          })
          .catch((error) => {
            console.log(error);
          });
      };
    useEffect(() => {
        fetchPost();
      }, [skip, filterValues]);
      
    return (
        <>
        <Header />
        <div className='upcAuction'>

            <section className="page-title p_relative centred">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Upcoming Auction</li>
                        </ul>
                    </div>
                </div>
            </section>
            <div className='upcAuctionPage'>
                <div className="auto-container">
                    <div className="titleSec mb_50 text-center">
                        <h2>Upcoming Auction</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 sidebar-side">
                            <div className="blog-sidebar default-sidebar">
                                <div className="sidebar-widget search-widget rounded-0">
                                    <div className="search-form">
                                        <form method="post">
                                            <div className="form-group">
                                            <input
                                                class="form-control"
                                                id="search"
                                                placeholder="search ..."
                                                type="text"
                                                onChange={(e) =>
                                                    handleChanageFilter("search", e.target.value)
                                                }
                                            />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className='filterSec'>
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-6 col-sm-12 mb-2 mb-md-0">
                                        <div className='innerBox d-flex'>
                                            <Dropdown className='me-4'>
                                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                    Location
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='p-0'>
                                                    <Dropdown.Item href="#action-1">Jaipur</Dropdown.Item>
                                                    <Dropdown.Item href="#action-2">Kota</Dropdown.Item>
                                                    <Dropdown.Item href="#action-3">Pune</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Dropdown>
                                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                    Price
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='p-0'>
                                                    <Dropdown.Item href="#action-1">0 to 50 lacs</Dropdown.Item>
                                                    <Dropdown.Item href="#action-2">50 lacs to 1 crore</Dropdown.Item>
                                                    <Dropdown.Item href="#action-3">1 crore to 10 crore</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 text-start text-md-end">
                                        <button className="theme-button me-3 m-0"><span>Apply Fiter</span></button>
                                        <button className="theme-button m-0"><span>Clear</span></button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className='table-responsive'>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Company Name</th>
                                            <th>Project</th>
                                            <th>Seller</th>
                                            <th>Start Date & Time</th>
                                            <th>End Date & Time</th>
                                            <th>Reserve Price</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {postData.length ? (
                                        postData.map((item, index) => {
                                            return (
                                        <tr>
                                            <td>
                                                <p className='text-muted mb-0'>{item.sellerID.companyName}</p>
                                            </td>
                                            <td>
                                                <p className='text-muted mb-0'>{item.projectId.name}</p>
                                            </td>
                                            <td>
                                                <p className='text-muted mb-0'>{item.sellerID.username}</p>
                                            </td>
                                            <td>
                                                <p className='text-muted mb-0'><Moment format="DD/MM/YYYY">{item.startDate}</Moment></p>
                                            </td>
                                            <td>
                                                <p className='text-muted mb-0'><Moment format="DD/MM/YYYY">{item.endDate}</Moment></p>
                                            </td>
                                            <td>
                                                <p className='text-muted mb-0'>₹ {item.exactValue}</p>
                                            </td>
                                            <td>
                                                <button color='link d-grid' rounded size='sm'>
                                                    <i className="fa fa-download lableBlue p-2 rounded mb-1" aria-hidden="true"></i>
                                                    <i className="fa fa-eye lableGreen p-2 rounded mb-1" aria-hidden="true"></i>
                                                    <i className="fa fa-thumbs-up lableLightBlue p-2 rounded" aria-hidden="true"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        );
                                    })
                                  ) : (
                                    <tr>
                                      <td colspan="11">no record found</td>
                                    </tr>
                                  )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Footer />
    </>
    );
}
export default UpcomingAuction;