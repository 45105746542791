import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Card, Form, Container, Row, Col, Button } from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
import toast from "react-hot-toast";

function UpdateStatus() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const navigate = useNavigate();
  const documentInputRef = useRef(null);
  const mediaInputRef = useRef(null);

  const handleDocumentUpload = () => {
    documentInputRef.current.click();
  };

  const handleMediaUpload = () => {
    mediaInputRef.current.click();
  };

  const handleFileChange = (event, type) => {
    const files = event.target.files;
    if (files.length > 0) {
      const newFiles = Array.from(files).map(file => ({
        name: file.name,
        type: type
      }));
      setUploadedFiles(prevFiles => [...prevFiles, ...newFiles]);
      console.log(`${type} selected:`, files);
      // You can add your file upload logic here
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle form submission, e.g., sending data to a server

    // Show a success toast

    // Redirect to the /my-projects page after a short delay
    setTimeout(() => {
      toast.success("Status updated successfully!");
      navigate("/my-projects");
    }, 2000); // 2 seconds delay to allow the user to see the toast
  };
  return (
    <>
      <Topbar />
      <Container fluid className="mb-4">
        <Row>
          <Col lg="2" md="2" sm="4" style={{ background: "#2A3FA0" }}>
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8" className="p-2 pe-4">
            <div className="tophd">
              My Projects{" "}
              <span style={{ color: "#000" }}>
                <i className="fa fa-angle-right"></i>
              </span>{" "}
              <Link to="/buyer-UpdateStatus">Update Status</Link>
            </div>
            <Card className="mt-3">
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <label>Description:</label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Type Here"
                    />
                  </Form.Group>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <label>Date:</label>
                        <Form.Control type="date" defaultValue="28/01/24" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <label>Status:</label>
                        <Form.Select defaultValue="In-Progress">
                          <option>In-Progress</option>
                          <option>Pending</option>
                          <option>Completed</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <label>Add Media:</label>
                    <div className="border p-2 mt-2 rounded-3">
                      {uploadedFiles.map((file, index) => (
                        <p key={index} className="mb-1">
                          <i
                            className={`fa ${
                              file.type === "Document"
                                ? "fa-file-pdf-o text-danger"
                                : "fa-file-image-o text-primary"
                            }`}
                          ></i>{" "}
                          {file.name}
                        </p>
                      ))}
                      <div>
                        <Button
                          variant="link"
                          className="p-0 me-3"
                          onClick={handleDocumentUpload}
                        >
                          Upload Document
                        </Button>
                        <Button
                          variant="link"
                          className="p-0"
                          onClick={handleMediaUpload}
                        >
                          Upload Images and Videos
                        </Button>
                        <input
                          type="file"
                          ref={documentInputRef}
                          style={{ display: "none" }}
                          onChange={(e) => handleFileChange(e, "Document")}
                          accept=".pdf,.doc,.docx,.txt"
                        />
                        <input
                          type="file"
                          ref={mediaInputRef}
                          style={{ display: "none" }}
                          onChange={(e) => handleFileChange(e, "Media")}
                          accept="image/*,video/*"
                          multiple
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Button
                    style={{
                      background: "#2A3FA0",
                      border: "none",
                    }}
                    variant="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </Container>
    </>
  );
}

export default UpdateStatus;
