import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./contact.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
const Contact = () => {
  const [formData, setformData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [token, setToken] = useState("");
  const [loading, setloading] = useState(false);
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState("");
  const handlechange = (name, value) => {
    setformData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    if (!token) {
      toast.error("Please verify captcha");
      return;
    }
    const validationError = validateForm(formData);
    console.log("Form data:", formData);
    console.log("Validation errors:", validationError);
    if (Object.keys(validationError).length > 0) {
      setErrors(validationError);
    } else {
      setloading(true);

      axios
        .post(`${baseServerUrl}/createenquiries`, formData)
        .then((resp) => {
          console.log("Server response:", resp);
          if (resp.data.status) {
            setStatus({ type: "success" });
            setformData({
              name: "",
              phone: "",
              email: "",
              message: "",
            });
          } else {
            setStatus({ type: "error" });
          }
        })
        .catch((error) => {
          console.log("Request error:", error);
          setStatus({ type: "error" });
        })
        .finally(() => {
          setloading(false);
        });
    }
  };

  function validateForm(formData) {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else {
      var pattern = new RegExp(/^[a-zA-Z\s]*$/);
      if (!pattern.test(formData.name) || formData.name.length < 3) {
        errors.name = "Name is not valid";
      }
    }
    if (!formData.phone.trim()) {
      errors.phone = "Mobile no is required";
    } else {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(formData.phone) || formData.phone.length != 10) {
        errors.phone = "Mobile no is not valid";
      }
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else {
      var pattern = new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/);
      if (!pattern.test(formData.email)) {
        errors.email = "Email is not valid";
      }
    }
    if (!formData.message.trim()) {
      errors.message = "Message is required";
    } else {
      var pattern = new RegExp(/^[a-zA-Z0-9\s]*$/);
      if (!pattern.test(formData.message) || formData.message.length < 10) {
        errors.message = "Message is not valid";
      }
    }
    return errors;
  }
  return (
    <>
      <Header />
      <div>
        <section className="contact-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12 content-column">
                <div className="content-box mr_70">
                  <div className="titleSec mb_45">
                    {/* <span className="sub-title lableOrange">Find Our Office</span> */}
                    <h3>Contact with the team</h3>
                  </div>
                  <ul className="info-list clearfix">
                    <li>
                      <div className="icon">
                        <i className="icon-43"></i>
                      </div>
                      <h4>Location</h4>
                      <p>
                        <strong>Work:</strong> I - 35B, First Floor, Lajpat
                        Nagar – 2, New Delhi – 110 024 <br />
                        <strong>Registered Office</strong> – G 1504, Great Value
                        Sharanam, Sector 107, Gautam Budh Nagar, Noida, Uttar
                        Pradesh
                      </p>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="icon-4"></i>
                      </div>
                      <h4>Phone no</h4>
                      <p>
                        <Link to="tel:+91-8588840800 ">+91-8588840800 </Link>
                      </p>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="icon-2"></i>
                      </div>
                      <h4>Email</h4>
                      <p>
                        <Link to="mailto:support@getmaxvalue.in">
                          support@getmaxvalue.in
                        </Link>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 form-column">
                <div className="form-inner">
                  {status?.type === "success" && (
                    <div className="alert alert-success">
                      <strong>Success!</strong> Thank you for your contribution.{" "}
                    </div>
                  )}
                  {status?.type === "error" && (
                    <div className="alert alert-danger">
                      <strong>Faild!</strong> Somthng went to wrong please try
                      again !!{" "}
                    </div>
                  )}
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    id="contact-form"
                    className="default-form"
                  >
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name of Person"
                          onChange={(e) => handlechange("name", e.target.value)}
                          required
                        />
                        {errors.name && (
                          <span className="text-danger">{errors.name}</span>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input
                          type="number"
                          name="phone"
                          id="phone"
                          required
                          placeholder="Mobile Number"
                          onChange={(e) =>
                            handlechange("phone", e.target.value)
                          }
                        />
                        {errors.phone && (
                          <span className="text-danger">{errors.phone}</span>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email ID"
                          required
                          onChange={(e) =>
                            handlechange("email", e.target.value)
                          }
                        />
                        {errors.email && (
                          <span className="text-danger">{errors.email}</span>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Message"
                          required
                          onChange={(e) =>
                            handlechange("message", e.target.value)
                          }
                        ></textarea>
                        {errors.message && (
                          <span className="text-danger">{errors.message}</span>
                        )}
                      </div>
                      <ReCAPTCHA
                        sitekey="6LdCZPkpAAAAAG_ueHCtp0rCT8QraEDBPdvlUVhR"
                        onChange={(token) => {
                          console.log(token);
                          setToken(token);
                        }}
                      />

                      <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn mt-3">
                        <button
                          disabled={loading}
                          className="theme-btn btn-one shadow"
                          type="submit"
                          name="submit-form"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          {loading ? (
                            <span>Loading...</span>
                          ) : (
                            <span>Send Message</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="google-map-section">
          <div className="map-inner">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3503.9871534683775!2d77.24024577549855!3d28.570148475698907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM0JzEyLjUiTiA3N8KwMTQnMzQuMiJF!5e0!3m2!1sen!2sin!4v1718627342021!5m2!1sen!2sin"
              height="450"
              style={{
                border: "0",
                width: "100%",
              }}
              allowfullscreen=""
              loading="lazy"
              title="address"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default Contact;
