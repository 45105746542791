import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import toast from "react-hot-toast";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import BaseService from "../../config/axiosService";
import { Switch } from "antd";

function Staff() {
  const [projects, setProjects] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [roles, setRoles] = useState([]);

  const [members, setMembers] = useState([]);

  const handleShow = () => setShow(true);

  const [teamProjects, setTeamProjects] = useState([]);

  const [projectShow, setProjectShow] = useState(false);

  const [teamMember, setTeamMember] = useState({
    username: "",
    email: "",
    phone: "",
    location: "",
    designation: "",
    department: "",
    rolesAndPermissions: "",
    password: "",
    isProjectManager: false,
  });

  const [selectedProjects, setSelectedProjects] = useState([]);
  const [currentTeamMemberId, setCurrentTeamMemberId] = useState(null);

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    phone: "",
    location: "",
    designation: "",
    department: "",
    role: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamMember((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    let validationErrors = {};
    if (!teamMember.username) {
      validationErrors.username = "Username is required";
    }
    if (!teamMember.email) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(teamMember.email)) {
      validationErrors.email = "Invalid email address";
    }
    if (!teamMember.phone) {
      validationErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(teamMember.phone)) {
      validationErrors.phone = "Phone number must be 10 digits";
    }
    if (!teamMember.location) {
      validationErrors.location = "Location is required";
    }
    if (!teamMember.designation) {
      validationErrors.designation = "Designation is required";
    }
    if (!teamMember.department) {
      validationErrors.department = "Department is required";
    }
    if (!teamMember.rolesAndPermissions) {
      validationErrors.role = "Role is required";
    }
    if (!teamMember.password) {
      validationErrors.password = "Password is required";
    } else if (teamMember.password.length < 6) {
      validationErrors.password = "Password must be at least 6 characters long";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set them and prevent form submission
      setErrors(validationErrors);
    } else {
      BaseService.post("/api/v2/team", teamMember)
        .then((res) => {
          toast.success("Team member added successfully");
          handleClose();
          fetchTeamMembers();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteTeamMember = (id) => {
    BaseService.delete(`/api/v2/team/${id}`)
      .then((res) => {
        toast.success("Team member deleted successfully");
        fetchTeamMembers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRoles = async () => {
    try {
      const response = await BaseService.get("/api/v2/roles");
      setRoles(response.data.data);
    } catch (error) {
      console.error("Error fetching roles: ", error);
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await BaseService.get("/api/v2/team");
      setMembers(response.data.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching team members: ", error);
    }
  };

  const fetchProjects = () => {
    BaseService.get("/api/v2/project")
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAssignProject = async (projectID) => {
    const selectedUserId = teamMember._id;
    const selectedProjects = projects
      .filter((project) => project.isSelected)
      .map((project) => project.id);
    const data = {
      userId: selectedUserId,
      projectIds: selectedProjects,
    };
    try {
      const response = await BaseService.post("/api/v2/assign-projects", data);
      if (response.status === 200) {
        toast.success("Projects assigned successfully");
        fetchProjects();
      }
    } catch (error) {
      console.error("Error assigning projects: ", error);
      toast.error("Error assigning projects");
    }
  };

  const handleassign = () => {
    toast.success("Projects assigned successfully");
  };

  const fetchAllTeamProjects = async (id) => {
    try {
      const response = await BaseService.get(
        `/api/v2/project/team-members/${id}`
      );
      setTeamProjects(response.data.projectsWithoutTeamMember);
    } catch (error) {
      console.error("Error fetching team projects: ", error);
    }
  };

  const handleProjectAssign = (id) => {
    fetchAllTeamProjects(id);
    setCurrentTeamMemberId(id);
    setProjectShow(true);
  };

  const handleProjectSelection = (projectId) => {
    setSelectedProjects((prevSelected) => {
      if (prevSelected.includes(projectId)) {
        return prevSelected.filter((id) => id !== projectId);
      } else {
        return [...prevSelected, projectId];
      }
    });
  };

  const handleAssignProjects = async () => {
    if (!currentTeamMemberId || selectedProjects.length === 0) {
      toast.error("Please select projects to assign");
      return;
    }

    try {
      const response = await BaseService.post(
        `/api/v2/project/team-members/assign-project/${currentTeamMemberId}`,
        {
          projectIds: selectedProjects,
        }
      );

      if (response.status === 200) {
        toast.success("Projects assigned successfully");
        setProjectShow(false);
        fetchTeamMembers(); // Refresh the team members list
        setSelectedProjects([]); // Clear selected projects
      }
    } catch (error) {
      console.error("Error assigning projects: ", error);
      toast.error("Error assigning projects");
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchTeamMembers();
    fetchProjects();
  }, []);

  return (
    <>
      <Topbar />
      <Container fluid className="mb-4">
        <Row>
          <Col
            lg="2"
            md="2"
            sm="4"
            style={{ background: "#2A3FA0", paddingRight: "0" }}
          >
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8">
            <div className="tophd">
              Dashboard{" "}
              <span style={{ color: "#000" }}>
                <i className="fa fa-angle-right"></i>
              </span>{" "}
              <Link to="/staff">Staff</Link>
            </div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Team Members</Card.Title>
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Button
                      variant="warning"
                      className="pull-right btn-sm addnew"
                      onClick={handleShow}
                    >
                      <i className="fa fa-plus me-2"></i>
                      Add Team Member
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped centered">
                  <thead>
                    <tr>
                      <th className="border-0">S.no</th>
                      <th className="border-0">Name</th>
                      <th className="border-0">Department</th>
                      <th className="border-0">Designation</th>
                      <th className="border-0">Location</th>
                      <th className="border-0">Contact</th>
                      <th className="border-0">Assign Project</th>
                      <th className="border-0">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map((member, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          {member.username}
                          {member.isProjectManager && (
                            <Badge bg="primary" className="">
                              Project Manager
                            </Badge>
                          )}
                        </td>
                        <td>{member.department || "- - -"}</td>
                        <td>{member.designation}</td>
                        <td>{member.location}</td>
                        <td>{member.phone}</td>
                        <td>
                          <a
                            role="button"
                            className="text-primary"
                            onClick={() => handleProjectAssign(member._id)}
                          >
                            Projects
                          </a>
                        </td>
                        <td onClick={() => deleteTeamMember(member._id)}>
                          <i class="fas fa-trash"></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </Container>

      <Modal
        onHide={() => setProjectShow(false)}
        show={projectShow}
        centered
        size="lg"
      >
        <Modal.Header
          closeButton
          onHide={() => setProjectShow(false)}
          className=""
        >
          <Modal.Title as="h6">Assign Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">Select</th>
                <th className="border-0">Project Id</th>
                <th className="border-0">Project Description</th>
                <th className="border-0">Location</th>
              </tr>
            </thead>
            <tbody>
              {
                teamProjects.length === 0 && (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No projects available
                    </td>
                  </tr>
                )
              }
              {teamProjects.map((project, index) => (
                <tr key={project._id}>
                  <td>
                    <input
                      type="checkbox"
                      name="project"
                      value={project._id}
                      checked={selectedProjects.includes(project._id)}
                      onChange={() => handleProjectSelection(project._id)}
                    />
                  </td>
                  <td>{project.projectId}</td>
                  <td>{project.description}</td>
                  <td>{project.location?.locationName}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <br />
          <Button
            type="submit"
            style={{
              background: "#2A3FA0",
              border: "0",
            }}
            className=""
            onClick={handleAssignProjects}
          >
            Assign Projects
          </Button>
          <Button
            type="submit"
            style={{
              background: "white",
              color: "#2A3FA0",
              border: "1px solid #2A3FA0",
            }}
            className=" ms-2"
            onClick={() => setProjectShow(false)}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={show} centered size="lg">
        <Modal.Header closeButton onHide={handleClose} className="">
          <Modal.Title as="h6">Add Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="name">Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter name"
                    name="username"
                    onChange={handleChange}
                  />
                  {errors.username && (
                    <p className="text-danger">{errors.username}</p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="email">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <p className="text-danger">{errors.email}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="phone">Phone Number:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Enter phone number"
                    name="phone"
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <p className="text-danger">{errors.phone}</p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="location">Location:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    placeholder="Enter location"
                    name="location"
                    onChange={handleChange}
                  />
                  {errors.location && (
                    <p className="text-danger">{errors.location}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="designation">Designation:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="designation"
                    placeholder="Enter designation"
                    name="designation"
                    onChange={handleChange}
                  />
                  {errors.designation && (
                    <p className="text-danger">{errors.designation}</p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="department">Department:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="department"
                    placeholder="Enter department"
                    name="department"
                    onChange={handleChange}
                  />
                  {errors.department && (
                    <p className="text-danger">{errors.department}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="role">Role:</label>
                  <select
                    className="form-control"
                    id="rolesAndPermissions"
                    name="rolesAndPermissions"
                    placeholder="Select Role"
                    onChange={handleChange}
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option value={role._id}>{role.name}</option>
                    ))}
                  </select>
                  {errors.role && <p className="text-danger">{errors.role}</p>}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="reporting">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Password"
                    placeholder="Enter Password"
                    name="password"
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <p className="text-danger">{errors.password}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="border rounded-3 p-3 d-flex align-items-center gap-3">
                <p>
                  {teamMember.isProjectManager
                    ? "Project Manager"
                    : "Team Member"}
                </p>
                <Switch
                  onChange={(checked) => {
                    setTeamMember((prev) => ({
                      ...prev,
                      isProjectManager: checked,
                    }));
                  }}
                />
              </Col>
            </Row>
            <button
              type="submit"
              className="btn text-white me-2 mt-4"
              style={{ background: "#2A3FA0" }}
            >
              Save
            </button>
            <button onClick={handleClose} className="btn border mt-4">
              Close
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Staff;
