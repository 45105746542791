import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import './scrap-details.css';
import 'swiper/swiper.min.css';
import banner1 from '../../assets/images/banner/banner-1.webp';
import banner2 from '../../assets/images/banner/banner-2.webp';
import banner3 from '../../assets/images/banner/banner-3.webp';
import { Swiper, SwiperSlide } from 'swiper/react';


import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import axios from "axios";
import ReactShowMoreText from "react-show-more-text";

SwiperCore.use([Autoplay, Navigation, Pagination]);

const ScrapDetails = () => {
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const {slug} = useParams();
    const parse = require('html-react-parser');
    const [postData, setpostData] = useState({
        username: '',
        company: '',
        email: '',
        phone: '',
        category: '',
        description: '',
        image: '',
        name: '',
        price: '',
        delivery: '',
        condition: '',
        policies: '',
        specificTerms: '',
        specifications: '',
    });
    useEffect(() => {
        axios
      .get(`${baseServerUrl}/getprojectdetails/${slug}`)
      .then((resp) => {
        setpostData({
            ...postData,
                username: resp.data.data.sellerID.username,
                company: resp.data.data.sellerID.companyName,
                email: resp.data.data.sellerID.email,
                phone: resp.data.data.sellerID.phone,
                category: resp.data.data.categoryID.name,
                description: resp.data.data.description,
                image: resp.data.data.image,
                name: resp.data.data.name,
                price: resp.data.data.expectedValue,
                delivery: resp.data.data.delivery_days,
                condition: resp.data.data.condition,
                policies: resp.data.data.policies,
                specificTerms: resp.data.data.specificTerms,
                specifications: resp.data.data.specifications,
      });
      })
      .catch((error) => {
        console.log(error)
      })
    }, [])
    const list = [
        {
            backgroundImage: banner1
        },
        {
            backgroundImage: banner2
        },
        {
            backgroundImage: banner3
        },
    ];
    const swiperParams = {
        autoplay: {
            delay: 4000,
        },
        loop: true,
        navigation: true,
        pagination: {
            clickable: true,
            type: 'bullets', 
        },
        slidesPerView: 1,
        spaceBetween: 0,
        effect: 'fade', 
        fadeEffect: {
            crossFade: true,
        }
    };
    return (
        <div className="boxed_wrapper scrapDetails">

            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Deal Details</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="scrapDetailsSec">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <section className="scrapDetails-block1 p_relative centred ">
                                <div className="scrap-carousel">
                                    <Swiper {...swiperParams}>
                                        {list.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div className='slide-item p_relative'>
                                                    <div className="bg-layer rounded-3" style={{ backgroundImage: `url(${item.backgroundImage})` }}></div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </section>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="scrapDetails-block2">
                                <div className="lower-content">
                                    <p className="category text-green">{postData.category}</p>

                                    <div className="d-flex justify-content-between pt-2 pb-1">
                                        <h4>{postData.name}</h4>
                                    </div>
                                    <div className="pt-2">
                                        <p className="fw-bold">Expected Value: {postData.price}</p>
                                        <span>Additional tax may apply</span>
                                    </div>
                                </div>
                                <hr />
                                <div className="lower-content2">
                                    <h5> <i className="fa fa-truck" aria-hidden="true"></i> Delivery done in {postData.delivery} days from date of purchase</h5>
                                    <span>Order now to get this product delivery</span>
                                </div>
                                <hr />
                                <div className="lower-content3">
                                    <h5>Details About The Seller</h5>
                                    <div className="row pt-2">
                                        <div className="col-md-6">
                                            <ul>
                                                <li><b>Company : {postData.company !== '' ?  postData.company : ''}</b> </li>
                                                <li><b>Name:</b> {postData.username}</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <ul>
                                                <li><b>Email:</b> {postData.email}</li>
                                                <li><b>Phone:</b> {postData.phone}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="scrapDetails-tabs">
                                <Tabs defaultActiveKey="tab1" id="tab-example">
                                    <Tab eventKey="tab1" title="Product Description">
                                        {parse(postData.description)}
                                    </Tab>
                                    <Tab eventKey="tab2" title="Condition">
                                        {parse(postData.condition)}
                                    </Tab>
                                    <Tab eventKey="tab3" title="Specific Terms">
                                        {parse(postData.specificTerms)}
                                    </Tab>
                                    <Tab eventKey="tab4" title="Additional Specifications">
                                        {parse(postData.specifications)}
                                    </Tab>
                                    <Tab eventKey="tab5" title="Seller Policies">
                                        {parse(postData.policies)}
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default ScrapDetails;
