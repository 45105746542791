import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Pagination,
  Dropdown,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Topbar from '../../components/Sidebar/Topbar';
import Footer from '../../components/Sidebar/Copyright';
function Staff() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [show, setShow] = useState(false);  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [viewShow, setViewShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [addressBookData,setAddressBookData] = useState([]);
  const[addFormData,setAddFormData] = useState({});
  const [selectUpdateData,setSelectUpdateData] = useState({});
  const [selectedAddressBook,setSelectedAddressBook] = useState({});

  const getaddressBookData =  () => {
    axios.get(`${baseServerUrl}/getaddressbookbyuserid`,{
      headers:{
        'Authorization': `${userInfo.token}`
      }
    }).then((response) => {
      setAddressBookData(response.data.addressBooks);
      console.log(response.data);


    }).catch((error) => {
      console.log(error);
    })
  }

  const handleAddChange = (e) => {
    const fieldName = e.target.getAttribute('name');
    if(fieldName === 'name' || fieldName === 'phone' || fieldName === 'email'){
      setAddFormData({
        ...addFormData,
        authorisedPerson:{
          ...addFormData.authorisedPerson,
          [fieldName]: e.target.value
        }
      })
      return;
    }
    const fieldValue = e.target.value;
    setAddFormData({
      ...addFormData,
      [fieldName]: fieldValue
    })
  }


  const handleAddSubmit = (slug) => {
    axios.post(`${baseServerUrl}/createaddressbook`,addFormData,{
      headers:{
        'Authorization': `${userInfo.token}`
      }
    }).then((response) => {
      if(!slug){
        handleClose();
      }
      setAddFormData({
        GSTNumber: '',
        locationName: '',
        pincode: '',
        state: '',
        district: '',
        addressLine1: '',
        addressLine2: '',
        authorisedPerson: {
          name: '',
          phone: '',
          email: ''
        }
      });
      console.log(response.data);
      toast.success('Address Book Added Successfully');
      getaddressBookData();
    }).catch((error) => {
      console.log(error);
      toast.error('Address Book Not Added');
    })

  }

  const handleDelete = (id) => {
    axios.delete(`${baseServerUrl}/deleteaddressbook/${id}`,{
      headers:{
        'Authorization': `${userInfo.token}`
      }
    }).then((response) => {
      console.log(response.data);
      toast.success('Address Book Deleted Successfully');
      getaddressBookData();
    }).catch((error) => {
      console.log(error);
      toast.error('Address Book Not Deleted');
    })
  }

  const handleUpdateChange = (e) => {
    const fieldName = e.target.getAttribute('name');
    if(fieldName === 'name' || fieldName === 'phone' || fieldName === 'email'){
      setSelectUpdateData({
        ...selectUpdateData,
        authorisedPerson:{
          ...selectUpdateData.authorisedPerson,
          [fieldName]: e.target.value
        }
      })
      return;
    }
    const fieldValue = e.target.value;
    setSelectUpdateData({
      ...selectUpdateData,
      [fieldName]: fieldValue
    })
  }

  const handleUpdateSubmit = () => {
    axios.put(`${baseServerUrl}/updateaddressbook/${selectUpdateData._id}`,selectUpdateData,{
      headers:{
        'Authorization': `${userInfo.token}`
      }
    }).then((response) => {
      console.log(response.data);
      toast.success('Address Book Updated Successfully');
      getaddressBookData();
      setUpdateShow(false);
    }).catch((error) => {
      console.log(error);
      toast.error('Address Book Not Updated');
    })
  }


  useEffect(() => {
    getaddressBookData();
   
  }, []);
  return (
    <>
    <Topbar />
    <ToastContainer></ToastContainer>
    {/* <Header /> */}
    <container-fluid className="mb-4">
      <Row>
      <Col lg="2" md="2" sm="4">       
        <Sidebar />
      </Col>
      <Col lg="10" md="10" sm="8" className="ps-2">
        <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/branches" >Address Book</Link></div>
        <Row className="mb-3 mx-0" style={{'height': '80px'}}>
              <Col lg="6" md="6" sm="6" className="mt-3">
                <input
                  class="form-control"
                  id="search"
                  style={{'line-height': '30px'}}
                  placeholder="search ..."
                  type="text"
                />
              </Col>
{"     "}
              <Col lg="6" md="6" sm="6" className="mt-3">
                <input
                    class="form-control"
                    id="pincode"
                    style={{'line-height': '30px'}}
                    placeholder="search by pin code"
                    type="text"
                  />
              </Col>
            </Row>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Address</Card.Title>
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Button variant="warning" className="pull-right btn-sm addnew" onClick={handleShow}>
                      <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">GST Number</th>
                      <th className="border-0">Address</th>
                      <th className="border-0">Authorised Person Name</th>
                      <th className="border-0">Authorised Person Phone</th>
                      <th className="border-0">Authorised Person Email</th>
                      <th className="border-0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addressBookData.length ? (
                      addressBookData.map((addressBook, index) => {
                        return (
                          <tr key={index}>
                            <td>{addressBook.GSTNumber}</td>
                            <td>{`${addressBook.locationName}, ${addressBook.addressLine1}, ${addressBook.addressLine2}, ${addressBook.pincode}, ${addressBook.district}, ${addressBook.state}`}</td>
                            <td>{addressBook.authorisedPerson?.name}</td>
                            <td>{addressBook.authorisedPerson?.phone}</td>
                            <td>{addressBook.authorisedPerson?.email}</td>
                            <td>
                            <Dropdown>
                                <Dropdown.Toggle  id="dropdown-basic" size="sm">
                                  Show
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                <Dropdown.Item>
                                    <Link
                                        onClick={() => {
                                          setSelectedAddressBook(addressBook);
                                          setViewShow(true);
                                        }}
                                        className="link-primary viewButton"
                                      >
                                        View
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                        onClick={() => {
                                          setSelectUpdateData(addressBook);
                                          setUpdateShow(true);
                                        }}
                                        className="link-primary editButton"
                                      >
                                        Edit
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link onClick={() => {
                                      handleDelete(addressBook._id);
                                    }} className="link-primary">
                                      Delete
                                    </Link>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="6">no record found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </container-fluid>
      <Modal show={show} centered size="xl">
        <Modal.Header
          closeButton
          onHide={handleClose}
          className="text-white"
        >
          <Modal.Title className="text-black" as="h6">Add New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className="bookingForm">
          <div class="card-body">
            <div class="row mt-4">
              <div className="row">
                   <div class="col-md-6">
                {/* <p>GST Number</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="gstNumber"
                    placeholder="GST Number"
                    type="text"
                    name="GSTNumber"
                    onChange={handleAddChange}
                    value={addFormData.GSTNumber}
                    required
                  />
                      <label className="form-label">
                        GST Number
                  </label>
                </div>
              </div>
              </div>
              <div className="row">
              <div class="col-md-6">
                {/* <p>Location Name</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="locationName"
                    placeholder="Location Name"
                    name="locationName"
                    onChange={handleAddChange}
                    value={addFormData.locationName}
                    type="text"
                    required
                  />
                  <label className="form-label">
                    Location Name
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                {/* <p>Pincode</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="pincode"
                    name="pincode"
                    onChange={handleAddChange}
                    value={addFormData.pincode}
                    placeholder="Pincode"
                    type="text"
                    required
                  />
                      <label className="form-label">
                        Pincode
                  </label>
                </div>
              </div>
         
              <div class="col-md-6">
                {/* <p>State</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="state"
                    placeholder="State"
                    name="state"
                    onChange={handleAddChange}
                    value={addFormData.state}
                    type="text"
                    required
                  />
                      <label className="form-label">
                        State
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                {/* <p>District</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="district"
                    placeholder="District"
                    name="district"
                    value={addFormData.district}
                    onChange={handleAddChange}
                    type="text"
                    required
                  />
                      <label className="form-label">
                        District
                  </label>
                </div>
              </div>
              <div class="col-md-12">
                {/* <p>Address Line 1</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="addressLine1"
                    placeholder="Address Line 1"
                    name="addressLine1"
                    onChange={handleAddChange}
                    value={addFormData.addressLine1}
                    type="text"
                    required
                  />
                      <label className="form-label">
                        Address Line 1
                  </label>
                </div>
              </div>
              <div class="col-md-12">
                {/* <p>Address Line 2</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="addressLine2"
                    name="addressLine2"
                    onChange={handleAddChange}
                    placeholder="Address Line 2"
                    value={addFormData.addressLine2}
                    type="text"
                    required
                  />
                      <label className="form-label">
                        Address Line 2
                  </label>
                </div>
              </div>
          <h5>Authorised Person</h5>

              <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0",marginTop:"0"}}></div>

              <div class="col-md-4">
                <div className="form-control">
                  <input
                    class="form-input"
                    id="authorisedPerson"
                    placeholder="Authorised Person"
                    name="name"
                    onChange={handleAddChange}
                    value={addFormData.authorisedPerson?.name}
                    type="text"
                    required
                  />
                      <label className="form-label">
                      Name
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                {/* <p>Phone</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="phone"
                    placeholder="Phone"
                    name="phone"
                    onChange={handleAddChange}
                    value={addFormData.authorisedPerson?.phone}
                    type="text"
                    required
                  />
                      <label className="form-label">
                        Phone
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                {/* <p>Email</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Email"
                    type="email"
                    name="email"
                    onChange={handleAddChange}
                    value={addFormData.authorisedPerson?.email}
                    required
                  />
                      <label className="form-label">
                        Email
                  </label>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0"}}></div>

          <Button
            className="btn btn-sm"
            color="#5C79FF"
            onClick={()=>handleAddSubmit()}
            style={{backgroundColor:"#E2E7FF",color:"#5C79FF"}}
          >
            Save & Exit
          </Button>
          {"   "}
          <Button
            className="btn btn-primary btn-sm" // Modified: Changed button color to primary
            onClick={()=>handleAddSubmit(true)}
            style={{backgroundColor:"#5C79FF"}}
          >
            Save & Add More Address
          </Button>
        </form>
        </Modal.Body>
      </Modal>
      <Modal show={updateShow} centered size="xl">
        <Modal.Header
          closeButton
          onHide={()=>setUpdateShow(false)}
          className="text-white"
        >
          <Modal.Title className="text-black" as="h6">Update New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className="bookingForm">
          <div class="card-body">
            <div class="row mt-4">
              <div className="row">
                   <div class="col-md-6">
                {/* <p>GST Number</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="gstNumber"
                    placeholder="GST Number"
                    type="text"
                    name="GSTNumber"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.GSTNumber}
                    required
                  />
                      <label className="form-label">
                        GST Number
                  </label>
                </div>
              </div>
              </div>
              <div className="row">
              <div class="col-md-6">
                {/* <p>Location Name</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="locationName"
                    placeholder="Location Name"
                    name="locationName"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.locationName}
                    type="text"
                    required
                  />
                  <label className="form-label">
                    Location Name
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                {/* <p>Pincode</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="pincode"
                    name="pincode"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.pincode}
                    placeholder="Pincode"
                    type="text"
                    required
                  />
                      <label className="form-label">
                        Pincode
                  </label>
                </div>
              </div>
         
              <div class="col-md-6">
                {/* <p>State</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="state"
                    placeholder="State"
                    name="state"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.state}
                    type="text"
                    required
                  />
                      <label className="form-label">
                        State
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                {/* <p>District</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="district"
                    placeholder="District"
                    name="district"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.district}
                    type="text"
                    required
                  />
                      <label className="form-label">
                        District
                  </label>
                </div>
              </div>
              <div class="col-md-12">
                {/* <p>Address Line 1</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="addressLine1"
                    placeholder="Address Line 1"
                    name="addressLine1"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.addressLine1}
                    type="text"
                    required
                  />
                      <label className="form-label">
                        Address Line 1
                  </label>
                </div>
              </div>
              <div class="col-md-12">
                {/* <p>Address Line 2</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="addressLine2"
                    name="addressLine2"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.addressLine2}
                    placeholder="Address Line 2"
                    type="text"
                    required
                  />
                      <label className="form-label">
                        Address Line 2
                  </label>
                </div>
              </div>
          <h5>Authorised Person</h5>

              <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0",marginTop:"0"}}></div>

              <div class="col-md-4">
                <div className="form-control">
                  <input
                    class="form-input"
                    id="authorisedPerson"
                    placeholder="Authorised Person"
                    name="name"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.authorisedPerson?.name}
                    type="text"
                    required
                  />
                      <label className="form-label">
                      Name
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                {/* <p>Phone</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="phone"
                    placeholder="Phone"
                    name="phone"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.authorisedPerson?.phone}
                    type="text"
                    required
                  />
                      <label className="form-label">
                        Phone
                  </label>
                </div>
              </div>
              <div class="col-md-4">
                {/* <p>Email</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Email"
                    type="email"
                    name="email"
                    onChange={handleUpdateChange}
                    value={selectUpdateData.authorisedPerson?.email}
                    required
                  />
                      <label className="form-label">
                        Email
                  </label>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0"}}></div>

          <Button
            className="btn btn-sm"
            color="#5C79FF"
            onClick={()=>setUpdateShow(false)}
            style={{backgroundColor:"#E2E7FF",color:"#5C79FF"}}
          >
            Close
          </Button>
          {"   "}
          <Button
            className="btn btn-primary btn-sm" // Modified: Changed button color to primary
            onClick={handleUpdateSubmit}
            style={{backgroundColor:"#5C79FF"}}
          >
            Update
          </Button>
        </form>
        </Modal.Body>
      </Modal>

      <Modal show={viewShow} centered size="md">
      <Modal.Header
        closeButton
        onHide={()=>setViewShow(false)}
        className=""
      >
        <Modal.Title className="text-black" as="h6">View Address Book Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedAddressBook && (
          <>
            <h4>Address Book Details:</h4>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0"}}></div>
            <p><strong>GST Number:</strong> {selectedAddressBook.GSTNumber}</p>
            <p><strong>Location Name:</strong> {selectedAddressBook.locationName}</p>
            <p><strong>Pincode:</strong> {selectedAddressBook.pincode}</p>
            <p><strong>State:</strong> {selectedAddressBook.state}</p>
            <p><strong>District:</strong> {selectedAddressBook.district}</p>
            <p><strong>Address Line 1:</strong> {selectedAddressBook.addressLine1}</p>

            <p><strong>Address Line 2:</strong> {selectedAddressBook.addressLine2}</p>
            <h5>Authorised Person</h5>
            <div style={{height:"1px",backgroundColor:"gray",margin:"10px 0"}}></div>
            <p><strong>Name:</strong> {selectedAddressBook.authorisedPerson?.name}</p>
            <p><strong>Phone:</strong> {selectedAddressBook.authorisedPerson?.phone}</p>
            <p><strong>Email:</strong> {selectedAddressBook.authorisedPerson?.email}</p>

          </>
        )}
      </Modal.Body>
    </Modal>     
    <Footer />
    </>
  );
}

export default Staff;
