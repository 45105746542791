import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import userIcon from "../../assets/images/user.png";
import bellIcon from "../../assets/images/bells.png";
import { Dropdown, DropdownButton } from "react-bootstrap";

const Header = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const nav = useNavigate();
  const [sticky, changeStickyData] = useState("main-header");
  window.onscroll = () => {
    let temp = "";
    let top = window.scrollY;
    if (top > 100) {
      temp = "main-header fixed-header";
    } else {
      temp = "main-header";
    }
    return changeStickyData(temp);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  //sidebar
  const [isSidebarMenuOpen, setisSidebarMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setisSidebarMenuOpen(!isSidebarMenuOpen);
  };
  const closeMenu = () => {
    setisSidebarMenuOpen(false);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = "/login";
  };
  return (
    <div className={`${isSidebarMenuOpen ? "mobile-menu-visible" : ""}`}>
      <header className={sticky}>
        <div
          className="header-top py-2"
          style={{ borderBottom: "2px solid lightgray" }}
        >
          <div className="top-inner justify-content-between">
            <div className="top-left text-start">
              <a href="/">
                <img
                  src={logo}
                  className="bg-white rounded-3 px-1"
                  width="50%"
                />
              </a>
            </div>
            <div className="top-right">
              {!userInfo ? (
                <div className="login-box">
                  <Link to="/login">
                    <i class="fas fa-user-plus"></i> Login
                  </Link>
                </div>
              ) : (
                <>
                  <div className="user-box pe-4">
                    <DropdownButton
                      id="dropdown-basic-button"
                      className="userBtn"
                      title={<img src={userIcon} />}
                    >
                      {userInfo.role.role === "Buyer" ? (
                        <>
                          <Dropdown.Item>
                            <Link to="/buyer-profile">
                              <i class="fas fa-user-circle"></i> Profile
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link to="/buyer-changepassword">
                              <i class="fas fa-key"></i> Change password
                            </Link>
                          </Dropdown.Item>
                        </>
                      ) : (
                        <></>
                      )}
                      {userInfo.role.role === "Seller" ? (
                        <>
                          <Dropdown.Item>
                            <Link to="/profile">
                              <i class="fas fa-user-circle"></i> Profile
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link to="/changepassword">
                              <i class="fas fa-key"></i> Change password
                            </Link>
                          </Dropdown.Item>
                        </>
                      ) : (
                        <></>
                      )}
                      <Dropdown.Item onClick={(e) => logout(e)}>
                        <i class="fas fa-sign-out-alt"></i> Log Out
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
