import React, { useState, useContext } from "react";
import { Formik } from "formik";
import { Button } from "antd";
import { Input } from "formik-antd";
import MultiStepFormContext from "./MultiStepFormContext";
import {toast,ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Badge,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";
import axios from "axios";
// Define a component named "Details"
const Experience = () => {
  const [formValues, setFormValues] = useState([{ name: "", email : ""}])
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  }

let addFormFields = () => {
    setFormValues([...formValues, { clients: "", projects: "", works: "" }])
  }

let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
}
  const { experience, setExperience, next, prev } = useContext(MultiStepFormContext);

  const [addFormData, setAddFormData] = useState({})
  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setAddFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  const handleSubmit = () => {
    axios.post(`${baseServerUrl}/createworkexperience`, {
      clientName: addFormData.clientName,
      projectValue: addFormData.projectSize,
      workType: addFormData.workType,
    }, {
      headers: {
        "Authorization": `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response) => {
      console.log(response); 
      toast.success("Work Experience Added Successfully");
      next();
    }).catch((error) => {
      console.log(error);
      toast.error("Error Adding Work Experience");
    })
  }


  return (
    <form className="bookingForm">
    <div class="card-body">
    <ToastContainer />
      <div class="row mt-4">
        <div class="col-md-6 pb-3">
          {/* <p>Name of Client</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="clientName"
              name="clientName"
              onChange={(e) => handleChangeAdd(e)}
              placeholder="Name of Client"
              type="text"
              value={addFormData.clientName}
              required
            />
                <label className="form-label">
            Name of Client
          </label>

          </div>
        </div>
        <div class="col-md-6">
          {/* <p>Size of Project (Rs. in Lakhs)</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="projectSize"
              name="projectSize"
              placeholder="Size of Project (Rs. in Lakhs)"
              type="number"
              onChange={(e) => handleChangeAdd(e)}
              value={addFormData.projectSize}
              required
            />
            <label className="form-label">
            Project (in Lakhs)
          </label>
          </div>
        </div>
        <div class="col-md-12 pb-3">
          {/* <p>Type of Work</p> */}
          <div className="form-control">
            <input
              class="form-input"
              id="workType"
              placeholder="Type of Work"
              type="text"
              name="workType"
              onChange={(e) => handleChangeAdd(e)}
              value={addFormData.workType}
              required
            />
            <label className="form-label">
            Type of Work
          </label>
          </div>
        </div>
      </div>
    </div>
    <div style={{ height: "1px", backgroundColor: "gray", margin: "20px 0", marginTop: "0" }}></div>

    <div
              className={
                "form__item button__items d-flex justify-content-between mt-4"
              }
            >
              {/* Render a "Back" button that moves to the previous step in the form */}
              <Button type={"default"} onClick={prev} size="large" className="border-primary text-primary">
                Back
              </Button>
              {/* Render a "Next" button that submits the form data and moves to the next step in the form */}
              <div className="d-flex gap-2">
              <Button type={"primary"} onClick={handleSubmit} size="large">
                  Submit & Next
              </Button>
              <Button type={"secondary"} className="border border-primary text-primary" onClick={()=>next()} size="large">
                Skip
              </Button>
              </div>
            </div>
    

  </form>
  );
};

export default Experience;
