// import { Button } from 'antd'
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
  Table,
  Button,
} from "react-bootstrap";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
import Sidebar from "../../components/Sidebar/Sidebar.js";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SellerBank = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bankData, setBankData] = useState([]);
  const [addBank, setAddBank] = useState({});
  const [updateSelectBank, setUpdateSelectBank] = useState({});
  const [selectedBank, setSelectedBank] = useState({});
  const [updateShow, setUpdateShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);

  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const getBankDetails = () => {
    axios
      .get(`${baseServerUrl}/getbankbyuserid `, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("userInfo")).token,
        },
      })
      .then((response) => {
        setBankData(response.data.banks);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateChange = (e) => {
    setUpdateSelectBank({
      ...updateSelectBank,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateSubmit = () => {
    console.log(updateSelectBank);
    axios
      .put(
        `${baseServerUrl}/updatebank/${updateSelectBank._id}`,
        updateSelectBank,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("userInfo")).token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        getBankDetails();
        setUpdateShow(false);
        toast.success("Bank updated successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error updating bank");
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseServerUrl}/deletebank/${id}`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("userInfo")).token,
        },
      })
      .then((response) => {
        console.log(response.data);
        getBankDetails();
        toast.success("Bank deleted successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error deleting bank");
      });
  };

  const handleAddChange = (e) => {
    setAddBank({ ...addBank, [e.target.name]: e.target.value });
  };

  const handleAddSubmit = (slug) => {
    axios
      .post(`${baseServerUrl}/createbank`, addBank, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("userInfo")).token,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (!slug) {
          handleClose();
        }
        getBankDetails();
        setAddBank({
          ifscCode: "",
          bankName: "",
          accountHolderName: "",
          accountNumber: "",
          accountType: "",
          accountStatus: "",
        });
        toast.success("Bank added successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error adding bank");
      });
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  return (
    <>
      <Topbar />
      <container-fluid className="mb-4">
        <ToastContainer />
        <Row>
          <Col
            lg="2"
            md="2"
            sm="4"
            style={{
              backgroundColor: "#2A3FA0",
              paddingRight: "0",
            }}
          >
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8" className="ps-2">
            <div className="tophd">
              Dashboard{" "}
              <span style={{ color: "#000" }}>
                <i className="fa fa-angle-right"></i>
              </span>{" "}
              <Link to="/buyer-bank">Bank</Link>
            </div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Bank</Card.Title>
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Button
                      variant="warning"
                      className="pull-right btn-sm addnew"
                      onClick={handleShow}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">IFSC Code</th>
                      <th className="border-0">Bank Name</th>
                      <th className="border-0">Account Holder's Name</th>
                      <th className="border-0">Account Number</th>
                      <th className="border-0">Type of Account</th>
                      <th className="border-0">Account Status</th>
                      <th className="border-0"></th>
                      <th className="border-0"></th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bankData.length ? (
                      bankData.map((bank, index) => {
                        return (
                          <tr key={index}>
                            <td>{bank.ifscCode}</td>
                            <td>{bank.bankName}</td>
                            <td>{bank.accountHolderName}</td>
                            <td>{bank.accountNumber}</td>
                            <td>{bank.accountType}</td>
                            <td>{bank.accountStatus}</td>
                            <td>
                            <i className="fa fa-eye"  onClick={() => {
                                        setViewShow(true);
                                        setSelectedBank(bank);
                                      }}></i>
                          </td>
                          <td>
                            <i className="fa fa-pencil"
                              onClick={() => {
                                setUpdateSelectBank(bank);
                                setUpdateShow(true);
                              }}></i>
                          </td>
                          <td>
                            <i className="fa fa-trash"   onClick={() => {
                                        handleDelete(bank._id);
                                      }}></i>
                          </td>
                            {/* <td>
                              <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" size="sm">
                                  Show
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <Link
                                      onClick={() => {
                                        setViewShow(true);
                                        setSelectedBank(bank);
                                      }}
                                      className="link-primary viewButton"
                                    >
                                      View
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      onClick={() => {
                                        setUpdateSelectBank(bank);
                                        setUpdateShow(true);
                                      }}
                                      className="link-primary editButton"
                                    >
                                      Edit
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      onClick={() => {
                                        handleDelete(bank._id);
                                      }}
                                      className="link-primary"
                                    >
                                      Delete
                                    </Link>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="6">No record found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </container-fluid>
      <Modal show={show} centered size="lg">
        <Modal.Header closeButton onHide={handleClose} className="text-white">
          <Modal.Title className="text-black" as="h6">
            Add New Bank
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="bookingForm">
            <div class="card-body">
              <div class="row mt-4">
                <div class="col-md-6 pb-3">
                  {/* <p>IFSC Code</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="ifscCode"
                      placeholder="IFSC Code"
                      type="text"
                      name="ifscCode"
                      onChange={handleAddChange}
                      value={addBank.ifscCode}
                      required
                    />
                    <label className="form-label">IFSC Code</label>
                  </div>
                </div>
                <div class="col-md-6">
                  {/* <p>Bank Name</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="bankName"
                      placeholder="Bank Name"
                      type="text"
                      name="bankName"
                      onChange={handleAddChange}
                      value={addBank.bankName}
                      required
                    />
                    <label className="form-label">Bank Name</label>
                  </div>
                </div>
                <div class="col-md-6 pb-3">
                  {/* <p>Account Holder Name</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="accountHolderName"
                      placeholder="Account Holder's Name"
                      type="text"
                      name="accountHolderName"
                      onChange={handleAddChange}
                      value={addBank.accountHolderName}
                      required
                    />
                    <label className="form-label">Account Holder's Name</label>
                  </div>
                </div>
                <div class="col-md-6">
                  {/* <p>Account Number</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="accountNumber"
                      placeholder="Account Number"
                      type="text"
                      name="accountNumber"
                      onChange={handleAddChange}
                      value={addBank.accountNumber}
                      required
                    />
                    <label className="form-label">Account Number</label>
                  </div>
                </div>
                <div class="col-md-6 pb-3">
                  <p style={{ fontSize: "20px", color: "black" }}>
                    Type of Account
                  </p>
                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "gray",
                      margin: "10px 0",
                      marginTop: "0",
                    }}
                  ></div>
                  <div className="d-flex gap-2">
                    <div className="d-flex gap-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="savingsAccount"
                        value="Savings"
                        name="accountType"
                        onChange={handleAddChange}
                        // value={addBank.accountType}
                        required
                      />
                      <label
                        className="form-check-label fs-6"
                        for="savingsAccount"
                      >
                        Savings
                      </label>
                    </div>
                    <div className="d-flex gap-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="accountType"
                        onChange={handleAddChange}
                        id="currentAccount"
                        value="Current"
                        required
                      />
                      <label
                        className="form-check-label fs-6"
                        for="currentAccount"
                      >
                        Current
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pb-3">
                  <p style={{ fontSize: "20px", color: "black" }}>
                    Account Status
                  </p>
                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "gray",
                      margin: "10px 0",
                      marginTop: "0",
                    }}
                  ></div>
                  <div className="d-flex gap-2">
                    <div className="d-flex gap-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="accountStatus"
                        id="activeAccount"
                        value="Active"
                        onChange={handleAddChange}
                        required
                      />
                      <label
                        className="form-check-label fs-6"
                        for="activeAccount"
                      >
                        Active
                      </label>
                    </div>
                    <div className="d-flex gap-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="accountStatus"
                        id="inactiveAccount"
                        value="Inactive"
                        onChange={handleAddChange}
                        required
                      />
                      <label
                        className="form-check-label fs-6"
                        for="inactiveAccount"
                      >
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                margin: "20px 0",
                marginTop: "0",
              }}
            ></div>

            <Button
              className="btn btn-sm"
              color="#5C79FF"
              onClick={() => handleAddSubmit()}
              style={{ backgroundColor: "#E2E7FF", color: "#5C79FF" }}
            >
              Save
            </Button>
            {"   "}
            <Button
              className="btn btn-primary btn-sm" // Modified: Changed button color to primary
              onClick={() => handleAddSubmit(true)}
              style={{ backgroundColor: "#5C79FF" }}
            >
              Save & Add More Bank
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={updateShow} centered size="lg">
        <Modal.Header
          closeButton
          onHide={() => setUpdateShow(false)}
          className="text-white"
        >
          <Modal.Title className="text-black" as="h6">
            Update New Bank
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="bookingForm">
            <div class="card-body">
              <div class="row mt-4">
                <div class="col-md-6 pb-3">
                  {/* <p>IFSC Code</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="ifscCode"
                      placeholder="IFSC Code"
                      type="text"
                      name="ifscCode"
                      onChange={handleUpdateChange}
                      value={updateSelectBank.ifscCode}
                      required
                    />
                    <label className="form-label">IFSC Code</label>
                  </div>
                </div>
                <div class="col-md-6">
                  {/* <p>Bank Name</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="bankName"
                      placeholder="Bank Name"
                      type="text"
                      name="bankName"
                      onChange={handleUpdateChange}
                      value={updateSelectBank.bankName}
                      required
                    />
                    <label className="form-label">Bank Name</label>
                  </div>
                </div>
                <div class="col-md-6 pb-3">
                  {/* <p>Account Holder Name</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="accountHolderName"
                      placeholder="Account Holder's Name"
                      type="text"
                      name="accountHolderName"
                      onChange={handleUpdateChange}
                      value={updateSelectBank.accountHolderName}
                      required
                    />
                    <label className="form-label">Account Holde'r Name</label>
                  </div>
                </div>
                <div class="col-md-6">
                  {/* <p>Account Number</p> */}
                  <div className="form-control">
                    <input
                      class="form-input"
                      id="accountNumber"
                      placeholder="Account Number"
                      type="text"
                      name="accountNumber"
                      onChange={handleUpdateChange}
                      value={updateSelectBank.accountNumber}
                      required
                    />
                    <label className="form-label">Account Number</label>
                  </div>
                </div>
                <div class="col-md-6 pb-3">
                  <p style={{ fontSize: "20px", color: "black" }}>
                    Type of Account
                  </p>
                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "gray",
                      margin: "10px 0",
                      marginTop: "0",
                    }}
                  ></div>
                  <div className="d-flex gap-2">
                    <div className="d-flex gap-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="savingsAccount"
                        value="Savings"
                        name="accountType"
                        onChange={handleUpdateChange}
                        checked={updateSelectBank.accountType === "Savings"}
                        required
                      />
                      <label
                        className="form-check-label fs-6"
                        for="savingsAccount"
                      >
                        Savings
                      </label>
                    </div>
                    <div className="d-flex gap-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="accountType"
                        onChange={handleUpdateChange}
                        id="currentAccount"
                        value="Current"
                        checked={updateSelectBank.accountType === "Current"}
                        required
                      />
                      <label
                        className="form-check-label fs-6"
                        for="currentAccount"
                      >
                        Current
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pb-3">
                  <p style={{ fontSize: "20px", color: "black" }}>
                    Account Status
                  </p>
                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "gray",
                      margin: "10px 0",
                      marginTop: "0",
                    }}
                  ></div>
                  <div className="d-flex gap-2">
                    <div className="d-flex gap-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="accountStatus"
                        id="activeAccount"
                        value="Active"
                        onChange={handleUpdateChange}
                        checked={updateSelectBank.accountStatus === "Active"}
                        required
                      />
                      <label
                        className="form-check-label fs-6"
                        for="activeAccount"
                      >
                        Active
                      </label>
                    </div>
                    <div className="d-flex gap-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="accountStatus"
                        id="inactiveAccount"
                        value="Inactive"
                        onChange={handleUpdateChange}
                        checked={updateSelectBank.accountStatus === "Inactive"}
                        required
                      />
                      <label
                        className="form-check-label fs-6"
                        for="inactiveAccount"
                      >
                        Inactive
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                margin: "20px 0",
                marginTop: "0",
              }}
            ></div>

            <Button
              className="btn btn-sm"
              color="#5C79FF"
              onClick={() => setUpdateShow(false)}
              style={{ backgroundColor: "#E2E7FF", color: "#5C79FF" }}
            >
              Close
            </Button>
            {"   "}
            <Button
              className="btn btn-primary btn-sm" // Modified: Changed button color to primary
              onClick={handleUpdateSubmit}
              style={{ backgroundColor: "#5C79FF" }}
            >
              Update
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={viewShow} centered size="md">
        <Modal.Header
          closeButton
          onHide={() => setViewShow(false)}
          className=""
        >
          <Modal.Title className="text-black" as="h6">
            View Bank Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedBank && (
            <>
              <h4>Bank Details:</h4>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "gray",
                  margin: "20px 0",
                }}
              ></div>
              <p>
                <strong>IFSC Code:</strong> {selectedBank.ifscCode}
              </p>
              <p>
                <strong>Bank Name:</strong> {selectedBank.bankName}
              </p>
              <p>
                <strong>Account Holder's Name:</strong>{" "}
                {selectedBank.accountHolderName}
              </p>
              <p>
                <strong>Account Number:</strong> {selectedBank.accountNumber}
              </p>
              <p>
                <strong>Type of Account:</strong> {selectedBank.accountType}
              </p>
              <p>
                <strong>Account Status:</strong> {selectedBank.accountStatus}
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SellerBank;
