import BaseService from "../config/axiosService";

const uploadFiles = async (files) => {
  const formData = new FormData();
  Array.from(files).forEach((file) => {
    formData.append("files", file); // Adjust key name if needed by your API
  });

  const response = await BaseService.post("/api/v2/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  const data = response.data.data;

  return data.map((file) => file.path);
};

export default uploadFiles;
