import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import userIcon from "../assets/images/user.png";
import { Dropdown, DropdownButton } from "react-bootstrap";
import toast from "react-hot-toast";

const Header = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [sticky, changeStickyData] = useState("main-header");
  window.onscroll = () => {
    let temp = "";
    let top = window.scrollY;
    if (top > 100) {
      temp = "main-header fixed-header";
    } else {
      temp = "main-header";
    }
    return changeStickyData(temp);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  //sidebar
  const [isSidebarMenuOpen, setisSidebarMenuOpen] = useState(false);
  const handleToggleMenu = () => {
    setisSidebarMenuOpen(!isSidebarMenuOpen);
  };
  const closeMenu = () => {
    setisSidebarMenuOpen(false);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const logout = (e) => {
    e.preventDefault();
    // dispatch(logout())
    localStorage.clear();
    window.location.reload("/Login");
  };
  return (
    <div className={`${isSidebarMenuOpen ? "mobile-menu-visible" : ""}`}>
      <header className={sticky}>
        <div className="header-top">
          <div className="top-inner">
            <div className="top-left">
              {/*<ul className="info-list clearfix">
                            <li><i className="icon-2"></i>Email: <Link to="mailto:support@getmaxvalue.in">support@getmaxvalue.in</Link></li>
                            <li><i className="icon-4"></i>Phone: <Link to="tel:+91-1234567890">+91-1234567890 </Link></li>
                        </ul>*/}
            </div>
            <div className="top-right">
              {!userInfo ? (
                <div className="login-box">
                  <Link to="/login">
                    <i class="fas fa-user-plus"></i> Login
                  </Link>
                </div>
              ) : (
                <div className="user-box pe-4">
                  <DropdownButton
                    id="dropdown-basic-button"
                    className="userBtn"
                    title={<img src={userIcon} />}
                  >
                    {userInfo.role.role === "Buyer" ? (
                      <>
                        <Dropdown.Item>
                          <Link to="/buyer-profile">
                            <i class="fas fa-user-circle"></i> Profile
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/buyer-changepassword">
                            <i class="fas fa-key"></i> Change password
                          </Link>
                        </Dropdown.Item>
                      </>
                    ) : (
                      <></>
                    )}
                    {userInfo.role.role === "Seller" ? (
                      <>
                        <Dropdown.Item>
                          <Link to="/seller-profile">
                            <i class="fas fa-user-circle"></i> Profile
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/changepassword">
                            <i class="fas fa-key"></i> Change password
                          </Link>
                        </Dropdown.Item>
                      </>
                    ) : (
                      <></>
                    )}
                    <Dropdown.Item onClick={(e) => logout(e)}>
                      <i class="fas fa-sign-out-alt"></i> Log Out
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              )}
              <ul className="info-list clearfix">
                <li>
                  <i className="icon-2"></i>{" "}
                  <Link to="mailto:support@getmaxvalue.in">
                    support@getmaxvalue.in
                  </Link>
                </li>
                <li>
                  <i className="icon-4"></i>{" "}
                  <Link to="tel:+91-8588840800">+91-8588840800 </Link>
                </li>
              </ul>
              {/* <div className="login-box"><Link to="/signup"><i class="fas fa-sign-in-alt"></i> Signup</Link></div> 
                        <ul className="social-links clearfix">
                            <li><Link to="#!"><i className="fab fa-facebook-f"></i></Link></li>
                            <li><Link to="#!"><i className="fab fa-twitter"></i></Link></li>
                            <li><Link to="#!"><i className="fab fa-linkedin-in"></i></Link></li>
                            <li><Link to="#!"><i className="fab fa-instagram"></i></Link></li>
                        </ul>*/}
            </div>
          </div>
        </div>

        {/* <!-- header-lower --> */}
        <div className="header-lower">
          <div className="outer-box">
            <div className="logo-box" style={{ padding: "0px 0px" }}>
              <figure className="logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </figure>
            </div>
            <div className="menu-area">
              {/* <!--Mobile Navigation Toggler--> */}
              <div
                className="mobile-nav-toggler rounded-2"
                onClick={handleToggleMenu}
              >
                <i className="icon-bar"></i>
                <i className="icon-bar"></i>
                <i className="icon-bar"></i>
              </div>
              <nav className="main-menu navbar-expand-md navbar-light clearfix">
                <div className="collapse navbar-collapse show clearfix">
                  <ul className="navigation clearfix">
                    <li onClick={scrollToTop}>
                      <Link to="/">Home</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li
                      className="dropdown"
                      onClick={() => {
                        scrollToTop();
                        toggleDropdown();
                      }}
                    >
                      <Link to="#!" className="dropdown-toggle">
                        Auction
                      </Link>
                      {isDropdownOpen && (
                        <ul className="dropdown-menu">
                          <li className="dropdown-item">
                            {/* <Link to="/auction">On Going</Link> */}
                            <a
                              onClick={() => {
                                toast("Comming Soon.!!!", {
                                  icon: "🚀",
                                  iconTheme: {
                                    primary: "#000",
                                    secondary: "#fff",
                                  },
                                  duration: 1000,
                                });
                              }}
                            >
                              On Going
                            </a>
                          </li>
                          <li className="dropdown-item">
                            {/* <Link to="/upcoming-auction">Up Comming</Link> */}
                            <a
                              onClick={() => {
                                toast("Comming Soon.!!!", {
                                  icon: "🚀",
                                  iconTheme: {
                                    primary: "#000",
                                    secondary: "#fff",
                                  },
                                  duration: 1000,
                                });
                              }}
                            >
                              Up Comming
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/services">Services</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/compliance">Compliance</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      {/* <Link to="/deal-book">Projects</Link> */}
                      <a
                        onClick={() => {
                          toast("Comming Soon.!!!", {
                            icon: "🚀",
                            iconTheme: {
                              primary: "#000",
                              secondary: "#fff",
                            },
                            duration: 1000,
                          });
                        }}
                      >
                        Projects
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              <ul className="menu-right-content">
                <li className="btn-box" onClick={scrollToTop}>
                  <Link
                    to="/contact"
                    className="theme-button bg-color text-light m-0"
                  >
                    <span>Request A Call</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Sticky Header */}
        <div className="sticky-header">
          <div className="outer-container">
            <div className="outer-box">
              <div className="logo-box" style={{ padding: "8px 0px" }}>
                <figure className="logo">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </figure>
              </div>
              <div className="menu-area">
                <nav className="main-menu clearfix">
                  <ul className="navigation clearfix">
                    <li onClick={scrollToTop}>
                      <Link to="/">Home</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li
                      className="dropdown"
                      onClick={() => {
                        scrollToTop();
                        toggleDropdown();
                      }}
                    >
                      <Link to="#!" className="dropdown-toggle">
                        Auction
                      </Link>
                      {isDropdownOpen && (
                        <ul className="dropdown-menu">
                          <li className="dropdown-item">
                            {/* <Link to="/auction">On Going</Link> */}
                            <a
                              onClick={() => {
                                toast("Comming Soon.!!!", {
                                  icon: "🚀",
                                  iconTheme: {
                                    primary: "#000",
                                    secondary: "#fff",
                                  },
                                  duration: 1000,
                                });
                              }}
                            >
                              On Going
                            </a>
                          </li>
                          <li className="dropdown-item">
                            {/* <Link to="/upcoming-auction">Up Comming</Link> */}
                            <a
                              onClick={() => {
                                toast("Comming Soon.!!!", {
                                  icon: "🚀",
                                  iconTheme: {
                                    primary: "#000",
                                    secondary: "#fff",
                                  },
                                  duration: 1000,
                                });
                              }}
                            >
                              Up Comming
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/services">Services</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/compliance">Compliance</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      {/* <Link to="/deal-book">Projects</Link> */}
                      <a
                        onClick={() => {
                          toast("Comming Soon.!!!", {
                            icon: "🚀",
                            iconTheme: {
                              primary: "#000",
                              secondary: "#fff",
                            },
                            duration: 1000,
                          });
                        }}
                      >
                        Projects
                      </a>
                    </li>
                  </ul>
                </nav>
                <ul className="menu-right-content">
                  <li className="btn-box" onClick={scrollToTop}>
                    <Link
                      to="/contact"
                      className="theme-btn bg-color text-light m-0"
                    >
                      <span>Request A Call</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* main-header end */}

        {/* Mobile Menu */}
        <div className="mobile-menu">
          <div className="menu-backdrop"></div>
          <div className="close-btn" onClick={closeMenu}>
            <i className="fas fa-times"></i>
          </div>

          <nav className="menu-box">
            <div className="nav-logo">
              <figure className="logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </figure>
            </div>
            <div className="menu-outer">
              <ul className="navigation clearfix">
                <li onClick={scrollToTop}>
                  <Link to="/">Home</Link>
                </li>
                <li onClick={scrollToTop}>
                  <Link to="/about">About Us</Link>
                </li>
                <li
                  className="dropdown"
                  onClick={() => {
                    scrollToTop();
                    toggleDropdown();
                  }}
                >
                  <Link to="#!" className="dropdown-toggle">
                    Auction
                  </Link>
                  {isDropdownOpen && (
                    <ul className="dropdown-menu">
                      <li className="dropdown-item">
                        {/* <Link to="/auction">On Going</Link> */}
                        <a
                          onClick={() => {
                            toast("Comming Soon.!!!", {
                              icon: "🚀",
                              iconTheme: {
                                primary: "#000",
                                secondary: "#fff",
                              },
                              duration: 1000,
                            });
                          }}
                        >
                          On Going
                        </a>
                      </li>
                      <li className="dropdown-item">
                        {/* <Link to="/upcoming-auction">Up Comming</Link> */}
                        <a
                          onClick={() => {
                            toast("Comming Soon.!!!", {
                              icon: "🚀",
                              iconTheme: {
                                primary: "#000",
                                secondary: "#fff",
                              },
                              duration: 1000,
                            });
                          }}
                        >
                          Up Comming
                        </a>
                      </li>
                    </ul>
                  )}
                </li>
                <li onClick={scrollToTop}>
                  <Link to="/services">Services</Link>
                </li>
                <li onClick={scrollToTop}>
                  <Link to="/compliance">Compliance</Link>
                </li>
                <li onClick={scrollToTop}>
                  {/* <Link to="/deal-book">Projects</Link> */}
                  <a
                    onClick={() => {
                      toast("Comming Soon.!!!", {
                        icon: "🚀",
                        iconTheme: {
                          primary: "#000",
                          secondary: "#fff",
                        },
                        duration: 1000,
                      });
                    }}
                  >
                    Projects
                  </a>
                </li>
              </ul>
            </div>
            <div className="contact-info">
              <h4>Contact Info</h4>
              <ul>
                <li>
                  <strong>Work:</strong> I - 35B, First Floor, Lajpat Nagar – 2,
                  New Delhi – 110 024 <strong>Registered Office</strong> – G
                  1504, Great Value Sharanam, Sector 107, Gautam Budh Nagar,
                  Noida, Uttar Pradesh
                </li>
                <li>
                  <Link to="tel:+91-8588840800">+91-8588840800</Link>
                </li>
                <li>
                  <Link to="mailto:support@getmaxvalue.in">
                    support@getmaxvalue.in
                  </Link>
                </li>
              </ul>
            </div>
            <div className="social-links">
              <ul className="clearfix">
                <li>
                  <Link to="#!">
                    <span className="fab fa-twitter"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#!">
                    <span className="fab fa-facebook-square"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#!">
                    <span className="fab fa-pinterest-p"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#!">
                    <span className="fab fa-instagram"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {/* End Mobile Menu */}
      </header>
    </div>
  );
};
export default Header;
