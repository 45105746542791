import React from 'react';
import './css/about-company.css';
import aboutImage1 from '../../assets/images/resource/dronview2.png';
import aboutImage5 from '../../assets/images/resource/about-5.jpg';

const AboutCompanySec = ({aboutData}) => {
    const parse = require('html-react-parser');
    return (
        <section className="about-style-two border-bottom">
            <div className="auto-container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-5 col-sm-12 image-column order-1">
                        <div className="image_block_three">
                            <div className="image-box">
                                <figure className="image image-1"><img src={aboutImage1} alt="" /></figure>
                                <figure className="image image-2"><img src={aboutImage5} alt="" /></figure>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-sm-12 content-column">
                        <div className="content_block_three">
                            <div className="content-box ml_30">
                                <div className="titleSec mb_25">
                                    {/* <span className="sub-title lableBlue">About Us</span> */}
                                    <h2>About US</h2>
                                </div>
                                <div className="text-box mb_30">
                                    {parse(aboutData.body)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutCompanySec;
