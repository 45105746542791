import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import Header from '../../components/header';
import Footer from '../../components/footer';

const PrivacyPolicy = () => {
    const parser = require('html-react-parser');
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const [data, setData] = useState({
        body: '',
    })
    useEffect(()=>{
       axios
      .get(`${baseServerUrl}/getprivacybyid/648c0dc06742f92bcd81842c`)
      .then((resp) => {
            setData({
          ...data,
          body: resp.data.data.body,
        })
      })
      .catch((error) => {
        console.log(error)
      })
    }, [])
    return (
        <>
        <Header />
        <div>
            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="pb_20 my-4">
                <div className="auto-container">
                    {parser(data.body)}
                </div>
            </section>
        </div>
    <Footer />
    </>    
    )
}
export default PrivacyPolicy;