import React from 'react'
import { Navigate } from 'react-router-dom'
function Protected({ isSignedIn, children }) {
  if (isSignedIn === null) {
    return <Navigate replace to="Login" />
  }
  return children
  // else if(isSignedIn.role.roleId === '64ce1037015116b791222651'){
  //   return children
  // }
}
export default Protected