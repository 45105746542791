import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import './css/index.css';
import ReactShowMoreText from "react-show-more-text";
import Countdown from 'react-countdown';
import Header from '../../components/header';
import Footer from '../../components/footer';
import auction5 from '../../assets/images/resource/auction-1.png';
import auction6 from '../../assets/images/resource/auction-2.png';
import auction7 from '../../assets/images/resource/auction-3.png';
import auction8 from '../../assets/images/resource/auction-4.png';


const Auction = () => {
    const parse = require('html-react-parser');
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const [auctionlist, setauctionList] = useState([]
        // {
        //     title: '',
        //     price: '',
        //     slug: '',
        //     description: '',
        //     startDate: '',
        //     endDate: '',
        //     image: '',
        // }
    );
    const [scrapcatlist, setscrapcatList] = useState([]);
    const [perPage] = useState(4);
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [filterValues, setfilterValues] = useState({
        search: '',
      })
    const [categoryfilter, setcategoryFilter] = useState('')
    const handlePage = (key) =>{
        setSkip('');
        setSkip(key-1);
    }
    const handlePageNext = () =>{
        if (pageCount > skip) {
            setSkip(skip + 1)
          }
    }
    const handlePagePrevious = () => {
          if (skip >= 1) {
            setSkip(skip - 1)
          }
    }
    const handleChanageFilter = (name, value) => {
        setfilterValues((prev) => ({
          ...prev,
          [name]: value,
        }))
      }
    const handlechangeCategory = (id) => {
        setcategoryFilter(id);
    }
    const fetchScrap = async () => {
        await axios.post(`${baseServerUrl}/ongingeauction`, 
        { 
            search: filterValues.search, 
            categoryID: categoryfilter, 
            page: perPage, 
            skip: skip 
        }).then((resp) =>{
            setauctionList(resp.data.data)    
            // setauctionList({
            //     ...auctionlist,
            //         title: resp.data.data.title,
            //         price: resp.data.data.price,
            //         slug: resp.data.data.slug,
            //         description: resp.data.data.description,
            //         startDate: resp.data.data.startDate,
            //         endDate: resp.data.data.endDate,
            //         image: resp.data.data.scrapID.image,
            // });
            setPageCount(Math.ceil(resp.data.count / perPage));
        }).catch(err=>{
            console.log(err);
        })
    }
    const fetchScrapCat = async () => {
        await axios.get(`${baseServerUrl}/getfrontcategory`).then((respcat) =>{
            setscrapcatList(respcat.data.data);
        }).catch(err=>{
            console.log(err);
        });
    }
    useEffect(() => {
        fetchScrap();
        fetchScrapCat();
    }, [skip, filterValues, categoryfilter])
    
    const [list,setList] = useState(true);
    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!/^[0-9]+$/.test(keyValue) && keyCode !== 8) {
            event.preventDefault();
        }
    };
    return (
        <>
        <Header />
        <div className="auctionPage">
            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>eAuction</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="auctionPageSec pb-0">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-4 col-md-4 col-sm-12 sidebar-side mb-4">
                           <div className="blog-sidebar default-sidebar">
                                <div className="sidebar-widget category-widget">
                                    <div className="widget-title text-center">
                                        <h3>Categories</h3>
                                    </div>
                                    <div className="widget-content">
                                        <ul className="category-list clearfix">
                                            {scrapcatlist.map((item, index) => (
                                                <li key={index} onClick={() => {
                                                    handlechangeCategory(item._id);
                                                }}>
                                                    <Link to="#!"><span> {item.name}</span></Link>
                                                </li> 
                                            ))}
                                                                                      
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-8 col-md-8 col-sm-12 auctionListing py-0">
                        <div className="auto-container">
                            <div className="row clearfix">
                            <div className="col-lg-9 col-md-9 col-sm-12 sidebar-side mb-3">
                            <div className="blog-sidebar default-sidebar">
                                <div className="sidebar-widget search-widget">
                                    <div className="search-form">
                                        <form method="post">
                                            <div className="form-group">
                                            <input type="search" name="search-field" placeholder="Search..." onChange={(e) => handleChanageFilter('search', e.target.value)} required />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-12 pb-4 listingViewCol">
                                        <ul className="listingView d-flex justify-content-end">
                                                <li className={`pe-2 ${list ? 'active' : ''}`} onClick={() => setList(!list)}>
                                                    <button className="btn">
                                                        <i className="fa fa-th" aria-hidden="true"></i>
                                                    </button>
                                                </li>
                                                <li className={list ? '' : 'active'} onClick={() => setList(!list)}>
                                                    <button className="btn">
                                                        <i className="fa fa-list" aria-hidden="true"></i>
                                                    </button>
                                                </li>
                                        </ul>
                                </div>
                                {auctionlist.length > 0 ? (
                                    auctionlist.map((item, index) => (
                                    <div className={list ? 'col-lg-6 col-md-6 col-sm-6 col-12 mb_20 gridView' : 'col-lg-12 col-md-12 col-sm-12 col-12 mb_20 listView'} key={index}>
                                        <div
                                            className="auction-block wow fadeInUp animated"
                                            data-wow-delay="00ms"
                                            data-wow-duration="1500ms"
                                        >
                                            <div className="inner-box h-100">
                                                <figure className="image-box">
                                                    <Link to={`/auction-details/${item.slug}`}>
                                                        <img src={item.projectId.image} alt="" />
                                                    </Link>
                                                </figure>
                                                <div className="lower-content border-top">
                                                        <div className='block-1 text-center py-2 px-3 border-bottom'>
                                                            <div>
                                                                <Link to={`/auction-details/${item.slug}`}>
                                                                    <h6 className='mb-3'> {item.name} </h6>
                                                                </Link>
                                                            </div>
                                                            <div className="">
                                                            <a class="theme-button m-0" href={`/auction-details/${item.slug}`}><span>View More</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                ):
                                (
                                    <>No auctions found</>
                                )
                                } 
                            </div>
                        </div>
                        </div>
                        <div className="fpagination-wrapper centred pb-3">
                        <ul className="fpagination clearfix">
                            <li><a href="#!" onClick={handlePagePrevious}><i className="far fa-angle-left"></i></a></li>
                            {[...Array(pageCount)]?.map((item, key) => {
                                    return (    
                                <li><a href="#!" key={key}
                                    onClick={(e) => handlePage(key + 1)}
                                    className={`${skip === key ? 'current' : ''}`}>{key + 1}</a></li>
                                            )
                            })}
                            <li><a href="#!" onClick={handlePageNext}><i className="far fa-angle-right"></i></a></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </section >
        </div >
    <Footer />
    </>
    )
}
export default Auction
