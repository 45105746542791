import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <div className="not-found-container">
        <h1>Loading...</h1>
    </div>
  );
};

export default NotFound;



{/* <div className="not-found-container">
<img
  src="./404-page-not-found.svg"
  alt="404 Page Not Found"
  className="not-found-image"
/>
<h1>Page Not Found</h1>
<p>Sorry, the page you are looking for does not exist.</p>
<button onClick={handleGoBack} className="back-button">
  Go Back Home
</button>
</div> */}