import React, { useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import Header from "../../components/header";
import Footer from "../../components/footer";
const CompliancePageSec = () => {
  const complianceBlocks = [
    {
      id: "01",
      key: "banefitkey1",
      name: "GST",
      description:
        "Generally, scrap or disposables assets do not have any proper record, so GST is charged on such items as lumpsum sales. As per GST Act, it is considered as a composite sale and GST is charged at the highest rate of any item included in such composite sale material. Generally, material is dispatched in small lots and every time Material Dispatch Challan is prepared and final bill is prepared at the end of the completion of the work, or it may be at a certain period.       ",
    },
    {
      id: "02",
      key: "banefitkey2",
      name: "Environment and Pollution",
      description:
        "All environmental related compliances are done with the Central Pollution Control Board/ State Pollution Control Board (CPCB/SPCB). These boards works under the Ministry of Environment. ",
    },
    {
      id: "03",
      key: "banefitkey3",
      name: "Labour Laws",
      description:
        "There are various labour laws that need to be followed by Scrap Seller and the Scrap Buyer. The scrap seller has to ensure all labour law-related compliances of the scrap buyer as he becomes the indirect employer of the Labour/Team of the Scrap buyer. ",
    },
    {
      id: "04",
      key: "banefitkey4",
      name: "Safety and Security",
      description:
        "It has been noticed that at the workplace, there are chances of accidents like fire and blasts, this happened due to non-following of the safety and security rules and guidelines. It is noticed that the workforce does not use safety gear and equipment at the work site which may cause accidents.",
    },
    {
      id: "05",
      key: "banefitkey5",
      name: "TDS/TCS",
      description: `Tax Collected at Source (TCS) - At the time of disposing of Scrap/residue few provisions of TCS and TDS applies. U/s …. Of the income tax act defines provisions for TCS. The Rate of TCS is …..
Tax Deducted at Source (TDS) – There are some high value transactions on which provisions of TDS applies. Section ….. of the Income Tax Act defines provisins of the TDS. The rate of TDS is…..
 `,
    },
    {
      id: "06",
      key: "banefitkey6",
      name: "EPR",
      description: `Extended Producer Responsibility (EPR) mandates that every producer of Electrical and Electronic Equipment (EEE) ensures proper management of e-waste. This involves directing e-waste to authorized dismantlers/recyclers for safe handling and disposal.
EPR Compliance for E-Waste in India requires the implementation of take-back systems and the establishment of collection centers. Producers, including importers, e-retailers, and online sellers, must obtain EPR authorization as per the E-Waste (Management) Rules, 2016. This includes applying for EPR Compliance, which can be facilitated by EPR Experts familiar with the process.`,
    },
  ];
  const [activeTab, setActiveTab] = useState(`tab${complianceBlocks[0].id}`);

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };
  return (
    <>
      <Header />
      <div className="boxed_wrapper compliancePage mb-4">
        <Tab.Container
          id="nested-tabs"
          activeKey={activeTab}
          onSelect={handleTabChange}
        >
          <section className="centred bg-white py-4 flterBtn">
            <div className="auto-container">
              <div className="row clearfix">
                {complianceBlocks.map((item, index) => (
                  <div className="col-lg-2 col-md-4 col-sm-3 col-6">
                    <Nav variant="pills" className="flex-column ul">
                      <Nav.Item className="li filter-tab">
                        <Nav.Link eventKey={`tab${item.id}`}>
                          {item.name} <span className="cntNum">{item.id}</span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="cmpInnerBox centred">
            <div className="auto-container">
              <Tab.Content>
                {complianceBlocks.map((item, index) => (
                  <Tab.Pane key={item.id} eventKey={`tab${item.id}`}>
                    <div className="row clearfix">
                      <Tab.Container
                        id="nested-inner-tabs"
                        defaultActiveKey="key1"
                      >
                        {/* <div className="col-lg-4 col-md-5 col-sm-12 side1">
                                                <div className="cmpSidebar">
                                                    <div className="widget-title mb-0">
                                                        <h3>{item.name}</h3>
                                                    </div>
                                                    <div className="widget-content">
                                                        <Nav variant="pills" className="flex-column ul">
                                                            <Nav.Item className='li'>
                                                                <Nav.Link eventKey="key1"><span>Verification of Scrap</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className='li'>
                                                                <Nav.Link eventKey="key2"><span>Valuation of Asset</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className='li'>
                                                                <Nav.Link eventKey="key3"><span>Bidding Document Preparation</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className='li'>
                                                                <Nav.Link eventKey="key4"><span>Selection of Sellers</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className='li'>
                                                                <Nav.Link eventKey="key5"><span>Arrangement of Inspection</span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item className='li'>
                                                                <Nav.Link eventKey="key6"><span>E-Auction Arrangement</span></Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>
                                                </div>
                                            </div> */}
                        <div className="col-lg-12 col-md-7 col-sm-12 col-12">
                          <div className="complianceBox">
                            <div
                              className="innerBlock mb_35 text-start wow fadeInUp animated tilt-effect"
                              data-wow-delay="00ms"
                              data-wow-duration="1500ms"
                            >
                              <Tab.Content>
                                <Tab.Pane eventKey="key1">
                                  <h4 className="pb-3">{item.name}</h4>
                                  <p>{item.description}</p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="key2">
                                  <h4 className="pb-3">Valuation of Asset</h4>
                                  <p>
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Aperiam quos accusamus
                                    exercitationem consectetur quis sint
                                    asperiores atque repellat ex voluptatum.
                                    Unde, expedita illum accusantium nulla
                                    pariatur minus fuga tempora consectetur
                                    temporibus illo officiis, nam qui culpa
                                    eligendi laboriosam dolore nisi molestias?
                                    Dignissimos impedit earum minus omnis odio
                                    magni asperiores temporibus. Officia
                                    praesentium neque optio cupiditate. Amet,
                                    cum. Quisquam, expedita cum!
                                  </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="key3">
                                  <h4 className="pb-3">
                                    Bidding Document Preparation
                                  </h4>
                                  <p>
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Aperiam quos accusamus
                                    exercitationem consectetur quis sint
                                    asperiores atque repellat ex voluptatum.
                                    Unde, expedita illum accusantium nulla
                                    pariatur minus fuga tempora consectetur
                                    temporibus illo officiis, nam qui culpa
                                    eligendi laboriosam dolore nisi molestias?
                                    Dignissimos impedit earum minus omnis odio
                                    magni asperiores temporibus. Officia
                                    praesentium neque optio cupiditate. Amet,
                                    cum. Quisquam, expedita cum!
                                  </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="key4">
                                  <h4 className="pb-3">Selection of Sellers</h4>
                                  <p>
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Aperiam quos accusamus
                                    exercitationem consectetur quis sint
                                    asperiores atque repellat ex voluptatum.
                                    Unde, expedita illum accusantium nulla
                                    pariatur minus fuga tempora consectetur
                                    temporibus illo officiis, nam qui culpa
                                    eligendi laboriosam dolore nisi molestias?
                                    Dignissimos impedit earum minus omnis odio
                                    magni asperiores temporibus. Officia
                                    praesentium neque optio cupiditate. Amet,
                                    cum. Quisquam, expedita cum!
                                  </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="key5">
                                  <h4 className="pb-3">
                                    Arrangement of Inspection
                                  </h4>
                                  <p>
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Aperiam quos accusamus
                                    exercitationem consectetur quis sint
                                    asperiores atque repellat ex voluptatum.
                                    Unde, expedita illum accusantium nulla
                                    pariatur minus fuga tempora consectetur
                                    temporibus illo officiis, nam qui culpa
                                    eligendi laboriosam dolore nisi molestias?
                                    Dignissimos impedit earum minus omnis odio
                                    magni asperiores temporibus. Officia
                                    praesentium neque optio cupiditate. Amet,
                                    cum. Quisquam, expedita cum!
                                  </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="key6">
                                  <h4 className="pb-3">
                                    E-Auction Arrangement
                                  </h4>
                                  <p>
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit. Aperiam quos accusamus
                                    exercitationem consectetur quis sint
                                    asperiores atque repellat ex voluptatum.
                                    Unde, expedita illum accusantium nulla
                                    pariatur minus fuga tempora consectetur
                                    temporibus illo officiis, nam qui culpa
                                    eligendi laboriosam dolore nisi molestias?
                                    Dignissimos impedit earum minus omnis odio
                                    magni asperiores temporibus. Officia
                                    praesentium neque optio cupiditate. Amet,
                                    cum. Quisquam, expedita cum!
                                  </p>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </div>
                        </div>
                      </Tab.Container>
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </div>
          </section>
        </Tab.Container>
      </div>
      <Footer />
    </>
  );
};
export default CompliancePageSec;
