import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// react-bootstrap components
import BaseService from '../../config/axiosService';

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Table
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from '../../components/footer';
const baseServerUrl = process.env.REACT_APP_baseServerURL;

function User() {

  const userData = JSON.parse(localStorage.getItem("userInfo") || "{}");
  

 const [details,setDetails]= useState([])

 // Retrieve the userInfo object from local storage
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

// Check if userInfo exists
if (userInfo) {
  // Log all fields and values of userInfo
  console.log('userInfo fields:', userInfo);
} else {
  console.log('userInfo is not found in local storage.');
}

 const fetchProjects = () => {
  BaseService.get("/getgeneralbyid")
    .then((response) => {
      console.log("API Response:", response); // Log the full response
      if (response.data && response.data.data) {
        setDetails(response.data.data);
        console.log("Details are:", response.data.data);
      } else {
        console.log("Unexpected response structure:", response);
      }
    })
    .catch((err) => {
      console.error("Fetching error:", err);
    });
};
  
useEffect(() => {
  fetchProjects();
}, []);


  return (
    <>
    <Container fluid className="mb-4">
      <Row>
      <Col lg="2" md="2" sm="4" style={{background: '#000248'}}>       
        <Sidebar />
      </Col>
      <Col lg="10" md="10" sm="8">
      <div className="tophd">Dashboard <span style={{color: '#000'}}><i className="fa fa-angle-right"></i></span> <Link to="/seller-profile-view" >Profile</Link></div>
      <Table className="table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Fields</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{userData.username}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{userData.email}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{userData.phone}</td>
                  </tr>
                 
                </tbody>
              </Table>    
      </Col>
      </Row>
      </Container>
      <Footer/>
    </>
  );
}

export default User;
