import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import forgot from "../../assets/images/forgot.png";
import { Form, Alert } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import Header from "../../components/header";
import Footer from "../../components/footer";
import BaseService from "../../config/axiosService";
const Forgot = () => {
  const [formData, setformData] = useState({
    email: "",
  });
  const [loading,setLoading] = useState(false);

  const navigate = useNavigate();
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [errors, setErrors] = useState({});
  const handleChange = (name, value) => {
    setformData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationError = Validation(formData);
    if (Object.keys(validationError).length > 0) {
      setErrors(validationError);
    } else {
      setLoading(true);
      BaseService.post("/api/v2/user/send-forgot-password-email", {
        email: formData.email,
      })
        .then((res) => {
          toast.success("Password reset link sent to your email");
        })
        .catch((err) => {
          console.log(err);
        }).finally(()=>{
          setLoading(false);
        })
    }
  };

  function Validation(formData) {
    const errors = {};
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    }
    return errors;
  }
  return (
    <>
      <Header />
      <div className="forgotPage">
        <section className="page-title p_relative">
          <div className="bg-layer pageTitle"></div>
          <div className="pattern-layer">
            <div className="pattern-1 shape-img-14"></div>
            <div className="pattern-2 shape-img-14"></div>
          </div>
          <div className="auto-container">
            <div className="content-box">
              <h1>Forgot Password</h1>
              <ul className="bread-crumb clearfix">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Forgot Password</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="login contact-section pricing-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 form-column">
                <div className="form-inner shadow">
                  <div className="row clearfix">
                    <div className="titleSec mb_45 text-center">
                      <span className="sub-title lableOrange">
                        Forgot Password
                      </span>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 content-column text-center">
                      <img src={forgot} alt="signin" />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                      <div className=" shadow p-3 rounded-3">
                        <form
                          id="contact-form"
                          className="default-form"
                          autoComplete="new-password"
                          onSubmit={handleSubmit}
                        >
                          <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <label htmlFor="email">Email*:</label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                onChange={(e) =>
                                  handleChange("email", e.target.value)
                                }
                                placeholder="Email ID"
                                required
                              />
                              {errors.email && (
                                <span className="text-danger">
                                  {errors.email}
                                </span>
                              )}
                            </div>

                            <button
                              className={`theme-btn btn-one shadow ${loading ? "opacity-75" : ""}`}
                              type="submit"
                              disabled={loading}
                            >
                              <span>{
                                loading ? (
                                  "Loading..."
                                ) : "Submit"
                                }</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Forgot;
