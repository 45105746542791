import React from 'react'
import Tilt from "react-parallax-tilt";
import './css/industries.css';
import Header from '../../components/header';
import Footer from '../../components/footer';
import bankImg from '../../assets/icon/bank.png';
import insoImg from '../../assets/icon/insurance-companies.png';
import manImg from '../../assets/icon/manufacturers.png';
import liqImg from '../../assets/icon/liquidators.png';
import comImg from '../../assets/icon/commercial.png';
const ListOfIndustries = () => {
    const homeindustries = [
        {
            id: '01',
            img: bankImg,
            title: 'Banks',
            desc: 'Banks regularly auction assets of NPA accounts like Land and Building, Inventory.',
        },
        {
            id: '02',
            img: insoImg,
            title: 'Insurance Companies',
            desc: 'Sale of items damaged in transition, and other items damaged and claimed.',
        },
        {
            id: '03',
            img: manImg,
            title: 'Manufacturers',
            desc: 'Profitably sell off scrap generated regularly during the manufacturing process.',
        },
        {
            id: '04',
            img: liqImg,
            title: 'Commercial and Industrial Premises',
            desc: 'Scrap generated during vacating/ dismantling premises.',
        },
        {
            id: '05',
            img: comImg,
            title: 'Liquidators',
            desc: 'Liquidate assets of businesses easily with our scrap management services.',
        },
    ]
    return (
        <>
        {/* <Header /> */}
        <section className="centred industriesSec industries">
            <div className="auto-container">
                <div className="row clearfix align-items-center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                        <div className="titleSec mb_50 text-center">
                            <h2 className="title text-center mb-4"> List of Industries</h2>
                        </div>                        
                    </div>
                    {homeindustries.map((item, index) => (
                        <div className="col">
                            <Tilt className='industries-block' glareEnable={true} tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000} >
                            <div className="industries-block-one wow fadeInUp animated tilt-effect" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div className="iconArea">
                                    <img src={item.img} className='' alt={item.img} />
                                </div>
                                <h5 className="text-start">{item.title}</h5>
                                <p>{item.desc}</p>
                            </div>
                            </Tilt>
                        </div>
                    ))}  
                </div>
            </div>
        </section>
    {/* <Footer /> */}
    </>
    );
};

export default ListOfIndustries;