import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import HeroSec from "./hero-section";
import AboutUsSec from "./aboutUsSec";
import ProcessSec from "./processSec";
import HomeComplianceSec from "./home-compliance";
import HomeSolutionSec from "./solution";
import BenefitSec from "./benefitSec";
import Cta from "./cta";
import AuctionListingSec from "./auctionListingSec";
import HomeIndustriesSec from "./homeIndustriesSec";
import ClientBenifit from "./slBenefitSec";
import Header from "../../components/header";
import Footer from "../../components/footer";
//import HomeBlogSec from './homeBlogSec';
import ClientLogoSec from "./clientLogoSec";
import ChatSec from "./chatSec";

const Home = () => {
  const [aboutData, setaboutData] = useState({ body: "" });
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [partnerLogo, setpartnerLogo] = useState([]);
  const [perPage] = useState(3);
  const [skip] = useState(0);
  const [blogData, setblogData] = useState([]);
  const [auctionperPage] = useState(8);
  const [auctiondata, setauctiondata] = useState([]);
  const fetchAbout = async () => {
    await axios
      .get(`${baseServerUrl}/gethomebyid/64a55f8feab2898f1ad07e0c`)
      .then((respAbout) => {
        setaboutData({ ...aboutData, body: respAbout.data.data.body });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchBlog = async () => {
    await axios
      .post(`${baseServerUrl}/allblog`, { page: perPage, skip: skip })
      .then((respblog) => {
        setblogData(respblog.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchPartner = async () => {
    await axios
      .get(`${baseServerUrl}/getallpart`)
      .then((resppartner) => {
        setpartnerLogo(resppartner.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchAuctions = async () => {
    await axios
      .post(`${baseServerUrl}/ongingeauction`, {
        page: auctionperPage,
        skip: skip,
      })
      .then((respauction) => {
        setauctiondata(respauction.data.data);
        console.log(respauction.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchAbout();
    fetchAuctions();
    fetchBlog();
    fetchPartner();
  }, []);
  return (
    <>
      <Header />
      <div className="boxed_wrapper">
        <HeroSec />
        <Cta />
        <ProcessSec />
        <BenefitSec />
        <HomeSolutionSec />
        <AboutUsSec data={aboutData} />
        {/* <AuctionListingSec auctionList={auctiondata} /> */}
        <HomeIndustriesSec />
        <ClientBenifit />
        <ClientLogoSec partnerList={partnerLogo} />
        <ChatSec />
        <div>
          <h2 className="p-5  border-bottom border-top text-black">
            What our <span className="text-warning">Partners</span> Say About
            us,
          </h2>
          <div
            className="d-flex justify-content-evenly"
            style={{ backgroundColor: "#F8F9FA" }}
          >
            <div className="d-flex align-items-center">
              <div className="flex flex-column align-items-center justify-content-center">
                <img src="./icons8-user-96.png" alt="" />
                <p className="text-center">
                  <strong>Atul Garg,</strong>
                  <br /> Group CFO
                </p>
              </div>

              <div className="p-5">
                <h6>Lite Bite Foods Private Limited</h6>
                <p
                  style={{
                    width: "330px",
                  }}
                >
                  We are thankful to Gat Max Value for taking such a great
                  initiative, they have resolved our major issue of dismantling
                  the outlets and e-waste
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="flex flex-column align-items-center justify-content-center">
                <img
                  style={{
                    marginLeft: "18px",
                  }}
                  src="./icons8-user-96.png"
                  alt=""
                />
                <p className="text-center">
                  <strong>Sanjay Jain</strong>
                  <br /> Promotor Director
                </p>
              </div>
              <div className="p-5">
                <h6>Resource Ewaste Solutions Private Limited</h6>
                <p
                  style={{
                    width: "330px",
                  }}
                >
                  We are into e-waste recycling and furbishing, the bast thing
                  about this company is transparency in process and support the
                  vendors. Also they are very organised, We appreciate GMV Team.
                  Thanks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
