import React, { useState, useContext } from "react";
import { Formik } from "formik";
import { Button, Select } from "antd";
import { Input } from "formik-antd";
import MultiStepFormContext from "./MultiStepFormContext";
import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Badge,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Pagination,
  Dropdown,
  ListGroup,
} from "react-bootstrap";
import { Option } from "antd/es/mentions";
import axios from "axios";
// Define a component named "Details"
const Compliance = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedItem, setSelectedItem] = useState()
  const handleChange = (e) => {
    setSelectedItem(e.target.value)
    if(selectedItem != ''){
      setShow(true);
    }
  }
  // Get "address", "setAddress", "next", and "prev" from MultiStepFormContext using useContext hook
  const { compliance, setCompliance, next, prev } = useContext(MultiStepFormContext);
  const [addFormData, setAddFormData] = useState({})
  const [complianceData, setComplianceData] = useState(compliance)
  const [complianceArray, setComplianceArray] = useState([])
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const handleChangeAdd = (e) => {
    if (e.target.name === "quantity" || e.target.name === "unitType") {
      setAddFormData({
        ...addFormData,
        licensedCapacity: {
          ...addFormData.licensedCapacity,
          [e.target.name]: e.target.value.trim(),
        },
      });
    } else {
      setAddFormData({
        ...addFormData,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };
  const handleAddSubmit = (slug) => {
    console.log(addFormData);
    axios.post(`${baseServerUrl}/createcompliance`, addFormData, {
      headers: {
        "Authorization": `${JSON.parse(localStorage.getItem("userInfo")).token}`,
      }
    }).then((response) => {
      console.log(response);
      toast.success("Compliance added successfully");
      next();

    }).catch((error) => {
      console.log(error);
      toast.error("Failed to add Compliance");
    })
  }
  return (
    <form className="bookingForm">
      <ToastContainer />
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-6 pb-3">
                {/* <p>E-Waste-Type</p> */}
                <div className="form-control">
                  <select
                    class="form-input"
                    id="email"
                    name="eWasteType"
                    placeholder="E-Waste-Type"
                    type="text"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.eWasteType}
                    required
                  >
                    <option>E-Waste-Type</option>
                    <option value="Plastic">Plastic Waste</option>
                    <option value="Battery">Battery Waste</option>
                    <option value="Hazardous">Hazardous Waste</option>
                    <option value="Vehicle">Vehicle Waste</option>
                    <option value="Construction">Construction Waste</option>
                  </select>
                  <label className="form-label">
                    E-Waste-Type
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                {/* <p>CPCB/SPCB Registration*</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="CPCB/SPCB Registration"
                    type="text"
                    name="cpcbSpcbRegistration"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.cpcbSpcbRegistration}
                    required
                  />
                             <label className="form-label">
                  CPCB/SPCB Registration
                  </label>
                </div>
              </div>
              <div class="col-md-6 pb-3">
                {/* <p>Location</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Location"
                    type="text"
                    name="location"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.location}
                    required
                  />
                                    <label className="form-label">
                    Location
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <p style={{position:"absolute",zIndex:'99',top:"42%",right:"38%",fontSize:"0.9rem",color:"black",background:"white",padding:"0 10px"}}>Licensed Capacity</p>
                <div className="d-flex bg-white border" style={{height:"53.5px",borderRadius:"0.25rem",padding:"0 5px"}}>
                <div className="form-control" style={{border:"none",outline:"none",width:"69%"}}>
                  <input
                    class="form-input2"
                    id="email"
                    placeholder="Quantity"
                    type="number" // Modified: Changed input type to "number"
                    name="quantity"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.licensedCapacity?.quantity}
                    required
                  />
                </div>
                <div className="form-control" style={{border:"none",outline:"none",borderLeft:"1px solid lightgray",borderRadius:"0",width:"31%"}}>
                  <select // Added: Select element for unit type
                    class="form-input2"
                    id="unitType"
                    name="unitType"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.licensedCapacity?.unitType}
                    required
                  >
                    <option value=""> Type</option>
                    <option value="ton">Ton</option>
                    <option value="kg">Kg</option>
                    <option value="ltr">Ltr</option>
                    <option value="kltr">Kltr</option>
                    <option value="acre">Acre</option>
                  </select>
                </div>
                </div>
              </div>
              <div class="col-md-6 pb-3">
              {/* <p>Licence Registration Number*</p> */}

                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Licence Registration Number"
                    type="text"
                    name="licenseRegistrationNumber"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.licenseRegistrationNumber}
                    required
                  />
                                    <label className="form-label">
                    Licence Registration Number
                  </label>
   
                </div>
              </div>
             
              <div class="col-md-6 pb-3">
                {/* <p>Details of the Licence*</p> */}
                <div className="form-control">
                  <input
                    class="form-input"
                    id="email"
                    placeholder="Details of the Licence"
                    type="text"
                    name="licenseDetails"
                    onChange={(e)=>handleChangeAdd(e)}
                    value={addFormData.licenseDetails}
                    required
                  />
                                    <label className="form-label">
                    Details of the Licence  
                  </label>

                </div>
              </div>
            </div>
          </div>
          <div style={{height:"1px",backgroundColor:"gray",margin:"20px 0",marginTop:"0"}}></div>

          <div
              className={
                "form__item button__items d-flex justify-content-between mt-4"
              }
            >
              {/* Render a "Back" button that moves to the previous step in the form */}
              <Button type={"default"} onClick={prev} size="large" className="border-primary text-primary">
                Back
              </Button>
              {/* Render a "Next" button that submits the form data and moves to the next step in the form */}
              <div className="d-flex gap-2">
              <Button type={"primary"} onClick={handleAddSubmit} size="large">
                Submit & Next
              </Button>
              <Button type={"secondary"} className="border border-primary text-primary" onClick={()=>next()} size="large">
                Skip
              </Button>
              </div>
            </div>

        </form>
  );
};

export default Compliance;
