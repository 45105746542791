import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import forgot from "../../assets/images/forgot.png";
import { Form, Alert } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import BaseService from "../../config/axiosService";
const Reset = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const [formData, setformData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  // get query param of token

  const navigate = useNavigate();
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [errors, setErrors] = useState({});
  const handleChange = (name, value) => {
    setformData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationError = Validation(formData);
    if (Object.keys(validationError).length > 0) {
      setErrors(validationError);
    } else {
      BaseService.post("/api/v2/user/reset-password", {
        token: token,
        password: formData.newPassword,
      })
        .then((res) => {
          toast.success("Password reset successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function Validation(formData) {
    const errors = {};
    if (!formData.newPassword.trim()) {
      errors.newPassword = "New password is required";
    }
    if (!formData.confirmPassword.trim()) {
      errors.confirmPassword = "Confirm password is required";
    }
    if (
      formData.newPassword.trim() &&
      formData.confirmPassword.trim() &&
      formData.newPassword !== formData.confirmPassword
    ) {
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  }

  useEffect(() => {
    if (!token) {
      navigate("/forgot");
    }
  }, [token]);

  return (
    <>
      <Header />
      <div className="forgotPage">
        <section className="page-title p_relative">
          <div className="bg-layer pageTitle"></div>
          <div className="pattern-layer">
            <div className="pattern-1 shape-img-14"></div>
            <div className="pattern-2 shape-img-14"></div>
          </div>
          <div className="auto-container">
            <div className="content-box">
              <h1>Reset Password</h1>
              <ul className="bread-crumb clearfix">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Reset Password</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="login contact-section pricing-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12 col-sm-12 form-column">
                <div className="form-inner shadow">
                  <div className="row clearfix">
                    <div className="titleSec mb_45 text-center">
                      <span className="sub-title lableOrange">
                        Reset Password
                      </span>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 content-column text-center">
                      <img src={forgot} alt="signin" />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 content-column">
                      <div className="shadow p-3 rounded-3">
                        <form
                          id="contact-form"
                          className="default-form"
                          autoComplete="new-password"
                          onSubmit={handleSubmit}
                        >
                          <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <label htmlFor="newPassword">
                                New Password*:
                              </label>
                              <input
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                onChange={(e) =>
                                  handleChange("newPassword", e.target.value)
                                }
                                placeholder="New Password"
                                required
                              />
                              {errors.newPassword && (
                                <span className="text-danger">
                                  {errors.newPassword}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <label htmlFor="confirmPassword">
                                Confirm Password*:
                              </label>
                              <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                onChange={(e) =>
                                  handleChange(
                                    "confirmPassword",
                                    e.target.value
                                  )
                                }
                                placeholder="Confirm Password"
                                required
                              />
                              {errors.confirmPassword && (
                                <span className="text-danger">
                                  {errors.confirmPassword}
                                </span>
                              )}
                            </div>
                            <button
                              className="theme-btn btn-one shadow"
                              type="submit"
                            >
                              <span>Reset Password</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Reset;
