import React from "react";
import { Link } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import "./css/benefit.css";

const BenefitSec = () => {
  return (
    <section className="compliaceBox centred">
      <div className="auto-container">
        <div class="titleSec pb_50 text-center">
          <h3>Legal and Compliances</h3>
          <p>
            When executing the disposal process of any Scrap/ assets/ waste,
            there are certain tax and environmental laws that have to be
            complied with by all concerned parties. Non-compliance may cause
            financial liability to the company and sometimes it may be
            imprisonment to the concerned persons.
          </p>
        </div>
        <Tab.Container id="nested-inner-tabs" defaultActiveKey="key1">
          <div className="row clearfix">
            <div className="col-lg-4 col-md-5 col-sm-12 side1">
              <div className="srvSidebar">
                <div className="widget-content">
                  <Nav variant="pills" className="flex-column ul">
                    <Nav.Item className="li">
                      <Nav.Link eventKey="key1">
                        <span>GST</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="li">
                      <Nav.Link eventKey="key2">
                        <span>Environment and Pollution</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="li">
                      <Nav.Link eventKey="key3">
                        <span>Labour Laws</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="li">
                      <Nav.Link eventKey="key4">
                        <span>Safety and Security</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="li">
                      <Nav.Link eventKey="key5">
                        <span>TDS/TCS</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="li">
                      <Nav.Link eventKey="key6">
                        <span>EPR</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12 col-12">
              <div className="serviceBox">
                <div
                  className="srvInnerBlock mb_35 text-start wow fadeInUp animated tilt-effect"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms"
                >
                  <Tab.Content>
                    <Tab.Pane eventKey="key1" className="row">
                      <div className="inner">
                        <div className="content col-lg-12">
                          <h4 className="pb-3">GST</h4>
                          <p>
                            General Overview on GST Post: 18/05/2024 05:10PM
                            <br />
                            Generally, scrap or disposables assets do not have
                            any proper record, so GST is charged on such items
                            as lumpsum sales. As per GST Act, it is considered
                            as a composite sale and GST is charged at the
                            highest rate of any item included in such composite
                            sale material. Generally, material is dispatched in
                            small lots and every time Material Dispatch Challan
                            is prepared and final bill is prepared at the end of
                            the completion of the work, or it may be at a
                            certain period.
                          </p>
                          <div className="pt_30 text-end">
                            <Link
                              className="theme-btn btn-two"
                              to="/compliance"
                            >
                              <span>View More</span>
                            </Link>
                          </div>
                        </div>
                        <div className="top-circle-shape"></div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="key2">
                      <div className="inner">
                        <div className="content col-lg-12">
                          <h4 className="pb-3">
                            Environment and Pollution Control
                          </h4>
                          <p>
                            Environmental Compliances <br /> All environmental
                            related compliances are done with the Central
                            Pollution Control Board/ State Pollution Control
                            Board (CPCB/SPCB). These boards works under the
                            Ministry of Environment.
                          </p>
                          <div className="pt_30 text-end">
                            <Link
                              className="theme-btn btn-two"
                              to="/compliance"
                            >
                              <span>View More</span>
                            </Link>
                          </div>
                        </div>
                        <div className="top-circle-shape"></div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="key3">
                      <div className="inner">
                        <div className="content col-lg-12">
                          <h4 className="pb-3">Labour Laws</h4>
                          <p>
                            There are various labour laws that need to be
                            followed by Scrap Seller and the Scrap Buyer. The
                            scrap seller has to ensure all labour law-related
                            compliances of the scrap buyer as he becomes the
                            indirect employer of the Labour/Team of the Scrap
                            buyer
                          </p>
                          <div className="pt_30 text-end">
                            <Link
                              className="theme-btn btn-two"
                              to="/compliance"
                            >
                              <span>View More</span>
                            </Link>
                          </div>
                        </div>
                        <div className="top-circle-shape"></div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="key4" className="row">
                      <div className="inner">
                        <div className="content col-lg-12">
                          <h4 className="pb-3">Safety and Security</h4>
                          <p>
                            It has been noticed that at the workplace, there are
                            chances of accidents like fire and blasts, this
                            happened due to non-following of the safety and
                            security rules and guidelines. It is noticed that
                            the workforce does not use safety gear and equipment
                            at the work site which may cause accidents.
                          </p>
                          <div className="pt_30 text-end">
                            <Link
                              className="theme-btn btn-two"
                              to="/compliance"
                            >
                              <span>View More</span>
                            </Link>
                          </div>
                        </div>
                        <div className="top-circle-shape"></div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="key5">
                      <div className="inner">
                        <div className="content col-lg-12">
                          <h4 className="pb-3">TDS/TCS</h4>
                          <p>
                            Tax Collected at Source (TCS) - At the time of
                            disposing of Scrap/residue few provisions of TCS and
                            TDS applies. U/s …. Of the income tax act defines
                            provisions for TCS. The Rate of TCS is ….. Tax
                            Deducted at Source (TDS) – There are some high value
                            transactions on which provisions of TDS applies.
                            Section ….. of the Income Tax Act defines provisins
                            of the TDS. The rate of TDS is…..
                          </p>
                          <div className="pt_30 text-end">
                            <Link
                              className="theme-btn btn-two"
                              to="/compliance"
                            >
                              <span>View More</span>
                            </Link>
                          </div>
                        </div>
                        <div className="top-circle-shape"></div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="key6">
                      <div className="inner">
                        <div className="content col-lg-12">
                          <h4 className="pb-3">EPR</h4>
                          <p>
                            Enterprise Producer Responsibility (EPR) <br/> Extended
                            Producer Responsibility (EPR) mandates that every
                            producer of Electrical and Electronic Equipment
                            (EEE) ensures proper management of e-waste. This
                            involves directing e-waste to authorized
                            dismantlers/recyclers for safe handling and
                            disposal. EPR Compliance for E-Waste in India
                            requires the implementation of take-back systems and
                            the establishment of collection centers. Producers,
                            including importers, e-retailers, and online
                            sellers, must obtain EPR authorization as per the
                            E-Waste (Management) Rules, 2016. This includes
                            applying for EPR Compliance, which can be
                            facilitated by EPR Experts familiar with the process
                          </p>
                          <div className="pt_30 text-end">
                            <Link
                              className="theme-btn btn-two"
                              to="/compliance"
                            >
                              <span>View More</span>
                            </Link>
                          </div>
                        </div>
                        <div className="top-circle-shape"></div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </div>
          </div>
        </Tab.Container>
      </div>
    </section>
  );
};
export default BenefitSec;
