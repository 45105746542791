import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './gallery.css';

import img1 from '../../assets/images/gallery/images-1.webp';
import img2 from '../../assets/images/gallery/images-2.webp';
import img3 from '../../assets/images/gallery/images-3.webp';
import img4 from '../../assets/images/gallery/images-4.webp';
import img5 from '../../assets/images/gallery/images-5.webp';
import img6 from '../../assets/images/gallery/images-6.webp';
import img7 from '../../assets/images/gallery/images-7.webp';
import img8 from '../../assets/images/gallery/images-8.webp';
import img9 from '../../assets/images/gallery/images-9.webp';
import Header from '../../components/header';
import Footer from '../../components/footer';
const Gallery = () => {
    const [selectedFilter, setSelectedFilter] = useState('all');
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
    };
    const [getcategory, setCategory] = useState([]);
    const [getgallery, setGallery] = useState([]);
    const [perPage] = useState(3);
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const handlePage = (key) =>{
        setSkip('');
        setSkip(key-1);
    }
    const handlePageNext = () =>{
        if (pageCount > skip) {
            setSkip(skip + 1)
          }
    }
    const handlePagePrevious = () => {
          if (skip >= 1) {
            setSkip(skip - 1)
          }
    }
    const fetchGallery = async () => {
        await axios.post(`${baseServerUrl}/frontgallery`, { page: perPage, skip: skip }).then((resp) => {    
        setGallery(resp.data.data);
        setPageCount(Math.ceil(resp.data.count / perPage));
        });
    }
    const fetchCategory = async () => {
        await axios.get(`${baseServerUrl}/allcategory`).then((respcat) => {    
        setCategory(respcat.data.data);
        });
    }
    useEffect(() => {
        fetchCategory();
        fetchGallery();
    }, [skip, setCategory, setGallery])
    const projectItems = [
        {
            filter: ['all', 'scrap_material'],
            imageSrc: img1,
            title: 'Factory Material Scrap',
            description: 'Waste Pickup',
        },
        {
            filter: ['all', 'material'],
            imageSrc: img2,
            title: 'Factory Material Scrap',
            description: 'Waste Pickup',
        },
        {
            filter: ['all', 'scrap_material'],
            imageSrc: img3,
            title: 'Factory Material Scrap',
            description: 'Waste Pickup',
        },
        {
            filter: ['all', 'material'],
            imageSrc: img4,
            title: 'Factory Material Scrap',
            description: 'Waste Pickup',
        },
        {
            filter: ['all', 'scrap_metal'],
            imageSrc: img5,
            title: 'Factory Material Scrap',
            description: 'Waste Pickup',
        },
        {
            filter: ['all', 'material'],
            imageSrc: img6,
            title: 'Factory Material Scrap',
            description: 'Waste Pickup',
        },
        {
            filter: ['all', 'scrap_metal'],
            imageSrc: img7,
            title: 'Factory Material Scrap',
            description: 'Waste Pickup',
        },
        {
            filter: ['all', 'material'],
            imageSrc: img8,
            title: 'Factory Material Scrap',
            description: 'Waste Pickup',
        },
        {
            filter: ['all', 'material'],
            imageSrc: img9,
            title: 'Factory Material Scrap',
            description: 'Waste Pickup',
        },
    ];

    return (
        <>
        <Header />
        <div>
            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Media</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="project-style-two">
                <div className="auto-container">
                    <div className="sortable-masonry">
                        <div className="filters centred mb_60">
                            <ul className="filter-tabs filter-btns clearfix">
                                <li
                                    className={`filter ${selectedFilter === 'all' ? 'active' : ''}`}
                                    data-role="button"
                                    onClick={() => handleFilterClick('all')}
                                    data-filter=".all"
                                >
                                    All Works
                                </li>
                                { getcategory.map((categoryItem, index) => {
                                    return(
                                        <li
                                            key={index}
                                            className={`filter ${selectedFilter === categoryItem.name ? 'active' : ''}`}
                                            data-role="button"
                                            onClick={() => handleFilterClick(categoryItem.name)}
                                            data-filter={'.'+categoryItem.name}
                                        >
                                            {categoryItem.name}
                                        </li>     
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="items-container row clearfix">
                            {getgallery.map((item, index) => {
                                let filter = ['all', item.categoryId.name ]
                                if (filter.includes(selectedFilter) || selectedFilter === 'all') {
                                    return (
                                        <div
                                            key={index}
                                            className={`col-lg-4 col-md-6 col-sm-6 col-12 animate__animated animate__fadeIn masonry-item small-column ${filter.join(
                                                ' '
                                            )}`}
                                        >
                                            <div className="project-block-two">
                                                <div className="inner-box">
                                                    <figure className="image-box">
                                                        <img src={item.image} alt="images" style={{'height': '250px'}}/>
                                                    </figure>
                                                    <div className="content-box">
                                                        {/* <p>{item.description}</p> */}
                                                        <h4>{item.title}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null; // Hide the item if it doesn't match the selected filter
                                }
                            })}

                            {/* <div className="pagination-wrapper centred pt_50">
                                <ul className="pagination clearfix">
                                    <li><a href="#!"><i className="far fa-angle-left"></i></a></li>
                                    <li><a href="#!" className="current">1</a></li>
                                    <li><a href="#!">2</a></li>
                                    <li><a href="#!"><i className="far fa-angle-right"></i></a></li>
                                </ul>
                            </div> */}
                        </div>

                    </div>
                </div>
            </section>
        </div>
    <Footer />
    </>
    )
}
export default Gallery;