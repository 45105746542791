import React, { useEffect, useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import { ToastContainer } from "react-toastify";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, Toast } from "react-bootstrap";
// import "./customSideBarCSS.scss"

const Sidebar = () => {
  const navigate = useNavigate();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [teamMenu, setTeamMenu] = useState(false);

  let adminApproval = false;
  if (localStorage.getItem("userInfo")) {
    adminApproval = JSON.parse(localStorage.getItem("userInfo")).adminApproval;
  }

  const fillLater = localStorage.getItem("fillLater");
  const [profileSubmMenu, setProfileSubmMenu] = useState(
    JSON.parse(localStorage.getItem("pSubMenu"))?.pSubMenu || false
  );
  const [documentSubMenu, setDocumentSubMenu] = useState(false);
  const [auctionSubMenu, setAuctionSubMenu] = useState(false);

  const handleNavLinkClick = (route, canAccess, closeSub) => {
    if (closeSub) {
      setProfileSubmMenu(false);
      localStorage.setItem("pSubMenu", JSON.stringify({ pSubMenu: false }));
    }
    if (canAccess) {
      navigate(route);
      return;
    }
    if (!adminApproval) {
      navigate(route);
      return;
    } else {
      navigate(route);
    }
  };
  const handleSubMenuClick = (route) => {
    let pSubMenu =
      JSON.parse(localStorage.getItem("pSubMenu"))?.pSubMenu || false;
    if (profileSubmMenu && pSubMenu) {
      setProfileSubmMenu(false);
      localStorage.setItem("pSubMenu", JSON.stringify({ pSubMenu: false }));
    } else {
      setProfileSubmMenu(true);
      localStorage.setItem("pSubMenu", JSON.stringify({ pSubMenu: true }));
    }
  };
  const handleFillLater = () => {
    // Handle "Fill Later" action
    console.log("Fill Later clicked");
    // Close the toast after clicking the button
    toast("Fill Later clicked");
  };

  const handleCompleteProfile = () => {
    // Handle "Complete your Profile" action
    console.log("Complete your Profile clicked");
    // Close the toast after clicking the button
    toast("Complete your Profile clicked");
  };

  const showAdminApprovalToast = () => {
    toast.custom(
      <div className="custom-toast">
        <p className="toast-header">Admin Approval Required</p>
        <p className="toast-body">
          You need admin approval to access this feature. Please contact the
          admin.
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (!adminApproval && !fillLater) {
      setShowProfileModal(true);
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          height: "inherit",
          width: "100%",
          overflow: "scroll initial",
        }}
      >
        <CDBSidebar textColor="#000" backgroundColor="#fff">
          <CDBSidebarHeader>Vendor Panel</CDBSidebarHeader>

          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <li
                onClick={() =>
                  handleNavLinkClick("/buyer-dashboard", true, true)
                }
              >
                <CDBSidebarMenuItem icon="tachometer">
                  Dashboard
                </CDBSidebarMenuItem>
              </li>
              <li onClick={() => setAuctionSubMenu((prev) => !prev)}>
                <CDBSidebarMenuItem icon="info-circle">
                  Projects{" "}
                  {auctionSubMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </CDBSidebarMenuItem>
              </li>
              {auctionSubMenu ? (
                <>
                  <li
                    onClick={() =>
                      handleNavLinkClick("/new-offering", false, true)
                    }
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      New Offerings
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() =>
                      handleNavLinkClick("/my-projects", false, true)
                    }
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      My Projects
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                   onClick={() =>
                    handleNavLinkClick("/my-projects", false, true)
                  }
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Update Status
                    </CDBSidebarMenuItem>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li
                onClick={() =>
                  handleNavLinkClick("/buyer-eauction", false, true)
                }
              >
                <CDBSidebarMenuItem icon="gavel">eAuctions</CDBSidebarMenuItem>
              </li>

              <li onClick={() => handleSubMenuClick("/buyer-profile")}>
                <CDBSidebarMenuItem icon="user">
                  Profile{" "}
                  {profileSubmMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </CDBSidebarMenuItem>
              </li>
              {profileSubmMenu ? (
                <>
                  <li
                    onClick={() => handleNavLinkClick("/buyer-profile", true)}
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      General
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() =>
                      handleNavLinkClick("/buyer-compliance", true)
                    }
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Compliance
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() =>
                      handleNavLinkClick("/buyer-work-experience", true)
                    }
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Work Experience
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() => handleNavLinkClick("/buyer-financial", true)}
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Financial
                    </CDBSidebarMenuItem>
                  </li>
                  <li onClick={() => handleNavLinkClick("/buyer-bank", true)}>
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Bank
                    </CDBSidebarMenuItem>
                  </li>
                  <li onClick={() => handleNavLinkClick("/branches", true)}>
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Address Book
                    </CDBSidebarMenuItem>
                  </li>
                  <li onClick={() => handleNavLinkClick("/buyer-others", true)}>
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Project Handling
                    </CDBSidebarMenuItem>
                  </li>
                  <li onClick={() => handleNavLinkClick("/buyer-profile-docs")}>
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      Profile Docs
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() =>
                      handleNavLinkClick("/buyer-login-details", true, true)
                    }
                  >
                    <CDBSidebarMenuItem className="ps-4" icon="angle-right">
                      User Details
                    </CDBSidebarMenuItem>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li onClick={() => setTeamMenu((prev) => !prev)}>
                <CDBSidebarMenuItem icon="users">
                  Team {teamMenu ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </CDBSidebarMenuItem>
              </li>
              {teamMenu ? (
                <>
                    <li onClick={() => handleNavLinkClick("/buyer-staff", false, true)}>
                    <CDBSidebarMenuItem icon="angle-right">
                      View Team Members
                    </CDBSidebarMenuItem>
                  </li>
                  <li onClick={() => handleNavLinkClick("/buyer-staff", false, true)}>
                    <CDBSidebarMenuItem icon="angle-right">
                      Add New Member
                    </CDBSidebarMenuItem>
                  </li>
                  <li
                    onClick={() =>
                      handleNavLinkClick("/staff-role", false, true)
                    }
                  >
                    <CDBSidebarMenuItem icon="angle-right">
                      Assign Role
                    </CDBSidebarMenuItem>
                  </li>
                
                </>
              ) : (
                <></>
              )}
              <li className="text-white ps-3 pt-2">
                <h6 className="text-white">For Feedback</h6>
                <p
                  style={{
                    fontSize: "13px",
                  }}
                  className="text-white"
                >
                  Email: feedback@getmaxvalue.in
                </p>
                <p
                  style={{
                    fontSize: "13px",
                  }}
                  className="text-white"
                >
                  Contact: 9313010417
                </p>
              </li> 
            </CDBSidebarMenu>
          </CDBSidebarContent>

          {/* <CDBSidebarFooter style={{ textAlign: 'center' }}>
  <div
    style={{
      padding: '20px 5px',
    }}
  >
    Sidebar Footer
  </div>
</CDBSidebarFooter>  */}
        </CDBSidebar>
      </div>
      <ToastContainer />
      <Modal show={showProfileModal} animation={false}>
        <Modal.Header>
          <Modal.Title>Profile Not Completed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please Complete your Profile or you can fill it later
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowProfileModal(false);
              localStorage.setItem("fillLater", "true");
            }}
          >
            Fill Later
          </Button>
          <Button style={{
            background: "#2A3FA0",
            border: "none",
          }} variant="primary" onClick={() => {
            setShowProfileModal(false);
            localStorage.setItem("fillLater", "true");
            navigate("/buyer-profile");
          }}>
            Complete your Profile
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Sidebar;
