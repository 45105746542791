import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Sidebar/Copyright";
import { Button, Row, Col, Modal } from "react-bootstrap";
import Topbar from "../../components/Sidebar/Topbar";
import uploadFiles from "../../helpers/uploadFile";
import BaseService from "../../config/axiosService";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Switch } from "antd";

const CreateProject = () => {
  const nav = useNavigate();
  const [option3, setOption3] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [show, setShow] = useState(false);
  const [teamShow, setTeamShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [addressData, setAddressData] = useState([]);
  const [projectManagers, setProjectManagers] = useState([]);

  const [loading, setLoading] = useState(false);
  // project form data
  const baseServerUrl = process.env.REACT_APP_baseServerURL;
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [projectName, setProjectName] = useState("");
  const [servicesDetails, setServicesDetails] = useState({});
  const [openingDateTime, setOpeningDateTime] = useState("");
  const [closingDateTime, setClosingDateTime] = useState("");
  const [items, setItems] = useState("");
  const [estimatedValue, setEstimatedValue] = useState("");
  const [materialCategory, setMaterialCategory] = useState("");
  const handleServiceDetailChange = (serviceId, detailType, value) => {
    setServicesDetails({
      ...servicesDetails,
      [serviceId]: {
        ...servicesDetails[serviceId],
        [detailType]: value,
      },
    });
  };
  const [services, setServices] = useState([]);
  const [roles, setRoles] = useState([]);
  const [serviceFiles, setServiceFiles] = useState({});

  const [teamMember, setTeamMember] = useState({
    username: "",
    email: "",
    phone: "",
    location: "",
    designation: "",
    department: "",
    rolesAndPermissions: "",
    password: "",
    isProjectManager: false,
  });

  const [errors, setErrors] = useState({
    username: "",
    email: "",
    phone: "",
    location: "",
    designation: "",
    department: "",
    role: "",
    password: "",
  });

  const fetchRoles = async () => {
    try {
      const response = await BaseService.get("/api/v2/roles");
      setRoles(response.data.data);
    } catch (error) {
      console.error("Error fetching roles: ", error);
    }
  };

  const [addFormData, setAddFormData] = useState({});
  const [others, setOthers] = useState("");
  const handleChange = (e) => {
    // put strict validation on every field

    if (e.target.name === "phone") {
      if (e.target.value.length > 10) {
        return;
      }
    }
    if (e.target.name === "email") {
      if (e.target.value.length > 50) {
        return;
      }
    }
    if (e.target.name === "username") {
      if (e.target.value.length > 50) {
        return;
      }
    }
    if (e.target.name === "location") {
      if (e.target.value.length > 50) {
        return;
      }
    }
    if (e.target.name === "designation") {
      if (e.target.value.length > 50) {
        return;
      }
    }
    if (e.target.name === "department") {
      if (e.target.value.length > 50) {
        return;
      }
    }
    if (e.target.name === "password") {
      if (e.target.value.length > 50) {
        return;
      }
    }

    const { name, value } = e.target;
    setTeamMember((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleAddSubmit = (slug) => {
    axios
      .post(`${baseServerUrl}/createaddressbook`, addFormData, {
        headers: {
          Authorization: `${userInfo.token}`,
        },
      })
      .then((response) => {
        if (!slug) {
          handleClose();
        }
        setAddFormData({
          GSTNumber: "",
          locationName: "",
          pincode: "",
          state: "",
          district: "",
          addressLine1: "",
          addressLine2: "",
          authorisedPerson: {
            name: "",
            phone: "",
            email: "",
          },
        });
        console.log(response.data);
        toast.success("Address Book Added Successfully");
        fetchAddress();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Address Book Not Added");
      });
  };

  const handleAddChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    if (
      fieldName === "name" ||
      fieldName === "phone" ||
      fieldName === "email"
    ) {
      setAddFormData({
        ...addFormData,
        authorisedPerson: {
          ...addFormData.authorisedPerson,
          [fieldName]: e.target.value,
        },
      });
      return;
    }
    const fieldValue = e.target.value;

    if (fieldName === "GSTNumber") {
      const gstRegex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
      if (fieldValue.length > 15) {
        return;
      }
    } else if (fieldName === "locationName") {
      if (fieldValue.length > 50) {
        return;
      }
    } else if (fieldName === "pincode") {
      if (fieldValue.length > 6) {
        return;
      }
    } else if (fieldName === "state") {
      if (fieldValue.length > 50) {
        return;
      }
    } else if (fieldName === "district") {
      if (fieldValue.length > 50) {
        return;
      }
    } else if (fieldName === "addressLine1") {
      if (fieldValue.length > 100) {
        return;
      }
    } else if (fieldName === "addressLine2") {
      if (fieldValue.length > 100) {
        return;
      }
    }

    setAddFormData({
      ...addFormData,
      [fieldName]: fieldValue,
    });
  };

  const fetchAddress = async () => {
    try {
      const response = await BaseService.get("/getaddressbookbyuserid");
      setAddressData(response.data.addressBooks);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectManagers = async () => {
    try {
      const response = await BaseService.get("/api/v2/team/project-managers");
      setProjectManagers(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (serviceId, fileType, files) => {
    setServiceFiles({
      ...serviceFiles,
      [serviceId]: {
        ...serviceFiles[serviceId],
        [fileType]: files,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const uploadFilesForService = async (files) => {
      const fileArray = Array.from(files);
      const urls = await uploadFiles(fileArray);
      return urls;
    };

    const servicesDetailsArray = await Promise.all(
      selectedServices.map(async (serviceId) => {
        const serviceDetail = servicesDetails[serviceId] || {};
        const fileUploads = serviceFiles[serviceId] || {};

        const documents = fileUploads.documents
          ? await uploadFilesForService(fileUploads.documents)
          : [];
        const medias = fileUploads.medias
          ? await uploadFilesForService(fileUploads.medias)
          : [];

        return {
          serviceId,
          serviceDescription: serviceDetail.serviceDescription || "",
          documents,
          medias,
        };
      })
    );

    if (!servicesDetailsArray.length) {
      toast.error("Please select at least one service");
      setLoading(false);
      return;
    }

    if (!location) {
      toast.error("Please select a location");
      setLoading(false);
      return;
    }

    if (!description) {
      toast.error("Please enter a description");
      setLoading(false);
      return;
    }

    if (!projectManager) {
      toast.error("Please select a project manager");
      setLoading(false);
      return;
    }

    if (!projectName) {
      toast.error("Please enter a project name");
      setLoading(false);
      return;
    }

    if (!openingDateTime) {
      toast.error("Please enter opening date time");
      setLoading(false);
      return;
    }

    if (!closingDateTime) {
      toast.error("Please enter closing date time");
      setLoading(false);
      return;
    }

    if (!items) {
      toast.error("Please enter items");
      setLoading(false);
      return;
    }

    if (!materialCategory) {
      toast.error("Please enter material category");
      setLoading(false);
      return;
    }

    // closing date time should be greater than opening date time

    if (new Date(closingDateTime) < new Date(openingDateTime)) {
      toast.error("Closing date time should be greater than opening date time");
      setLoading(false);
      return;
    }

    const data = {
      location,
      description,
      projectManager,
      projectName,
      servicesDetails: servicesDetailsArray,
      others: option3 ? others : "",
      closing: closingDateTime,
      openning: openingDateTime,
      items,
      estimatedValue,
      materialCategory,
    };

    BaseService.post("/api/v2/project", data)
      .then((response) => {
        console.log(response);
        nav("/project-status");
        toast.success("Project added successfully");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitTeam = (e) => {
    e.preventDefault();

    // Validate form fields
    let validationErrors = {};
    if (!teamMember.username) {
      validationErrors.username = "Username is required";
    }
    if (!teamMember.email) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(teamMember.email)) {
      validationErrors.email = "Invalid email address";
    }
    if (!teamMember.phone) {
      validationErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(teamMember.phone)) {
      validationErrors.phone = "Phone number must be 10 digits";
    }
    if (!teamMember.location) {
      validationErrors.location = "Location is required";
    }
    if (!teamMember.designation) {
      validationErrors.designation = "Designation is required";
    }
    if (!teamMember.department) {
      validationErrors.department = "Department is required";
    }
    if (!teamMember.rolesAndPermissions) {
      validationErrors.role = "Role is required";
    }
    if (!teamMember.password) {
      validationErrors.password = "Password is required";
    } else if (teamMember.password.length < 6) {
      validationErrors.password = "Password must be at least 6 characters long";
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, set them and prevent form submission
      setErrors(validationErrors);
    } else {
      BaseService.post("/api/v2/team", teamMember)
        .then((res) => {
          toast.success("Team member added successfully");
          setTeamShow(false);
          fetchProjectManagers();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchServices = () => {
    try {
      BaseService.get("/getuserservice")
        .then((res) => {
          setServices(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  useEffect(() => {
    fetchAddress();
    fetchProjectManagers();
    fetchServices();
    fetchRoles();
  }, []);

  return (
    <div>
      <Topbar />
      <Row>
        <Col
          lg="2"
          md="2"
          sm="4"
          style={{ background: "#2B3FA1", paddingRight: "0" }}
        >
          <Sidebar />
        </Col>
        <Col
          lg="10"
          md="10"
          sm="8"
          style={{
            background: "#F5F6FA",
            minHeight: "100vh",
            overflowY: "scroll",
          }}
        >
          <div
            className="p-3"
            style={{
              height: "100vh",
              overflowY: "scroll",
            }}
          >
            <h4>Create New Project</h4>
            <hr />
            <form className="bookingForm" onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="row mt-4">
                  <div className="col-md-6 pb-3">
                    <label htmlFor="location"> Location</label>
                    <select
                      style={{
                        marginBottom: "0",
                      }}
                      className="form-control"
                      id="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      required
                    >
                      <option value="" selected disabled>
                        {" "}
                        Select Location
                      </option>
                      {addressData.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.locationName}
                        </option>
                      ))}
                    </select>
                    <span>
                      If not in the list,{" "}
                      <a
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={handleShow}
                      >
                        click here
                      </a>{" "}
                      to add new
                    </span>
                  </div>
                  <div className="col-md-6 pb-3">
                    <label htmlFor="projectManager">Project Manager</label>
                    <select
                      style={{
                        marginBottom: "0",
                      }}
                      className="form-control"
                      id="projectManager"
                      value={projectManager}
                      onChange={(e) => setProjectManager(e.target.value)}
                      required
                    >
                      <option value="">Project Manager</option>
                      {projectManagers.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.username} / {item.email} / {item.phone}
                        </option>
                      ))}
                    </select>
                    <span>
                      If not in the list,{" "}
                      <a
                        role="button"
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setTeamShow(true);
                        }}
                      >
                        click here
                      </a>{" "}
                      to add new
                    </span>
                  </div>
                  <div className="col-md-12 pb-3">
                    <label htmlFor="project-name">Project Name</label>
                    <input
                      className="form-control"
                      onChange={(e) => setProjectName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12 pb-3">
                    <label htmlFor="description">
                      Detailed Description of the Project
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={12}
                      required
                    ></textarea>
                  </div>
                  {/* add these three field items,material category, openning and close date time */}
                  <div className="row">
                    <div className="col-md-4 pb-3">
                      <label htmlFor="openingDateTime">Opening Date Time</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="openingDateTime"
                        onChange={(e) => setOpeningDateTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-4 pb-3">
                      <label htmlFor="closingDateTime">Closing Date Time</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="closingDateTime"
                        onChange={(e) => setClosingDateTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-4 pb-3">
                      <label htmlFor="estimatedValue">
                        Estimated Value (Rs.)
                      </label>
                      <input
                        type="number"
                        min={1}
                        className="form-control"
                        id="estimatedValue"
                        onChange={(e) => {
                          // only allow numbers
                          const value = e.target.value;
                          if (isNaN(value)) {
                            return;
                          }
                          setEstimatedValue(value);
                        }}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 pb-3">
                      <label htmlFor="items">Items</label>
                      <input
                        type="text"
                        className="form-control"
                        id="items"
                        onChange={(e) => setItems(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6 pb-3">
                      <label htmlFor="materialCategory">
                        Material Category
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="materialCategory"
                        onChange={(e) => setMaterialCategory(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-12 pb-3">
                    <label htmlFor="">
                      Select the services you are looking for
                    </label>
                    {services.map((service) => {
                      return (
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={() => {
                              if (selectedServices.includes(service._id)) {
                                setSelectedServices(
                                  selectedServices.filter(
                                    (id) => id !== service._id
                                  )
                                );
                              } else {
                                setSelectedServices([
                                  ...selectedServices,
                                  service._id,
                                ]);
                              }
                            }}
                            id={service._id}
                            value="valuation"
                          />
                          <label
                            className="form-check-label"
                            htmlFor={service._id}
                          >
                            {service.title}
                          </label>
                        </div>
                      );
                    })}
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => setOption3(!option3)}
                        id="others"
                        value="others"
                      />
                      <label htmlFor="others">Others</label>
                    </div>
                  </div>
                  <div className="col-md-12 pb-3">
                    <label htmlFor="">Service Looking for</label>
                    {selectedServices.length == 0 && !option3 && (
                      <p className="border bg-white p-2 rounded-2">
                        Please select at least one service from above, (If your
                        isn't listed above please click on others)
                      </p>
                    )}
                    {selectedServices.map((serviceId) => {
                      const service = services.find((s) => s._id === serviceId);
                      return (
                        <div
                          key={serviceId}
                          className="border d-flex flex-column gap-2 align-items-start p-3 rounded-2 mb-2"
                        >
                          <label className="fs-6">{service.title}</label>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: service.description,
                            }}
                          ></div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Please Describe your requirment here."
                            onChange={(e) =>
                              handleServiceDetailChange(
                                serviceId,
                                "serviceDescription",
                                e.target.value
                              )
                            }
                          />
                          <label>{service.title} (Documents)</label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={(e) =>
                              handleFileChange(
                                serviceId,
                                "documents",
                                e.target.files
                              )
                            }
                          />
                          <label>{service.title} (Images/Videos)</label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={(e) =>
                              handleFileChange(
                                serviceId,
                                "medias",
                                e.target.files
                              )
                            }
                          />
                        </div>
                      );
                    })}

                    {option3 && (
                      <div className="border d-flex flex-column gap-2 align-items-start p-3 rounded-2 mb-2">
                        <label className="fs-6">Others</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Please Describe your service here."
                          onChange={(e) => setOthers(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary"
                style={{
                  backgroundColor: "#2A3FA0",
                  border: "none",
                  width: "200px",
                }}
                type="submit"
              >
                {loading ? <LoadingOutlined /> : "Save and Exit"}
              </button>{" "}
              <Link to="/project-status">
                <button
                  className="btn btn-primary"
                  style={{
                    color: "#2A3FA0",
                    background: "none",
                    border: "1px solid #2A3FA0",
                    width: "200px",
                  }}
                >
                  {"Exit"}
                </button>
              </Link>
            </form>
          </div>
        </Col>
      </Row>
      <Footer />
      <Modal show={show} centered size="xl">
        <Modal.Header closeButton onHide={handleClose} className="text-white">
          <Modal.Title className="text-black" as="h6">
            Add New Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="bookingForm">
            <div class="card-body">
              <div class="row mt-4">
                <div className="row">
                  <div class="col-md-6">
                    {/* <p>GST Number</p> */}
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="gstNumber"
                        placeholder="GST Number"
                        type="text"
                        name="GSTNumber"
                        onChange={handleAddChange}
                        value={addFormData.GSTNumber}
                        required
                      />
                      <label className="form-label">GST Number</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-md-6">
                    {/* <p>Location Name</p> */}
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="locationName"
                        placeholder="Location Name"
                        name="locationName"
                        onChange={handleAddChange}
                        value={addFormData.locationName}
                        type="text"
                        required
                      />
                      <label className="form-label">Location Name</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    {/* <p>Pincode</p> */}
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="pincode"
                        name="pincode"
                        onChange={handleAddChange}
                        value={addFormData.pincode}
                        placeholder="Pincode"
                        type="number"
                        required
                      />
                      <label className="form-label">Pincode</label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    {/* <p>State</p> */}
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="state"
                        placeholder="State"
                        name="state"
                        onChange={handleAddChange}
                        value={addFormData.state}
                        type="text"
                        required
                      />
                      <label className="form-label">State</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    {/* <p>District</p> */}
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="district"
                        placeholder="District"
                        name="district"
                        value={addFormData.district}
                        onChange={handleAddChange}
                        type="text"
                        required
                      />
                      <label className="form-label">District</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    {/* <p>Address Line 1</p> */}
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="addressLine1"
                        placeholder="Address Line 1"
                        name="addressLine1"
                        onChange={handleAddChange}
                        value={addFormData.addressLine1}
                        type="text"
                        required
                      />
                      <label className="form-label">Address Line 1</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    {/* <p>Address Line 2</p> */}
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="addressLine2"
                        name="addressLine2"
                        onChange={handleAddChange}
                        placeholder="Address Line 2"
                        value={addFormData.addressLine2}
                        type="text"
                        required
                      />
                      <label className="form-label">Address Line 2</label>
                    </div>
                  </div>
                  <h5>Authorised Person</h5>

                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "gray",
                      margin: "20px 0",
                      marginTop: "0",
                    }}
                  ></div>

                  <div class="col-md-4">
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="authorisedPerson"
                        placeholder="Authorised Person"
                        name="name"
                        onChange={handleAddChange}
                        value={addFormData.authorisedPerson?.name}
                        type="text"
                        required
                      />
                      <label className="form-label">Name</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    {/* <p>Phone</p> */}
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="phone"
                        placeholder="Phone Number"
                        name="phone"
                        onChange={handleAddChange}
                        value={addFormData.authorisedPerson?.phone}
                        type="number"
                        required
                      />
                      <label className="form-label">Phone Number</label>
                    </div>
                  </div>
                  <div class="col-md-4">
                    {/* <p>Email</p> */}
                    <div className="form-control">
                      <input
                        class="form-input"
                        id="email"
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={handleAddChange}
                        value={addFormData.authorisedPerson?.email}
                        required
                      />
                      <label className="form-label">Email</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                margin: "20px 0",
              }}
            ></div>

            <Button
              className="btn btn-sm"
              color="#5C79FF"
              onClick={() => handleAddSubmit()}
              style={{ backgroundColor: "#E2E7FF", color: "#5C79FF" }}
            >
              Save & Exit
            </Button>
            {"   "}
            <Button
              className="btn btn-primary btn-sm" // Modified: Changed button color to primary
              onClick={() => handleAddSubmit(true)}
              style={{ backgroundColor: "#5C79FF" }}
            >
              Save & Add More Address
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={teamShow} centered size="lg">
        <Modal.Header
          closeButton
          onHide={() => {
            setTeamShow(false);
          }}
          className=""
        >
          <Modal.Title as="h6">Add Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitTeam}>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="name">Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter name"
                    name="username"
                    onChange={handleChange}
                  />
                  {errors.username && (
                    <p className="text-danger">{errors.username}</p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="email">Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <p className="text-danger">{errors.email}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="phone">Phone Number:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Enter phone number"
                    name="phone"
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <p className="text-danger">{errors.phone}</p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="location">Location:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    placeholder="Enter location"
                    name="location"
                    onChange={handleChange}
                  />
                  {errors.location && (
                    <p className="text-danger">{errors.location}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="designation">Designation:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="designation"
                    placeholder="Enter designation"
                    name="designation"
                    onChange={handleChange}
                  />
                  {errors.designation && (
                    <p className="text-danger">{errors.designation}</p>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="department">Department:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="department"
                    placeholder="Enter department"
                    name="department"
                    onChange={handleChange}
                  />
                  {errors.department && (
                    <p className="text-danger">{errors.department}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="role">Role:</label>
                  <select
                    className="form-control"
                    id="rolesAndPermissions"
                    name="rolesAndPermissions"
                    placeholder="Select Role"
                    onChange={handleChange}
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option value={role._id}>{role.name}</option>
                    ))}
                  </select>
                  {errors.role && <p className="text-danger">{errors.role}</p>}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label for="reporting">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Password"
                    placeholder="Enter Password"
                    name="password"
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <p className="text-danger">{errors.password}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="border rounded-3 p-3 d-flex align-items-center gap-3">
                <p>
                  {teamMember.isProjectManager
                    ? "Project Manager"
                    : "Team Member"}
                </p>
                <Switch
                  onChange={(checked) => {
                    setTeamMember((prev) => ({
                      ...prev,
                      isProjectManager: checked,
                    }));
                  }}
                />
              </Col>
            </Row>
            <button
              type="submit"
              className="btn text-white me-2 mt-4"
              style={{ background: "#2A3FA0" }}
            >
              Save
            </button>
            <button
              onClick={() => {
                setTeamShow(false);
              }}
              className="btn border mt-4"
            >
              Close
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateProject;
