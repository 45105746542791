import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import './index.css';
import ReactShowMoreText from "react-show-more-text";
import Header from '../../components/header';
import Footer from '../../components/footer';
const ScrapList = () => {
    const parse = require('html-react-parser');
    const [scraplist, setscrapList] = useState([]);
    const [scrapcatlist, setscrapcatList] = useState([]);
    const [perPage] = useState(3);
    const baseServerUrl = process.env.REACT_APP_baseServerURL;
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [filterValues, setfilterValues] = useState({
        categoryID: '',
        search: '',
      })
    const [categoryfilter, setcategoryFilter] = useState('')
    const handlePage = (key) =>{
        setSkip('');
        setSkip(key-1);
    }
    const handlePageNext = () =>{
        if (pageCount > skip) {
            setSkip(skip + 1)
          }
    }
    const handlePagePrevious = () => {
          if (skip >= 1) {
            setSkip(skip - 1)
          }
    }
    const handleChanageFilter = (name, value) => {
        setfilterValues((prev) => ({
          ...prev,
          [name]: value,
        }))
      }
    const handlechangeCategory = (id) => {
        setcategoryFilter(id);
    }
    const fetchScrap = async () => {
        await axios.post(`${baseServerUrl}/projectfrontpage`, 
        { 
            search: filterValues.search, 
            categoryID: categoryfilter, 
            page: perPage, 
            skip: skip 
        }).then((resp) =>{
            setscrapList(resp.data.data);
            setPageCount(Math.ceil(resp.data.count / perPage));
        });
    }
    const fetchScrapCat = async () => {
        await axios.get(`${baseServerUrl}/getfrontcategory`).then((respcat) =>{
            setscrapcatList(respcat.data.data);
        });
    }
    useEffect(() => {
        fetchScrap();
        fetchScrapCat();
    }, [skip, filterValues, categoryfilter])

    // const [minPrice, setMinPrice] = useState(0);
    // const [maxPrice, setMaxPrice] = useState(1000);

    // const handleMinPriceChange = (event) => {
    //     setMinPrice(event.target.value);
    // };

    // const handleMaxPriceChange = (event) => {
    //     setMaxPrice(event.target.value);
    // };
    return (
        <>
        <Header />
        <div className="boxed_wrapper ">

            <section className="page-title p_relative">
                <div className="bg-layer pageTitle"></div>
                <div className="pattern-layer">
                    <div className="pattern-1 shape-img-14"></div>
                    <div className="pattern-2 shape-img-14"></div>
                </div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Deal book</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="scrapListingd blog-grid">
                <div className="auto-container">
                    <div className="titleSec mb_50 centred">
                        <h2>Deal Book</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 sidebar-side mb-5">
                            <div className="blog-sidebar default-sidebar">
                                <div className="sidebar-widget search-widget">
                                    <div className="search-form">
                                        <div className="form-group">
                                            <input type="search" name="search-field" placeholder="Search..." onChange={(e) => handleChanageFilter('search', e.target.value)} required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 sidebar-side">
                            <div className="blog-sidebar default-sidebar">
                                <div className="sidebar-widget category-widget">
                                    <div className="widget-title centred">
                                        <h3>Categories</h3>
                                    </div>
                                    <div className="widget-content">
                                        <ul className="category-list clearfix">
                                        {scrapcatlist.map((item, index) => (
                                            <li key={index} onClick={() => {
                                                handlechangeCategory(item._id);
                                            }}>
                                                <Link to="#!"><span> {item.name}</span></Link>
                                            </li> 
                                        ))}
                                                                                      
                                        </ul>
                                    </div>
                                    {/* <div className='priceSec widget-content'>
                                        <div className='priceBlk'>
                                            <div className='priceBlk1'>
                                                <label htmlFor="minPrice">Min Price:</label>
                                                <div className='input-group'>
                                                    <input
                                                        type="number"
                                                        id="minPrice"
                                                        min="0"
                                                        max="1000"
                                                        value={minPrice}
                                                        onChange={handleMinPriceChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='priceBlk2'>
                                                <label htmlFor="maxPrice">Max Price:</label>
                                                <div className='input-group'>
                                                <input
                                                    type="number"
                                                    id="maxPrice"
                                                    min="0"
                                                    max="1000"
                                                    value={maxPrice}
                                                    onChange={handleMaxPriceChange}
                                                />
                                                </div>
                                            </div>
                                        </div>

                                        <p>Selected Range: {minPrice} - {maxPrice}</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-12">
                            <div className="row clearfix">
                                {scraplist.length > 0 ? (
                                    scraplist.map((item, index) => (
                                        <div className="col-lg-12 col-md-12 col-sm-12 news-block" key={index}>
                                            <div
                                                className="news-block-two mb-4 wow fadeInUp animated"
                                                data-wow-delay="00ms"
                                                data-wow-duration="1500ms"
                                            >
                                                <div className="inner-box mb-0">
                                                    <div className="row clearfix">
                                                        <div className="col-lg-4 col-md-12 col-sm-12">
                                                            <div className="image-box">
                                                                <figure className="image">
                                                                    <Link to={`/scrap-details/${item.slug}`}>
                                                                        <img src={item.image} alt="" />
                                                                    </Link>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-12 col-sm-12">
                                                            <div className="lower-content">
                                                                <div className="d-flex justify-content-between pb-3">
                                                                    <span className="category">{item.categoryID.name}</span>
                                                                    <Link to={`/scrap-details/${item.slug}`} className="theme-btn btn-two"><span className="fs_14 p-b">Read More</span></Link>
                                                                    {/* <span className="avalable bg-green">available</span> */}
                                                                </div>
                                                                <h4>{item.name.slice(0, 100)} ...</h4>
                                                                <p>
                                                                <ReactShowMoreText
                                                                    lines={2}
                                                                    more="Show more"
                                                                    less="Show less"
                                                                    className="content-css"
                                                                    anchorClass="show-more-less-clickable"
                                                                    // onClick={this.executeOnClick}
                                                                    expanded={false}
                                                                    // width={180}
                                                                    truncatedEndingComponent={'... '}
                                                                >
                                                                    {parse(item.description)}
                                                                </ReactShowMoreText>
                                                                </p>
                                                                {/* <div className="d-flex justify-content-between align-items-center">
                                                                    <h6 className="text-green">Price: {item.price}</h6>
                                                                    
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    ) :
                                    (
                                       <div style={{textAlign: "center:"}}> No scrap found </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="fpagination-wrapper centred pb-3">
                        <ul className="fpagination clearfix">
                            <li><a href="#!" onClick={handlePagePrevious}><i className="far fa-angle-left"></i></a></li>
                            {[...Array(pageCount)]?.map((item, key) => {
                                    return (    
                                <li><a href="#!" key={key}
                                    onClick={(e) => handlePage(key + 1)}
                                    className={`${skip === key ? 'current' : ''}`}>{key + 1}</a></li>
                                            )
                            })}
                            <li><a href="#!" onClick={handlePageNext}><i className="far fa-angle-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    <Footer />
    </>
    )
}
export default ScrapList;
