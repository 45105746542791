import React from 'react'
import MultiStepForm from '../buyer/MultiStepForm'

const OnboardingWizard = () => {
  return (
    <div className='pt-3' style={{
      backgroundColor: "#F5F5F5",
      backgroundImage:"url('/onboarding-page.svg')",
      backgroundRepeat:"no-repeat",
      backgroundSize:"cover",
      backgroundPosition:"center",
      minHeight:"100vh"
    }}>
    <h4 style={{
      color:"#000248"
    }} className='text-center pb-3'>ONBOARDING</h4>
    <div style={{padding:"70px",paddingTop:"0px"}}>
      <MultiStepForm/>
    </div>
    </div>
  )
}

export default OnboardingWizard
