import React, { useEffect } from "react";
import Topbar from "../../components/Sidebar/Topbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Link } from "react-router-dom";
import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Footer from "../../components/Sidebar/Copyright";
import { FaSearch } from "react-icons/fa";
import { PieChart } from "react-minimal-pie-chart";
import BaseService from "../../config/axiosService";
import uploadFiles from "../../helpers/uploadFile";
import { LoadingOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { FaPlus } from "react-icons/fa";
import {
  CDBSidebar,
  CDBSidebarHeader,
  CDBSidebarMenuItem,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarSubMenu,
  CDBSidebarFooter,
} from "cdbreact";

const projectStatusData = [
  {
    ProjectID: "ABC001",
    Location: "Delhi",
    ProjectDetails: "Disposal of South Delhi Garments Showroom",
    ProjectStatus: "Contract Signing stage",
  },
  {
    ProjectID: "ABC002",
    Location: "Noida, Uttar Pradesh",
    ProjectDetails: "Disposal of old Cloths",
    ProjectStatus: "Inspection done",
  },
  {
    ProjectID: "ABC003",
    Location: "Chennai, Tamil Nadu",
    ProjectDetails: "Disposal of Factory Building including Land",
    ProjectStatus: "Tendering process scheduled",
  },
  {
    ProjectID: "ABC004",
    Location: "Kolkata, West Bengal",
    ProjectDetails: "Disposal of Showroom at New Market",
    ProjectStatus: "Physical verification done",
  },
  {
    ProjectID: "ABC005",
    Location: "Mumbai, Maharashtra",
    ProjectDetails: "Disposal of Factory",
    ProjectStatus: "Under valuation done",
  },
];

const ProjectStatus = () => {
  const [modalShow, setModalShow] = useState(false);
  const [option1, setOption1] = useState(false);
  const [option2, setOption2] = useState(false);
  const [option3, setOption3] = useState(false);
  const [selected, setSelected] = useState("on-going");
  const [viewModal, setViewModal] = useState(false);
  const [viewSelected, setViewSelected] = useState(null);
  const [addressData, setAddressData] = useState([]);
  const [projectManagers, setProjectManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  // project form data

  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const [valuationDoc, setValuationDoc] = useState(null);
  const [valuationMedias, setValuationMedias] = useState([]);
  const [project, setProject] = useState(null);
  const [physicalVerificationDoc, setPhysicalVerificationDoc] = useState(null);
  const [physicalVerificationMedias, setPhysicalVerificationMedias] = useState(
    [],
  );
  const [others, setOthers] = useState("");

  const fetchAddress = async () => {
    try {
      const response = await BaseService.get("/getaddressbookbyuserid");
      setAddressData(response.data.addressBooks);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectManagers = async () => {
    try {
      const response = await BaseService.get("/api/v2/team/project-managers");
      setProjectManagers(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e, setFileState) => {
    const files = e.target.files;
    setFileState(files);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const valuationDocPaths = valuationDoc
      ? await uploadFiles(valuationDoc)
      : [];
    const valuationMediaPaths = valuationMedias.length
      ? await uploadFiles(valuationMedias)
      : [];
    const physicalVerificationDocPaths = physicalVerificationDoc
      ? await uploadFiles(physicalVerificationDoc)
      : [];
    const physicalVerificationMediaPaths = physicalVerificationMedias.length
      ? await uploadFiles(physicalVerificationMedias)
      : [];

    const data = {
      location,
      description,
      projectManager,
      valuationDoc: valuationDocPaths[0] || null,
      valuationMedias: valuationMediaPaths,
      physicalVerificationDoc: physicalVerificationDocPaths[0] || null,
      physicalVerificationMedias: physicalVerificationMediaPaths,
      others: option3 ? others : "",
    };

    BaseService.post("/api/v2/project", data)
      .then((response) => {
        console.log(response);
        setModalShow(false);
        toast.success("Project added successfully");
        fetchProjects();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchProjects = () => {
    BaseService.get("/api/v2/project")
      .then((response) => {
        setProjects(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchAddress();
    fetchProjectManagers();
  }, []);

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div>
      <Topbar />
      <container-fluid className="mb-4">
        <Row>
          <Col
            lg="2"
            md="2"
            sm="4"
            style={{ background: "#2A3FA0", paddingRight: "0" }}
          >
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8">
            <div className="tophd">
              <Link to="/project-status">Project Status</Link>
            </div>
            {/* Search Box */}
            <Row>
              <Col lg="6" md="6" sm="6">
                <div className="d-flex align-items-center form-control gap-2">
                  <FaSearch />
                  <input
                    type="text"
                    className="text-grey"
                    placeholder="Search"
                  />
                </div>
                {/* search icon */}
              </Col>
            </Row>
            {/* three tabs 1.on-going 2.completed 3.all project and on the right
            side there will be a add new button */}
            <Row className="mt-3">
              <Col lg="6" md="6" sm="6">
                <ul className="nav nav-tabs gap-2">
                  <li
                    onClick={() => setSelected("on-going")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "on-going" ? "nav-link active" : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#on-going"
                    >
                      On Going
                    </a>
                  </li>
                  <li
                    onClick={() => setSelected("completed")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "completed"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#completed"
                    >
                      Completed
                    </a>
                  </li>
                  <li
                    onClick={() => setSelected("all-project")}
                    className="nav-item"
                  >
                    <a
                      className={
                        selected === "all-project"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-bs-toggle="tab"
                      href="#all-project"
                    >
                      All Project
                    </a>
                  </li>
                </ul>
              </Col>
              <Col lg="6" md="6" sm="6">
                <Link to="/add-new-project">
                  <Button variant="warning" className="pull-right">
                    <FaPlus />
                  </Button>
                </Link>
              </Col>
            </Row>

            <Card className="">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Project Status</Card.Title>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="">
                <Table className="table-hover table-striped" size="sm">
                  <thead>
                    <tr>
                      <th className="">#</th>
                      <th>Project ID</th>
                      <th>Location</th>
                      <th>Project Details</th>
                      <th>Project Status (Last)</th>
                      <th>Approval Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.length ? (
                      projects.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td className="">{index + 1}</td>
                            <td>{item.projectId}</td>
                            <td>{item.location?.locationName ?? ""}</td>
                            <td>{item.description}</td>
                            <td>
                              <Badge>{item.status}</Badge>
                            </td>
                            <td>
                            {
                              item.isApprovedByAdmin ? (
                                <Badge variant="success">Approved</Badge>
                              )
                              : (
                                <Badge variant="danger">Pending</Badge>
                              )
                            }
                            </td>
                            <td>
                              <Button
                                variant="info"
                                size="sm"
                                style={{
                                  background: "#2A3FA0",
                                  border: "0",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setViewSelected(item);
                                  setViewModal(true);
                                }}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="5">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal
          show={viewModal}
          onHide={() => setViewModal(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Card>
            <Card.Header>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <p className="text-white">
                    Project Description :{" "}
                    <strong>{viewSelected?.ProjectDetails}</strong>
                  </p>
                </Col>
                <Col lg="6" md="6" sm="6">
                  <span
                    variant="danger"
                    style={{
                      cursor: "pointer",
                    }}
                    className="pull-right btn-sm"
                    onClick={() => setViewModal(false)}
                  >
                    X
                  </span>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Row>
                  <Col lg="3" md="4" sm="6">
                    <p>Project Id</p>
                    <h3>123123</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>Location</p>
                    <h3>Delhi</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>Current Status</p>
                    <h3>In-Progress</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>Manager</p>
                    <h3>Krishna</h3>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col lg="3" md="4" sm="6">
                    <p>
                      Total Receivable / <br /> (Payable)
                    </p>
                    <h3>INR 231 Lac</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>
                      Net Paid / <br /> (Received)
                    </p>
                    <h3>INR 231 Lac</h3>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <p>Balance Outstanding Payable / (Receivable)</p>
                    <h3>INR 231 Lac</h3>
                    <a href="#" className="text-link">
                      View full ledger
                    </a>
                  </Col>
                  <Col lg="3" md="4" sm="6">
                    <PieChart
                      totalValue={100}
                      label={({ dataEntry }) => dataEntry.value + "%"}
                      labelStyle={{
                        color: "white",
                        fontSize: "12px",
                      }}
                      style={{
                        width: "50%",
                      }}
                      data={[
                        { title: "One", value: 15, color: "#0F9ED5" },
                        { title: "Two", value: 85, color: "#4EA72E" },
                      ]}
                    />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md={4}>
                    <CDBSidebar>
                      <CDBSidebarContent>
                        <CDBSidebarMenu>
                          <CDBSidebarMenuItem icon="th-large">
                            Inspection
                          </CDBSidebarMenuItem>
                          <CDBSidebarMenuItem icon="sticky-note">
                            Valuation
                          </CDBSidebarMenuItem>
                          <CDBSidebarMenuItem
                            icon="credit-card"
                            iconType="solid"
                          >
                            Physical Verification
                          </CDBSidebarMenuItem>
                          <CDBSidebarMenuItem
                            icon="credit-card"
                            iconType="solid"
                          >
                            Eauction
                          </CDBSidebarMenuItem>
                        </CDBSidebarMenu>
                      </CDBSidebarContent>
                    </CDBSidebar>
                  </Col>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </Modal>

        <Modal
          onHide={() => setModalShow(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
        >
          <Modal.Header closeButton>
            <Modal.Title as="h6" id="contained-modal-title-vcenter">
              Add New Project
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="bookingForm" onSubmit={handleSubmit}>
              <div className="card-body">
                <div className="row mt-4">
                  <div className="col-md-6 pb-3">
                    <label htmlFor="location">Select Location</label>
                    <select
                      className="form-control"
                      id="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      required
                    >
                      <option value="">Select Location</option>
                      {addressData.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.locationName}
                        </option>
                      ))}
                    </select>
                    <span>
                      If not in the list,{" "}
                      <Link to="/seller-branches">click here</Link> to add new
                    </span>
                  </div>
                  <div className="col-md-6 pb-3">
                    <label htmlFor="projectManager">
                      Select Project Manager
                    </label>
                    <select
                      className="form-control"
                      id="projectManager"
                      value={projectManager}
                      onChange={(e) => setProjectManager(e.target.value)}
                      required
                    >
                      <option value="">Select Project Manager</option>
                      {projectManagers.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.username} / {item.email} / {item.phone}
                        </option>
                      ))}
                    </select>
                    <span>
                      If not in the list, <Link to="/staff">click here</Link> to
                      add new
                    </span>
                  </div>
                  <div className="col-md-12 pb-3">
                    <label htmlFor="description">
                      Detailed Description of the Project
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={12}
                      required
                    ></textarea>
                  </div>
                  <div className="col-md-12 pb-3">
                    <label htmlFor="">Service Looking for</label>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onClick={() => setOption1(!option1)}
                        id="valuationCheckbox"
                        value="valuation"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="valuationCheckbox"
                      >
                        Valuation
                      </label>
                    </div>
                    {option1 && (
                      <div className="border d-flex flex-column gap-2 align-items-start p-2 rounded-2 mb-2">
                        <label>Valuation Document</label>
                        <input
                          type="file"
                          onChange={(e) => handleFileChange(e, setValuationDoc)}
                        />
                        <label>Valuation Media (Images/Videos)</label>
                        <input
                          type="file"
                          multiple
                          onChange={(e) =>
                            handleFileChange(e, setValuationMedias)
                          }
                        />
                      </div>
                    )}
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onClick={() => setOption2(!option2)}
                        id="physicalVerificationCheckbox"
                        value="physicalVerification"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="physicalVerificationCheckbox"
                      >
                        Physical Verification
                      </label>
                    </div>
                    {option2 && (
                      <div className="border d-flex flex-column gap-2 align-items-start p-2 rounded-2 mb-2">
                        <label>Physical Verification Document</label>
                        <input
                          type="file"
                          onChange={(e) =>
                            handleFileChange(e, setPhysicalVerificationDoc)
                          }
                        />
                        <label>
                          Physical Verification Media (Images/Videos)
                        </label>
                        <input
                          type="file"
                          multiple
                          onChange={(e) =>
                            handleFileChange(e, setPhysicalVerificationMedias)
                          }
                        />
                      </div>
                    )}
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onClick={() => setOption3(!option3)}
                        id="othersCheckbox"
                        value="others"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="othersCheckbox"
                      >
                        If desired service is not in the above list, please
                        describe here
                      </label>
                    </div>
                    {option3 && (
                      <div>
                        <textarea
                          className="form-control"
                          id="others"
                          value={others}
                          onChange={(e) => setOthers(e.target.value)}
                          rows={12}
                        ></textarea>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary"
                style={{
                  backgroundColor: "#2A3FA0",
                  border: "none",
                  width: "200px",
                }}
                type="submit"
              >
                {loading ? <LoadingOutlined /> : "Submit"}
              </button>
            </form>
          </Modal.Body>
        </Modal>
        <Footer />
      </container-fluid>
    </div>
  );
};

export default ProjectStatus;
