import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import toast from "react-hot-toast";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <footer className="main-footer bglight">
      <div className="footer-top">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 footer-column">
              <div className="border-end">
                <div className="footer-widget logo-widget mr_35">
                  <figure className="footer-logo">
                    <Link to="/">
                      <img
                        src={logo}
                        alt="Get Max value"
                        style={{ height: "75px" }}
                      />
                    </Link>
                  </figure>
                  <div className="text-box text-justify">
                    <p>
                      Efficiently managing scrap involves creating a sustainable
                      ecosystem of collection, recycling, and repurposing. Key
                      components include sorting facilities, recycling centers,
                      waste-to-energy plants, responsible disposal, circular
                      economy practices, and public awareness campaigns.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 col-6 footer-column">
              <div className="footer-widget links-widget ml_5">
                <div className="widget-title">
                  <h4>Important Links</h4>
                </div>
                <div className="widget-content">
                  <ul className="links-list clearfix">
                    <li onClick={scrollToTop}>
                      <Link
                        onClick={() => {
                          toast.error("Coming Soon!!!");
                        }}
                        to="#"
                      >
                        Deal Book
                      </Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link
                        onClick={() => {
                          toast.error("Coming Soon!!!");
                        }}
                        to="#"
                      >
                        Media{" "}
                      </Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link
                        onClick={() => {
                          toast.error("Coming Soon!!!");
                        }}
                        to="#"
                      >
                        Blog
                      </Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link
                        onClick={() => {
                          toast.error("Coming Soon!!!");
                        }}
                        to="#"
                      >
                        FAQ'S
                      </Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 footer-column">
              <div className="footer-widget links-widget ml_60 mr_70">
                <div className="widget-title">
                  <h4>Usefull Links</h4>
                </div>
                <div className="widget-content">
                  <ul className="links-list clearfix">
                    <li onClick={scrollToTop}>
                      <Link to="/terms-and-condition">Terms and condition</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/payment-and-refund-policy">
                        Payment and Refund
                      </Link>
                    </li>
                    <li onClick={scrollToTop}>
                      <Link to="/disclaime">Disclaimer</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-12 col-12 footer-column">
              <div className="footer-widget contact-widget">
                <div className="widget-title">
                  <h4>Get In Touch</h4>
                </div>
                <div className="widget-content">
                  <ul className="info-list mb_20 clearfix">
                    <li>
                      <i className="icon-3"></i>Work: I - 35B, First Floor,
                      Lajpat Nagar – 2, New Delhi – 110 024 <br /> Registered
                      Office – G 1504, Great Value Sharanam, Sector 107, Gautam
                      Budh Nagar, Noida, Uttar Pradesh
                    </li>
                    <li>
                      <i className="icon-2"></i>Email:{" "}
                      <Link to="mailto:support@getmaxvalue.in">
                        support@getmaxvalue.in
                      </Link>
                    </li>
                    <li>
                      <i className="icon-4"></i>Phone:{" "}
                      <Link to="tel:+91-8588840800">+91-8588840800</Link>
                    </li>
                  </ul>
                  {/* <ul className="social-links clearfix">
                    <li>
                      <Link to="#!">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#!">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#!">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#!">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom centred">
        <div className="auto-container">
          <div className="copyright">
            <p>
              Copyright © 2023{" "}
              <Link
                to="https://kriscent.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kriscent Techno Hub Pvt. Ltd.
              </Link>{" "}
              All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
    /* main - footer */
  );
};
export default Footer;
