import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import Topbar from "../../components/Sidebar/Topbar";
import Footer from "../../components/Sidebar/Copyright";
// react-bootstrap components
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Badge,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/Sidebar";
import BaseService from "../../config/axiosService";

const modules = [
  { name: "Auction", actions: ["view", "create"] },
  { name: "Account Statement", actions: ["view"] },
  { name: "My Team", actions: ["view", "edit", "delete", "create"] },
  { name: "Project", actions: ["view", "edit", "delete", "create"] },
  { name: "Roles", actions: ["view", "edit", "delete", "create"] },
  { name: "Profile", actions: ["view",   "create"] },
];

function Role() {
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState(
    modules.map((module) => ({
      module: module.name,
      actions: [],
    }))
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [visible, setVisible] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const handleCheckboxChange = (module, action, checked) => {
    setPermissions((prevPermissions) => {
      return prevPermissions.map((perm) => {
        if (perm.module === module) {
          if (checked) {
            if (!perm.actions.includes(action)) {
              perm.actions.push(action);
            }
          } else {
            perm.actions = perm.actions.filter((a) => a !== action);
          }
        }
        return perm;
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!name) {
        toast.error("Please enter role name");
        return;
      }

      const response = await BaseService.post("/api/v2/roles", {
        name,
        permissions,
      });

      if (response.status == 201) {
        toast.success("Role created successfully");
        fetchRoles();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  
  const fetchRoles = async () => {
    try {
      const response = await BaseService.get("/api/v2/roles");
      console.log(response.data.data);
      setRolesData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (roleId) => {
    try {
      const response = await BaseService.delete(`/api/v2/roles/${roleId}`);
      if (response.status === 200) {
        toast.success("Role deleted successfully");
        fetchRoles(); 
      } else {
        toast.error("Failed to delete the role. Please try again.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while deleting the role. Please try again.");
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);
  return (
    <>
      <Topbar />
      <Container fluid className="">
        <Row>
          <Col
            lg="2"
            md="2"
            sm="4"
            style={{ background: "#2A3FA0", paddingRight: "0" }}
          >
            <Sidebar />
          </Col>
          <Col lg="10" md="10" sm="8">
            <div className="tophd">
              Dashboard{" "}
              <span style={{ color: "#000" }}>
                <i className="fa fa-angle-right"></i>
              </span>{" "}
              <Link to="/staff-role">Role</Link>
            </div>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <Card.Title as="h6">Manage Role</Card.Title>
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <Button
                      variant="warning"
                      className="pull-right btn-sm addnew"
                      onClick={handleShow}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Role</th>
                      <th className="border-0">Module</th>
                      <th className="border-0">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolesData.map((role) => (
                      <tr>
                        <td>{role.name}</td>
                        <td>
                          {/* only show that perrsmission array module which doesn't have action array empty */}
                          {role.permissions
                            .filter((perm) => perm.actions.length)
                            .map((perm) => (
                              <div className="d-flex gap-3">
                                <b className="mb-3">{perm.module}</b>
                                <ul className="d-flex align-items-start gap-3 ">
                                  {perm.actions.map((action) => (
                                    <Badge>{action}</Badge>
                                  ))}
                                </ul>
                              </div>
                            ))}
                        </td>
                        <td>
                        <i className= "fa fa-trash" onClick={()=>handleDelete(role._id)}></i>

                        </td>
                      </tr>
                    ))}

                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </Container>
      <Modal show={show} centered size="lg">
        <Modal.Header closeButton onHide={handleClose} className="">
          <Modal.Title color="black" as="h6">
            Add New Role
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="bookingForm" onSubmit={handleSubmit}>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 mb-2">
                  <label for="subrole">Role</label>
                  <input
                    className="border rounded-2 p-2"
                    id="subrole"
                    placeholder="Enter Role"
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>
              <div className="row border-top pt-2">
                <div className="col-md-12">
                  <label htmlFor="exampleAmount">Permission</label>
                  <Table responsive striped hover>
                    <thead>
                      <tr>
                        <th>Module</th>
                        <th>View</th>
                        <th>Add</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modules.map((module, index) => (
                        <tr key={index}>
                          <td>{module.name}</td>
                          {["view", "create", "edit", "delete"].map(
                            (action) => (
                              <td key={action}>
                                {module.actions.includes(action) && (
                                  <Form.Check
                                    inline
                                    name="permission"
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        module.name,
                                        action,
                                        e.target.checked
                                      )
                                    }
                                  />
                                )}
                              </td>
                            )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              style={{
                backgroundColor: "#2A3FA0",
              }}
            >
              Save Role
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={visible} centered size="lg">
        <Modal.Header
          closeButton
          onClick={() => setVisible(false)}
          className="text-black"
        >
          <Modal.Title as="h6">Edit Role</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          <form className="bookingForm" onSubmit={handleUpdate}>
            <div class="card-body">
              <div class="row mt-4">
                <div class="col-md-6 pb-3">
                  <label for="subrole">Role</label>
                  <input
                    class="form-control"
                    id="subrole"
                    value={formData.subrole}
                    placeholder="role"
                    type="text"
                    onChange={(e) => handleChange("subrole", e.target.value)}
                    required
                  />
                  {errors.subrole && (
                    <span className="text-danger">{errors.subrole}</span>
                  )}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 pb-3">
                  <label for="exampleAmount">Permission:</label>
                  <Table responsive striped hover>
                    <thead>
                      <tr>
                        <th>Module</th>
                        <th>View</th>
                        <th>Add</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    {permissionData.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>{item.module.value}</td>

                            <td>
                              {!item.permission.view ? (
                                ""
                              ) : (
                                <Form.Check
                                  inline
                                  name="permission"
                                  type="checkbox"
                                  value={item.permission.view}
                                  checked={permissions.find((element) =>
                                    element === item.permission.view
                                      ? "checked"
                                      : ""
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e.target.value,
                                      e.target.checked
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {!item.permission.add ? (
                                ""
                              ) : (
                                <Form.Check
                                  inline
                                  type="checkbox"
                                  name="permission"
                                  value={item.permission.add}
                                  checked={permissions.find((element) =>
                                    element === item.permission.add
                                      ? "checked"
                                      : ""
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e.target.value,
                                      e.target.checked
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {!item.permission.edit ? (
                                ""
                              ) : (
                                <Form.Check
                                  inline
                                  type="checkbox"
                                  name="permission"
                                  value={item.permission.edit}
                                  checked={permissions.find((element) =>
                                    element === item.permission.edit
                                      ? "checked"
                                      : ""
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e.target.value,
                                      e.target.checked
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              {!item.permission.delete ? (
                                ""
                              ) : (
                                <Form.Check
                                  inline
                                  type="checkbox"
                                  name="permission"
                                  value={item.permission.delete}
                                  checked={permissions.find((element) =>
                                    element === item.permission.delete
                                      ? "checked"
                                      : ""
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e.target.value,
                                      e.target.checked
                                    )
                                  }
                                />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </Table>
                </div>
              </div>
            </div>
            <Button type="submit" variant="dark" onClick={handleClose}>
              Update Role
            </Button>
          </form>
        </Modal.Body> */}
      </Modal>
    </>
  );
}

export default Role;
