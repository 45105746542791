import React, { useEffect } from "react";
import Topbar from "../../components/Sidebar/Topbar";
import {
  Button,
  Card,
  Col,
  Modal,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import Sidebar from "../../components/Sidebar/SidebarBidder";
import Footer from "../../components/Sidebar/Copyright";
import { Link } from "react-router-dom";
import BaseService from "../../config/axiosService";
import Moment from "react-moment";
import toast from "react-hot-toast";

const NewOffering = () => {
  const [show, setShow] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showInterestModal, setShowInterestModal] = React.useState(false);
  const [type, setType] = React.useState("all");
  const [remarks, setRemarks] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [currentProjectId, setCurrentProjectId] = React.useState(null);

  const handleShow = () => {
    setShow(true);
  };

  const fetchProjects = async (searchQuery) => {
    // Fetch all projects
    try {
      let url = `/api/v2/project/vendor/new-offering?page=${currentPage}&limit=5&type=${type}`;
      if (searchQuery) {
        url = `/api/v2/project/vendor/new-offering?search=${searchQuery}&page=${currentPage}&limit=5&type=${type}`;
      }
      const response = await BaseService.get(url);
      console.log(response.data);
      setProjects(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInterestConfirmation = (projectId) => {
    setCurrentProjectId(projectId);
    setShowInterestModal(true);
  };

  const handleInterested = async () => {
    if (!currentProjectId) return;

    try {
      setLoading(true);
      const response = await BaseService.post(
        `/api/v2/project/vendor/new-offering/interested/${currentProjectId}`
      );
      console.log(response.data);
      toast.success(
        "Thanks for confirming for participation. Our team will connect you for further action."
      );
      fetchProjects(search);
    } catch (err) {
      console.log(err);
      toast.error("Failed to express interest. Please try again.");
    } finally {
      setLoading(false);
      setShowInterestModal(false);
    }
  };

  const handleNotInterested = async () => {
    if (!currentProjectId) return;

    try {
      setLoading(true);
      const response = await BaseService.post(
        `/api/v2/project/vendor/new-offering/uninterested/${currentProjectId}`,
        { remarks }
      );
      console.log(response.data);
      toast.success("Successfully marked as not interested in the project.");
      fetchProjects(search);
      setShow(false);
    } catch (err) {
      console.log(err);
      toast.error("Failed to mark as not interested. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects(search);
  }, [search, currentPage, type]);

  return (
    <div>
      <Topbar />
      <Row>
        <Col
          lg="2"
          md="2"
          sm="4"
          style={{ background: "#2A3FA0", minHeight: "78vh" }}
        >
          <Sidebar />
        </Col>
        <Col lg="10" md="10" sm="8" className="p-2 pt-0">
          <div className="tophd">
            <span style={{ color: "#000" }}></span>{" "}
            <Link to="/buyer-changepassword">New Offerings</Link>
          </div>
          <input
            type="text"
            placeholder="Search"
            className="searchfield form-control w-25"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <hr />
          <Col lg="6" md="6" sm="6" className="mt-6">
            <ul className="nav nav-tabs gap-2">
              <li onClick={() => setType("all")} className="nav-item">
                <a
                  className={type === "all" ? "nav-link active" : "nav-link"}
                  data-bs-toggle="tab"
                  href="#on-going"
                >
                  All Projects
                </a>
              </li>
              <li onClick={() => setType("interested")} className="nav-item">
                <a
                  className={
                    type === "interested" ? "nav-link active" : "nav-link"
                  }
                  data-bs-toggle="tab"
                  href="#completed"
                >
                  Interested Projects
                </a>
              </li>
              <li onClick={() => setType("uninterested")} className="nav-item">
                <a
                  className={
                    type === "uninterested" ? "nav-link active" : "nav-link"
                  }
                  data-bs-toggle="tab"
                  href="#all-project"
                >
                  Not Interested Projects
                </a>
              </li>
            </ul>
          </Col>
          <hr />

          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Row>
                <Col lg="6" md="6" sm="6">
                  <Card.Title as="h6">New Offerings</Card.Title>
                </Col>
              </Row>
            </Card.Header>
            <Table className="table-hover table-striped centered">
              <thead>
                <tr>
                  <th className="border-0">Date/Time</th>
                  <th className="border-0">Project ID</th>
                  <th className="border-0">Location</th>
                  <th className="border-0">Project Details</th>
                  <th className="border-0">Estimated Value</th>
                  <th className="border-0">Items</th>
                  <th className="border-0"></th>
                  <th className="border-0"></th>
                  <th className="border-0"></th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project, index) => {
                  return (
                    <tr>
                      <td>
                        <Moment date={project.createdAt} />
                      </td>
                      <td>{project.projectId}</td>
                      <td>{project.location?.locationName}</td>
                      <td>{project.description}</td>
                      <td>{project.estimatedValue ?? "N/A"}</td>
                      <td>{project.items ?? "N/A"}</td>
                      <td>
                        <Link
                          to={`/more-details/${project._id}`}
                          className="text-primary"
                        >
                          <Button
                            style={{
                              background: "#2A3FA0",
                              color: "#fff",
                              border: "none",
                            }}
                            variant="primary"
                          >
                            View
                          </Button>
                        </Link>
                      </td>
                      <td>
                        {project.vendorInterestStatus === "pending" ||
                        project.vendorInterestStatus === "rejected" ? (
                          <Button
                            onClick={() =>
                              handleInterestConfirmation(project._id)
                            }
                            disabled={loading}
                            style={{
                              background: "#2A3FA0",
                              color: "#fff",
                              border: "none",
                            }}
                            variant="success"
                          >
                            Interested
                          </Button>
                        ) : (
                          <Button
                            disabled
                            style={{
                              background: "#2A3FA0",
                              color: "#fff",
                              border: "none",
                            }}
                            variant="success"
                          >
                            Interested
                          </Button>
                        )}
                      </td>
                      <td>
                        {(project.vendorInterestStatus === "pending" ||
                          project.vendorInterestStatus === "approved") ? (
                          <Button
                            onClick={() => {
                              setShow(true);
                              setCurrentProjectId(project._id);
                            }}
                            disabled={loading}
                            variant="outline-danger"
                          >
                            Not Interested
                          </Button>
                        ): (
                          <Button
                            disabled
                            variant="outline-danger"
                          >
                            Not Interested
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          <Pagination className="mt-2 mb-4">
            <Pagination.Prev />
            {Array.from({ length: totalPages }, (_, index) => {
              return (
                <Pagination.Item
                  key={index}
                  className={currentPage === index + 1 ? "active" : ""}
                  onClick={() => {
                    setCurrentPage(index + 1);
                  }}
                >
                  {index + 1}
                </Pagination.Item>
              );
            })}
            <Pagination.Next />
          </Pagination>
        </Col>
      </Row>
      {/* Modal for not interested */}
      <Modal centered={true} show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title className="text-black">Not Interested</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>I am not interested because</p>
          <input type="text" className="form-control" />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              background: "#2A3FA0",
            }}
            variant="primary"
            onClick={handleNotInterested}
            disabled={loading}
          >
            {loading ? "Processing..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        show={showInterestModal}
        onHide={() => setShowInterestModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-black">Confirm Interest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I have read and understood the project, tender documents etc. and
          accept all terms and conditions, and I am Interested in participating
          in this project.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowInterestModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleInterested}
            disabled={loading}
            style={{
              background: "#2A3FA0",
              color: "#fff",
              border: "none",
            }}
          >
            {loading ? "Processing..." : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
};

export default NewOffering;
